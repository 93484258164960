import React, { useRef, useState } from 'react';
import styled from 'libs/styled';
import PropTypes from 'prop-types';
import Info from 'assets/icons/Info';
import Paragraph from 'components/text/Paragraph';

const InfoboxContainer = styled('div')`
    display: flex;
    align-items: center;
    cursor: help;
`;

const Infobox = styled('div')`
    width: 100%;
    max-width: 285px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--static-color-brand-secondary-light);
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    color: var(----static-color-primary);
    padding: 12px;
    white-space: normal;
    z-index: 3;
    margin-top: 4px;
`;

const Toolip = ({ content, closeDelay = 250 }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const closeDelayTimeout = useRef();

    const handleMouseEnter = () => {
        if (closeDelayTimeout.current) {
            clearTimeout(closeDelayTimeout.current);
        }
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        closeDelayTimeout.current = setTimeout(() => {
            setTooltipVisible(false);
        }, closeDelay);
    };

    const handleClick = () => {
        if (closeDelayTimeout.current) {
            clearTimeout(closeDelayTimeout.current);
        }
        setTooltipVisible(prevState => !prevState);
    };

    return (
        <InfoboxContainer
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleClick()}
        >
            <Info width="12px" height="12px" color="var(--static-color-light-five)" />
            {tooltipVisible && (
                <Infobox>
                    <Paragraph fontKeys={['NB International/14_120']} as="span">
                        {content}
                    </Paragraph>
                </Infobox>
            )}
        </InfoboxContainer>
    );
};

Toolip.propTypes = {
    closeDelay: PropTypes.number,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Toolip;
