import Arrow from 'assets/icons/Arrow';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import RecursiveMenuMobileView from './RecursiveMenuMobileView';
import { fadeAnimation } from 'Header/Default/Menu/menuStyledComponents';
import styled from 'libs/styled';

const Button = styled('button', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    display: flex;
    align-items: center;
    width: 100%;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

const MenuItem = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })};
`;

const SubMenu = ({
    activeSubMenu,
    closeMenu = () => {},
    fadeIn,
    fadeOut,
    menu = [],
    menuItemTextStyles = {},
    setActiveSubMenu = () => {},
    totalAnimationDuration,
}) => {
    if (menu?.length < 1) {
        return null;
    }

    return menu.map(({ highlight = false, label = '', link = {}, menuType = 'link', submenu = {} }, index) => {
        switch (menuType) {
            case 'link':
                return label && link.to ? (
                    <MenuItem
                        color={highlight ? 'var(--dynamic-color-active)' : 'currentColor'}
                        fadeIn={fadeIn}
                        fadeOut={fadeOut}
                        key={link.to + index}
                        totalAnimationDuration={totalAnimationDuration}
                        {...menuItemTextStyles}
                    >
                        <Link to={link.to} onClick={closeMenu}>
                            {label}
                        </Link>
                    </MenuItem>
                ) : null;
            case 'submenu':
                return label && submenu ? (
                    <MenuItem key={label + index} {...menuItemTextStyles}>
                        {label && (
                            <Button
                                color={highlight ? 'var(--dynamic-color-active)' : 'currentColor'}
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                totalAnimationDuration={totalAnimationDuration}
                                type="button"
                                onClick={() => setActiveSubMenu(index)}
                                {...menuItemTextStyles}
                            >
                                {label}
                                <Arrow color="currentColor" height="16px" ml="auto" width="16px" />
                            </Button>
                        )}
                        <RecursiveMenuMobileView
                            closeMenu={closeMenu} // Close the menu completely
                            closeSubMenu={setActiveSubMenu} // By setting setActiveSubMenu to null in the next level we can "move up" one level
                            isActive={activeSubMenu === index}
                            label={label}
                            linksType={submenu.linksType}
                            menuImages={submenu.primaryMenuImages}
                            primaryMenuHeading={submenu.primaryMenuHeading}
                            primarySubMenu={submenu.primaryMenuLinks}
                            totalAnimationDuration={totalAnimationDuration}
                        />
                    </MenuItem>
                ) : null;
            default:
                return null;
        }
    });
};

SubMenu.propTypes = {
    activeSubMenu: PropTypes.number,
    closeMenu: PropTypes.func,
    fadeIn: PropTypes.bool,
    fadeOut: PropTypes.bool,
    highlight: PropTypes.bool,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.object,
            menuType: PropTypes.string,
            subMenu: PropTypes.array,
        })
    ),
    menuItemTextStyles: PropTypes.object,
    setActiveSubMenu: PropTypes.func,
    totalAnimationDuration: PropTypes.number,
};

export default SubMenu;
