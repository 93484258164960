import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const PlusIcon = ({ color = colors.black, height = '100%', width = '10px', ...rest }) => (
    <SVG width={width} height={height} fill={color} viewBox="0 0 10 10" {...rest}>
        <Path
            clipRule="evenodd"
            d="M4.375 5.625H0v-1.25h4.374V0h1.25v4.374H10v1.25H5.625V10h-1.25V5.625Z"
            fill={color}
            fillRule="evenodd"
        />
    </SVG>
);

PlusIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default PlusIcon;
