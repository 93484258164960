import Breadcrumbs from 'components/Breadcrumbs';
import InformationSection from './InformationSection';
import ProductImages from './ProductImages';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import contentSpacing from 'config/theme/contentSpacing';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;

    ${above.desktopSm} {
        flex-direction: row;
        margin: 0 ${contentSpacing[2]};
    }
`;

const Div = styled('div')``;

const ProductHero = ({
    basketProductData = {},
    breadcrumbs = [],
    comingSoon = false,
    images = [],
    inStock = false,
    name = '',
    price = '',
    relatedVariationAttributes = [],
    sku = '',
    usps = [],
    variantData = {},
    variationId = '',
    handleSeeMoreButtonClick = () => null,
    ...rest
}) => {
    const { mobileAndGap, desktopAndGap } = useHeaderHeights();

    return (
        <Div marginTop={[`${mobileAndGap + 12}px`, null, null, null, `${desktopAndGap + 12}px`]} {...rest}>
            <Breadcrumbs
                currentTitle={name}
                items={breadcrumbs}
                marginBottom="12px"
                marginLeft={['0', null, null, null, null, '16px']}
            />
            <Wrapper>
                <ProductImages key={sku} images={images} sku={sku} />
                <InformationSection
                    basketProductData={basketProductData}
                    comingSoon={comingSoon}
                    inStock={inStock}
                    name={name}
                    price={price}
                    relatedVariationAttributes={relatedVariationAttributes}
                    usps={usps}
                    variantData={variantData}
                    variationId={variationId}
                    handleSeeMoreButtonClick={handleSeeMoreButtonClick}
                />
            </Wrapper>
        </Div>
    );
};

ProductHero.propTypes = {
    basketProductData: PropTypes.object,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    comingSoon: PropTypes.bool,
    handleSeeMoreButtonClick: PropTypes.func,
    images: PropTypes.array,
    inStock: PropTypes.bool,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
        isOnSale: PropTypes.bool,
        priceWithCurrency: PropTypes.string.isRequired,
        salePriceWithCurrency: PropTypes.string,
    }),
    relatedVariationAttributes: PropTypes.array,
    sku: PropTypes.string,
    usps: PropTypes.array,
    variantData: PropTypes.object,
    variationId: PropTypes.string,
};

export default ProductHero;
