import { ADD_THEME, LOCK_SCROLL, REMOVE_THEME, RESET_THEME, UNLOCK_SCROLL } from './constants';

export const lockScroll = () => dispatch => {
    dispatch({
        type: LOCK_SCROLL,
    });
};

export const unlockScroll = () => dispatch => {
    dispatch({
        type: UNLOCK_SCROLL,
    });
};

export const addTheme = theme => dispatch => {
    dispatch({
        type: ADD_THEME,
        payload: {
            theme,
        },
    });
};

export const removeTheme = theme => dispatch => {
    dispatch({
        type: REMOVE_THEME,
        payload: {
            theme,
        },
    });
};

export const resetTheme = theme => dispatch => {
    dispatch({
        type: RESET_THEME,
        payload: {
            theme,
        },
    });
};
