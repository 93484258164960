interface Suggestion {
    nb_words: number;
    objectID: string;
    popularity: number;
    query: string;
    _highlightResult: {
        query: {
            matchedWords: String[];
            matchLevel: string;
            value: string;
        };
    };
}

/**
 * Transform the algoliaSuggestionData returned from algolia
 *
 * @param {object} algoliaSuggestionData - Suggestion data from Algolia
 * @returns object
 */

export const transformSuggestion = (algoliaSuggestionData: Suggestion) => {
    if (!algoliaSuggestionData) {
        return null;
    }

    return {
        highlightResult: { ...algoliaSuggestionData._highlightResult.query },
        nbWords: algoliaSuggestionData.nb_words,
        objectId: algoliaSuggestionData.objectID,
        popularity: algoliaSuggestionData.popularity,
        query: algoliaSuggestionData.query,
    };
};

/**
 * Transform suggestions data from Aligola Response
 *
 * @param {object} response - Response data from Algolia
 * @returns object
 */

export const transformAlgoliaSuggestionsResponse = (response: Record<string, any>) => {
    const clonedResponse = JSON.parse(JSON.stringify(response));

    if (clonedResponse.hits?.length) {
        clonedResponse.hits = clonedResponse.hits.map(suggestion => ({
            ...transformSuggestion(suggestion),
        }));
    }

    return clonedResponse;
};
