import React, { useEffect, useState } from 'react';

import Loading from 'assets/icons/Loading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const Wrapper = styled('div', { shouldForwardProp: prop => ['isLoading'].indexOf(prop) === -1 })`
    position: relative;

    svg {
        display: none;
    }

    ${({ isLoading }) =>
        isLoading &&
        `
        > *:not(svg) {
            opacity: 0.5;
        }

        svg {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    `}
`;

const Label = styled('label')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 12px;
    cursor: pointer;

    p {
        margin: 0;
    }

    input:checked ~ span {
        background-color: var(--dynamic-color-primary);
        border: 1px solid var(--dynamic-color-primary);
        box-shadow: inset 0 0 0 3px var(--dynamic-background-color-primary);
    }
`;

const Input = styled('input')`
    display: none;
`;

const RadioMarker = styled('span')`
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid var(--dynamic-color-primary);
    border-radius: 100%;
`;

const ErrorMessage = styled(Paragraph)`
    color: var(--dynamic-color-error);

    margin: 4px 0 0 24px;
`;

const StyledParagraph = styled(WysiwygParagraph)`
    line-height: 1;
    display: inline-block;

    a {
        color: inherit;
    }
`;

const Radio = ({
    className = '',
    defaultActive = '',
    error = '',
    handleChange = () => {},
    isLoading = false,
    items = [],
    itemStyling = {},
    name = '',
    ...rest
}) => {
    const [active, setActive] = useState(defaultActive);
    const fontStyles = styleObjectToProps('NB International/16_100');
    const errorFontStyles = styleObjectToProps('NB International/12');

    useEffect(() => {
        // Make sure that active is updated if defaultActive changes
        setActive(prev => {
            if (prev !== defaultActive) {
                return defaultActive;
            }

            return prev;
        });
    }, [defaultActive]);

    return (
        <Wrapper className={className} isLoading={isLoading} {...rest}>
            {items?.length > 0 &&
                items.map(item => (
                    <Label key={item.id} {...itemStyling}>
                        <Div
                            display="flex"
                            justifyContent={item.extraText ? 'space-between' : 'normal'}
                            lineHeight="1"
                            width="100%"
                            opacity={item.disabled ? 0.5 : 1}
                            textDecoration={item.disabled ? 'line-through' : 'none'}
                        >
                            <Wysiwyg
                                data={item.text}
                                textComponent={props => <StyledParagraph {...props} {...fontStyles} />}
                                tagComponents={{ url: TextLinkWysisyg }}
                            />
                            {item.extraText && (
                                <Wysiwyg
                                    data={item.extraText}
                                    textComponent={props => <StyledParagraph {...props} {...fontStyles} />}
                                    tagComponents={{ url: TextLinkWysisyg }}
                                />
                            )}
                        </Div>
                        <Div display="flex">
                            <Input
                                type="radio"
                                name={name}
                                disabled={isLoading || item.disabled}
                                checked={active === item.id}
                                onChange={() => {
                                    setActive(item.id);
                                    handleChange(item);
                                }}
                            />
                            <RadioMarker opacity={item.disabled ? 0.5 : 1} />
                        </Div>
                    </Label>
                ))}
            {error && <ErrorMessage {...errorFontStyles}>*{error.message}</ErrorMessage>}
            <Loading />
        </Wrapper>
    );
};

Radio.propTypes = {
    className: PropTypes.string,
    defaultActive: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    error: PropTypes.string,
    handleChange: PropTypes.func,
    isLoading: PropTypes.bool,
    itemStyling: PropTypes.object,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            extraText: PropTypes.wysiwygProp,
            id: PropTypes.string.isRequired,
            text: wysiwygProp.isRequired,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
};

export default Radio;
