import PropTypes from 'prop-types';
import React from 'react';
import SwitchInput from 'components/forms/SwitchInput';
import styled from 'libs/styled';

const Wrapper = styled('li')`
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    padding: 12px 0;
`;

const FilterSwitchOption = ({ filters = [], handleChange = () => {}, parentFilterKey = '' }) => (
    <Wrapper>
        {filters.map((item, index) => (
            <SwitchInput
                checked={item.selected}
                key={`${item.value}-${index}`}
                label={item.label}
                name={item.label}
                handleChange={() => handleChange(parentFilterKey, item.value)}
            />
        ))}
    </Wrapper>
);

FilterSwitchOption.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool })).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
};

export default FilterSwitchOption;
