import ArrowLink from 'components/text/ArrowLink';
import ArticleCard from 'components/ArticleCard';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const HeadingAndButtons = styled('div')`
    margin-bottom: 24px;

    ${above.tabletSm} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

const Grid = styled('div')`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px 8px;

    ${above.tabletSm} {
        gap: 24px 16px;
    }
`;

const Article = styled('article')`
    width: auto;
    max-width: none;
    flex: none;
    min-width: 0;
    grid-column: span 6;

    &:nth-of-type(4n + 1) {
        grid-column: span 12;
    }

    ${above.tabletSm} {
        grid-column: span 4;

        &:nth-of-type(4n + 1) {
            grid-column: span 4;
        }
    }
`;

const ArticleFlow = ({ articles = [], button = {}, heading, headingTag = 'h2' }) => (
    <Wrapper>
        <HeadingAndButtons>
            {heading && (
                <Heading
                    as={headingTag}
                    fontKeys={['Recife Display/32_120', null, null, null, 'Recife Display/40']}
                    width={['100%', null, 'calc(75% - 4px)']}
                >
                    {heading}
                </Heading>
            )}
            {button.label && button.to && (
                <ArrowLink display={['none', null, 'inline-flex']} to={button.to}>
                    {button.label}
                </ArrowLink>
            )}
        </HeadingAndButtons>
        <Grid>
            {articles.length > 0 &&
                articles.map(article => (
                    <Article key={article.id}>
                        <ArticleCard {...article} />
                    </Article>
                ))}
        </Grid>
        {button.label && button.to && (
            <ThemeButton display={['block', null, 'none']} marginTop="24px" theme="solid" to={button.to} width="100%">
                {button.label}
            </ThemeButton>
        )}
    </Wrapper>
);

ArticleFlow.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object).isRequired,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingTag: PropTypes.string,
};

export default ArticleFlow;
