import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { borderRadius } from 'config/theme/borderRadius';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Button = styled('button')`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    color: var(--static-color-secondary);
    background-color: var(--static-background-color-secondary);
    text-transform: uppercase;
`;

const QuickShopButton = ({ inStock = false, variationId, ...rest }) => {
    const { t } = useTranslation();
    const basket = useSelector(state => state.basket);
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');

    const [buttonState, setButtonState] = useState({
        success: false,
        loading: false,
        warning: false,
    });

    const getButtonText = buttonState => {
        if (buttonState.warning) {
            return 'checkout_basket.error';
        }

        if (buttonState.success) {
            return 'checkout_basket.added';
        }

        return isAboveTabletSm ? 'checkout_basket.add_to_basket' : 'checkout_basket.apply';
    };

    const addToCart = async quantity => {
        setButtonState({ ...buttonState, loading: true });

        try {
            const response = await basket.addToBasket(variationId, quantity, null);

            if (response.status === 200 || response.status === 201) {
                setButtonState({ ...buttonState, success: true, loading: false });
            } else {
                setButtonState({ ...buttonState, warning: true, success: false, loading: false });
            }
        } catch (error) {
            console.error(error);
            setButtonState({ ...buttonState, warning: true });
        } finally {
            setTimeout(
                () =>
                    setButtonState({
                        success: false,
                        loading: false,
                        warning: false,
                    }),
                2800
            );
        }
    };

    const getLoading = () => {
        return basket.isFetching || buttonState.loading;
    };

    const isLoading = getLoading();
    const quantity = 1;
    const buttonText = inStock ? t(getButtonText(buttonState)) : t('checkout_basket.out_of_stock');

    return (
        <Button
            borderBottomLeftRadius={borderRadius}
            borderBottomRightRadius={borderRadius}
            disabled={!inStock}
            onClick={() => {
                if (!isLoading && inStock) {
                    addToCart(quantity);
                }
            }}
            {...styleObjectToProps('NB International/12_120_7')}
            {...rest}
        >
            {buttonText}
        </Button>
    );
};

QuickShopButton.propTypes = {
    inStock: PropTypes.bool,
    variationId: PropTypes.string,
};

export default QuickShopButton;
