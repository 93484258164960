import BreadcrumbsStructuredData from 'components/metadata/structuredData/BreadcrumbsStructuredData';
import Items from 'components/Breadcrumbs/Items';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import colors from 'config/theme/colors';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')``;

// Standard Breadcrumbs component
const Breadcrumbs = ({
    activeColor = 'var(--dynamic-color-active)',
    currentTitle,
    displayFadeEffect = true,
    items = [],
    linkColor = 'var(--dynamic-color-primary)',
    ...rest
}) => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('breadcrumbs.start'), permalink: '/' },
        ...items,
        currentTitle && { title: currentTitle.replace(/<br \/>/g, '') },
    ];

    if (breadcrumbs.length < 2) {
        return null;
    }

    return (
        <>
            <BreadcrumbsStructuredData breadcrumbs={breadcrumbs} />
            <Wrapper {...rest}>
                <ScrollWrapper
                    startScrollAtEnd
                    display={['block', null, 'none']}
                    direction="horizontal"
                    displayFadeEffect={displayFadeEffect}
                    fadeEffectColor={colors.white}
                    fadeEffectLength="16px"
                    scrollContentProps={{
                        flexWrap: ['noWrap', null, 'wrap'],
                        display: 'inline-flex',
                        width: 'auto !important',
                        maxWidth: '100%',
                    }}
                >
                    <Items
                        activeColor={activeColor}
                        display="flex"
                        justifyContent="flex-end"
                        linkColor={linkColor}
                        list={breadcrumbs}
                        textStyles={{ ...styleObjectToProps('NB International/12_120_4'), lineHeight: '160%' }} // Prevents text from overflowing
                    />
                </ScrollWrapper>
                <Items
                    activeColor={activeColor}
                    display={['none', null, 'flex']}
                    flexWrap="wrap"
                    linkColor={linkColor}
                    list={breadcrumbs}
                    margin="0 2px"
                />
            </Wrapper>
        </>
    );
};

Breadcrumbs.propTypes = {
    activeColor: PropTypes.string,
    currentTitle: PropTypes.string,
    displayFadeEffect: PropTypes.bool,
    items: PropTypes.array.isRequired,
    linkColor: PropTypes.string,
};

export default Breadcrumbs;
