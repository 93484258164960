import Heading from 'components/text/Heading';
import ImageAndTextCard from 'components/cards/ImageAndTextCard';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import contentSpacing from 'config/theme/contentSpacing';
import { fadeAnimation } from 'Header/Default/Menu/menuStyledComponents';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { transformMenuLinksIntoColumns } from 'utils/dataTransformers/header';

const Div = styled('div')``;

const Wrapper = styled('section', {
    shouldForwardProp: prop => ['newViewTransition', 'isActive', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 48px 24px;
    overflow: visible;
    background: var(--dynamic-color-brand-secondary-light);
    border-radius: 0 0 12px 12px;

    // The delay for non-newTransitions is needed for the fadeIn- and fadeOut-animations to work properly
    ${({ newViewTransition, totalAnimationDuration }) =>
        newViewTransition
            ? `transition: transform ${totalAnimationDuration}ms ease-out;`
            : `transition: z-index 0ms linear ${totalAnimationDuration / 2}ms, 
                visibility 0ms linear ${totalAnimationDuration / 2}ms, 
                opacity 0ms linear ${totalAnimationDuration / 2}ms, 
                transform 0ms linear ${totalAnimationDuration / 2}ms;`}
`;

const AnimationWrapper = styled('div', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })};
`;

const MenuColumns = styled('div')`
    display: flex;
    gap: 0 120px;
`;

const MenuList = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 6px 0;
    padding: 0;
    margin: 0;
`;

const ImageItemWrapper = styled('ul')`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 16px;
    align-items: start;
`;

const ListWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: top;
`;

const Submenu = ({
    closeMenu = () => {},
    isActive = false,
    linksType = '',
    newViewTransition = false,
    primaryMenuHeading = '',
    primaryMenuImages = [],
    primaryMenuLinks = [],
    totalAnimationDuration,
    zIndex,
}) => {
    const fadeIn = !newViewTransition && isActive;
    const fadeOut = !newViewTransition && !isActive;

    const hasPrimaryMenuLinks = primaryMenuLinks?.length > 0;
    const hasPrimaryMenuImages = primaryMenuImages?.length > 0;

    const menuItemTextStyles = styleObjectToProps('NB International/14_120');

    const transformedLinksWithColumns = hasPrimaryMenuLinks
        ? transformMenuLinksIntoColumns(primaryMenuLinks)
        : undefined;

    return (
        <Wrapper
            isActive={isActive}
            newViewTransition={newViewTransition}
            opacity={isActive ? 1 : 0}
            px={contentSpacing}
            totalAnimationDuration={totalAnimationDuration}
            visibility={isActive ? 'visible' : 'hidden'}
            zIndex={zIndex}
        >
            <AnimationWrapper
                fadeIn={fadeIn}
                fadeOut={fadeOut}
                totalAnimationDuration={totalAnimationDuration}
                width="100"
            >
                <ListWrapper>
                    {(hasPrimaryMenuLinks || hasPrimaryMenuImages) && (
                        <MenuColumns
                            flexDirection={linksType === 'images' && hasPrimaryMenuImages ? 'column' : 'row'}
                            width="100%"
                        >
                            {linksType === 'links' &&
                                hasPrimaryMenuLinks &&
                                transformedLinksWithColumns.map((column, index) => (
                                    <MenuList key={index} margin="0" width="25%">
                                        {column?.map(({ label, link, menuType, submenu }) => (
                                            <Div
                                                key={label + link?.to}
                                                marginBottom="32px"
                                                width="100%"
                                                {...menuItemTextStyles}
                                            >
                                                <Div marginBottom="8px" onClick={closeMenu}>
                                                    <Link to={link.to}>
                                                        <Heading as="span" fontKeys="Recife Display/22_120">
                                                            {label}
                                                        </Heading>
                                                    </Link>
                                                </Div>
                                                {menuType === 'submenu' && submenu?.primaryMenuLinks?.length > 0 && (
                                                    <MenuList
                                                        borderLeft="1px solid var(--dynamic-color-brand-secondary-light)"
                                                        key={index}
                                                        margin="4px 0 4px 2px"
                                                        paddingLeft="16px"
                                                    >
                                                        {submenu.primaryMenuLinks.map(({ label, link }) => (
                                                            <Div
                                                                key={label + link?.to}
                                                                width="100%"
                                                                {...menuItemTextStyles}
                                                            >
                                                                <TextLinkUnderline to={link?.to} onClick={closeMenu}>
                                                                    {label}
                                                                </TextLinkUnderline>
                                                            </Div>
                                                        ))}
                                                    </MenuList>
                                                )}
                                            </Div>
                                        ))}
                                    </MenuList>
                                ))}
                            {linksType === 'images' && hasPrimaryMenuImages && (
                                <>
                                    {primaryMenuHeading && (
                                        <Heading
                                            as="span"
                                            fontKeys="Recife Display/40"
                                            marginBottom="24px"
                                            width="100%"
                                        >
                                            {primaryMenuHeading}
                                        </Heading>
                                    )}
                                    <ImageItemWrapper>
                                        {primaryMenuImages.map(({ label, link, image }) => (
                                            <Div as="li" key={link.to}>
                                                <ImageAndTextCard
                                                    handleClick={closeMenu}
                                                    imageBackgroundPosition={image.backgroundPosition}
                                                    imageBackgroundSize={image.backgroundSize}
                                                    imageUrl={image.url}
                                                    label={label}
                                                    to={link.to}
                                                />
                                            </Div>
                                        ))}
                                    </ImageItemWrapper>
                                </>
                            )}
                        </MenuColumns>
                    )}
                </ListWrapper>
            </AnimationWrapper>
        </Wrapper>
    );
};

Submenu.propTypes = {
    closeMenu: PropTypes.func,
    isActive: PropTypes.bool,
    linksType: PropTypes.string,
    newViewTransition: PropTypes.bool,
    primaryMenuHeading: PropTypes.string,
    primaryMenuImages: PropTypes.array,
    primaryMenuLinks: PropTypes.array,
    totalAnimationDuration: PropTypes.number,
    zIndex: PropTypes.number,
};

export default Submenu;
