import { SET_SUGGESTIONS } from './constants';

// The initial state of this store.
const initialState = {
    suggestions: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SUGGESTIONS:
            return {
                ...state,
                suggestions: action.suggestions,
            };

        default:
            return state;
    }
};
