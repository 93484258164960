/* eslint-disable camelcase */
import Helmet from 'react-helmet';
import React from 'react';
import { recursiveDeleteKeyByValue } from 'utils/object';
import { useSelector } from 'react-redux';

/**
 * ProductStructuredData for Sagaform
 * @version 1.1
 *
 * ProductStructuredData
 * @param {string} protocol - application.protocol string. always https
 * @param {string} domain - application.domain, current domain
 * @param {string} path - application.path, current path
 * @param {object} companyData - Building the organization data into the productstructured. passed down from views/product
 * @param {object} shopConfig - Shopconfig object, not always in use by all projects. But keep here as standard.
 * @param {object} product - product object build up the meta. Here it may need some kind of tweaking, the custom_attributes dosent always match, neither is the data in generall (not 100%).
 */

const ProductStructuredData = () => {
    const application = useSelector(
        state => state.application,
        (prev, next) =>
            prev.application?.domain !== next.application?.domain ||
            prev.application?.protocol !== next.application?.protocol ||
            prev.application?.path !== next.application?.path ||
            prev.application?.config !== next.application?.config ||
            prev.application?.shopConfig !== next.application?.shopConfig
    );

    const product = useSelector(
        state => state.page.data,
        (prev, next) => prev.page?.data !== next.page?.data
    );

    const { domain, protocol, path, shop_config: shopConfig, config } = application;

    const protocolPath = protocol ? `${protocol}://` : '';
    const localePath = path || '';
    const baseUrl = protocolPath + domain || '';
    const {
        brand,
        media,
        price: { price, discount },
        uri,
        custom_attributes: customAttributes,
        variations,
    } = product;

    // Organization data
    const companyData = config?.options?.company_data || {};
    const companyName = companyData.organization?.company_name || null;
    const companyLogo = companyData.organization?.logo?.url || null;

    // Base product variables
    let baseItemAvailability = false;
    const itemCondition = 'https://schema.org/NewCondition';
    const productName = product.name || null;
    const productSKU = product.sku || null;
    const productEAN = product.ean || null;
    const productUrl = `${baseUrl}${localePath}${uri}`;
    const productImage = media && media.length > 0 ? media.map(media => media.sizes.original.url) : null;
    const productPrice = discount ? price - discount : price;
    const productColors = customAttributes.sa_color?.display_text || null;
    const productMaterials = customAttributes.sa_materials?.values
        ? Object.keys(customAttributes.sa_materials?.values)
              .map(key => customAttributes.sa_materials?.values[key].display_text)
              .join(', ')
        : null;

    // TODO: possibly add the alternate seo meta desc as Hestra/Cura will have.
    const seoDescription = product.seo?.description || product.description || null;

    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    const priceValidUntil = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${
        date.getDate() + 1
    }`.slice(-2)}`;

    // Gender specific sites, hardcode the gender or changes data if needed
    const gender = customAttributes?.sa_gender?.display_name || null;
    const suggestedGender = gender
        ? {
              '@type': 'PeopleAudience',
              suggestedGender: gender,
          }
        : null;

    // Structured brand, product brand type
    const structuredBrand = brand
        ? {
              '@type': 'Brand',
              name: brand.name,
          }
        : null;

    // // Reviews
    // const structuredAggregateRating = reviewRating?.ratingValue
    //     ? {
    //           '@type': 'AggregateRating',
    //           ratingValue: reviewRating.ratingValue,
    //           bestRating: reviewRating?.bestRating || null,
    //           reviewCount: reviewRating?.reviewCount || null,
    //           worstRating: reviewRating?.worstRating || null,
    //       }
    //     : null;

    // const structuredReviews =
    //     reviews?.length > 0
    //         ? reviews.map(review => ({
    //               '@type': 'Review',
    //               author: review.author || null,
    //               datePublished: review.datePublished || null,
    //               reviewBody: review.reviewBody || null,
    //               reviewRating: {
    //                   '@type': 'Rating',
    //                   bestRating: review?.bestRating || null,
    //                   ratingValue: review?.ratingValue || null,
    //                   worstRating: 1,
    //               },
    //           }))
    //         : null;

    let baseProductSchema = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: productName,
        productID: productEAN,
        gtin13: productEAN,
        sku: productSKU,
        url: productUrl,
        image: productImage,
        description: seoDescription,
        brand: structuredBrand,
        itemCondition,
        audience: suggestedGender,
        // aggregateRating: structuredAggregateRating,
        // review: structuredReviews,
        offers: {
            '@type': 'Offer',
            url: productUrl,
            price: productPrice,
            priceCurrency: shopConfig ? shopConfig.currency : null,
            priceValidUntil,
            seller: {
                '@type': 'Organization',
                name: companyName,
                logo: companyLogo,
            },
        },
    };

    // Loop through variations to create structured data for each
    const productVariations = variations.map(variation => {
        // Constant variables
        const itemAvailability =
            variation.in_stock === false ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock';
        baseItemAvailability = baseItemAvailability || variation.in_stock !== false ? true : baseItemAvailability;
        const rawVariationMaterials = variation?.custom_attributes?.sa_materials?.values || null;

        // Overwrite general variables if possible
        const variationName = product.variant_name ? product.variant_name : `${productName}- ${variation.name}`;
        const variationImage =
            variation.media && variation.media.length > 0
                ? variation.media.map(media => media.sizes.original.url)
                : productImage;
        const variationMaterials = rawVariationMaterials
            ? rawVariationMaterials.map(v => v.display_name).join(', ')
            : productMaterials;
        const variationPriceObj = variation?.price || {};
        const variationPrice = variationPriceObj.discount
            ? variationPriceObj.price - variationPriceObj.discount
            : variationPriceObj.price;

        // Create structured data
        return {
            '@context': 'http://schema.org',
            '@type': 'ProductModel',
            name: variationName,
            productID: variation.ean || productEAN,
            gtin13: variation.ean || productEAN,
            sku: variation.sku || productSKU,
            image: variationImage,
            description: seoDescription,
            brand: structuredBrand,
            itemCondition,
            audience: suggestedGender,
            color: productColors,
            material: variationMaterials,
            // aggregateRating: structuredAggregateRating,
            // review: structuredReviews,
            offers: {
                '@type': 'Offer',
                name: variationName,
                url: productUrl,
                price: variationPrice ? variationPrice : productPrice,
                priceCurrency: shopConfig ? shopConfig.currency : null,
                priceValidUntil,
                itemCondition,
                availability: itemAvailability,
                seller: {
                    '@type': 'Organization',
                    name: companyName,
                },
            },
        };
    });

    // Set availability of baseSchema to InStock if atleast one variation is InStock.
    baseItemAvailability = baseItemAvailability ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';
    baseProductSchema.offers.availability = baseItemAvailability;

    // If there is only one variation, add the data to baseProductSchema instead.
    if (productVariations.length === 1) {
        baseProductSchema = { ...baseProductSchema, ...productVariations[0] };
        baseProductSchema['@type'] = 'Product';
        baseProductSchema.name = productName;

        // If there are several variations, add them as ProductModels
    } else {
        baseProductSchema.model = productVariations;
    }

    /*const video = get(product, 'custom_attributes.sa_promotion_video.desktop', null);
    let videoSchema = null;
    if (video) {
        videoSchema = {
            '@context': 'http://schema.org',
            '@type': 'VideoObject',
            contentUrl: video.url,
            // More data necessary before use
        };
    }*/

    // Remove null values from baseProductSchema and videoSchema
    recursiveDeleteKeyByValue(baseProductSchema, null);
    //recursiveDeleteKeyByValue(videoSchema, null);

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(baseProductSchema, null, 2)}</script>
            {/*videoSchema && <script type="application/ld+json">{JSON.stringify(videoSchema, null, 2)}</script>*/}
        </Helmet>
    );
};

export default ProductStructuredData;
