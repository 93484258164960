import Close from 'assets/icons/Close';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Button = styled('button')`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 48px;
    background: var(--dynamic-color-light-seven);
    color: var(--static-color-primary);

    cursor: pointer;

    ${media.hover} {
        &:hover {
            .remove-filter-text {
                color: var(--static-color-primary);
            }
        }

        &:hover {
            .close-icon {
                transform: rotate(90deg);
                .close-icon-path {
                    fill: var(--static-color-primary) !important;
                }
            }
        }

        &:active {
            .close-icon {
                transform: rotate(360deg);
            }
        }
    }

    ${({ active }) =>
        active &&
        `
        .close-icon {
                transform: rotate(45deg);
            }
    `}
`;

const RemoveFilterButton = ({ children, handleClick = () => {}, type = 'button', withIcon = false, ...rest }) => (
    <Button type={type} onClick={() => handleClick()} {...rest}>
        {withIcon && <Close mr="10px" transition={`transform ${transitions.primary.medium}`} width="8px" />}
        <Paragraph
            as="span"
            className="remove-filter-text"
            fontKeys="NB International/12_120_7"
            transition={`color ${transitions.primary.medium}`}
        >
            {children}
        </Paragraph>
    </Button>
);

RemoveFilterButton.propTypes = {
    handleClick: PropTypes.func,
    type: PropTypes.string,
    withIcon: PropTypes.bool,
};

export default RemoveFilterButton;
