import React, { useEffect, useRef, useState } from 'react';

import Heading from 'components/text/Heading';
import ProductCardMini from 'components/products/ProductCardMini';
import { basketProps } from 'utils/proptypes/basket';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    margin-bottom: 32px;
`;

const Products = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    border-top: 1px solid var(--static-opacity-color-secondary);

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;

const CheckoutBasket = ({ items = [] }) => {
    const heading = useSelector(state => state.page.data.checkout.basket?.heading);
    const totalNumberOfProducts = useSelector(state => state.basket.totalNumberOfProducts);
    const productCardRef = useRef();
    const [productCardHeight, setProductCardHeight] = useState();

    // Calculates the height of a product card to be able to show, for example, 2.5 cards.
    useEffect(() => {
        if (productCardRef.current) {
            setProductCardHeight(productCardRef.current.getBoundingClientRect()?.height);
        }
         
    }, [productCardRef]);

    if (!items) {
        return null;
    }

    return (
        <Wrapper>
            {heading && (
                <Heading
                    as={heading.type}
                    fontKeys={['Recife Display/24_100', null, 'Recife Display/32_120']}
                    m="24px 0 32px"
                >
                    {heading.text}
                    {` (${totalNumberOfProducts})`}
                </Heading>
            )}
            <Products maxHeight={[`${productCardHeight * 2.5}px`, null, null, null, `${productCardHeight * 4.5}px`]}>
                {items.map((product, index) => (
                    <div key={`${product.id}-${index}`} ref={productCardRef}>
                        <ProductCardMini last={index === items.length - 1} {...product} />
                    </div>
                ))}
            </Products>
        </Wrapper>
    );
};

CheckoutBasket.propTypes = {
    items: basketProps.items,
};

export default CheckoutBasket;
