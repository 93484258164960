/* eslint-disable camelcase */
import React, { Fragment } from 'react';

import Helmet from 'react-helmet';
import createOgTags from 'components/metadata/utils/createOgTags';
import createTwitterTags from 'components/metadata/utils/createTwitterTags';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { extractQueryParams } from 'utils/query';
import getOptimalContentImageSrc from 'components/metadata/utils/getOptimalContentImageSrc';
import suffix from 'components/metadata/utils/suffix';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * PageMeta for Sagaform
 * @version 1.3.0
 *
 * PageMeta Init in RouteResolver
 * @param {string} suffix - Dont forget to change this to current project name.
 * @param {func} buildCanonicalUrl - This creates a dynamic canonical url. We can add specific condition ex. also add the magazine type
 * @param {string} protocol - application.protocol string. always https
 * @param {string} domain - application.domain, current domain
 * @param {string} path - application.path, current path
 * @param {object} pageData -  Getting the needed SEO information, and building the meta also important for the canonical
 * @param {external func} createOgTags - Creates the necessary og tags passed down to it
 * @param {external func} createTwitterTags - Creates the necessary twitter tags passed down to it
 * Note: The file will only fire if the permalink has changed.
 */

const PageMeta = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const domain = useSelector(state => state.application.domain);
    const protocol = useSelector(state => state.application.protocol);
    const path = useSelector(state => state.application.path);
    const locale = useSelector(state => state.application.locale);
    const pageData = useSelector(state => state.page?.data || {});

    // Url/Canonical setup
    const permalink = pageData.permalink;
    const protocolPath = protocol ? `${protocol}://` : '';
    const localePath = path || '';
    const baseUrl = protocolPath + domain || '';
    const seoObject = pageData.seo || {};

    // Build the canonical if we have a canonical or permalink
    let canonical = seoObject.canonical;
    if (canonical && canonical.charAt(0) === '/') {
        canonical = `${baseUrl}${localePath}${canonical}`;
    } else if ((!canonical || canonical === '/') && permalink) {
        canonical = `${baseUrl}${localePath}${permalink}`;
    }

    // Generall setup of desc and title.
    const pageName = seoObject.title || pageData.title || null;
    const title = pageName && decodeHTMLEntities(pageName + suffix);
    const description = seoObject.meta_description || seoObject.description || t('utils.seo_description_fallback');

    // Href langs from application aliases
    const hrefLangs = pageData.alternate_hrefs || [];

    // This should be "EN", "SV" etc. from the CMS, but it's not and we can not change.
    // It is "sv_SE", "en_GB" etc. so that is why we slice it.
    // Returns "sv", "en" etc.
    const lang = locale?.slice(0, 2) || 'en';

    // Little bit of a work in progress. Will get the first best image provided for the current site.
    // Very specific function
    const contentImageSrc = getOptimalContentImageSrc(pageData);

    // The no follow || no index, noIndex will we overwritten hence the let.
    const noFollow = parseInt(seoObject.no_follow, 10) || false;
    let noIndex = parseInt(seoObject.no_index, 10) || false;

    // Do not index pages with search parameters
    if (location.search) {
        const queryParams = extractQueryParams(location.search);
        if (queryParams.s) {
            noIndex = true;
        }
    }

    // Setup OG object data for tags
    const ogData = {
        title: seoObject.og_title || title || null,
        description: seoObject.og_description || description || null,
        media: seoObject.og_image || contentImageSrc,
    };

    // Setup twitter object data for tags
    const twitterData = {
        title: seoObject.twitter_title || title || null,
        description: seoObject.twitter_description || description || null,
        media: seoObject.twitter_image || contentImageSrc,
    };

    // Create ogTags (facebook)
    const ogTags = createOgTags(ogData).props.children || [];

    // Create twitter tags
    const twitterTags = createTwitterTags(twitterData).props.children || [];

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang }}>
                <meta name="robots" content={`${noIndex ? 'noindex' : 'index'}, ${noFollow ? 'nofollow' : 'follow'}`} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {title && <title>{title}</title>}
                {canonical && <link rel="canonical" href={canonical} />}
                {description && <meta name="description" content={description} />}
                {twitterTags.map(tag => tag)}
                {ogTags.map(tag => tag)}
                {hrefLangs.map(({ uri, href_lang: hrefLang }) => (
                    <link key={hrefLang} rel="alternate" href={uri} hrefLang={hrefLang} />
                ))}
            </Helmet>
        </Fragment>
    );
};

export default PageMeta;
