import PropTypes from 'prop-types';
import Radio from 'components/forms/Radio';
import React from 'react';
import { borderRadius } from 'config/theme/borderRadius';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: absolute;
    top: 52px;
    left: 108px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: var(--dynamic-color-brand-secondary-light);
    transition: opacity ${transitions.primary.medium};
    z-index: ${zIndex.filterBar};
    box-shadow: 0px 4px 4px var(--dynamic-opacity-color-dark-higher);
`;

const SortOptions = ({ sorts = [], show = false, handleChange }) => {
    if (sorts.length < 1) {
        return null;
    }

    const defaultActiveSort = sorts.find(sort => sort.selected);

    return (
        <Wrapper borderRadius={borderRadius} opacity={show ? 1 : 0} visibility={show ? 'visible' : 'hidden'}>
            <Radio
                defaultActive={defaultActiveSort?.id}
                display="flex"
                flexDirection="column"
                gap={['8px 0', null, null, null, '16px 0']}
                handleChange={item => handleChange(item.id)}
                items={sorts}
                name="productSort"
            />
        </Wrapper>
    );
};

SortOptions.propTypes = {
    handleChange: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    sorts: PropTypes.array.isRequired,
};

export default SortOptions;
