import React, { useLayoutEffect, useMemo } from 'react';
import { above, media } from 'utils/mediaqueries';

import Above from 'components/breakpoints/Above';
import BackgroundImage from 'components/background/BackgroundImage';
import Badges from 'components/Badges';
import Below from 'components/breakpoints/Below';
import Events from 'libs/Events';
import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PlusIcon from 'assets/icons/Plus';
import Price from 'components/products/Price';
import ProductCardImage from 'components/products/ProductCard/ProductCardImage';
import { ProductEvents } from 'libs/Events/constants';
import PropTypes from 'prop-types';
import QuickShopButton from 'components/buttons/functionalButtons/QuickShopButton';
import { getVariantsOfSameSize } from 'utils/products';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useIsShowroom from 'hooks/useIsShowroom';
import { withRouter } from 'react-router-dom';

const Div = styled('div')``;

const Card = styled('article', {
    shouldForwardProp: prop => ['history', 'loading', 'location', 'match', 'staticContext'].indexOf(prop) === -1,
})`
    align-self: start;
`;

const InnerWrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;

    ${media.hover} {
        :hover {
            .hover-image {
                opacity: 1;
            }

            ${above.tabletSm} {
                .quick-shop-button {
                    opacity: 1;
                }
            }
        }
    }
`;

const Name = styled(Heading, {
    shouldForwardProp: prop => ['fontKeys'].indexOf(prop) === -1,
})`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const ImageWrapper = styled('div')`
    position: relative;

    ${above.desktopSm} {
        ${media.hover} {
            :hover .available-colors {
                display: flex;
            }
        }
    }
`;

const AvailableColors = styled('ul')`
    display: flex;
    gap: 0 8px;
    align-items: center;
    margin-top: 2px;

    ${above.tabletSm} {
        margin-top: 0;
    }

    ${above.desktopSm} {
        display: none;
        position: absolute;
        bottom: 16px;
        left: 16px;
    }
`;

const Color = styled('li', {
    shouldForwardProp: prop => ['isActive'].indexOf(prop) === -1,
})`
    height: ${({ isActive }) => (isActive ? '18px' : '12px')};
    width: ${({ isActive }) => (isActive ? '18px' : '12px')};
    border-radius: 100%;
    overflow: hidden;
    position: relative;

    ${({ isActive }) =>
        isActive &&
        `
        border: 1px solid var(--static-color-primary);

      ::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: inset 0px 0px 0px 2px var(--static-background-color-primary);
      }
        `}
`;

const Multipack = styled(Paragraph)`
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: var(--static-color-light-five);
`;

const ProductCard = ({
    badges = [],
    category = '',
    colorName = '',
    detailedColor = '',
    hoverImage = '',
    id,
    image = '',
    imageSizes,
    inStock = false,
    loadImage = true,
    loading = 'lazy',
    multipack = '',
    name,
    position,
    price: { price, salePrice, isOnSale, priceWithCurrency, salePriceWithCurrency },
    primaryImage,
    quickshop = false,
    relatedVariants = [],
    size = '',
    sku,
    srcWidths,
    url,
    variationId = '',
    ...rest
}) => {
    const product = useMemo(() => ({ category, name, price, salePrice, sku, position }), [
        category,
        name,
        price,
        salePrice,
        sku,
        position,
    ]);

    const currentVariant = {
        color: colorName,
        id,
        image,
        isActive: true,
        size,
        uri: url,
    };

    const isShowroom = useIsShowroom();

    const newRelatedVariants = [currentVariant, ...relatedVariants];

    let transformedRelatedVariants = newRelatedVariants;
    const hasSizes = relatedVariants.some(variant => variant.size);
    const hasColors = transformedRelatedVariants.length > 1;

    if (hasSizes) {
        transformedRelatedVariants = getVariantsOfSameSize(newRelatedVariants, size);
    }
    /**
     * Used for tracking
     */
    const trackHandler = () => {
        Events.trigger(ProductEvents.CLICK, { product });
    };

    const [entry, ref] = useIntersectionObserver({ triggerOnce: true });
    useLayoutEffect(() => {
        if (entry.target && entry.isIntersecting) {
            Events.trigger(ProductEvents.IMPRESSION, {
                products: [product],
            });
        }
    }, [entry, product]);

    return (
        <Card ref={ref} {...rest}>
            <InnerWrapper>
                <ImageWrapper aspectRatio={ratios.vertical} marginBottom={quickshop ? '0' : '16px'}>
                    <Link to={url} onClick={trackHandler}>
                        <ProductCardImage
                            alt={name}
                            hoverImage={hoverImage ?? ''}
                            imageSizes={imageSizes}
                            loadImage={loadImage}
                            loading={loading}
                            primaryImage={primaryImage}
                            quickshop={quickshop}
                            srcWidths={srcWidths}
                            title={name}
                        />
                        {badges.length > 0 && (
                            <Badges
                                badges={badges}
                                flexDirection={['column', null, null, null, 'row']}
                                left="8px"
                                position="absolute"
                                top="8px"
                            />
                        )}
                    </Link>
                    {hasColors && (
                        <Above
                            breakpoint="desktopSm"
                            render={() => (
                                <AvailableColors className="available-colors">
                                    {transformedRelatedVariants
                                        .slice(0, 5)
                                        .map(({ id, uri, image, isActive, color, useOutline }) => (
                                            <Link key={id} to={uri}>
                                                <Color
                                                    title={color}
                                                    border={
                                                        useOutline ? '1px solid var(--static-color-light-two)' : 'none'
                                                    }
                                                    isActive={isActive}
                                                >
                                                    <BackgroundImage src={image} />
                                                </Color>
                                            </Link>
                                        ))}
                                    {relatedVariants.length > 5 && <PlusIcon width="9px" />}
                                </AvailableColors>
                            )}
                        />
                    )}
                    {multipack && (
                        <Multipack fontKeys={['NB International/11_120', null, 'NB International/12_120_4']}>
                            {multipack}
                        </Multipack>
                    )}
                </ImageWrapper>
                {!isShowroom && quickshop && (
                    <QuickShopButton
                        className="quick-shop-button"
                        inStock={inStock}
                        marginBottom={['8px', null, '16px']}
                        variationId={variationId}
                        width="100%"
                    />
                )}
                <Div display="flex" flexDirection="column" padding={['0 6px', null, '0 12px']}>
                    <Link to={url} onClick={trackHandler}>
                        <Div
                            display="flex"
                            flexDirection="column"
                            flexGrow="1"
                            gap={['6px 0', null, '8px 0']}
                            onClick={trackHandler}
                        >
                            {name && (
                                <Name as="h3" fontKeys={['Recife Display/14', null, 'Recife Display/16']}>
                                    {name}
                                </Name>
                            )}
                            {detailedColor && (
                                <Paragraph
                                    color="var(--dynamic-color-light-five)"
                                    fontKeys={[
                                        'NB International/11_120',
                                        null,
                                        'NB International/12_120_7',
                                        null,
                                        'NB International/14_100_7',
                                    ]}
                                >
                                    {detailedColor}
                                </Paragraph>
                            )}
                            {!isShowroom && (
                                <Price
                                    fontKeys={['NB International/14_100', null, 'NB International/16_100']}
                                    isOnSale={!!isOnSale}
                                    priceWithCurrency={priceWithCurrency}
                                    salePriceWithCurrency={salePriceWithCurrency}
                                    textColor="currentColor"
                                />
                            )}
                            {hasColors && (
                                <Below
                                    breakpoint="desktopSm"
                                    render={() => (
                                        <AvailableColors className="available-colors">
                                            {transformedRelatedVariants
                                                .slice(0, 3)
                                                .map(({ id, uri, image, color, useOutline }) => (
                                                    <Link key={id} to={uri}>
                                                        <Color
                                                            title={color}
                                                            border={
                                                                useOutline
                                                                    ? '1px solid var(--static-color-light-two)'
                                                                    : 'none'
                                                            }
                                                        >
                                                            <BackgroundImage src={image} />
                                                        </Color>
                                                    </Link>
                                                ))}
                                            {relatedVariants.length > 3 && <PlusIcon width="9px" />}
                                        </AvailableColors>
                                    )}
                                />
                            )}
                        </Div>
                    </Link>
                </Div>
            </InnerWrapper>
        </Card>
    );
};

ProductCard.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            theme: PropTypes.string,
        })
    ),
    category: PropTypes.string,
    colorName: PropTypes.string,
    detailedColor: PropTypes.string,
    hoverImage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    image: PropTypes.string,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    inStock: PropTypes.bool,
    loadImage: PropTypes.bool,
    loading: PropTypes.string,
    multipack: PropTypes.string,
    name: PropTypes.string.isRequired,
    position: PropTypes.number, // tracking position
    price: PropTypes.object.isRequired,
    primaryImage: PropTypes.string.isRequired,
    quickshop: PropTypes.bool,
    relatedVariants: PropTypes.array,
    size: PropTypes.string,
    sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    url: PropTypes.string.isRequired,
    variationId: PropTypes.string,
};

export default withRouter(ProductCard);
