import Minus from 'assets/icons/Minus';
import Paragraph from 'components/text/Paragraph';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 0 16px;
    background-color: var(--dynamic-color-light-one);
    border-radius: 48px;
    padding: 6px 16px;
`;

const Quantity = styled('div')`
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    min-width: 10px; // Prevent icon movement when changing between 1 - 10
`;

const IncreaseOrDecrease = ({ quantity = 0, decrease, increase }) => (
    <Wrapper>
        <button type="button" onClick={decrease}>
            <Minus />
        </button>
        <Quantity>
            <Paragraph as="span" lineHeight="1">
                {quantity}
            </Paragraph>
        </Quantity>
        <button type="button" onClick={increase}>
            <Plus />
        </button>
    </Wrapper>
);

IncreaseOrDecrease.propTypes = {
    decrease: PropTypes.func.isRequired,
    increase: PropTypes.func.isRequired,
    quantity: PropTypes.number,
};

export default IncreaseOrDecrease;
