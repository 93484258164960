import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Close from 'assets/icons/Close';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import SearchContainer from 'containers/SearchContainer';
import SearchContent from 'Header/Default/Search/SearchContent';
import SearchInput from 'components/pages/search/SearchInput';
import { above } from 'utils/mediaqueries';
import sanitizeUserInput from 'utils/sanitizeUserInput';
import searchAndReplaceWysiwyg from 'libs/wordpress/utils/searchAndReplaceWysiwyg';
import { setSuggestions } from 'state/models/Search/actions';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const StyledMaxWrapper = styled(MaxWidthWrapper, {
    shouldForwardProp: prop => ['clientHeight', 'hasSearchString'].indexOf(prop) === -1,
})`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    color: var(--dynamic-color-primary);
    background: var(--dynamic-color-brand-secondary-light);
    height: ${({ clientHeight, hasSearchString }) => (hasSearchString ? clientHeight : '100%')};

    ${above.tabletSm} {
    }

    ${above.desktopMd} {
        padding-top: 48px;
        height: ${({ hasSearchString }) => (hasSearchString ? '80vh' : '100%')};
    }
`;

const CloseButton = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--dynamic-background-color-secondary);
    color: var(--dynamic-color-secondary);
`;

const Search = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const clientHeight = useClientHeight();

    const hideOverlay = useSelector(state => state.overlay.hide);
    const { placeholder, noResultText } = useSelector(state => state.header.data.search) || {};
    const defaultSuggestions = useSelector(state => state.search.suggestions) || {};
    const searchPage = useSelector(state => state.application.config.options.specified_pages.search_results?.slug);
    const applicationPrefix = useSelector(state => state.application.path);
    const history = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [searchstring, setSearchstring] = useState('');
    const replacedNoResultText = searchAndReplaceWysiwyg(noResultText, '{search}', searchstring);

    // Handles keyup
    useEffect(() => {
        const handleKeyup = e => {
            // ESC
            if (e.keyCode === 27) {
                history.replace();
                hideOverlay();
            }
        };

        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        };
         
    }, []);

    // Handles search string updates
    useEffect(() => {
        // Update search value in url
        const string = sanitizeUserInput(searchstring, 100);

        if (string) {
            params.set('s', string);
            history.push({
                search: params.toString(),
            });
        }
         
    }, [searchstring]);

    const searchSubmit = e => {
        setSearchstring(e);
        const url = `${applicationPrefix}${searchPage}?s=${encodeURI(searchstring)}`;
        history.push(url);
        hideOverlay();
    };

    return (
        <StyledMaxWrapper includeContentSpacing clientHeight={clientHeight} hasSearchString={!!searchstring}>
            <Div width="100%">
                <Div
                    alignItems={['center', null, null, null, null, 'flex-start']}
                    display="flex"
                    justifyContent="space-between"
                    paddingBottom={['24px', null, null, null, null, '0']}
                >
                    <SearchInput
                        focusOnMount
                        border="1px solid var(--dynamic-color-primary)"
                        borderRadius="32px"
                        color="var(--dynamic-color-primary)"
                        editButtonStyling={{ bottom: ['10%', null, null, null, null, '15%'], right: '0', top: 'unset' }}
                        handleSubmit={searchSubmit}
                        padding="12px 16px"
                        placeholderColor="var(--dynamic-color-light-five)"
                        placeholderText={placeholder}
                        searchFieldStyling={{
                            ...styleObjectToProps([
                                'Recife Display/20',
                                null,
                                null,
                                null,
                                null,
                                'Recife Display/32_120',
                            ]),
                        }}
                        searchString={searchstring}
                        updateSearch={setSearchstring}
                        width={['calc(100% - 32px - 16px)', null, null, null, null, 'calc(41.66666667% - 8px)']} // icon and  space between
                    />
                    <CloseButton display="block" type="button" onClick={() => hideOverlay()}>
                        <Close color="currentColor" width="12px" />
                    </CloseButton>
                </Div>
            </Div>
            <SearchContainer
                pageSize={8}
                render={({ response }) => (
                    <SearchContent
                        articles={response?.articles}
                        categories={response?.categories?.slice(0, 5)}
                        defaultSuggestions={defaultSuggestions}
                        fallbackProducts={response?.fallbackProducts}
                        noResultText={replacedNoResultText}
                        products={response?.products}
                        productsHitsCount={response?.productsHitsCount}
                        searchString={searchstring}
                        suggestions={response?.suggestions}
                        updateSearch={setSearchstring}
                    />
                )}
                searchString={searchstring || ''}
                setSuggestions={suggestions => dispatch(setSuggestions(suggestions))}
                t={t}
            />
        </StyledMaxWrapper>
    );
};

export default Search;
