import { allowScroll, preventScroll } from 'utils/scrollFunctions';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Scroll component that handles scroll locking on the page.
 */

const ScrollLocker = () => {
    const lockScroll = useSelector(state => state.site.lockScroll);

    useEffect(() => {
        if (lockScroll) {
            preventScroll();
        } else {
            allowScroll();
        }

        return () => allowScroll();
    }, [lockScroll]);

    return null;
};

export default ScrollLocker;
