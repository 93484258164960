import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 4px;
    margin-top: 4px;
`;

const Select = styled('select')`
    height: 44px;
    width: 50px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
`;

const Option = styled('option')``;

const PrefixSelect = ({ callingCodes = [], handleSelectedOption = () => null, ...rest }) => {
    if (!callingCodes.length) {
        return null;
    }

    return (
        <Wrapper {...rest}>
            <Select
                onChange={e => {
                    const callingCode = e.target.options?.[e.target.selectedIndex].getAttribute('data-calling-code');
                    const isoCode = e.target.options?.[e.target.selectedIndex].getAttribute('data-iso-code');

                    handleSelectedOption(isoCode, callingCode);
                }}
            >
                {callingCodes.map(({ callingCode, isoCode, country }) => (
                    <Option key={isoCode} data-iso-code={isoCode} data-calling-code={callingCode}>
                        {country} ({callingCode})
                    </Option>
                ))}
            </Select>
        </Wrapper>
    );
};

PrefixSelect.propTypes = {
    callingCodes: PropTypes.arrayOf(
        PropTypes.shape({
            callingCode: PropTypes.string,
            isoCode: PropTypes.string,
            country: PropTypes.string,
        })
    ),
    handleSelectedOption: PropTypes.func,
};

export default PrefixSelect;
