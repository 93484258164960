import { closeMenu, openMenu, resetMenu, setActiveMenu } from 'state/models/Header/actions';
import { useDispatch, useSelector } from 'react-redux';

import Links from './Links';
import React from 'react';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding-left: 48px;
    padding-right: 24px;
`;

const TopLevel = () => {
    const dispatch = useDispatch();
    const { primaryMenu, secondaryMenu } = useSelector(state => state.header.data.navigation);
    const { activeMenu, menuIsOpen } = useSelector(
        state => state.header.state,
        (prev, next) => prev.activeMenu === next.activeMenu && prev.menuIsOpen === next.menuIsOpen
    );

    // Handle onClick for Links
    const handleOnClick = ({ e, hasSubmenu, label }) => {
        if (hasSubmenu && label !== activeMenu) {
            e.preventDefault();
            dispatch(setActiveMenu(label));

            if (!menuIsOpen) {
                dispatch(openMenu());
            }
        } else {
            dispatch(resetMenu());

            if (menuIsOpen) {
                dispatch(closeMenu());
            }
        }
    };

    return (
        <Wrapper>
            <Links handleOnClick={handleOnClick} items={primaryMenu} justifyContent="flex-start" />
            <Links handleOnClick={handleOnClick} items={secondaryMenu} justifyContent="center" />
        </Wrapper>
    );
};

export default TopLevel;
