interface Category {
    ancestors: [];
    application_id: number;
    centra_id: string;
    id: string;
    objectID: string;
    title: string;
    type: string;
    uri: string;
    _highlightResult: {
        title: {
            value: string;
            matchLevel: string;
            fullyHighlighted: boolean;
            matchedWords: String[];
        };
    };
}

/**
 * Transform the algoliaCategoryData returned from algolia
 *
 * @param {object} algoliaCategoryData - Category data from Algolia
 * @returns object
 */

export const transformCategory = (algoliaCategoryData: Category) => {
    if (!algoliaCategoryData) {
        return null;
    }

    return {
        ancestors: algoliaCategoryData.ancestors,
        applicationId: algoliaCategoryData.application_id,
        centraId: algoliaCategoryData.centra_id,
        highlightResult: { ...algoliaCategoryData._highlightResult.title },
        id: algoliaCategoryData.id,
        objectId: algoliaCategoryData.objectID,
        title: algoliaCategoryData.title,
        type: algoliaCategoryData.type,
        url: algoliaCategoryData.uri,
    };
};

/**
 * Transform categories data from Aligola Response
 *
 * @param {object} response - Response data from Algolia
 * @returns object
 */

export const transformAlgoliaCategoryResponse = (response: Record<string, any>) => {
    const clonedResponse = JSON.parse(JSON.stringify(response));

    if (clonedResponse.hits?.length) {
        clonedResponse.hits = clonedResponse.hits.map(category => transformCategory(category));
    }

    return clonedResponse;
};
