import { useDispatch, useSelector } from 'react-redux';

import { changeSiteColorTheme } from 'utils/changeSiteColorTheme';
import { resetTheme } from 'state/models/Site/actions';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Theme component that handles site theme on the page.
 */

const SiteTheme = () => {
    const theme = useSelector(state => state.site.theme) || [];
    const dispatch = useDispatch();
    const location = useLocation();
    const hasTheme = theme.length;

    // Reset theme between pages
    useEffect(() => {
        if (hasTheme) {
            dispatch(resetTheme());
        }
    }, [location.key]);

    // Change between active and default theme
    useEffect(() => {
        if (hasTheme) {
            changeSiteColorTheme(theme[0]);
        } else {
            changeSiteColorTheme('default');
        }

        return () => changeSiteColorTheme('default');
    }, [theme]);

    return null;
};

export default SiteTheme;
