import { Component } from 'react';
import Events from 'libs/Events';
import PropTypes from 'prop-types';
import { injectModel } from 'state';

class CentraCheckoutScript extends Component {
    addedEventListener = false;
    paymentEventListener = null;
    shippingEventListener = null;

    static propTypes = {
        basket: PropTypes.object.isRequired,
        script: PropTypes.string.isRequired,
    };

    state = {
        paymentLoaded: false,
        shippingLoaded: false,
        scriptLoaded: false,
    };

    componentDidMount() {
        this.paymentEventListener = Events.subscribe('payment.loaded', this.onPaymentLoaded);
        this.shippingEventListener = Events.subscribe('shipping.loaded', this.onShippingLoaded);
    }

    componentDidUpdate = prevProps => {
        const { script: oldScript } = prevProps;
        const { script: newScript } = this.props;

        if (
            newScript !== oldScript ||
            (this.state.paymentLoaded && this.state.shippingLoaded && !this.state.scriptLoaded)
        ) {
            this.loadScript();
        }
    };

    componentWillUnmount() {
        // Remove the event listener to avoid memory leaks.
        document.removeEventListener('centra_checkout_callback', this.centraCheckoutCallback);
        const existingScriptElement = document.getElementById('centra_checkout_script');

        if (existingScriptElement) {
            // Remove it so we're sure that the code is executed again.
            document.body.removeChild(existingScriptElement);
        }

        if (this.paymentEventListener) {
            Events.unsubscribe(this.paymentEventListener);
        }

        if (this.shippingEventListener) {
            Events.unsubscribe(this.shippingEventListener);
        }

        // Remove CentraCheckout from the window object.
        // This is needed so we can baseline some functionality that depends upon window.CentraCheckout existing or not.
        // If it would still exist and be called, it might cause unexpected behaviour.
        window.CentraCheckout = undefined;
    }

    onPaymentLoaded = () => {
        this.setState({
            paymentLoaded: true,
        });
    };

    onShippingLoaded = () => {
        this.setState({
            shippingLoaded: true,
        });
    };

    centraCheckoutCallback = origdata => {
        const {
            basket: { addBasketInformation },
        } = this.props;
        if (origdata.detail) {
            const data = { ...origdata.detail };
            // Ugly hack because they have name their shit wrong.
            // It should be address, not billingAddress.
            if (data.billingAddress) {
                data.address = { ...data.billingAddress };
                delete data.billingAddress;
            }
            addBasketInformation(data).then(() => {
                window.CentraCheckout && window.CentraCheckout.resume(data.additionalFields.suspendIgnore);
            });
        }
    };

    init = () => {
        this.loadScript();
    };

    loadScript = (props = this.props) => {
        // Don't do shit if document is undefined.
        if (typeof document === 'undefined') {
            return;
        }

        const existingScriptElement = document.getElementById('centra_checkout_script');

        if (existingScriptElement) {
            // Remove it so we're sure that the code is executed again.
            document.body.removeChild(existingScriptElement);
        }

        const { script } = props;

        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.innerHTML = script;
        scriptElement.id = 'centra_checkout_script';
        document.getElementsByTagName('body')[0].appendChild(scriptElement);

        // Adds a check so that we don't register this event listener multiple times.
        if (!this.addedEventListener) {
            document.addEventListener('centra_checkout_callback', this.centraCheckoutCallback);

            this.addedEventListener = true;

            this.setState({
                scriptLoaded: true,
            });
        }
    };

    render() {
        return null;
    }
}

export default injectModel('basket')(CentraCheckoutScript);
