// These components are used by both RecursiveMenuMobileView and RecursiveMenuDesktopView

// Helper function for fadeIn animation
// It's possible to turn off translateX as that can couse a overflow-x problem
export const fadeAnimation = ({ fadeIn = false, fadeOut = false, translate = true, duration }) => `
    @keyframes translateIn {
        0% {
            opacity: 0;
            transform: translate3d(-10px, 0, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes translateOut {
        0% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
        100% {
            opacity: 0;
            transform: translate3d(10px, 0, 0);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    animation: ${
        fadeIn
            ? `${translate ? 'translateIn' : 'fadeIn'} ${duration / 2}ms ease-out ${duration / 2}ms`
            : fadeOut
            ? `${translate ? 'translateOut' : 'fadeOut'} ${duration / 2}ms ease-out 0ms`
            : 'none'
    };
`;
