/**
 * This is a base component of an html5 "a" tag.
 * @version 1.0
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { injectModel } from 'state';

/**
 * @param {object} application - Object from redux containing path for internal links.
 * @param {string} volatile - If true, the component is renderd as a link and the to-prop is false the component will only render its children.
 * @param {string} to - Link to internal or external url. Acts like a href.
 * @param {bool} children - JSX children.
 * @param {bool} dispatch - Should not be a part of rest.
 */

const Link = ({ application, volatile = true, to, children, dispatch, ...rest }) => {
    // Only render children if link is missing and volatile is true
    if ((!to && volatile) || typeof to !== 'string') {
        return children;
    }

    // Set default values
    let LinkRenderer = RouterLink;
    let href = to;

    // Check if link is internal or external
    if (to) {
        // Probably an external link because the first character is not '/'
        if (to.indexOf('/') !== 0) {
            LinkRenderer = ({ to, target, rel, children, ...rest }) => (
                <a href={to} target={target || '_blank'} rel={rel || 'noopener noreferrer'} {...rest}>
                    {children}
                </a>
            );
            // Internal link
        } else {
            // Add application.path before the link if the link does not start on the same path as application.path
            href = to.indexOf(`${application.path}/`) !== 0 ? `${application.path}${to}` : to;
        }
    }

    return (
        <LinkRenderer {...rest} to={(href && href) || '/#'}>
            {children}
        </LinkRenderer>
    );
};

Link.propTypes = {
    application: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
    children: PropTypes.node.isRequired,
    rel: PropTypes.string,
    target: PropTypes.string,
    to: PropTypes.string,
    volatile: PropTypes.bool,
};

export default injectModel('application')(Link);
