// Get Basket
export const GET_BASKET = 'GET_BASKET';
export const GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS';
export const GET_BASKET_ERROR = 'GET_BASKET_ERROR';

// Set Basket
export const SET_BASKET = 'SET_BASKET';
export const SET_BASKET_SUCCESS = 'SET_BASKET_SUCCESS';
export const SET_BASKET_ERROR = 'SET_BASKET_ERROR';

// Create Basket
export const CREATE_BASKET = 'CREATE_BASKET';
export const CREATE_BASKET_SUCCESS = 'CREATE_BASKET_SUCCESS';
export const CREATE_BASKET_ERROR = 'CREATE_BASKET_ERROR';

// Add to Basket
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const ADD_TO_BASKET_SUCCESS = 'ADD_TO_BASKET_SUCCESS';
export const ADD_TO_BASKET_ERROR = 'ADD_TO_BASKET_ERROR';

// Add all to Basket
export const ADD_MULTIPLE_TO_BASKET = 'ADD_MULTIPLE_TO_BASKET';
export const ADD_MULTIPLE_TO_BASKET_SUCCESS = 'ADD_MULTIPLE_TO_BASKET_SUCCESS';
export const ADD_MULTIPLE_TO_BASKET_ERROR = 'ADD_MULTIPLE_TO_BASKET_ERROR';

// Add Bundle to Basket
export const ADD_BUNDLE_TO_BASKET = 'ADD_BUNDLE_TO_BASKET';
export const ADD_BUNDLE_TO_BASKET_SUCCESS = 'ADD_BUNDLE_TO_BASKET_SUCCESS';
export const ADD_BUNDLE_TO_BASKET_ERROR = 'ADD_BUNDLE_TO_BASKET_ERROR';

// Create Basket
export const REMOVE_BASKET = 'REMOVE_BASKET';
export const REMOVE_BASKET_SUCCESS = 'REMOVE_BASKET_SUCCESS';
export const REMOVE_BASKET_ERROR = 'REMOVE_BASKET_ERROR';

// Remove from Basket
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET';
export const REMOVE_FROM_BASKET_SUCCESS = 'REMOVE_FROM_BASKET_SUCCESS';
export const REMOVE_FROM_BASKET_ERROR = 'REMOVE_FROM_BASKET_ERROR';

// Empty Basket
export const EMPTY_BASKET = 'EMPTY_BASKET ';
export const EMPTY_BASKET_SUCCESS = 'EMPTY_BASKET_SUCCESS';
export const EMPTY_BASKET_ERROR = 'EMPTY_BASKET_ERROR';

// Show/Hide Basket
export const OPEN_BASKET = 'OPEN_BASKET';
export const CLOSE_BASKET = 'CLOSE_BASKET';
export const TOGGLE_BASKET = 'TOGGLE_BASKET';

// Add voucher
export const ADD_VOUCHER = 'ADD_VOUCHER';
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_ERROR = 'ADD_VOUCHER_ERROR';

// Add voucher
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS';
export const REMOVE_VOUCHER_ERROR = 'REMOVE_VOUCHER_ERROR';

// Select payment method
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SELECT_PAYMENT_METHOD_SUCCESS = 'SELECT_PAYMENT_METHOD_SUCCESS';
export const SELECT_PAYMENT_METHOD_ERROR = 'SELECT_PAYMENT_METHOD_ERROR';

// Seöect shipping method
export const SELECT_SHIPPING_METHOD = 'SELECT_SHIPPING_METHOD';
export const SELECT_SHIPPING_METHOD_SUCCESS = 'SELECT_SHIPPING_METHOD_SUCCESS';
export const SELECT_SHIPPING_METHOD_ERROR = 'SELECT_SHIPPING_METHOD_ERROR';

export const GET_BASKET_PAYMENT = 'GET_BASKET_PAYMENT';
export const GET_BASKET_PAYMENT_SUCCESS = 'GET_BASKET_PAYMENT_SUCCESS';
export const GET_BASKET_PAYMENT_ERROR = 'GET_BASKET_PAYMENT_ERROR';
export const GET_BASKET_PAYMENT_WARNING = 'GET_BASKET_PAYMENT_WARNING';

export const ADD_BASKET_PAYMENT_FIELDS = 'ADD_BASKET_PAYMENT_FIELDS';
export const ADD_BASKET_PAYMENT_FIELDS_SUCCESS = 'ADD_BASKET_PAYMENT_FIELDS_SUCCESS';
export const ADD_BASKET_PAYMENT_FIELDS_ERROR = 'ADD_BASKET_PAYMENT_FIELDS_ERROR';

export const BASKET_SET_COUNTRY = 'BASKET_SET_COUNTRY';
export const BASKET_SET_COUNTRY_SUCCESS = 'BASKET_SET_COUNTRY_SUCCESS';
export const BASKET_SET_COUNTRY_ERROR = 'BASKET_SET_COUNTRY_ERROR';

// Add Basket Information
export const ADD_BASKET_INFORMATION = 'ADD_BASKET_INFORMATION';
export const ADD_BASKET_INFORMATION_SUCCESS = 'ADD_BASKET_INFORMATION_SUCCESS';
export const ADD_BASKET_INFORMATION_ERROR = 'ADD_BASKET_INFORMATION_ERROR';

export const BASKET_SET_ADDRESS_STATE = 'BASKET_SET_ADDRESS_STATE';

// Add Basket Information
export const UPDATE_BASKET_LINE_QUANTITY = 'UPDATE_BASKET_LINE_QUANTITY';
export const UPDATE_BASKET_LINE_QUANTITY_SUCCESS = 'UPDATE_BASKET_LINE_QUANTITY_SUCCESS';
export const UPDATE_BASKET_LINE_QUANTITY_ERROR = 'UPDATE_BASKET_LINE_QUANTITY_ERROR';

// Resolve
export const SET_SECURE_AUTH_DATA = 'SET_SECURE_AUTH_DATA';
