import G from 'assets/icons/base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const CookieInformation = ({ color = colors.black, height = '100%', width = '304.4px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 304.4 304.4" width={width} {...rest}>
        <G>
            <Path
                d="M80.8,53.3c-6.6-8.2-19.5-8.5-27.5,0.5C33,76.8,1.7,124.4,16.4,187.7c0,0-4.1,1.2-9.3,2.7
		c-5.1,1.5-2.5,9.7,5.9,18.3l35.9,36.9c8.4,8.6,16.6,6,18.4-5.9l7.2-48.3c1.8-11.9-2.3-19.9-9.1-17.9L53,177
		c-8.9-42.1,10-75.1,24.7-93.3C85.3,74.4,87.4,61.5,80.8,53.3z"
                fill={color}
            />
            <Path
                d="M121.7,300.8c9.5,1.9,21.6,3.6,35.3,3.6c29.2,0,65.3-7.7,97.5-36.7c0,0,4.1,3.9,9.2,8.8
		c5.1,4.9,11.8-0.5,15.1-12.1l14.1-49.5c3.3-11.6-3.1-17.4-14.3-13l-45.4,17.8c-11.2,4.4-17.1,11-13.3,14.7l7,6.7
		c-31.7,27.1-68.7,27.1-91.4,23.4c-11.9-1.9-24.1,2.7-27.9,12.5C103.8,287,110,298.3,121.7,300.8z"
                fill={color}
            />
            <Path
                d="M299.3,127.6c-6.9-29.3-26.8-80.8-84.1-106.1c0,0,1.9-5.5,4.2-12.2c2.3-6.7-5.4-10.7-17.3-8.9l-50.8,7.9
		c-11.9,1.8-14.5,10.1-5.8,18.4l35.2,33.8c8.7,8.3,17.1,11.1,18.8,6.1l3.1-8.9c36.8,17.6,52,50.7,58.1,72.5
		c3.3,11.6,12.6,20.8,23.1,20.1C294.3,149.6,302,139.3,299.3,127.6z"
                fill={color}
            />
        </G>
        <G>
            <G>
                <G>
                    <Path
                        d="M203.9,101.4c-2.6-2.6-6.1-4.1-9.1-6.2c-3-2.2-5.6-4.7-8.9-6.4c-3.3-1.7-6.4-3.9-9.9-5
				c-3.5-1.1-7.2-1.7-10.8-2.3c-3.6-0.6-7.3-1.6-11-1.6c-3.7,0-7.2,2.6-10.8,3.2c-3.7,0.6-7.3,0.6-10.8,1.7
				c-3.5,1.1-6.5,3.1-9.8,4.7c-3.3,1.7-6.5,3.1-9.5,5.3c-2.9,2.1-6.6,3.5-9.2,6.2c-2.6,2.6-5.7,5.1-7.8,8c-2.2,3-2.8,7-4.5,10.3
				c-1.7,3.3-2,7-3.1,10.5c-1.1,3.5-3.3,6.7-3.9,10.3c-0.6,3.6-0.9,7.3-0.9,11c0,3.7-0.7,7.6-0.1,11.2c0.6,3.7,1.9,7.3,3.1,10.7
				c1.1,3.5,4.2,6.2,5.9,9.4c1.7,3.3,2.1,7.3,4.2,10.3c2.1,2.9,5.8,4.7,8.4,7.3c2.6,2.6,4,6.7,6.9,8.9c3,2.2,7.2,2.5,10.4,4.2
				c3.3,1.7,6.2,4.2,9.7,5.4c3.5,1.1,7,2.8,10.7,3.3c3.6,0.6,7.5-0.2,11.2-0.2c3.7,0,7.2-1.8,10.8-2.3c3.7-0.6,7.3-0.6,10.8-1.7
				c3.5-1.1,7-2.2,10.3-3.9c3.3-1.7,6.5-3.5,9.5-5.7c2.9-2.1,4.9-5.4,7.5-8c2.6-2.6,5-5.2,7.2-8.2c2.2-3,5.7-5.1,7.4-8.4
				c1.7-3.3,3-6.9,4.1-10.4c1.1-3.5,0.3-7.5,0.8-11.1c0.6-3.6,1.7-7.1,1.7-10.8c0-3.7-1.4-7.2-2-10.8c-0.6-3.7-1.2-7.1-2.3-10.6
				c-1.1-3.5-1.3-7.3-3-10.6c-1.7-3.3-3.7-6.4-5.9-9.4C209,106.8,206.5,104.1,203.9,101.4z M209.6,151.1c0,4.2,0.6,8.6-0.3,12.6
				c-0.9,4.1-3.1,7.9-4.9,11.6c-1.8,3.8-4.7,6.8-7.3,10c-2.6,3.2-5.3,6.3-8.5,8.9c-3.2,2.6-7,4.1-10.8,5.9c-3.7,1.8-6.9,5.4-11,6.3
				c-4,0.9-8.4,0.6-12.6,0.6c-4.2,0-8.6,0.1-12.6-0.8c-4.1-0.9-8.1-2.8-11.7-4.6c-3.8-1.8-6.6-5.1-9.8-7.7c-3.2-2.6-5.5-5.9-8.1-9.1
				c-2.6-3.2-6-5.8-7.9-9.6c-1.8-3.7-4.3-7.4-5.2-11.5c-0.9-4,0-8.4,0-12.6c0-4.2,0.5-8.3,1.5-12.3c0.9-4.1,3.2-7.5,5-11.2
				c1.8-3.8,3.5-7.4,6.1-10.6c2.6-3.2,4.3-7.5,7.5-10.1c3.2-2.6,7.7-3.3,11.5-5.1c3.7-1.8,7.1-4.7,11.2-5.7c4-0.9,8.3,0.8,12.6,0.8
				c4.2,0,8.5-1.4,12.5-0.5c4.1,0.9,7.6,3.5,11.3,5.3c3.8,1.8,6.7,4.6,9.9,7.1c3.2,2.6,6.8,4.7,9.4,7.9c2.6,3.2,6.1,6,7.9,9.8
				c1.8,3.7,2.4,8.1,3.4,12.1C209.5,142.7,209.6,146.9,209.6,151.1z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M154.2,191.6c0-2.7-2.3-5-5-5c-2.7,0-4.9,2.3-4.9,5c0,2.7,2.2,4.8,4.9,4.8
				C151.9,196.4,154.2,194.3,154.2,191.6z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M198.4,143.3c0-2.7-2.2-5-4.9-5c-2.7,0-4.8,2.2-4.8,5s2.1,4.9,4.8,4.9C196.2,148.2,198.4,146,198.4,143.3z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M161,150c0-2.7-2.1-4.8-4.8-4.8c-2.7,0-4.9,2.1-4.9,4.8s2.2,4.8,4.9,4.8C158.9,154.9,161,152.7,161,150z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M127,175.1c0-2.7-2.1-4.9-4.8-4.9c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9C124.9,180,127,177.8,127,175.1z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M141.6,110.6c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.1-4.9,4.8s2.2,4.8,4.9,4.8
				C139.4,115.4,141.6,113.3,141.6,110.6z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M127.6,130c-3.8,0-7.2,1.6-9.7,4.1c-2.5,2.5-4,5.9-4,9.7c0,3.8,1.3,7.4,3.8,9.9c2.5,2.5,6.1,3.8,9.9,3.8
				s7.4-1.3,9.9-3.9c2.5-2.5,4.1-6.1,4.1-9.9c0-3.8-1.9-7.1-4.4-9.7C134.8,131.6,131.5,130,127.6,130z M127.6,150
				c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.9-6.3,6.2-6.3c3.4,0,5.9,2.9,5.9,6.3C133.5,147.2,131,150,127.6,150z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M177.7,161.9c-3.8,0-7.1,1.7-9.7,4.2c-2.5,2.5-4.4,5.8-4.4,9.7c0,3.8,1.7,7.3,4.2,9.8c2.5,2.5,6,4.2,9.8,4.2
				c3.8,0,7.2-1.8,9.7-4.3c2.5-2.5,4.4-5.9,4.4-9.7c0-3.8-1.9-7.2-4.4-9.7C184.8,163.6,181.5,161.9,177.7,161.9z M177.7,181.9
				c-3.4,0-6.3-2.8-6.3-6.1c0-3.4,3-5.9,6.3-5.9c3.4,0,6.1,2.6,6.1,5.9C183.8,179.1,181,181.9,177.7,181.9z"
                        fill={color}
                    />
                </G>
                <G>
                    <Path
                        d="M181.3,123.9c0-3.2-1.2-6.1-3.3-8.2c-2.1-2.1-5-3.2-8.2-3.2c-3.2,0-5.9,1.3-8,3.3c-2.1,2.1-3.6,4.8-3.6,8
				c0,3.2,1.4,6,3.5,8.1c2.1,2.1,4.9,3.2,8.1,3.2s6.2-1,8.3-3.1C180.2,130.1,181.3,127.1,181.3,123.9z M166.1,123.9
				c0-2.1,1.7-3.7,3.8-3.7c2.1,0,3.9,1.6,3.9,3.7s-1.8,3.9-3.9,3.9C167.8,127.8,166.1,126,166.1,123.9z"
                        fill={color}
                    />
                </G>
            </G>
        </G>
    </SVG>
);

CookieInformation.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default CookieInformation;
