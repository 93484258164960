import BottomRow from './BottomRow';
import FooterContent from './FooterContent';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import React from 'react';
import maxWidth from 'config/theme/maxWidth';
import moduleSpacing from 'config/theme/moduleSpacing';
import styled from 'libs/styled';
import transformButton from 'libs/wordpress/utils/transformButton';
import { useSelector } from 'react-redux';

const PositionWrapper = styled('div')`
    max-width: ${maxWidth};
    margin: 0 auto;
`;

const Footer = () => {
    const { company = {}, heading = {}, menu = [], newsletter = {}, partners = [], socialMedia = [] } = useSelector(
        state => state.footer.data || {}
    );

    const newsletterForm = newsletter?.form || {};

    const contentProps = {
        heading: {
            headingTag: heading.type,
            title: heading.text,
        },
        menu,
        newsletter: {
            text: newsletter.text,
            id: 'footerNewsletter',
            tag: 'onSignUp',
            ...newsletterForm,
        },
        socialMedia,
    };

    return (
        <>
            <MaxWidthWrapper
                includeContentSpacing
                as="footer"
                backgroundColor="var(--static-color-brand-primary)"
                borderRadius={['24px 24px 0 0', null, null, null, '48px 48px 0 0']}
                boxShadow="inset 0px 24px 24px var(--static-opacity-color-dark-higher)"
                color="var(--static-color-secondary)"
                marginTop={moduleSpacing.md}
                paddingBottom={['16px', null, '24px']}
                paddingTop={['32px', null, null, null, '48px']}
            >
                <PositionWrapper>
                    <FooterContent {...contentProps} />
                    <BottomRow partners={partners} privacyButton={transformButton(company.privacyButton)} />
                </PositionWrapper>
            </MaxWidthWrapper>
        </>
    );
};

export default Footer;
