import Breadcrumbs from 'components/Breadcrumbs';
import CmsModules from 'libs/wordpress/content/CmsModules';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import SubCategoryList from 'components/SubCategoryList';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Hero = styled('div')``;
const CmsModulesWrapper = styled('div')``;

const CustomerService = ({ data }) => {
    const {
        ancestors = [],
        breadcrumbs = [],
        children = [],
        heading = '',
        id,
        page_content: pageContent = [],
        siblings = [],
        title = '',
    } = data;
    const isSubPage = ancestors.length > 0;

    // Menu array
    const menuBase = isSubPage ? siblings : children;
    const relatedLinks = menuBase.map(item => ({
        title: item.title,
        to: item.permalink,
        current: item.id === id,
    }));

    const { mobileAndGap, desktopAndGap } = useHeaderHeights();

    return (
        <>
            <Hero marginTop={[`${mobileAndGap + 12}px`, null, null, null, `${desktopAndGap + 12}px`]}>
                <Breadcrumbs
                    currentTitle={title}
                    items={breadcrumbs}
                    marginBottom="40px"
                    marginLeft={['24px', null, '40px']}
                />
                <Heading
                    as="h1"
                    fontKeys={['Recife Display/40', null, 'Recife Display/80', null, 'Recife Display/80']}
                    margin="0 0 24px"
                    textAlign="center"
                >
                    {heading || title}
                </Heading>
                {relatedLinks.length > 0 && (
                    <SubCategoryList
                        items={relatedLinks}
                        marginTop={['12px', null, '24px']}
                        maxWidth="800px"
                        mx="auto"
                        {...styleObjectToProps(['Recife Display/20', null, 'Recife Display/24_100'])}
                    />
                )}
            </Hero>
            <CmsModulesWrapper
                margin={isSubPage ? ['24px 0 40px', null, '80px 0 80px', null, '64px 0 80px'] : '40px 0 80px'}
                textAlign={!isSubPage ? 'center' : null}
            >
                <CmsModules data={pageContent} />
            </CmsModulesWrapper>
        </>
    );
};

CustomerService.propTypes = {
    data: PropTypes.object.isRequired, // CMS Data
};

export default CustomerService;
