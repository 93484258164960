/**
 * @author Timmie Sarjanen
 * @version 1.0.0
 * @todo: Document this
 */

import React, { Children, PureComponent, createRef, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { inServer } from 'config/constants';
import styled from 'libs/styled';

const generateStyle = (slidesPerView, spaceBetween, isCentered, numberOfSlides) => {
    const spaceBetweenInt = parseInt(spaceBetween || 0, 10);
    const itemsWidth = (numberOfSlides / slidesPerView) * 100;
    const spaceBetweenExtra = spaceBetween && spaceBetweenInt * Math.floor(numberOfSlides / slidesPerView);

    const diff = spaceBetweenInt / slidesPerView;
    const x = (numberOfSlides % slidesPerView) * diff;

    return {
        '--co': isCentered ? `${slidesPerView / 2 - 0.5} * ${100 / numberOfSlides}%` : '0px',
        width: spaceBetweenExtra ? `calc(${itemsWidth}% + ${spaceBetweenExtra + x}px)` : `${itemsWidth}%`,
        '& > *': {
            marginRight: numberOfSlides < slidesPerView || spaceBetweenExtra ? spaceBetween : 0,
        },
    };
};

const breakpoints = ({ breakpoints, ...props }) => {
    const styles = [generateStyle(props.slidesPerView, props.spaceBetween, props.isCentered, props.numberOfSlides)];

    const breakpointKeys = Object.keys(breakpoints);

    if (!breakpointKeys.length) {
        return styles;
    }

    for (let i = 0; i < breakpointKeys.length; i++) {
        const breakpoint = breakpointKeys[i];
        const { slidesPerView, spaceBetween = 0, isCentered } = breakpoints[breakpoint];

        styles.push({
            [breakpoint]: generateStyle(slidesPerView, spaceBetween, isCentered, props.numberOfSlides),
        });
    }

    return styles;
};

const ignoredProps = [
    'defaultIndex',
    'numberOfSlides',
    'defaultTransform',
    'duration',
    'numberOfSlides',
    'breakpoints',
    'slidesPerView',
    'spaceBetween',
    'isCentered',
    'numberOfSlides',
];

const OverflowWrapper = styled('div')();

const Wrapper = styled('div', { shouldForwardProp: prop => ignoredProps.indexOf(prop) === -1 })`
    --i: ${({ defaultIndex }) => defaultIndex || 0};
    --n: ${({ numberOfSlides }) => numberOfSlides};
    display: flex;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    transform: translate3d(${({ defaultTransform }) => defaultTransform}, 0, 0);
    transition: ${({ duration }) => `transform ${duration}ms ease`};
    will-change: transform;
    touch-action: pan-y pinch-zoom;
    & > * {
        width: 100%;
    }
    ${breakpoints};
`;

class Slider extends PureComponent {
    sliderRef = createRef();
    isIE = !inServer && !!document.documentMode;
    isEdge = !inServer && !this.isIE && !!window.StyleMedia;
    defaultIndex = this.props.defaultIndex || this.props.activeSlideIndex;

    state = {
        activeSlideIndex: 0,
        internalActiveSlideIndex: this.props.activeSlideIndex,
        numberOfSlides: 0,
        width: '100%',
    };

    static propTypes = {
        activeSlideIndex: PropTypes.number,
        breakpoints: PropTypes.PropTypes.shape({
            slidesPerView: PropTypes.number,
            spaceBetween: PropTypes.string,
        }),
        children: PropTypes.node.isRequired,
        defaultIndex: PropTypes.number,
        isCentered: PropTypes.bool,
        onSlideChange: PropTypes.func,
        overflow: PropTypes.string,
        slidesPerView: PropTypes.number,
        spaceBetween: PropTypes.string,
        transformOffset: PropTypes.number,
        transitionDuration: PropTypes.number,
    };

    static defaultProps = {
        activeSlideIndex: null,
        breakpoints: {},
        defaultIndex: null,
        isCentered: false,
        onSlideChange: null,
        overflow: 'hidden',
        slidesPerView: 1,
        spaceBetween: '0',
        transformOffset: 0,
        transitionDuration: 300,
    };

    static getDerivedStateFromProps(props, state) {
        const numberOfSlides = Children.toArray(props.children).length;
        const maxIndex = numberOfSlides - props.slidesPerView;

        let internalActiveSlideIndex = state.internalActiveSlideIndex;

        // If number of slides changes and current index is above maximum, go back one slide.
        if (
            internalActiveSlideIndex > 0 &&
            numberOfSlides < state.numberOfSlides &&
            maxIndex < internalActiveSlideIndex
        ) {
            --internalActiveSlideIndex;
        }

        return {
            ...state,
            internalActiveSlideIndex,
            numberOfSlides,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { internalActiveSlideIndex, numberOfSlides } = this.state;
        const { activeSlideIndex, onSlideChange, transformOffset } = this.props;
        const sliderEl = this.sliderRef.current;

        if (sliderEl) {
            sliderEl.style.transition = numberOfSlides > prevState.numberOfSlides ? 'none' : null;

            const index =
                activeSlideIndex !== prevProps.activeSlideIndex
                    ? activeSlideIndex
                    : internalActiveSlideIndex !== prevState.internalActiveSlideIndex
                    ? internalActiveSlideIndex
                    : null;

            if (index !== null) {
                if (this.isIE || this.isEdge) {
                    sliderEl.style.transform = `translate(${(index / numberOfSlides) * -100}%)`;
                } else {
                    sliderEl.style.setProperty('--i', index);
                }

                onSlideChange && onSlideChange(index);
            }

            if (transformOffset !== prevProps.transformOffset) {
                requestAnimationFrame(() => {
                    if (transformOffset) {
                        const inner = sliderEl.children[0];

                        if (inner) {
                            const slidesOffset =
                                activeSlideIndex === null ? internalActiveSlideIndex : activeSlideIndex;
                            const rect = inner.getBoundingClientRect();
                            const spaceBetween = parseInt(window.getComputedStyle(inner).marginRight, 10);

                            const outerWidth = rect.width + spaceBetween;
                            const translate = -(slidesOffset * outerWidth + transformOffset);

                            sliderEl.style.transform = `translate3d(calc(${translate}px + var(--co)), 0, 0)`;
                            sliderEl.style.transition = 'none';
                        }
                    } else {
                        sliderEl.style.transition = null;
                        sliderEl.style.transform = null;
                    }
                });
            }
        }
    }

    slideTo = index => {
        const { numberOfSlides } = this.state;
        const { slidesPerView } = this.props;

        if (index > -1 && index <= numberOfSlides) {
            requestAnimationFrame(() => {
                this.setState({
                    internalActiveSlideIndex:
                        index + slidesPerView > numberOfSlides ? numberOfSlides - slidesPerView : index,
                });
            });
        }
    };

    slidePrev = () => this.slideTo(this.state.internalActiveSlideIndex - 1);
    slideNext = () => this.slideTo(this.state.internalActiveSlideIndex + 1);

    render() {
        const { numberOfSlides } = this.state;
        const {
            children,
            overflow,
            slidesPerView,
            transitionDuration,
            breakpoints,
            spaceBetween,
            isCentered,
        } = this.props;

        const centerOffset = isCentered ? `${slidesPerView / 2 - 0.5} * ${100 / numberOfSlides}%` : '0px';

        const translateX =
            this.isIE || this.isEdge
                ? `calc(${(this.defaultIndex / numberOfSlides).toFixed(2) * -100}%${centerOffset})`
                : `calc(var(--i) / ${numberOfSlides} * -100% + var(--co))`;

        return (
            <OverflowWrapper overflowX={overflow}>
                <Wrapper
                    breakpoints={breakpoints}
                    defaultIndex={this.defaultIndex}
                    isCentered={isCentered}
                    defaultTransform={translateX}
                    numberOfSlides={numberOfSlides}
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    duration={transitionDuration}
                    ref={this.sliderRef}
                >
                    {children}
                </Wrapper>
            </OverflowWrapper>
        );
    }
}

export default forwardRef((props, ref) => <Slider {...props} ref={ref} />);
