import Circle from 'assets/icons/base/Circle';
import Defs from 'assets/icons/base/Defs';
import G from 'assets/icons/base/G';
import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import Stop from 'assets/icons/base/Stop';
import colors from 'config/theme/colors';

const LoadingIcon = ({ color = colors.black, height = '100%', width = '38px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 38 38" width={width} {...rest}>
        <Defs>
            <linearGradient id="a" x1="8%" x2="65.7%" y1="0%" y2="23.9%">
                <Stop offset="0%" stopColor={color} stopOpacity="0" />
                <Stop offset="63.1%" stopColor={color} stopOpacity=".6" />
                <Stop offset="100%" stopColor={color} />
            </linearGradient>
        </Defs>
        <G fill="none" fillRule="evenodd" transform="translate(1 1)">
            <Path stroke="url(#a)" strokeWidth="2" d="M36 18C36 8 28 0 18 0">
                <animateTransform
                    attributeName="transform"
                    dur="0.9s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                />
            </Path>
            <Circle cx="36" cy="18" r="1" fill={color}>
                <animateTransform
                    attributeName="transform"
                    dur="0.9s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                />
            </Circle>
        </G>
    </SVG>
);

LoadingIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default LoadingIcon;
