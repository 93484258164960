// Redux
import { applyMiddleware, combineReducers, createStore } from 'redux';

import { INITIAL_STATE_KEY } from 'config/constants';
import application from './models/Application/reducers';
import basket from './models/Basket/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import footer from './models/Footer/reducers';
import header from './models/Header/reducers';
import latestAddedProduct from './models/LatestAddedProduct/reducers';
import newsletter from './models/Newsletter/reducers';
import notice from './models/Notice/reducers';
import overlay from './models/Overlay/reducers';
import page from './models/Page/reducers';
import popups from './models/Popups/reducers';
import product from './models/Product/reducers';
import products from './models/Products/reducers';
import search from './models/Search/reducers';
import site from './models/Site/reducers';
import thunk from 'redux-thunk';
import wishlists from './models/Wishlists/reducers';

const reducer = combineReducers({
    application,
    basket,
    footer,
    header,
    latestAddedProduct,
    newsletter,
    notice,
    overlay,
    page,
    popups,
    product,
    products,
    search,
    site,
    wishlists,
});

export const initiateStore = (initialState, middleWares = []) =>
    createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk, ...middleWares)));

let store = null;

// added so we don't error out when compiling for SSR
if (typeof window !== 'undefined') {
    store = store || initiateStore(window[INITIAL_STATE_KEY] && JSON.parse(window[INITIAL_STATE_KEY]));
    delete window[INITIAL_STATE_KEY];
} else {
    // When we are creating a store for the SSR..
    store = store || initiateStore();
}

export default store;

export const getStore = () => store;
