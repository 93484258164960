import { DocumentEvents, SiteEvents } from 'libs/Events/constants';
import Events, { hooks } from 'libs/Events';
import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';

import Cookies from 'js-cookie';
import ErrorCatcher from 'components/ErrorCatcher';
import FacebookSiteVerifications from 'components/metadata/FacebookSiteVerifications';
import Footer from 'Footer';
import GoogleSiteVerifications from 'components/metadata/GoogleSiteVerifications';
import GTM from '@grebban/gtm';
import Header from 'Header';
import Overlay from 'components/Overlay';
import PinterestSiteVerifications from 'components/metadata/PinterestSiteVerifications';
import Popup from 'components/Popup';
import PropTypes from 'prop-types';
import RouteResolver from 'components/RouteResolver';
import ScrollLocker from 'components/scrollComponents/ScrollLocker';
import ScrollRestoration from 'components/scrollComponents/ScrollRestoration';
import SiteTheme from './SiteTheme';
import appProp from 'utils/proptypes/application';
import basketProp from 'utils/proptypes/basket';
import { extractQueryParams } from 'utils/query';
import { inServer } from 'config/constants';
import { injectModels } from 'state';
import locationProp from 'utils/proptypes/location';
import tracking from 'components/tracking';
import { withRouter } from 'react-router-dom';
import zIndex from 'config/theme/z-index';
import Helmet from 'react-helmet';

export { default as hooks } from 'libs/Events';

class Site extends Component {
    static propTypes = {
        application: appProp.isRequired,
        basket: basketProp.isRequired,
        countryOverlay: PropTypes.bool,
        location: locationProp.isRequired,
        overlay: PropTypes.object,
        ready: PropTypes.bool,
    };

    static defaultProps = {
        ready: false,
        countryOverlay: false,
        overlay: {},
    };

    constructor(props) {
        super(props);

        // @todo DONT FORGET TO BUILD THE REAL FUNCTIONALITY FOR WHEN THE COUNTRY SELECTOR SHOULD BE SHOWN!
        // This is only for the swedish site since there is no time to implement the real deal. With cookies..
        this.state = {
            ready: true,
        };

        if (!inServer) {
            tracking.init();
        }
    }

    async componentDidMount() {
        const { application, basket, location } = this.props;
        const currentHref = application.getCurrentHref();
        const campaignCookie = Cookies.get('campaign_site');
        const queryObject = extractQueryParams(location.search) || {};
        let checkoutThanksUrl;

        // Resolve the application.
        if (!application.applicationId) {
            // Right now, this is only supposed to happen when doing `yarn start`.
            // When doing it the SSR way, it will come predefined and skip this step on the client.
            // const shopConfig = getShopConfig(window.location.host);
            let shopConfig = {};
            const shopConfigCookie = Cookies.get('shop_config');

            if (campaignCookie !== undefined) {
                const response = await application.resolveCampaignSite(campaignCookie);
                if (response.status === 200 || response.status === 201) {
                    shopConfig = {
                        'market_id': response.data.market_id,
                    };
                }
            }

            if (shopConfigCookie) {
                try {
                    const cookieShopConfig = JSON.parse(decodeURIComponent(shopConfigCookie));
                    shopConfig = {
                        ...shopConfig,
                        ...cookieShopConfig,
                    };
                } catch (e) {}
            }

            try {
                const appResponse = await application.resolveApp(currentHref, {}, shopConfig);
                // Set checkoutThanksUrl on client
                checkoutThanksUrl = `${appResponse.data?.application?.path || ''}${
                    appResponse.data?.shop_config?.checkout_thanks_uri || ''
                }`;
            } catch (e) {
                // The application wasn't found. Or something is missing in either Header or Footer.
                // const redirect = await ResolveRedirect(null, window.location.pathname);
                // if (redirect && redirect.data && redirect.data.to) {
                //     window.location.href = redirect.data.to;
                // }
            }
        } else {
            // Set checkoutThanksUrl on server
            checkoutThanksUrl = `${application?.path || ''}${application.shop_config?.checkout_thanks_uri || ''}`;
        }

        if (typeof document !== 'undefined') {
            document.addEventListener(
                'click',
                e => {
                    Events.trigger(DocumentEvents.CLICK, e);
                },
                false
            );
            document.addEventListener(
                'touchend',
                e => {
                    Events.trigger(DocumentEvents.TOUCHEND, e);
                },
                false
            );
        }

        const isOnCheckoutThanksPage = checkoutThanksUrl && checkoutThanksUrl === window.location.pathname;

        // Don't get the basket if page is checkout thank you
        if (basket.getBasketIdCookie() && !isOnCheckoutThanksPage) {
            basket.getBasket();
        }

        Events.trigger(SiteEvents.MOUNTED);
        await hooks.trigger(SiteEvents.MOUNTED);

        /* eslint-disable-next-line react/no-did-mount-set-state */
        this.setState({
            ready: true,
        });

        if ((queryObject && queryObject.campaign) || campaignCookie) {
            const key = queryObject.campaign ? queryObject.campaign : campaignCookie;
            const result = await application.resolveCampaignSite(key);

            if (result) {
                Cookies.set('campaign_site', key, { expires: 30 });
            } else {
                Cookies.remove('campaign_site', { expires: 30 });
            }
        }

        // Handle Country Site Selector Popup
        this.countrySiteSelector();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { application, location } = this.props;
        const { ready, countryOverlay } = this.props;

        if (nextProps.application && application && nextProps.application.country !== application.country) {
            // The country has changed.
            // Check if the country matches the current domain.
            // const countryConfig = getShopConfig(window.location.host);
            // if (
            //     countryConfig &&
            //     countryConfig.countries.findIndex(v => v.id === nextProps.application.country) === -1
            // ) {
            //     this.setState({
            //         countryOverlay: true,
            //     });
            // }
        }

        if (location.pathname !== nextProps.location.pathname) {
            return true;
        }

        if (!nextProps.application.isFetching && application.isFetching) {
            return true;
        }

        if (nextProps.application.isScrollable !== application.isScrollable) {
            return true;
        }

        if (nextProps.application.locale !== application.locale) {
            return true;
        }

        if (nextState.countryOverlay !== countryOverlay) {
            return true;
        }

        if (nextState.ready !== ready) {
            return true;
        }

        if (nextProps.application && application && nextProps.application.country !== application.country) {
            // The country has changed.
            // Check if the country matches the current domain.
            // const countryConfig = getShopConfig(window.location.host);
            // if (
            //     countryConfig &&
            //     countryConfig.countries.findIndex(v => v.id === nextProps.application.country) === -1
            // ) {
            //     this.setState({
            //         countryOverlay: true,
            //     });
            // }
        }

        if (location.pathname !== nextProps.location.pathname) {
            return true;
        }

        if (!nextProps.application.isFetching && application.isFetching) {
            return true;
        }

        if (nextProps.application.isScrollable !== application.isScrollable) {
            return true;
        }

        if (nextProps.application.locale !== application.locale) {
            return true;
        }

        if (nextState.countryOverlay !== countryOverlay) {
            return true;
        }

        if (nextState.ready !== ready) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { application } = this.props;
        if (application.isScrollable !== prevProps.application.isScrollable) {
            if (application.isScrollable === true) {
                const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

                document.body.classList.add('prevent-scroll');
                document.body.style.paddingRight = `${scrollBarGap}px`;
            } else {
                document.body.classList.remove('prevent-scroll');
                document.body.style.paddingRight = null;
            }
        }
    }
    
    countrySiteSelector() {
        const {
            application: { aliasId, customer, ecommerce, domain, protocol },
            location: { search: urlQuery },
            overlay,
        } = this.props;

        const geo = customer?.geo;
        const shippingCountries = ecommerce?.shippingCountries;
        const availableInMarket = shippingCountries?.available_in_market;
        const customerAliasId = customer?.primaryApplication?.alias_id;

        /**
         * Prevent the site selector popup to be displayed everytime we render the frontpage
         * from local domain without a path.
         */
        if (process.env.REACT_APP_ENV === 'local') {
            return;
        }

        const URLParams = new URLSearchParams(urlQuery);
        if (URLParams.get('ip_redirect') !== null) {
            // Do not display popup since the user was already redirected based on IP.
            return;
        }

        // Get site selector popup cookie
        const siteSelectorCookie = JSON.parse(Cookies.get(process.env.REACT_APP_SITE_SELECTOR_POPUP_COOKIE) || '{}');

        /**
         * If the user visits the recommended site or if the user already selected not to use the recommended site
         * and the saved app id matches the current application id, do nothing.
         * Just return from countrySiteSelector.
         */
        if (aliasId === customerAliasId || aliasId === siteSelectorCookie.selectedAliasId) {
            return;
        }

        /**
         * If the application id and the app id the customer should be using mismatches each other
         * and the customer's country is not listed as one the the countries this market can shipp to
         * then notify the user that he/she can redirect to another application.
         */
        if (
            aliasId !== customerAliasId && 
            !availableInMarket?.find(shippingCountry => shippingCountry.id === geo?.countryCode)
        ) {
            overlay.show('siteSelectorOverlay', { zIndex: zIndex.siteSelector });
        }
    }

    render() {
        const { ready } = this.state;
        const { application } = this.props;

        const cookieInformationLangCodes = {
            'da_DK': 'DA',
            'de_DE': 'DE',
            'en_GB': 'EN',
            'fi_FI': 'FI',
            'nl_NL': 'NL',
            'no_NO': 'NB',
            'sv_SE': 'SV',
        };

        return (
            <Fragment>
                <ScrollRestoration />
                <ScrollLocker />
                <SiteTheme />
                {(!ready || application.applicationId === null) && !inServer ? null : (
                    <Fragment>
                        {process.env.REACT_APP_GTM_TRACKING_KEY && (
                            <ErrorCatcher>
                                <GTM
                                    trackingKey={process.env.REACT_APP_GTM_TRACKING_KEY}
                                    helmetComponent={Helmet}
                                    initialVariables={{
                                        currency: application.shop_config.currency,
                                        locale: application.locale,
                                        'site_id': application.applicationId,
                                        url: application.getCurrentHref(),
                                        domain: application.domain,
                                        path: application.path,
                                    }}
                                    dependencies={
                                              [
                                                  {
                                                      name: 'CookieInformation',
                                                      locale: cookieInformationLangCodes[`${application.locale}`],
                                                      consentModeEnabled: true,
                                                  },
                                              ]
                                    }
                                />
                            </ErrorCatcher>
                        )}
                        <ErrorCatcher>
                            <Popup />
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <Overlay />
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <GoogleSiteVerifications />
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <FacebookSiteVerifications />
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <PinterestSiteVerifications />
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <Header />
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <main>
                                <Switch>
                                    <Route path="/" component={RouteResolver} />
                                    <Route path="/:slug" component={RouteResolver} />
                                </Switch>
                            </main>
                        </ErrorCatcher>
                        <ErrorCatcher>
                            <Footer />
                        </ErrorCatcher>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(injectModels(['application', 'basket', 'overlay'])(Site));

export { Site as DefaultSite };
