import Above from 'components/breakpoints/Above';
import Arrow from 'assets/icons/Arrow';
import ArrowLink from 'components/text/ArrowLink';
import Below from 'components/breakpoints/Below';
import Heading from 'components/text/Heading';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import TouchableSlider from 'components/base/sliders/TouchableSlider';
import { above } from 'utils/mediaqueries';
import { bleedSpacing } from 'config/theme/contentSpacing';
import { buttonProp } from 'utils/proptypes/modules/buttonsProps';
import { headingTagProp } from 'utils/proptypes/modules/textProps';
import styled from 'libs/styled';
import useSlider from 'hooks/useSlider';

const Div = styled('div')``;

const HorizontalScrollWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    oveflow-y: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }

    /* To get a space at the end of the content blocks */
    &::after,
    &::before {
        content: '';
        min-width: 4px;
        display: block;
        height: 1px;

        ${above.tabletSm} {
            min-width: 0;
        }
    }
`;

const Button = styled('button')`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 40px;
    background-color: var(--static-background-color-primary);
`;

const ProductsPromotion = ({
    button = {},
    heading = '',
    headingTag = 'h2',
    loadImages = true,
    products = [],
    ...rest
}) => {
    const [sliderProps, { activeIndex, slidePrev, slideNext }] = useSlider(null);

    const numberOfProducts = products.length;
    const slidesPerView = 4;
    const showNavButtons = numberOfProducts > slidesPerView;
    const showPrevNavButton = activeIndex > 0;
    const showNextNavButton = activeIndex < numberOfProducts - slidesPerView;
    const hasButton = button.label && button.to;

    return (
        <Div
            alignItems={['flex-start', null, null, null, 'normal']}
            display="flex"
            flexDirection="column"
            position="relative"
            {...rest}
        >
            <Div display="flex" justifyContent="space-between" marginBottom="24px">
                {heading && (
                    <Heading
                        as={headingTag}
                        fontKeys={['Recife Display/32_120', null, null, null, 'Recife Display/40']}
                    >
                        {heading}
                    </Heading>
                )}
                {hasButton && (
                    <ArrowLink display={['none', null, null, null, 'inline-flex']} to={button.to}>
                        {button.label}
                    </ArrowLink>
                )}
            </Div>
            <Below
                breakpoint="desktopSm"
                render={() => (
                    <HorizontalScrollWrapper gap={['8px', null, '16px']} width="100vw" mx={bleedSpacing}>
                        {products.map(product => (
                            <ProductCard
                                loadImage={loadImages}
                                imageSizes={['75vw', '25vw']}
                                key={`${product?.id}-${product?.sku}`}
                                srcWidths={[300, 400, 500]}
                                flex={['0 0 calc(75vw - 16px)', null, '0 0 calc(30vw - 32px)']}
                                {...product}
                            />
                        ))}
                    </HorizontalScrollWrapper>
                )}
            />
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <TouchableSlider slidesPerView={slidesPerView} spaceBetween="12px" {...sliderProps}>
                        {products.map(product => (
                            <ProductCard
                                loadImage={loadImages}
                                imageSizes="25vw"
                                key={`${product?.id}-${product?.sku}`}
                                overflow="hidden"
                                srcWidths={[300, 400, 500]}
                                width="100%"
                                {...product}
                            />
                        ))}
                    </TouchableSlider>
                )}
            />
            {showNavButtons && (
                <Button
                    disabled={!showPrevNavButton}
                    display={['none', null, null, null, 'flex']}
                    left="10px"
                    opacity={showPrevNavButton ? 1 : 0.3}
                    onClick={slidePrev}
                >
                    <Arrow width="8px" transform="rotate(180deg)" />
                </Button>
            )}
            {showNavButtons && (
                <Button
                    display={['none', null, null, null, 'flex']}
                    disabled={!showNextNavButton}
                    right="10px"
                    opacity={showNextNavButton ? 1 : 0.3}
                    onClick={slideNext}
                >
                    <Arrow width="8px" />
                </Button>
            )}
            {hasButton && (
                <Div display="flex" justifyContent="center" width="100%">
                    <ThemeButton
                        display={['block', null, null, null, 'none']}
                        marginTop="48px"
                        theme="solid"
                        to={button.to}
                        width={['100%', null, 'auto']}
                    >
                        {button.label}
                    </ThemeButton>
                </Div>
            )}
        </Div>
    );
};

ProductsPromotion.propTypes = {
    button: buttonProp,
    heading: PropTypes.string,
    headingTag: headingTagProp,
    loadImages: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductsPromotion;
