import { closeMenu, hideHeader, openMenu, resetMenu, setActiveMenu, showHeader } from './actions';

import store from 'state/store';

export default {
    setActiveMenu: (activeMenu = null) => {
        store.dispatch(setActiveMenu(activeMenu));
    },
    resetMenu: () => {
        store.dispatch(resetMenu());
    },
    openMenu: () => {
        store.dispatch(openMenu());
    },
    closeMenu: () => {
        store.dispatch(closeMenu());
    },
    hideHeader: () => {
        store.dispatch(hideHeader());
    },
    showHeader: () => {
        store.dispatch(showHeader());
    },
};
