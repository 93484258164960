import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Div = styled('div')``;

const EmptyBasket = ({ closeBasket = () => {} }) => {
    const { text = '', button = {} } = useSelector(state => state.header.data.basket?.empty || {});

    return (
        <Div alignItems="center" display="flex" height="100%" justifyContent="center" width="100">
            <Div alignItems="center" display="flex" flexDirection="column" justifyContent="center">
                {text && (
                    <Paragraph fontKeys="NB International/20" marginRight="4px" textAlign="center" width="83.33333333%">
                        {text}
                    </Paragraph>
                )}
                {button.text && button.url && (
                    <ThemeButton marginTop="40px" theme="solid" to={button.url} onClick={() => closeBasket()}>
                        {button.text}
                    </ThemeButton>
                )}
            </Div>
        </Div>
    );
};

EmptyBasket.propTypes = {
    closeBasket: PropTypes.func.isRequired,
};

export default EmptyBasket;
