import PropTypes from 'prop-types';
import React from 'react';
import TextButtonUnderline from 'components/buttons/TextButtonUnderline';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Wrapper = styled('ul')`
    display: flex;
    align-items: center;
    gap: 0 24px;
`;

const Item = styled('li')`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
`;

const Links = ({ handleOnClick = () => {}, items = [], ...rest }) => {
    if (items?.length < 1) {
        return null;
    }

    return (
        <Wrapper {...rest}>
            {items.map(({ highlight = false, label = '', link = '', menuType = '' }) => {
                const hasSubmenu = menuType !== 'link';
                const RenderComponent = link?.to && !hasSubmenu ? TextLinkUnderline : TextButtonUnderline;

                if (!label) {
                    return null;
                }

                return (
                    <Item
                        color={highlight ? 'var(--dynamic-color-active)' : 'currentColor'}
                        key={label}
                        {...styleObjectToProps('NB International/12_120_7')}
                    >
                        <RenderComponent to={link?.to} onClick={e => handleOnClick({ e, hasSubmenu, label })}>
                            {label}
                        </RenderComponent>
                    </Item>
                );
            })}
        </Wrapper>
    );
};

Links.propTypes = {
    handleOnClick: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.object,
            mneuType: PropTypes.string,
            highlight: PropTypes.bool,
            submenu: PropTypes.object,
        })
    ),
};

export default Links;
