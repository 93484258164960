import React from 'react';
import Search from 'Header/Default/Search';
import { above } from 'utils/mediaqueries';
import { borderRadius } from 'config/theme/borderRadius';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import useHeaderHeights from 'hooks/useHeaderHeights';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'headerHeights'].indexOf(prop) === -1,
})`
    position: fixed;
    top: 0;
    height: auto;
    max-height: ${({ clientHeight }) => clientHeight};
    width: 100vw;
    z-index: ${zIndex.searchOverlayMobile};
    overflow: hidden;
    transform: translateY(-100%);

    ${above.desktopMd} {
        top: ${({ headerHeights }) => `${headerHeights.desktopAndGap - 20}px`}; // 20px overlapping
        left: 24px;
        z-index: ${zIndex.searchOverlayDesktop};
        transform: translateY(0);
        width: calc(100% - 48px);
        height: 0;
        border-bottom-left-radius: ${borderRadius[2]};
        border-bottom-right-radius: ${borderRadius[2]};
    }
`;

const SearchOverlay = () => {
    const clientHeight = useClientHeight();
    const headerHeights = useHeaderHeights();

    return (
        <TransitionElement
            className="slide-in search-overlay"
            clientHeight={clientHeight}
            headerHeights={headerHeights}
        >
            <Search />
        </TransitionElement>
    );
};

export default SearchOverlay;
