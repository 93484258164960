import Above from 'components/breakpoints/Above';
import Button from 'components/base/Button';
import CookieInformationButton from 'components/CookieInformationButton';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollButton from 'components/scrollComponents/ScrollButton';
import SiteSelector from './SiteSelector';
import { above } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Row = styled('div')`
    position: relative;
    display: flex;
    flex-wrap: wrap;

    ${above.tabletLg} {
        align-items: center;
    }
`;

const Div = styled('div')``;

const Icons = styled('ul')`
    display: inline-flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    margin: -8px;
`;

const Icon = styled('li')`
    padding: 8px;
`;

const CopyrightAndPrivacy = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;

    ${above.tabletSm} {
        flex-direction: row;
        align-items: center;
        gap: 0;
    }
`;

const PrivacyButton = styled('button')`
    text-decoration: underline;
`;
// NOTE: Country-select will not be in use in phase one. Uncomment all styles to restore site-selector layout.

const FooterBottomRow = ({ partners = [], privacyButton = {} }) => {
    const itemsTextStyles = styleObjectToProps('NB International/16_100');
    const copyrightTextStyles = styleObjectToProps('NB International/12_120_4');

    return (
        <Row width="100%" gap={['16px 0', null, null, null, '16px']} {...itemsTextStyles}>
            <Div
                display="flex"
                justifyContent="flex-start"
                order={1}
                width={['100%', null, null, null, 'calc(41.66666% - 10px)']}
            >
                <SiteSelector />
                {partners.length > 0 && (
                    <Icons>
                        {partners.map((partner, index) => (
                            <Icon key={`${partner.icon}-${index}`}>
                                <Link volatile to={partner.to}>
                                    <Image
                                        alt={partner.name}
                                        width="auto"
                                        height="24px"
                                        sizes="32px"
                                        src={{ url: partner.icon, width: [32, 64, 96] }} // Three sizes because of 1, 2 and 3 DPR
                                        title={partner.name}
                                    />
                                </Link>
                            </Icon>
                        ))}
                    </Icons>
                )}
            </Div>
            <CopyrightAndPrivacy
                order={2}
                width={['70%', null, null, null, '25%']}
                {...copyrightTextStyles}
            >
                <span>Copyright © {new Date().getFullYear()} New Wave Group&nbsp;</span>
                {privacyButton && (
                    <PrivacyButton as={Button} to={privacyButton.to}>
                        {privacyButton.label}
                    </PrivacyButton>
                )}
            </CopyrightAndPrivacy>
            {/* The button is located in FooterContent below DesktopSm */}
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <Div display="flex" height="35px" justifyContent="center" order={3} width="16.66666667%">
                        <CookieInformationButton marginTop="-7.5px" transformOrigin="center center" />
                    </Div>
                )}
            />

            <Div
                display="flex"
                flexGrow={['unset', null, null, null, '1']}
                justifyContent="flex-end"
                order={4}
                position="relative"
                width={['30%', null, null, null, 'auto']}
            >
                <ScrollButton color="var(--static-color-secondary)" />
            </Div>
        </Row>
    );
};

FooterBottomRow.propTypes = {
    partners: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    privacyButton: PropTypes.object,
};

export default FooterBottomRow;
