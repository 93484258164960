import React, { useState } from 'react';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import IncreaseOrDecrease from 'components/products/ProductCardMini/IncreaseOrDecrease';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import Price from '../Price';
import PropTypes from 'prop-types';
import SpinnerIcon from 'assets/icons/Spinner';
import TextLink from 'components/text/TextLink';
import { above } from 'utils/mediaqueries';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    position: relative;
    padding: 16px 0;

    ${above.tabletSm} {
        padding: 24px 0;
    }
`;

const ProductLink = styled(Link)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const InfoAndRemove = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    flex-grow: 1;
    padding-left: 8px;
    width: 50%;
`;

const Info = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 6px 0;
`;

const PriceAndControls = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;
`;

const Spinner = styled(SpinnerIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ProductCardMini = ({
    detailedColor,
    imageSrc = '',
    isOnSale = false,
    last = false,
    line,
    multipack = '',
    name = '',
    priceWithCurrency = '',
    quantity,
    salePriceWithCurrency = '',
    size = '',
    to = '',
    onClickPermalink = () => {},
    ...rest
}) => {
    const { t } = useTranslation();
    const basket = useSelector(state => state.basket);
    const [isLoading, setIsLoading] = useState(false);
    const srcWidths = [76, 95, 152, 190, 228, 285];
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    const isShowroom = useIsShowroom();

    const handleIncrease = line => {
        if (line) {
            setIsLoading(true);

            basket.updateBasketLineQuantity(line, +1).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleDecrease = line => {
        if (line) {
            setIsLoading(true);

            basket.updateBasketLineQuantity(line, -1).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleRemove = (line, quantity) => {
        if (line) {
            setIsLoading(true);

            basket.removeFromBasket(line, quantity).then(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <Wrapper
            borderBottom={!last ? '1px solid var(--static-opacity-color-secondary)' : 'none'}
            opacity={isLoading ? 0.6 : 1}
            {...rest}
        >
            <Link to={to} onClick={() => onClickPermalink()}>
                <AspectWrapper
                    backgroundColor="var(--static-placeholder-color)"
                    borderRadius="8px"
                    overflow="hidden"
                    ratio={ratios.vertical}
                    width={['78px', null, null, null, '97px']}
                >
                    <Image
                        alt={name}
                        mixBlendMode="multiply"
                        sizes="97px"
                        src={{ url: imageSrc, width: srcWidths, height: srcHeights }}
                        title={name}
                    />
                </AspectWrapper>
            </Link>
            <InfoAndRemove>
                <Info>
                    <ProductLink to={to} onClick={onClickPermalink}>
                        <Heading as="h3" fontKeys="Recife Display/16">
                            {name}
                        </Heading>
                    </ProductLink>
                    {detailedColor && (
                        <Paragraph as="span" fontKeys="NB International/12_120_4">
                            {detailedColor}
                        </Paragraph>
                    )}
                    {(multipack || size) && (
                        <Paragraph
                            as="span"
                            color="var(--dynamic-opacity-color-primary)"
                            fontKeys="NB International/12_120_4"
                        >
                            {multipack && size ? `${multipack} – ${size}` : multipack || size}
                        </Paragraph>
                    )}
                </Info>
                <TextLink
                    as="button"
                    color="var(--dynamic-color-light-five)"
                    textAlign="left"
                    mb="8px"
                    textTransform="uppercase"
                    onClick={() => handleRemove(line, quantity)}
                    {...styleObjectToProps('NB International/12_120_7')}
                >
                    {t('checkout_basket.remove')}
                </TextLink>
                {isLoading && <Spinner />}
            </InfoAndRemove>
            {!isShowroom && (
                <PriceAndControls>
                    <Price
                        flexDirection="column"
                        fontKeys={['NB International/14', null, null, null, 'NB International/16']}
                        gap="0 2px"
                        isOnSale={isOnSale}
                        priceWithCurrency={priceWithCurrency}
                        salePriceWithCurrency={salePriceWithCurrency}
                        textColor="var(--dynamic-color-primary)"
                        textColorLight="var(--dynamic-opacity-color-primary)"
                    />
                    <IncreaseOrDecrease
                        quantity={quantity}
                        decrease={() => handleDecrease(line)}
                        increase={() => handleIncrease(line)}
                    />
                </PriceAndControls>
            )}
        </Wrapper>
    );
};

ProductCardMini.propTypes = {
    detailedColor: PropTypes.string,
    first: PropTypes.bool,
    imageSrc: PropTypes.string.isRequired,
    isOnSale: PropTypes.bool.isRequired,
    last: PropTypes.bool,
    line: PropTypes.string,
    multipack: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClickPermalink: PropTypes.func,
    priceWithCurrency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    salePriceWithCurrency: PropTypes.string.isRequired,
    size: PropTypes.string,
    to: PropTypes.string.isRequired,
};

export default ProductCardMini;
