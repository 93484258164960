import React, { Component } from 'react';
import { injectModels } from 'state';
import PropTypes from 'prop-types';
import Events from 'libs/Events';

const dummy = () => {};
class KlarnaPaymentContainer extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        languageTransform: PropTypes.object,
        onError: PropTypes.func,
    };

    static defaultProps = {
        languageTransform: {},
        onError: dummy,
    };

    componentDidMount() {
        this.loadKlarna();
    }

    async loadKlarna() {
        const {
            application,
            basket: { paymentMethodId, getBasketPayment },
            onError,
            languageTransform,
        } = this.props;

        if (paymentMethodId) {
            const baseUrl = `${window.location.protocol}//${window.location.host}${application.path}`;
            const successUrl = `${baseUrl}${application.shop_config.checkout_thanks_uri}`;
            const errorUrl = `${baseUrl}${application.shop_config.checkout_error_uri}`;

            const centraLanguage = languageTransform[application.locale] || application.locale;

            // @TODO: RefreshCheckout?
            const response = await getBasketPayment(paymentMethodId, successUrl, errorUrl, centraLanguage);

            if (response) {
                // Secure that klarna container do exist
                if (document.getElementById('klarna-checkout-container') === null) {
                    return;
                }
                document.getElementById('klarna-checkout-container').innerHTML = '';

                if (response.data && response.data.formHtml) {
                    const script = document.createElement('div');
                    script.innerHTML = response.data.formHtml.trim();
                    const klarnaScript = script.getElementsByTagName('script')[0].innerHTML;

                    const tag = document.createElement('script');
                    tag.type = 'text/javascript';
                    tag.async = true;
                    tag.innerHTML = klarnaScript;
                    tag.id = 'klarna-checkout-script';
                    tag.style.paddingBottom = '30px';
                    document.getElementById('klarna-checkout-container').appendChild(tag);
                    Events.trigger('payment.loaded');
                } else {
                    // When this happens response has a completely different set of data
                    // As warning (Response Changed) and basket is a part of it
                    if (response.warning && response.warning.code === 3) {
                        // @TODO: Redo this.
                        onError &&
                            onError(
                                'Out of stock',
                                'Unfortunately we had to remove a product from your shopping cart due to low stock levels.'
                            );
                    }
                }
            }
        }
    }

    render() {
        return <div id="klarna-checkout-container" />;
    }
}

export default injectModels(['application', 'basket'])(KlarnaPaymentContainer);
