/* eslint-disable camelcase */
import React, { useMemo, useRef } from 'react';

import CmsModules from 'libs/wordpress/content/CmsModules';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import ProductHero from 'components/products/ProductHero';
import ProductInformation from 'components/products/ProductInformation';
import ProductMeta from 'components/metadata/ProductMeta';
import ProductStructuredData from 'components/metadata/structuredData/ProductStructuredData';
import PropTypes from 'prop-types';
import RelatedProducts from 'components/products/RelatedProducts';
import { bleedSpacing } from 'config/theme/contentSpacing';
import { inServer } from 'config/constants';
import { scrollTo } from 'utils/scrollFunctions';
import { stringReplace } from 'utils/string';
import styled from 'libs/styled';
import transformHeading from 'libs/wordpress/utils/transformHeading';
import { transformProductPageData } from 'utils/dataTransformers/product';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CmsModulesWrapper = styled('div')``;

const ProductView = ({ data, relationships = {} }) => {
    const { t } = useTranslation();
    const { desktopAndGap } = useHeaderHeights();
    const ref = useRef();
    const product = useMemo(() => transformProductPageData(data, relationships, t), [data, relationships, t]);

    const scrollToMoreInfo = () => {
        ref.current && scrollTo({ top: !inServer ? ref.current.offsetTop - desktopAndGap - 16 : 0 });
    };

    const {
        badges,
        basketProductData,
        breadcrumbs,
        characteristics,
        comingSoon,
        description,
        id,
        inStock,
        mediaGallery,
        name,
        pageContent,
        price,
        relatedProductsIds,
        relatedVariationAttributes,
        series,
        sku,
        variantData,
        variationId,
    } = product;

    const {
        attributes_label: attributesLabel,
        information_label: informationLabel,
        product_sliders: productSliders,
        usps,
    } = useSelector(state => state.application.config.options.product_page);

    const { more_in_series: moreInSeries, related_products: relatedProducts } = productSliders;

    const moreInSeriesHeadingsVariables = {
        '{series}': series?.displayText,
    };

    const transformedMoreInSeriesHeading = transformHeading(moreInSeries.heading);
    const transformedRelatedHeading = relatedProducts ? transformHeading(relatedProducts.heading) : {};

    if (transformedMoreInSeriesHeading?.text) {
        transformedMoreInSeriesHeading.text = stringReplace(
            transformedMoreInSeriesHeading.text,
            moreInSeriesHeadingsVariables
        );
    }

    return (
        <MaxWidthWrapper includeContentSpacing>
            <ProductMeta transformedProduct={product} />
            <ProductStructuredData />
            <ProductHero
                badges={badges}
                basketProductData={basketProductData}
                breadcrumbs={breadcrumbs}
                characteristics={characteristics}
                comingSoon={comingSoon}
                description={description}
                handleSeeMoreButtonClick={scrollToMoreInfo}
                images={mediaGallery}
                inStock={inStock}
                marginBottom={['48px', null, null, null, '56px']}
                name={name}
                price={price}
                relatedVariationAttributes={relatedVariationAttributes}
                sku={sku}
                usps={usps}
                variantData={variantData}
                variationId={variationId}
            />
            <ProductInformation
                attributesLabel={attributesLabel}
                characteristics={characteristics}
                description={description}
                informationLabel={informationLabel}
                paddingRight={[0, null, '4px', null, 0]}
                ref={ref}
                seriesDescription={series.description}
                seriesHeading={series.displayText}
            />
            {pageContent && (
                <CmsModulesWrapper mx={bleedSpacing}>
                    <CmsModules isProductPage data={pageContent} />
                </CmsModulesWrapper>
            )}
            {(relatedProductsIds?.length > 0 || series?.displayText) && (
                <RelatedProducts
                    currentId={id}
                    relatedHeading={transformedRelatedHeading}
                    relatedProductsIds={relatedProductsIds}
                    series={series.displayText}
                    seriesHeading={transformedMoreInSeriesHeading}
                />
            )}
        </MaxWidthWrapper>
    );
};

ProductView.propTypes = {
    data: PropTypes.object.isRequired,
    relationships: PropTypes.object,
};

export default ProductView;
