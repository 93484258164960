import React, { useEffect, useState } from 'react';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import Cards from './Cards';
import Categories from './Categories';
import NoResult from './NoResult';
import PropTypes from 'prop-types';
import Suggestions from './Suggestions';
import Tabs from './Tabs';
import ViewProductsButton from './ViewProductsButton';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useSelector } from 'react-redux';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['clientHeight', 'topSpaceMobile'].indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    ${above.desktopMd} {
        flex-direction: row;
        justify-content: space-between;
        gap: 0 16px;
        height: 100%;
        overflow: unset;
        padding: 0 24px;
    }
`;

const ScrollContent = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 16px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    ${above.desktopMd} {
        height: 80vh;
    }
`;

const SearchContent = ({
    articles = [],
    categories = [],
    defaultSuggestions = [],
    noResultText = '',
    products = [],
    productsHitsCount = 0,
    searchString = '',
    suggestions = [],
    updateSearch = () => {},
}) => {
    const hideOverlay = useSelector(state => state.overlay.hide);
    const searchPage = useSelector(state => state.application.config.options.specified_pages.search_results.slug);

    const hasArticles = articles.length > 0;
    const hasCategories = categories.length > 0;
    const hasProducts = products.length > 0;
    const hasSuggestions = suggestions.length > 0;
    const hasDefaultSuggestions = defaultSuggestions.length > 0;
    const noResult = !hasArticles && !hasCategories && !hasProducts;
    const hasSearched = !!searchString;

    const clientHeight = useClientHeight();
    const topSpaceMobile = 150;
    const topSpaceDesktop = 70;

    const defaultActiveTab = hasProducts ? 'products' : hasArticles ? 'articles' : null;
    const [activeTab, setActiveTab] = useState();

    useEffect(() => {
        if (defaultActiveTab !== activeTab) {
            setActiveTab(defaultActiveTab);
        }

         
    }, [articles, products]);

    return (
        <Wrapper clientHeight={clientHeight} topSpaceMobile={topSpaceMobile}>
            <>
                <Below
                    breakpoint="desktopMd"
                    render={() => (
                        <>
                            {noResult && <NoResult color="var(--dynamic-color-primary)" noResultText={noResultText} />}
                            {hasSearched && hasProducts && (
                                <ViewProductsButton
                                    display={['block', null, 'none']}
                                    hideOverlay={() => hideOverlay('searchOverlay')}
                                    hitsCount={productsHitsCount}
                                    marginBottom="32px"
                                    to={`${searchPage}?s=${encodeURI(searchString)}`}
                                    width="100%"
                                />
                            )}
                            {hasSearched && (hasArticles || hasProducts) && (
                                <Tabs
                                    activeTab={activeTab}
                                    hasArticles={hasArticles}
                                    hasProducts={hasProducts}
                                    changeTab={setActiveTab}
                                />
                            )}
                            <ScrollContent>
                                {hasSearched && (hasArticles || hasProducts) && (
                                    <Cards
                                        activeTab={activeTab}
                                        articles={articles}
                                        hasProducts={hasProducts}
                                        hideOverlay={() => hideOverlay('searchOverlay')}
                                        products={products}
                                    />
                                )}
                                {(hasSuggestions || hasDefaultSuggestions) && (
                                    <Suggestions
                                        marginTop={hasSearched && '32px'}
                                        categories={categories}
                                        suggestions={hasSuggestions ? suggestions : defaultSuggestions}
                                        updateSearch={updateSearch}
                                    />
                                )}
                                {hasSearched && hasCategories && (
                                    <Categories
                                        categories={categories}
                                        hideOverlay={() => hideOverlay('searchOverlay')}
                                        suggestions={suggestions}
                                    />
                                )}
                            </ScrollContent>
                        </>
                    )}
                />
                <Above
                    breakpoint="desktopMd"
                    render={() => (
                        <>
                            <Div
                                alignItems="flex-start"
                                display="flex"
                                flexDirection="column"
                                width={['100%', null, 'calc(50% - 8px)', null, 'calc(41.66666667% - 8px)']}
                            >
                                {noResult && (
                                    <NoResult
                                        color="var(--dynamic-color-primary)"
                                        marginTop="24px"
                                        noResultText={noResultText}
                                    />
                                )}
                                {(hasSuggestions || hasDefaultSuggestions) && (
                                    <Suggestions
                                        categories={categories}
                                        suggestions={hasSuggestions ? suggestions : defaultSuggestions}
                                        updateSearch={updateSearch}
                                        marginTop="32px"
                                        marginBottom={hasSearched ? '0' : '48px'}
                                    />
                                )}
                                {hasSearched && (
                                    <>
                                        {hasCategories && (
                                            <Categories
                                                categories={categories}
                                                hideOverlay={() => hideOverlay('searchOverlay')}
                                                suggestions={suggestions}
                                            />
                                        )}
                                        {hasProducts && (
                                            <ViewProductsButton
                                                hideOverlay={() => hideOverlay('searchOverlay')}
                                                hitsCount={productsHitsCount}
                                                marginTop="40px"
                                                to={`${searchPage}?s=${encodeURI(searchString)}`}
                                            />
                                        )}
                                    </>
                                )}
                            </Div>
                            <Div
                                marginTop={['none', null, null, null, `-${topSpaceDesktop}px`]}
                                width={['100%', null, null, null, 'calc(41.666% + 32px)']}
                                marginRight={['none', null, null, null, 'calc(8.333% + 16px)']}
                            >
                                {hasSearched && (hasArticles || hasProducts) && (
                                    <Tabs
                                        activeTab={activeTab}
                                        hasArticles={hasArticles}
                                        hasProducts={hasProducts}
                                        changeTab={setActiveTab}
                                    />
                                )}
                                {hasSearched && (
                                    <ScrollContent>
                                        <Cards
                                            activeTab={activeTab}
                                            articles={articles}
                                            hideOverlay={() => hideOverlay('searchOverlay')}
                                            padding="24px 0"
                                            products={products}
                                        />
                                    </ScrollContent>
                                )}
                            </Div>
                        </>
                    )}
                />
            </>
        </Wrapper>
    );
};

SearchContent.propTypes = {
    articles: PropTypes.array,
    categories: PropTypes.array,
    defaultSuggestions: PropTypes.array,
    noResultText: wysiwygProp,
    products: PropTypes.array,
    productsHitsCount: PropTypes.number,
    searchString: PropTypes.string,
    suggestions: PropTypes.array,
    updateSearch: PropTypes.func,
};

export default SearchContent;
