import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const SearchIcon = ({ color = colors.black, height = '100%', width = '15px', ...props }) => (
    <SVG width={width} height={height} fill="none" viewBox="0 0 15 15" {...props}>
        <Path
            clipRule="evenodd"
            d="M10.988 6a4.988 4.988 0 1 1-9.976 0 4.988 4.988 0 0 1 9.976 0Zm-1.14 4.604a6 6 0 1 1 .755-.755L15 14.244l-.755.755-4.397-4.396Z"
            fill={color}
            fillRule="evenodd"
        />
    </SVG>
);

SearchIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default SearchIcon;
