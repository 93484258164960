import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

const StyledLink = styled(Link)`
    text-decoration: underline;
`;

const Url = ({ attrs, children }) => {
    return (
        <StyledLink to={attrs['data-url']} tabIndex={attrs['tabIndex']}>
            {children}{' '}
        </StyledLink>
    );
};

Url.propTypes = {
    attrs: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export default Url;
