import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Wrapper = styled('div')``;

const NoResult = ({ noResultText = '', ...rest }) => (
    <Wrapper {...rest}>
        <Wysiwyg
            data={noResultText}
            textComponent={props => <WysiwygParagraph {...props} />}
            tagComponents={{ url: TextLinkWysisyg }}
        />
    </Wrapper>
);

NoResult.propTypes = {
    noResultText: wysiwygProp,
};

export default NoResult;
