import { useDispatch, useSelector } from 'react-redux';

import Basket from 'assets/icons/Basket';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import zIndex from 'config/theme/z-index';

const Button = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--dynamic-background-color-secondary);
    color: var(--dynamic-color-secondary);
`;

const BasketHeaderIcon = () => {
    const dispatch = useDispatch();
    const overlay = useSelector(state => state.overlay);
    const totalNumberOfProducts = useSelector(state => state.basket.totalNumberOfProducts);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);

    const toggleBasket = () => {
        const openOverlay = overlay.current;

        if (menuIsOpen) {
            dispatch(closeMenu());
        }

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', { zIndex: zIndex.basket });
        } else {
            overlay.hide();
        }
    };

    return (
        <Button type="button" onClick={() => toggleBasket()}>
            {totalNumberOfProducts > 0 && (
                <Paragraph
                    as="span"
                    display="block"
                    fontKeys="NB International/9"
                    left="50%"
                    marginTop="1px"
                    position="absolute"
                    top="50%"
                    transform="translate(-50%, -50%)"
                >
                    {totalNumberOfProducts}
                </Paragraph>
            )}
            <Basket color="currentColor" width="12px" />
        </Button>
    );
};

export default BasketHeaderIcon;
