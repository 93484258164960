import Helmet from 'react-helmet';
import React from 'react';
import { inServer } from 'config/constants';
import { useSelector } from 'react-redux';

const Favicon = () => {
    const { favicons, tool_bar_color: toolBarColor } = useSelector(
        state => state.application.config.global_options || {}
    );

    // Detect light- or dark-mode browsers
    const darkMode = !inServer ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;

    // Color theme for browser navigationbars etc depending on browser mode
    const { dark: darkColor, light: lightColor } = toolBarColor || {};

    // Use light or dark favicon depending on browser mode
    const { dark, light } = favicons || {};
    let favicon = {};
    if (darkMode && dark) {
        favicon = dark;
    } else if (light) {
        favicon = light;
    }
    const url = favicon.url;

    return (
        <>
            {url && (
                <Helmet>
                    {/* Favicons */}
                    <link rel="apple-touch-icon" sizes="57x57" href={`${url}?w=57&h=57&auto=format`} />
                    <link rel="apple-touch-icon" sizes="60x60" href={`${url}?w=60&h=60&auto=format`} />
                    <link rel="apple-touch-icon" sizes="72x72" href={`${url}?w=72&h=72&auto=format`} />
                    <link rel="apple-touch-icon" sizes="76x76" href={`${url}?w=76&h=76&auto=format`} />
                    <link rel="apple-touch-icon" sizes="114x114" href={`${url}?w=114&h=114&auto=format`} />
                    <link rel="apple-touch-icon" sizes="120x120" href={`${url}?w=120&h=120&auto=format`} />
                    <link rel="apple-touch-icon" sizes="144x144" href={`${url}?w=144&h=144&auto=format`} />
                    <link rel="apple-touch-icon" sizes="152x152" href={`${url}?w=152&h=152&auto=format`} />
                    <link rel="apple-touch-icon" sizes="180x180" href={`${url}?w=180&h=180&auto=format`} />
                    <link
                        rel="icon"
                        type={favicon.meta?.mime_type}
                        sizes="16x16"
                        href={`${url}?w=16&h=16&auto=format`}
                    />
                    <link
                        rel="icon"
                        type={favicon.meta?.mime_type}
                        sizes="32x32"
                        href={`${url}?w=32&h=32&auto=format`}
                    />
                    <link
                        rel="icon"
                        type={favicon.meta?.mime_type}
                        sizes="96x96"
                        href={`${url}?w=96&h=96&auto=format`}
                    />
                    <link
                        rel="icon"
                        type={favicon.meta?.mime_type}
                        sizes="192x192"
                        href={`${url}?w=192&h=192&auto=format`}
                    />

                    {/* MS Tile */}
                    <meta name="msapplication-TileImage" content={`${url}?w=150&h=150&auto=format`} />
                    <meta name="msapplication-TileColor" content={darkMode ? darkColor : lightColor} />
                </Helmet>
            )}
            <Helmet>
                {/* The theme-color sets the color of the tool bar */}
                {lightColor && <meta name="theme-color" content={lightColor} media="(prefers-color-scheme: light)" />}
                {darkColor && <meta name="theme-color" content={darkColor} media="(prefers-color-scheme: dark)" />}
            </Helmet>
        </>
    );
};

export default Favicon;
