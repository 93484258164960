import ProductCardMini from 'components/products/ProductCardMini';
import PropTypes from 'prop-types';
import React from 'react';
import Summary from 'Header/Default/Basket/WithProducts/Summary';
import styled from 'libs/styled';

const Products = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }

    > div {
        border-top: 1px solid var(--dynamic-color-light-four);
    }
`;

const WithProducts = ({ closeBasket = () => {}, products = [] }) => (
    <>
        <Products>
            {products.map((product, index) => (
                <ProductCardMini
                    key={`${product.id}-${index}`}
                    last={index === products.length - 1}
                    position={index}
                    onClickPermalink={() => closeBasket()}
                    {...product}
                />
            ))}
        </Products>
        <Summary closeBasket={closeBasket} />
    </>
);

WithProducts.propTypes = {
    closeBasket: PropTypes.func.isRequired,
    products: PropTypes.array,
};

export default WithProducts;
