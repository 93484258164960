import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Form = styled('form')`
    position: relative;
    cursor: text;
`;

const HiddenPlaceholder = styled('span')`
    position: absolute;
    height: 0;
    overflow: hidden;
    white-space: pre;
`;

const SearchField = styled('input', {
    shouldForwardProp: prop => ['hasValue', 'placeholderColor'].indexOf(prop) === -1,
})`
    width: 100%;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: inherit;
    background-color: inherit;

    ::placeholder {
        color: ${({ placeholderColor }) => placeholderColor};
    }

    :focus {
        outline: none;

        + .editText {
            display: none;
        }
    }

    &[type='search'] {
        -webkit-appearance: none;
    }

    /* clears the 'X' from Chrome */
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        display: none;
    }
`;

const EditButton = styled('button')`
    position: absolute;
    left: 0;
    bottom: -16px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    color: 'var(--dynamic-color-color-light-five)';

    ${above.tabletSm} {
        left: 50%;
        transform: translateX(-50%);
    }
`;

const SearchInput = ({
    delay = 500,
    editButtonStyling = {},
    focusOnMount = true,
    handleSubmit = () => null,
    maxSearch = 100,
    placeholderColor,
    placeholderText,
    searchFieldStyling = {},
    searchString = '',
    showEdit = false,
    singleRow,
    updateSearch = () => null,
    ...rest
}) => {
    const { t } = useTranslation();

    /*** MAIN FUNCTIONS: Handle search ***/

    // Main functions are necessary for the search to work
    const searchTimeout = useRef(null);
    const inputRef = useRef(null);

    // localSearchString is used for instant updates for the input value
    // searchString is not used for this because of the delayed update
    const [localSearchString, setLocalSearchString] = useState(searchString);

    // searchString will display as default, otherwise localSearchString will be set
    const displaySearchString = localSearchString !== null ? localSearchString : searchString;

    // Update localSearchString if a new searchString is provided
    useEffect(() => {
        setLocalSearchString(searchString);
    }, [searchString]);

    /*** EditText functions ***/
    // These functions are used to position the EditText-node properly
    // Only show edit text if local search string has a value
    const [showEditText, setShowEditText] = useState(!!localSearchString);
    const editRef = useRef(null);
    const hiddenRef = useRef(null);

    // Handle functions connected to EditText
    const updateEditTextStyles = () => {
        const inputWidth = inputRef.current.offsetWidth;
        const searchStringWidth = hiddenRef.current.offsetWidth;
        const editTextWidth = 110;

        // See if EditText fits on screen and a search string has a value, hide it otherwise
        setShowEditText(!!localSearchString && searchStringWidth + editTextWidth < inputWidth);
    };

    // Handle delayed for searchString
    // Used to prevent a search overload
    useEffect(() => {
        clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => {
            updateSearch(localSearchString);
        }, delay);

        updateEditTextStyles();

        return () => clearTimeout(searchTimeout.current);
    }, [localSearchString, editRef]);

    /*** Misc functions ***/
    // Focus input on mount, used when opening header search
    useEffect(() => {
        if (focusOnMount) {
            inputRef.current.focus();
        }
    }, [focusOnMount]);

    // Function triggers on submit
    const handleOnSubmit = e => {
        e.preventDefault();
        inputRef.current.blur();
        handleSubmit(e.target.children[1].value);
    };

    return (
        <Form onClick={() => inputRef.current.focus()} onSubmit={e => handleOnSubmit(e)} {...rest}>
            <HiddenPlaceholder ref={hiddenRef} {...searchFieldStyling}>
                {displaySearchString || placeholderText || t('search.placeholder')}
            </HiddenPlaceholder>
            <SearchField
                autoComplete="off"
                maxLength={maxSearch}
                placeholder={placeholderText || t('search.placeholder')}
                placeholderColor={placeholderColor}
                ref={inputRef}
                type="search"
                value={displaySearchString}
                onChange={e => setLocalSearchString(e.target.value)}
                {...searchFieldStyling}
                lineHeight="125%" // Prevent clipped off text
            />
            {showEdit && (
                <EditButton
                    className="editText"
                    display={showEditText ? 'inline' : 'none'}
                    ref={editRef}
                    type="button"
                    onClick={() => inputRef.current.focus()}
                    {...styleObjectToProps('NB International/12_120_7')}
                    {...editButtonStyling}
                >
                    {`${t('search.edit_search')}`}
                </EditButton>
            )}
        </Form>
    );
};

SearchInput.propTypes = {
    delay: PropTypes.number,
    editButtonStyling: PropTypes.object,
    focusOnMount: PropTypes.bool,
    handleSubmit: PropTypes.func,
    maxSearch: PropTypes.number,
    placeholderColor: PropTypes.string,
    placeholderText: PropTypes.string,
    searchFieldFontKeys: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    searchFieldStyling: PropTypes.object,
    searchString: PropTypes.string,
    showEdit: PropTypes.bool,
    singleRow: PropTypes.bool,
    updateSearch: PropTypes.func,
};

export default SearchInput;
