import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { animations } from 'config/theme/transitions';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';

const StyledLink = styled(Link, {
    shouldForwardProp: prop => ['duration', 'easing', 'noUnderline'].indexOf(prop) === -1,
})`
    position: relative;
    display: inline-block;

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: currentColor;

        ${({ noUnderline, duration, easing }) =>
            noUnderline &&
            `
                transform: scale3d(0, 1, 1);
                transform-origin: 100% 50%;
                transition: all ${duration / 2}ms ${easing}
            `}
    }

    ${media.hover} {
        &:hover::after {
            /* animation: noUnderlinehoverTextLink 300ms linear;
            transform: scale3d(1, 1, 1); */
            ${({ noUnderline, duration, easing }) =>
                noUnderline
                    ? `animation: noUnderlinehoverTextLink ${duration / 2}ms ${easing}; transform: scale3d(1, 1, 1);`
                    : `animation: hoverTextLink ${duration}ms ${easing};`}
        }
    }

    @keyframes hoverTextLink {
        0% {
            transform: scale3d(1, 1, 1);
            transform-origin: 100% 50%;
        }
        50% {
            transform: scale3d(0, 1, 1);
            transform-origin: 100% 50%;
        }
        51% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }

    @keyframes noUnderlinehoverTextLink {
        0% {
            transform: scale3d(0, 1, 1);
            transform-origin: 0% 50%;
        }
        100% {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 50%;
        }
    }
`;

/**
 * TextLinkUnderline
 *
 * @param {number} duration - Transition duration in ms
 * @param {string} easing - Transition timing function
 * @param {bool} noUnderline - If true, the link will not have a underline as default, it will only appear on hover
 * @param {string} to - Link to internal or external url. Acts like a href.
 */

const TextLinkUnderline = ({
    children,
    duration = 600,
    easing = animations.primary.timingFunction,
    noUnderline = true,
    to = '',
    ...rest
}) => (
    <StyledLink
        as={to ? Link : 'button'}
        duration={duration}
        easing={easing}
        noUnderline={noUnderline}
        to={to}
        {...rest}
    >
        {children}
    </StyledLink>
);

TextLinkUnderline.propTypes = {
    children: PropTypes.node.isRequired,
    duration: PropTypes.number,
    easing: PropTypes.string,
    noUnderline: PropTypes.bool,
    to: PropTypes.string,
};

export default TextLinkUnderline;
