import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const CheckIcon = ({ color = colors.black, height = '100%', width = '12px', ...rest }) => (
    <SVG width={width} height={height} fill="none" viewBox="0 0 12 8" {...rest}>
        <Path clipRule="evenodd" d="m0 4 1-.5 2.5 2L11 0l.5 1-8.063 7L0 4Z" fill={color} fillRule="evenodd" />
    </SVG>
);

CheckIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default CheckIcon;
