import CmsModules from 'libs/wordpress/content/CmsModules';
import Heading from 'components/text/Heading';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import ThemeButton from 'components/buttons/ThemeButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import styled from 'libs/styled';
import transformButton from 'libs/wordpress/utils/transformButton';
import useHeaderHeights from 'hooks/useHeaderHeights';

const Div = styled('div')``;

const NotFound = ({ data }) => {
    const { hero, page_content: pageContent } = data || {};
    const { button: rawButton, heading, paragraph } = hero || {};
    const button = transformButton(rawButton, { theme: 'solid' });
    const { mobileAndGap, desktopAndGap } = useHeaderHeights();

    return (
        <>
            <MaxWidthWrapper
                includeContentSpacing
                alignItems={['center', null, 'flex-start']}
                display="flex"
                flexDirection={['column', null, 'row-reverse']}
                justifyContent={['flex-start', null, 'space-between']}
                marginBottom={['80px', null, '112px', null, '136px']}
                marginTop={[`${mobileAndGap + 104}px`, null, `${mobileAndGap + 64}px`, null, `${desktopAndGap + 64}px`]}
            >
                <Heading
                    as="span"
                    fontKeys="Recife Display/64"
                    fontSize={['64px', null, null, null, '138px']} // Custom for just this page
                    paddingBottom={['16px', null, '24px']}
                >
                    404
                </Heading>
                <Div
                    alignItems={['center', null, 'flex-start']}
                    display="flex"
                    flexDirection="column"
                    gap="24px 0"
                    marginTop={['40px', null, '0']}
                    paddingRight={['0', null, '48px']}
                    textAlign={['center', null, 'left']}
                    width={['100%', null, 'calc(66.66666667% - 8px)', 'calc(41.66666667% - 8px)']}
                >
                    {heading && (
                        <Heading
                            as="h1"
                            fontKeys={[
                                'Recife Display/24_120',
                                null,
                                'Recife Display/32_120',
                                null,
                                'Recife Display/40',
                            ]}
                            margin="0"
                        >
                            {heading}
                        </Heading>
                    )}
                    {paragraph && (
                        <Wysiwyg
                            data={paragraph}
                            tagComponents={{ url: TextLinkWysisyg }}
                            textComponent={props => <WysiwygParagraph {...props} />}
                        />
                    )}
                    {button && (
                        <ThemeButton theme={button.theme} to={button.link}>
                            {button.label}
                        </ThemeButton>
                    )}
                </Div>
            </MaxWidthWrapper>
            <CmsModules data={pageContent} />
        </>
    );
};

NotFound.propTypes = {
    data: PropTypes.any,
};

export default NotFound;
