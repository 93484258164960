import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 6px 0;
`;

const InputAndLabel = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
`;

const StyledInput = styled('input')`
    display: none;

    ${media.hover} {
        :hover + label::before {
            background-color: var(--dynamic-color-primary);
            border: 1px solid var(--dynamic-color-primary);
            box-shadow: inset 0 0 0 2px var(--dynamic-background-color-primary);
        }
    }

    :checked + label::before {
        background-color: var(--dynamic-color-primary);
        border: 1px solid var(--dynamic-color-primary);
        box-shadow: inset 0 0 0 2px var(--dynamic-background-color-primary);
    }

    :required {
        + label {
            ::after {
                content: '*';
                display: block;
                position: absolute;
                top: 0;
                right: -7px;
            }
        }
    }
`;

const Label = styled('label')`
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    color: var(--dynamic-color-primary);

    ::before {
        content: '';
        display: inline-block;
        border: 1px solid var(--dynamic-color-primary);
        border-radius: 1px;
        height: 14px;
        width: 14px;
        margin-right: 8px;
        flex-shrink: 0;
        transition: background ${transitions.primary};
        transform: translateY(1px); // Alignment fix
    }
`;

const ErrorMessage = styled(Paragraph)`
    color: var(--dynamic-color-error);
    margin: 4px 0 0 24px;
`;

const Checkbox = ({
    checked,
    error = {},
    handleBlur = () => {},
    handleChange = () => {},
    label = '',
    name = '',
    required = false,
    ...rest
}) => {
    const fontStyles = styleObjectToProps('NB International/16_100');
    const errorFontStyles = styleObjectToProps('NB International/12_120_7');

    return (
        <Wrapper {...rest}>
            <InputAndLabel>
                <StyledInput
                    aria-label={label}
                    checked={checked}
                    id={name}
                    name={name}
                    required={required}
                    type="checkbox"
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                <Label htmlFor={name} {...fontStyles}>
                    {label}
                </Label>
            </InputAndLabel>
            {error.message && <ErrorMessage {...errorFontStyles}>*{error.message}</ErrorMessage>}
        </Wrapper>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    error: PropTypes.exact({
        message: PropTypes.string,
        name: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

export default Checkbox;
