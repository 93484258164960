/* eslint-disable camelcase */
import AlgoliaContainer from 'containers/AlgoliaContainer';
import ProductsPromotion from 'components/contentLayouts/ProductsPromotion';
import PropTypes from 'prop-types';
import React from 'react';
import { inServer } from 'config/constants';
import { transformAlgoliaProductResponse } from 'utils/dataTransformers/product';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RelatedProducts = ({ currentId, relatedProductsIds, series, seriesHeading, relatedHeading }) => {
    const { t } = useTranslation();
    const customAttributesRelationship = useSelector(
        state => state.application.config.options.custom_attributes_relationship
    );
    const locale = useSelector(state => state.application.locale);
    const marketId = useSelector(state => state.application.shop_config.market_id);
    const pricelistId = useSelector(state => state.application.shop_config.pricelist_id);

    /**
     * Prepp data for Algolia search
     */

    let seriesIndex = null;
    let relatedIndex = null;
    const parameters = [];

    // Add parameters to find products in same series
    if (series) {
        seriesIndex = 0;
        parameters.push({
            indexName: `products_${locale}`,
            params: {
                filters: [
                    [`_marketplaces:${marketId}`],
                    [`_pricelists:${pricelistId}`],
                    [`_serie:'${series}'`],
                    [`NOT id:${currentId}`],
                ],
                hitsPerPage: 10, // Get some extra products to be able to shuffle the order
                page: 1,
            },
        });

        // Make sure that series dosn't include duplicates from related products
        if (relatedProductsIds.length > 0) {
            relatedProductsIds.forEach(id => {
                parameters[0].params.filters.push([`NOT id:${id}`]);
            });
        }
    }

    // Add parameters to get data for related products
    if (relatedProductsIds.length > 0) {
        relatedIndex = seriesIndex === 0 ? 1 : 0;
        parameters.push({
            indexName: `products_${locale}`,
            params: {
                filters: [
                    relatedProductsIds.map(id => `id:${id}`),
                    [`_marketplaces:${marketId}`],
                    [`_pricelists:${pricelistId}`],
                ],
                hitsPerPage: 10,
                page: 1,
            },
        });
    }

    /**
     * Prepp data for "More In Series" button
     */

    let moreInSeriesButton = {};

    const attrRelationshipMap = customAttributesRelationship
        ? customAttributesRelationship.reduce((map, attr) => {
              map[attr.internal_name.trim()] = attr.relationship?.url;
              return map;
          }, {})
        : {};

    if (attrRelationshipMap[series]) {
        moreInSeriesButton = {
            label: t('product_page.see_all_products'),
            to: attrRelationshipMap[series],
            theme: 'outlinedBlack',
        };
    }

    return (
        <AlgoliaContainer
            parameters={parameters}
            render={({ response }) => {
                if (!response) {
                    return null;
                }

                const moreInSeriesProducts =
                    seriesIndex !== null && response[seriesIndex]
                        ? transformAlgoliaProductResponse(response[seriesIndex], marketId, t).hits
                        : [];
                const relatedProducts =
                    relatedIndex !== null && response[relatedIndex]
                        ? transformAlgoliaProductResponse(response[relatedIndex], marketId, t).hits
                        : [];

                // Prevent same products in the same order on all products in same series
                const shuffledMoreInSeriesProducts = moreInSeriesProducts.sort(() => Math.random() - 0.5);

                return (
                    <>
                        {moreInSeriesProducts.length > 0 ? (
                            <ProductsPromotion
                                button={moreInSeriesButton}
                                heading={seriesHeading?.text}
                                headingTag={seriesHeading?.type}
                                loadImages={!inServer} // Loading images on server breaks shuffle
                                my={['56px', null, null, null, '80px', '112px']}
                                products={shuffledMoreInSeriesProducts}
                            />
                        ) : null}
                        {relatedProducts.length > 0 ? (
                            <ProductsPromotion
                                heading={relatedHeading?.text}
                                headingTag={relatedHeading?.type}
                                products={relatedProducts}
                                my={['56px', null, null, null, '80px', '112px']}
                            />
                        ) : null}
                    </>
                );
            }}
        />
    );
};

RelatedProducts.propTypes = {
    currentId: PropTypes.string,
    relatedHeading: PropTypes.object,
    relatedProductsIds: PropTypes.array,
    series: PropTypes.string,
    seriesHeading: PropTypes.object,
};

export default RelatedProducts;
