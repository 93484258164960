import { useSelector } from 'react-redux';

export const mobileHeaderHeight = 60;
export const desktopHeaderHeight = 84;
export const mobileTopGapHeight = 8;
export const desktopTopGapHeight = 24;

/**
 * Used for handling height regarding the header across the site.
 */

const useHeaderHeights = () => {
    const hasBanner = useSelector<any, boolean>(state => state.header.state.hasBanner);
    const bannerHeight = 26;
    const dynamicBannerHeight = hasBanner ? bannerHeight : 0;

    return {
        banner: dynamicBannerHeight,
        desktop: desktopHeaderHeight,
        desktopAndGap: desktopHeaderHeight + desktopTopGapHeight,
        desktopGap: desktopTopGapHeight,
        desktopGapAndBanner: desktopHeaderHeight + desktopTopGapHeight + dynamicBannerHeight,
        mobile: mobileHeaderHeight,
        mobileAndGap: mobileHeaderHeight + mobileHeaderHeight,
        mobileGap: mobileTopGapHeight,
        mobileGapAndBanner: mobileHeaderHeight + mobileHeaderHeight + dynamicBannerHeight,
    };
};

export default useHeaderHeights;
