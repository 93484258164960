import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const ArrowDownIcon = ({ color = colors.black, height = '100%', width = '16px', ...rest }) => (
    <SVG width={width} height={height} fill="none" viewBox="0 0 16 15" {...rest}>
        <Path
            clipRule="evenodd"
            d="M.349609 5.25924 1.00049 4.5l6.99991 4 7.0001-4 .6507.75924L8.0004 11.5.349609 5.25924Z"
            fill={color}
            fillRule="evenodd"
        />
    </SVG>
);

ArrowDownIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default ArrowDownIcon;
