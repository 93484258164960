import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Loading = styled('div')`
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-left: 3px solid ${colors.grey.three};
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 26px;
    height: 26px;

    @keyframes load8 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default Loading;
