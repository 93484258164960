import { borderRadius, hoverRadius } from 'config/theme/borderRadius';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const ImageWrapper = styled('div', {
    shouldForwardProp: prop => ['hoverRadius', 'quickshop'].indexOf(prop) === -1,
})`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--dynamic-placeholder-color);
    transition: border-radius ${transitions.primary};

    .hover-image {
        opacity: 0;
    }

    ${media.hover} {
        ${({ quickshop }) =>
            !quickshop &&
            `
            :hover {
                border-radius: ${hoverRadius};
            }
        `}
    }
`;

const ProductCardImage = ({
    alt = '',
    hoverImage = '',
    imageSizes = ['50vw', '50vw', '33.33vw', null, '650px'],
    loadImage = true,
    loading = 'lazy',
    primaryImage,
    quickshop = false,
    srcWidths = [188, 384, 341, 480, 650],
    title,
}) => {
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');

    return (
        <ImageWrapper
            borderBottomLeftRadius={quickshop ? '0' : borderRadius}
            borderBottomRightRadius={quickshop ? '0' : borderRadius}
            borderTopLeftRadius={borderRadius}
            borderTopRightRadius={borderRadius}
            hoverRadius={hoverRadius}
            overflow="hidden"
            quickshop={quickshop}
        >
            {loadImage && (
                <>
                    <Image
                        alt={alt || title}
                        height="100%"
                        left="0"
                        loading={loading}
                        mixBlendMode="multiply"
                        objectFit="cover"
                        position="absolute"
                        quality="90"
                        sizes={imageSizes}
                        src={{ url: primaryImage, width: srcWidths, height: srcHeights }}
                        title={title}
                        top="0"
                        transition={`opacity ${transitions.primary}`}
                        width="100%"
                    />
                    {hoverImage && isAboveDesktopSm && (
                        <Image
                            alt={alt || title}
                            className="hover-image"
                            height="100%"
                            left="0"
                            loading="none"
                            objectFit="cover"
                            position="absolute"
                            sizes={imageSizes}
                            src={{ url: hoverImage, width: srcWidths, height: srcHeights }}
                            title={title}
                            top="0"
                            transition={`opacity ${transitions.primary}`}
                            width="100%"
                        />
                    )}
                </>
            )}
        </ImageWrapper>
    );
};

ProductCardImage.propTypes = {
    alt: PropTypes.string,
    hoverImage: PropTypes.string,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    loadImage: PropTypes.bool,
    loading: PropTypes.string,
    primaryImage: PropTypes.string.isRequired,
    quickshop: PropTypes.bool,
    srcWidths: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    title: PropTypes.string.isRequired,
};

export default ProductCardImage;