import colors from './colors';

interface Theme {
    backgroundColor: string;
    color: string;
    errorColor: string;
    opacityColor: string;
    solidButtonBackgroundColorHover: string;
}

// Export used in changeSiteColorTheme.ts
export type ColorThemes = 'black_white' | 'default';

/*@todo: 
 "[key in ColorThemes]" generates an eslint error due to eslint not being configured for typescript.
 Solved with ignoring this file in the eslint config.
 Come back to this and add configuration for typescript in eslint.
*/
type Themes = {
    [key in ColorThemes]: Theme;
};

export const themes = {
    default: {
        activeColor: colors.green.two,
        errorColor: colors.error,
        primaryColor: colors.black,
        secondaryColor: colors.white,
    },
    'black_white': {
        activeColor: colors.green.two,
        errorColor: colors.error,
        primaryColor: colors.white,
        secondaryColor: colors.black,
    },
};
