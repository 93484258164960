import React, { useState } from 'react';

import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import styleObjectToProps from 'utils/styleObjectToProps';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AddMultipleToBasketButton = ({ multipleIds = [], ...rest }) => {
    const { t } = useTranslation();
    const basket = useSelector(state => state.basket);
    const quantity = 1;

    const numberOfProducts = multipleIds.length;
    const [numberOfProductsAdded, setNumberOfProductsAdded] = useState(numberOfProducts);

    const [buttonState, setButtonState] = useState({
        success: false,
        loading: false,
        warning: false,
    });

    const addMultipleToBasket = async (items = [], quantity = 1) => {
        setButtonState({ ...buttonState, loading: true });

        try {
            const response = await basket.addMultipleToBasket(items, quantity, null);

            if (response.status === 200 || response.status === 201) {
                setButtonState({ ...buttonState, success: true, loading: false });
                setNumberOfProductsAdded(response.data.valid.length);
            } else {
                setButtonState({ ...buttonState, warning: true, success: false, loading: false });
            }
        } catch (error) {
            console.error(error);
            setButtonState({ ...buttonState, warning: true });
        } finally {
            setTimeout(
                () =>
                    setButtonState({
                        success: false,
                        loading: false,
                        warning: false,
                    }),
                2800
            );
        }
    };

    const getButtonText = (buttonState, numberOfProducts, numberOfProductsAdded) => {
        if (buttonState.success) {
            return `${t('checkout_basket.added')} (${numberOfProductsAdded}/${numberOfProducts})`;
        }

        if (buttonState.warning) {
            return t('checkout_basket.error');
        }

        return `${t('checkout_basket.apply_all')} (${numberOfProducts})`;
    };

    const getLoading = () => {
        return basket.isFetching || buttonState.loading;
    };

    const transformProductsData = multipleIds.map(id => {
        return {
            'product_variation_id': id,
            quantity,
        };
    });

    const isLoading = getLoading();
    const buttonText = getButtonText(buttonState, numberOfProducts, numberOfProductsAdded);

    return (
        <ThemeButton
            width="100%"
            display="inline-flex"
            theme="solid"
            {...styleObjectToProps('Recife Display/14')}
            {...rest}
            onClick={() => {
                if (!isLoading) {
                    addMultipleToBasket(transformProductsData, quantity);
                }
            }}
        >
            {buttonText}
        </ThemeButton>
    );
};

AddMultipleToBasketButton.propTypes = {
    multipleIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default AddMultipleToBasketButton;
