import ColorDotWithText from 'components/Filter/ColorDotWithText';
import PropTypes from 'prop-types';
import React from 'react';
import { getSortedFilters } from 'utils/dataTransformers/filters';
import styled from 'libs/styled';

const Wrapper = styled('li')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px 16px;
`;

const FilterColorOption = ({
    filters = [],
    handleChange = () => {},
    parentFilterKey = '',
    sortBy = 'alphabetically',
}) => {
    const sortedFilters = getSortedFilters(filters, sortBy);

    return (
        <Wrapper>
            {sortedFilters.map(item => (
                <ColorDotWithText
                    imageUrl={item.image}
                    isActive={item.selected}
                    key={item.value}
                    text={item.value}
                    useOutline={item.useOutline}
                    width="20%"
                    handleClick={() => handleChange(parentFilterKey, item.value)}
                />
            ))}
        </Wrapper>
    );
};

FilterColorOption.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, image: PropTypes.string, selected: PropTypes.bool })
    ).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
    sortBy: PropTypes.string,
};

export default FilterColorOption;
