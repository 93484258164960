// Models
import Application from './Application';
import Basket from './Basket';
import Header from './Header';
import LatestAddedProduct from './LatestAddedProduct';
import Newsletter from './Newsletter';
import Notice from './Notice';
import Overlay from './Overlay';
import Page from './Page';
import Popups from './Popups';
import Product from './Product';
import Products from './Products';
import Search from './Search';
import Site from './Site';
import Wishlists from './Wishlists';

let models = {};

try {
    const themeModels = require('state');
    if (themeModels.default) {
        models = themeModels.default;
    }
} catch (e) {
    // Do nothing.
}

export default {
    application: Application,
    basket: Basket,
    header: Header,
    latestAddedProduct: LatestAddedProduct,
    notice: Notice,
    newsletter: Newsletter,
    overlay: Overlay,
    page: Page,
    popups: Popups,
    product: Product,
    products: Products,
    search: Search,
    site: Site,
    wishlists: Wishlists,
    ...models,
};
