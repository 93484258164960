import React from 'react';
import PropTypes from 'prop-types';

/**
 * Creates the added Twitter meta tags given
 */

const createOgTags = ({ title = '', description = '', media = '' }) => {
    // Create new list with meta and add the current locale directly
    const twitterDescription = description || null;
    const twitterTitle = title || null;
    const imageSrc = typeof media === 'string' ? media : media?.src;
    const twitterImage = imageSrc ? `${imageSrc}?w=1200&q=70&auto=format` : null; // Twitter wants specific widths and heights on their images;

    return (
        <>
            {twitterTitle && <meta property="twitter:title" content={twitterTitle} />}
            {twitterDescription && <meta property="twitter:description" content={twitterDescription} />}
            {twitterImage && <meta property="twitter:image" content={twitterImage} />}
        </>
    );
};

createOgTags.propTypes = {
    description: PropTypes.string,
    media: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
};

export default createOgTags;