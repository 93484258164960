import React, { useMemo } from 'react';

import Checkout from './Checkout';
import Default from './Default';
import { useSelector } from 'react-redux';

const Header = () => {
    const pageTemplate = useSelector(state => state.page.template);
    const checkoutTemplates = pageTemplate === 'ecommerce_checkout';
    return useMemo(() => (checkoutTemplates ? <Checkout /> : <Default />), [checkoutTemplates]);
};

export default Header;
