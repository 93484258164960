import React, { useEffect, useState } from 'react';
import { transformArticleCard, transformArticleCategories, transformArticleDate } from 'utils/dataTransformers/article';

import ArticleFlow from 'components/contentLayouts/ArticleFlow';
import ArticleStructuredData from 'components/metadata/structuredData/ArticleStructuredData';
import CmsModules from 'libs/wordpress/content/CmsModules';
import Hero from 'components/pages/post/Hero';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import { Search } from 'libs/Algolia-v2';
import moduleSpacing from 'config/theme/moduleSpacing';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SingleArticle = ({ data }) => {
    const {
        author,
        breadcrumbs,
        categories,
        created_at: createdAt,
        hero,
        id,
        page_content: pageContent,
        preamble,
        preview,
        publish_date: publishDate,
        seo,
        tags,
        title,
    } = data;

    const { t } = useTranslation();
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const { mobileAndGap, desktopAndGap } = useHeaderHeights();
    const [relatedArticles, setRelatedArticles] = useState([]);
    const appID = useSelector(state => state.application.applicationId);
    const articlesPage = useSelector(state => state.application.config.options.specified_pages.magazine?.slug);
    const magazinePage = useSelector(state => state.application.config.options.specified_pages.magazine);
    const categoryIds = Object.keys(categories);
    const hasRelatedArticles = relatedArticles.length > 0;

    const updatedBreadcumbs = [...breadcrumbs];

    if (magazinePage) {
        updatedBreadcumbs.unshift({
            title: magazinePage.title,
            permalink: magazinePage.slug,
        });
    }

    // Transform background data
    const { background_media_flexible: backgroundObject } = hero || {};
    const transformedBackground = transformFlexibleBackground(backgroundObject);
    const background = isAboveDesktopSm ? transformedBackground?.desktop : transformedBackground?.mobile;
    const backgroundType = isAboveDesktopSm
        ? transformedBackground?.desktop?.type
        : transformedBackground?.mobile?.type;
    const formattedDate = transformArticleDate(publishDate || createdAt);

    // If there are no tag present in the hero, add article categories as the tag
    const articleTags =
        Object.keys(tags).length > 0 ? transformArticleCategories(tags) : transformArticleCategories(categories);

    // Algolia filter search which returns an array with related articles
    useEffect(() => {
        const allFilters = [
            // Add application id to get the correct market
            [`application_id: ${appID}`],
            [`categories_ids: ${categoryIds}`],
        ];

        Search(`articles`, '', {
            filters: allFilters,
            length: 3,
            offset: 0,
        }).then(response => {
            const result = response?.results?.[0] || [];
            if (result) {
                const transformedArticles = result.hits
                    .map(article => transformArticleCard(article))
                    .filter(article => article.id !== id); // Filter out the article we are currently on

                // Save articles
                setRelatedArticles(transformedArticles);
            }
        });
    }, [categories]);

    return (
        <>
            <ArticleStructuredData
                articleSummary={{ excerpt: seo?.description, imageSrc: background?.url }}
                author={data.author || ''}
                dateModified={data.updated_at || ''}
                datePublished={data.publish_date || data.created_at || ''}
                keywords={data.seo?.keywords}
                permalink={data.permalink}
                title={data.title || ''}
            />
            <MaxWidthWrapper
                includeContentSpacing
                marginTop={[`${mobileAndGap + 12}px`, null, null, null, `${desktopAndGap + 12}px`]}
            >
                <Hero
                    author={preview?.author || author || null}
                    background={background}
                    backgroundType={backgroundType}
                    breadcrumbs={updatedBreadcumbs}
                    categories={articleTags}
                    date={formattedDate}
                    preamble={preamble}
                    title={title}
                />
            </MaxWidthWrapper>
            {pageContent && <CmsModules data={pageContent} />}
            {hasRelatedArticles && (
                <MaxWidthWrapper includeContentSpacing as="section" my={moduleSpacing.lg}>
                    <ArticleFlow
                        articles={relatedArticles}
                        button={{ label: t('articles.see_all_articles'), to: articlesPage }}
                        heading={t('articles.related_articles')}
                    />
                </MaxWidthWrapper>
            )}
        </>
    );
};

SingleArticle.propTypes = {
    data: PropTypes.object.isRequired, // CMS Data
};

export default SingleArticle;
