import Close from 'assets/icons/Close';
import Cookies from 'js-cookie';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getApplicationAliasNameById from 'utils/getApplicationAliasNameById';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TransitionElement = styled('div')`
    position: absolute;
    right: 0;
    height: auto;
    bottom: 0 !important;
    width: 100%;

    ${above.tabletSm} {
        bottom: 16px !important;
        right: 16px !important;
        width: auto;
    }
`;

const Wrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: ${colors.white};

    ${above.tabletSm} {
        max-width: 611px;
        padding: 24px;
    }

    ${above.desktopSm} {
        max-width: 659px;
    }
`;

const CloseButton = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: var(--static-background-color-secondary);
    color: var(--dynamic-color-secondary);
`;

const TopSection = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SiteSelectorOverlay = () => {
    const { t } = useTranslation();
    const isAboveMobile = useAboveBreakpoint('tabletSm');
    const { search: urlQuery } = useLocation();

    const { aliasId, domain, path, protocol, customer } = useSelector(state => state.application);
    const overlay = useSelector(state => state.overlay);

    const externalApplications = process.env.REACT_APP_EXTERNAL_APPLICATIONS?.split(',');

    const customerCountryName = customer?.geo?.country_name;
    const customerAliasId = customer?.primaryApplication?.alias_id;
    const currentAppName = getApplicationAliasNameById(aliasId);
    const suggestedAppName = externalApplications?.includes(customerCountryName)
        ? customerCountryName
        : getApplicationAliasNameById(customerAliasId);

    let suggestedAppPath = '';
    if (customerAliasId && customer.primaryApplication?.url) {
        // Check if there's a localized page for the customer primary application
        suggestedAppPath = `${customer.primaryApplication.path}${urlQuery}`;
    }

    const stayOnCurrentApp = () => {
        setSiteSelectorCookie(path, aliasId);
        handleClose();
    };

    const switchToSuggestedApp = () => {
        setSiteSelectorCookie(suggestedAppPath, customerAliasId);
        if (!inServer) {
            window.location.href = `${protocol}://${domain}${suggestedAppPath}`;
        }
    };

    const setSiteSelectorCookie = (selectedPath, selectedAliasId) => {
        const cookieValue = JSON.stringify({ selectedPath, selectedAliasId });
        Cookies.set(process.env.REACT_APP_SITE_SELECTOR_POPUP_COOKIE, cookieValue, {
            expires: 7,
            selectedPath,
        });
    };

    const handleClose = () => {
        overlay.hide();
    };
    const transformAppName = {
        'Sweden': 'Swedish/Sweden',
        'Finland': 'Finnish/Finland',
        'Denmark': 'Danish/Denmark',
        'Global': 'English/Global',
    };
    return (
        <TransitionElement className={`slide-in ${isAboveMobile ? 'right--bottom' : 'bottom'}`}>
            <Wrapper borderRadius={['12px 12px 0 0', null, '12px']}>
                <TopSection mb={['16px', null, '24px']}>
                    <Heading fontKeys={['Recife Display/20', null, 'Recife Display/32_120']} as="h2">
                        {t('site_selector_popup.title')}
                    </Heading>
                    <CloseButton
                        type="button"
                        width={['24px', null, '36px']}
                        height={['24px', null, '36px']}
                        onClick={() => overlay.hide()}
                    >
                        <Close color={colors.white} width={['12px', null, '14px']} />
                    </CloseButton>
                </TopSection>
                <Paragraph
                    borderTop="1px solid var(--dynamic-color-light-four)"
                    fontKeys="NB International/16_160"
                    pt="24px"
                    mb="24px"
                >
                    {t('site_selector_popup.country_popup_text', {
                        customerCountryName,
                        applicationName: currentAppName,
                    })}
                </Paragraph>

                {/* Stay on the current application */}
                <ThemeButton theme="solidBlack" onClick={stayOnCurrentApp}>
                    {t('site_selector_popup.stay_current_application', {
                        applicationName: transformAppName[currentAppName],
                    })}
                </ThemeButton>

                {/* Switch to suggested application */}
                {suggestedAppPath && (
                    <ThemeButton mt="16px" theme="outlinedBlack" target="_self" onClick={switchToSuggestedApp}>
                        {t('site_selector_popup.go_to_suggested_location', {
                            applicationName: transformAppName[suggestedAppName],
                        })}
                    </ThemeButton>
                )}
            </Wrapper>
        </TransitionElement>
    );
};

export default SiteSelectorOverlay;
