import React, { useState } from 'react';
import { linkProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import CookieInformationButton from 'components/CookieInformationButton';
import FooterLinks from './FooterLinks';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import Link from 'components/base/Link';
import Newsletter from 'components/Newsletter';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import SagaformLogoCircle from 'assets/icons/logos/SagaformLogoCircle';
import SingleAccordion from 'components/accordions/SingleAccordion';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const Row = styled('div')`
    display: flex;
`;

const Col = styled('div')``;

const Grid = styled('div')`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    ${above.desktopSm} {
        grid-gap: 32px 16px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

const LinkList = styled('ul')`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const LinkAndIcons = styled('li')`
    display: flex;
    ${above.tabletSm} {
        flex-direction: column;
    }
`;

const FooterContent = ({ heading = {}, newsletter = {}, menu = [], socialMedia = {} }) => {
    const [activeAccordion, setActiveAccordion] = useState([]);

    return (
        <Wrapper marginBottom="48px">
            <Row justifyContent="space-between" marginBottom={['48px', null, null, null, '56px']} width="100%">
                <Heading
                    as={heading.headingTag}
                    fontKeys={['Recife Display/24_100', null, 'Recife Display/40', null, 'Recife Display/48']}
                    marginLeft={[null, null, null, null, '32px']}
                    width={['calc(66.66666% - 4px)', null, '50%', null, 'calc(33.33333% - 10px)']}
                >
                    {heading.title}
                </Heading>

                <SagaformLogoCircle marginRight={['12px', null, '8', null, '32px']} width={['58px', null, '90px']} />
            </Row>
            <Row
                flexWrap={['wrap', null, null, null, 'nowrap']}
                gap={['0', null, null, null, '16px']}
                position="relative"
            >
                <Col
                    paddingBottom="24px"
                    paddingLeft={[null, null, null, null, '32px']}
                    width={['100%', null, null, null, 'calc(41.66666% - 10px)']}
                >
                    <Col width={['100%', null, null, null, 'calc(80% - 10px)']}>
                        <Newsletter
                            backgroundColor="var(--static-color-brand-primary)"
                            buttonTheme="outlinedWhite"
                            hoverColor="var(--static-color-secondary)"
                            inputTextColor={colors.opacity.white.low}
                            linkColor="var(--static-color-secondary)"
                            textColor="var(--static-color-secondary)"
                            {...newsletter}
                        />
                    </Col>
                </Col>
                <Grid
                    width={['100%', null, null, null, 'calc(50% - 8px)']}
                    paddingTop={['12px', null, null, null, '0']}
                >
                    {menu?.length > 0 && (
                        <>
                            <Below
                                breakpoint="desktopSm"
                                render={() =>
                                    menu.map((menuItem, index) => (
                                        <SingleAccordion
                                            key={`${menuItem.label}-${index}`}
                                            animationDuration={500}
                                            text={menuItem.label}
                                            colorText="var(--static-color-secondary)"
                                            fontKeys={[
                                                'Recife Display/24_120',
                                                null,
                                                null,
                                                null,
                                                'Recife Display/24_100',
                                            ]}
                                            headerPX="0"
                                            headerPY="12px"
                                            iconType="arrow"
                                            timingFunction="ease"
                                            isActive={activeAccordion.includes(index)}
                                            handleClick={() =>
                                                setActiveAccordion(
                                                    activeAccordion.includes(index)
                                                        ? activeAccordion.filter(activeIndex => activeIndex !== index)
                                                        : [...activeAccordion, index]
                                                )
                                            }
                                        >
                                            <FooterLinks links={menuItem.links} />
                                        </SingleAccordion>
                                    ))
                                }
                            />

                            <Above
                                breakpoint="desktopSm"
                                render={() =>
                                    menu.map((menuItem, index) => (
                                        <Col key={`${menuItem.label}-${index}`}>
                                            <Paragraph
                                                as="span"
                                                color="var(--static-opacity-color-light-low)"
                                                display="inline-block"
                                                fontKeys={[
                                                    'Recife Display/24_120',
                                                    null,
                                                    null,
                                                    null,
                                                    'Recife Display/24_100',
                                                ]}
                                                marginBottom="24px"
                                            >
                                                {menuItem.label}
                                            </Paragraph>
                                            <FooterLinks links={menuItem.links} />
                                        </Col>
                                    ))
                                }
                            />
                        </>
                    )}
                    <Col>
                        <Paragraph
                            as="span"
                            color="var(--static-opacity-color-light-low)"
                            display="inline-block"
                            fontKeys={['Recife Display/24_120', null, null, null, 'Recife Display/24_100']}
                            marginBottom={['16px', null, null, null, '24px']}
                            paddingTop={['12px', null, null, null, '0']}
                        >
                            {socialMedia.label}
                        </Paragraph>
                        <LinkList>
                            {socialMedia.links?.map((link, index) => (
                                <LinkAndIcons key={`${link.to}-${index}`}>
                                    <Link volatile to={link.to}>
                                        <Image
                                            alt={link.name}
                                            width="auto"
                                            sizes={['38px', null, null, null, '40px']}
                                            src={{ url: link.icon, width: [24, 48, 72] }} // Three sizes because of 1, 2 and 3 DPR
                                            title={link.name}
                                        />
                                    </Link>
                                </LinkAndIcons>
                            ))}
                        </LinkList>
                    </Col>
                </Grid>
                {/* The button is located in BottomRow above DesktopSm */}
                <Below
                    breakpoint="desktopSm"
                    render={() => (
                        <CookieInformationButton
                            bottom="0"
                            position="absolute"
                            right="0"
                            transformOrigin="bottom right"
                        />
                    )}
                />
            </Row>
        </Wrapper>
    );
};

FooterContent.propTypes = {
    address: wysiwygProp,
    contact: wysiwygProp,
    heading: PropTypes.object,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.arrayOf(linkProp),
        })
    ),
    newsletter: PropTypes.shape({
        heading: PropTypes.string,
        headingTag: PropTypes.string,
        form: PropTypes.shape({
            submitLabel: PropTypes.string,
            placeholder: PropTypes.string,
            inputErrorText: PropTypes.string,
            privacyText: wysiwygProp,
            checkboxErrorText: PropTypes.string,
            formSuccessText: PropTypes.string,
            formErrorText: PropTypes.string,
        }),
    }),
    socialMedia: PropTypes.shape({
        label: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                name: PropTypes.string,
                to: PropTypes.string,
            })
        ),
    }),
    textarea: PropTypes.shape({
        text: wysiwygProp,
        link: linkProp,
    }),
};

export default FooterContent;
