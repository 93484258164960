/* eslint-disable camelcase */
import {
    ALLOW_SCROLL,
    DISABLE_OVERLAY,
    ENABLE_OVERLAY,
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_ERROR,
    FETCH_CATEGORIES_SUCCESS,
    PREVENT_SCROLL,
    RESOLVE_APP,
    RESOLVE_APP_ERROR,
    RESOLVE_APP_SUCCESS,
    RESOLVE_COUNTRY,
    RESOLVE_COUNTRY_ERROR,
    RESOLVE_COUNTRY_SUCCESS,
    SET_CONTENT_LOADED,
    SET_CURRENT_HREF,
    SET_CURRENT_PATHNAME,
    SET_INITIAL_QUERY,
    SET_MARKET_ID,
    SET_PATH_BEFORE_STORE,
    SET_STATE,
    UPDATE_SHOP_CONFIG,
} from './constants';
import { ResolveApp, ResolveCampaignSite, ResolveCountry } from 'libs/GrebbCommerceAPI/Content';

import { ApplicationEvents } from 'libs/Events/constants';
import Cookies from 'js-cookie';
import Events from 'libs/Events';
import { GetCategories } from 'libs/GrebbCommerceAPI/Products';
import { keysToCamel } from 'utils/object';

export const resolveApp = (url = '', parameters = {}, shopConfig = {}) => async (dispatch, getState) => {
    const { isFetching, currentHref } = getState().application;
    // Since we cannot access window.location.href for sure when SSR rendering,
    // we are doing this fix, accessing the url from the state.
    let resolvedUrl = url;
    if (url === '') {
        resolvedUrl = currentHref;
    }
    if (isFetching) {
        return;
    }
    dispatch({ type: RESOLVE_APP });
    try {
        const response = await ResolveApp(resolvedUrl, parameters);
        if (response.status === 200) {
            dispatch({
                type: RESOLVE_APP_SUCCESS,
                application: response.data.application,
                config: response.data.config,
                'shop_config': {
                    ...response.data.shop_config,
                    ...shopConfig,
                },
                customer: keysToCamel(response.data.customer),
                ecommerce: keysToCamel(response.data.ecommerce),
                stores: response.data.stores,
                languages: response.data.languages,
            });

            Events.trigger(ApplicationEvents.RESOLVED, response.data);

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: RESOLVE_APP_ERROR });
        throw e;
    }
};

export const resolveCampaignSite = campaignSite => async dispatch => {
    const response = await ResolveCampaignSite(campaignSite);
    if (response.status === 200) {
        dispatch({
            type: SET_MARKET_ID,
            'market_id': response.data.market_id,
        });
        return response;
    }

    return response.error;
};

export const resolveCountry = (parameters = {}) => async (dispatch, getState) => {
    const { isFetching } = getState().application;
    if (isFetching) {
        return;
    }

    dispatch({ type: RESOLVE_COUNTRY });
    try {
        const response = await ResolveCountry(parameters);
        if (response.status === 200) {
            dispatch({
                type: RESOLVE_COUNTRY_SUCCESS,
                country: response.data.country,
            });

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: RESOLVE_COUNTRY_ERROR });
        throw e;
    }
};

export const setCurrentHref = currentHref => dispatch => {
    dispatch({
        type: SET_CURRENT_HREF,
        currentHref,
    });
};

export const setCurrentPathname = currentPathname => dispatch => {
    dispatch({
        type: SET_CURRENT_PATHNAME,
        currentPathname,
    });
};

export const setPathBeforeStore = pathBeforeStore => dispatch => {
    dispatch({
        type: SET_PATH_BEFORE_STORE,
        pathBeforeStore,
    });
};

export const enableOverlay = overlay => dispatch => {
    dispatch({ type: ENABLE_OVERLAY, overlay });
};

export const disableOverlay = () => dispatch => {
    dispatch({ type: DISABLE_OVERLAY });
};

export const allowScroll = () => dispatch => {
    dispatch({ type: ALLOW_SCROLL });
};

export const preventScroll = () => dispatch => {
    dispatch({ type: PREVENT_SCROLL });
};

export const setState = state => dispatch => {
    dispatch({
        type: SET_STATE,
        state,
    });
};

export const setContentLoaded = contentLoaded => dispatch => {
    return dispatch({ type: SET_CONTENT_LOADED, contentLoaded });
};

export const setMarketId = marketId => dispatch => {
    return dispatch({ type: SET_MARKET_ID, marketId });
};

export const updateShopConfig = (marketId, pricelistId, countryId, currency) => (dispatch, getState) => {
    const shopConfig = { market_id: marketId, pricelist_id: pricelistId, user_country: countryId, currency };
    const { path } = getState().application;
    Cookies.set('shop_config', JSON.stringify(shopConfig), {
        path,
    });
    return dispatch({ type: UPDATE_SHOP_CONFIG, marketId, pricelistId, countryId, currency });
};

export const setInitialQuery = (queryString, queryObject) => dispatch => {
    return dispatch({ type: SET_INITIAL_QUERY, queryString, queryObject });
};

export const fetchCategories = () => async dispatch => {
    dispatch({ type: FETCH_CATEGORIES });
    try {
        const response = await GetCategories();
        if (response.status === 200) {
            dispatch({
                type: FETCH_CATEGORIES_SUCCESS,
                categories: response.data[0].categories,
            });

            return response;
        }
        throw response.error;
    } catch (e) {
        dispatch({ type: FETCH_CATEGORIES_ERROR });
        throw e;
    }
};
