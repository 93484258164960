import AdyenView from 'components/pages/checkout/CheckoutView/Payment/paymentMethods/Adyen';
import KlarnaView from 'components/pages/checkout/CheckoutView/Payment/paymentMethods/Klarna';
import PropTypes from 'prop-types';
import React from 'react';
import { basketProps } from 'utils/proptypes/basket';

const paymentMethodViews = {
    'kco-v3': KlarnaView,
    'adyen-drop-in-sagaform': AdyenView,
};

const PaymentView = ({ paymentMethodId, tracking = {} }) => {
    const View = paymentMethodViews[paymentMethodId] || (() => null);
    return <View tracking={tracking} />;
};

PaymentView.propTypes = {
    paymentMethodId: basketProps.paymentMethodId.isRequired,
    tracking: PropTypes.shape({
        trigger: PropTypes.bool,
        setTrigger: PropTypes.func,
    }),
};

export default PaymentView;
