/* eslint-disable camelcase */
import React, { Fragment } from 'react';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import Cookies from 'js-cookie';
import Newsletter from 'components/Newsletter';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import appProps from 'utils/proptypes/application';
import colors from 'config/theme/colors';
import { injectModels } from 'state';
import popupProps from 'utils/proptypes/popup';
import styled from 'libs/styled';

const TransitionElement = styled('div')`
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 32px 24px;
    background-color: ${colors.background};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);

    ${above.tabletSm} {
        width: auto;
        margin-right: 24px;
        margin-bottom: 40px;
        border-radius: 4px;
    }

    ${above.tabletLg} {
        margin-right: 40px;
    }
`;

const NewsletterPopup = ({ application, popups, lifespan }) => {
    const popupData = application?.config?.options?.popup || {};
    const activeCampaign = popupData.type === 'newsletter';
    const newsletterData = popupData.newsletter;

    if (!activeCampaign) {
        return null;
    }

    const closePopup = () => {
        popups.hide('newsletterPopup');
        Cookies.set('newsletter_signup', true, { expires: +lifespan });
    };

    const newsletterProps = {
        button: {
            label: newsletterData?.signup_button?.label,
            theme: newsletterData?.signup_button?.button_theme,
        },
        checkboxErrorText: newsletterData?.newsletter_form?.privacy_checkbox_input?.error_message,
        extraButton: {
            label: newsletterData?.close_button?.label,
            theme: newsletterData?.close_button?.button_theme,
            onClick: () => closePopup(),
        },
        formErrorText: newsletterData?.newsletter_form?.form?.error_message,
        formSuccessText: newsletterData?.newsletter_form?.form?.success_message,
        heading: newsletterData?.heading?.text,
        headingTag: newsletterData?.heading?.type,
        id: 'newsletter-popup',
        inputErrorText: newsletterData?.newsletter_form?.email_input?.error_message,
        placeholder: newsletterData?.newsletter_form?.email_input?.placeholder,
        privacyText: newsletterData?.newsletter_form?.privacy_checkbox_input?.text,
        tag: newsletterData.tag,
        onSignUp: () => closePopup(),
    };

    return (
        <Fragment>
            <Below
                breakpoint="tabletSm"
                render={() => (
                    <TransitionElement className="slide-in bottom">
                        <Newsletter {...newsletterProps} />
                    </TransitionElement>
                )}
            />
            <Above
                breakpoint="tabletSm"
                render={() => (
                    <TransitionElement className="slide-in right--bottom">
                        <Newsletter {...newsletterProps} />
                    </TransitionElement>
                )}
            />
        </Fragment>
    );
};

NewsletterPopup.propTypes = {
    application: appProps.isRequired,
    lifespan: PropTypes.number,
    popups: popupProps.isRequired,
};

NewsletterPopup.defaultProps = {
    lifespan: 60,
};

export default injectModels(['application', 'popups'])(NewsletterPopup);
