import Basket from 'Header/Default/Basket';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { borderRadius } from 'config/theme/borderRadius';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';

const TransitionElement = styled('div')`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    color: var(--static-color-primary);
    background: var(--static-background-color-primary);
    overflow: hidden;

    ${above.tabletSm} {
        width: calc(83.33333333vw - 16px);
        right: 16px !important;
        top: 16px;
        border-radius: ${borderRadius[2]};
    }

    ${above.desktopSm} {
        width: calc(50vw - 16px);
    }
`;

const BasketOverlay = () => {
    const clientHeight = useClientHeight();

    return (
        <TransitionElement className="slide-in right" height={[clientHeight, null, `calc(${clientHeight} - 32px)`]}>
            <Basket />
        </TransitionElement>
    );
};

export default BasketOverlay;
