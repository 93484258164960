import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')``;

const Input = styled('input')`
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute; // Don't take up space in dom

    &:checked + label::before {
        background: var(--dynamic-color-brand-secondary);
    }

    &:checked + label::after {
        right: 2px;
        background-color: var(--dynamic-background-color-secondary);
    }
`;

const Label = styled('label')`
    position: relative;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;

    ::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 44px;
        height: 24px;
        background: var(--dynamic-color-brand-secondary-light);
        border-radius: 16px;
        transition: all ${transitions.primary};
    }

    ::after {
        content: '';
        position: absolute;
        top: 3px;
        right: 24px; // 44px switch width - 2px space to edge - 18px switch width
        width: 18px;
        height: 18px;
        background: var(--dynamic-opacity-color-dark-high);
        border-radius: 90px;
        transition: all ${transitions.primary};
    }
`;

/**
 * Switch input - Works like a checkbox box looks like a switch
 *
 * @param {bool} checked - The state of switch
 * @param {string} label - Visible text
 * @param {string} name - Used to connect input and label
 * @param {func} handleChange - Function that runs on click
 */

const SwitchInput = ({ checked = false, label = '', name = '', handleChange = () => {}, ...rest }) => (
    <Wrapper onClick={handleChange} {...rest}>
        <Input checked={checked} name={name} type="checkbox" />
        <Label htmlFor={name} {...rest}>
            <Paragraph as="span" fontKeys="Recife Display/20">
                {label}
            </Paragraph>
        </Label>
    </Wrapper>
);

SwitchInput.propTypes = {
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default SwitchInput;
