import ErrorCatcher from 'components/ErrorCatcher';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Accepts raw module data from the cms and tries to match the data to a key inside availableContent.
 * @param {object} availableContent - A object with avalible content as key and the corresponding Component as value.
 * @param {object} data - A object with block/module/layout data.
 * @param {...*} rest - All additional props will be forwarded to the Component.
 */

const ContentComponent = ({ availableContent, data, ...rest }) => {
    // The name of the key inside data that holds the name of the block/module/layout
    const componentNameKey = 'layout';

    // Use componentNameKey to get the name of the component
    const componentName = (data && data[componentNameKey]) || null;
    if (componentName) {
        // See if the componentName exists inside availableContent
        const ContentComponent = availableContent[componentName] || null;
        if (ContentComponent && data.data) {
            // See if the component has settings
            const hasSettings = data.data?.data;

            // Save everything except data as settings
            const { data: __, ...componentSettings } = data.data || {};

            // The content of the component is at different levels depending on whether there are settings or not
            const componentContent = hasSettings ? data.data.data : data.data;

            // Pass the componentContent to match inside availableContent and render it inside a ErrorCatcher
            return (
                <ErrorCatcher>
                    <ContentComponent
                        content={componentContent}
                        layout={componentName}
                        settings={hasSettings ? componentSettings : undefined}
                        {...rest}
                    />
                </ErrorCatcher>
            );
        }
        console.warn(`ContentComponent ${componentName} not found.`);
        return null;
    }
    console.warn(`ContentComponent name/layout could not be found not found.`);
    return null;
};

ContentComponent.propTypes = {
    availableContent: PropTypes.object.isRequired,
    data: PropTypes.shape({
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
        layout: PropTypes.string.isRequired,
    }).isRequired,
};

/**
 * Accepts raw module data from the cms and loops through it. It will pass all necessary data to the ContentComponent on each itteration.
 * @param {object} availableContent - A object with avalible content as key and the corresponding Component as value.
 * @param {object[]} data - An array of objects with block/module/layout data.
 * @param {...*} rest - All additional props will be forwarded to the Component.
 */

const ContentComponents = ({ availableContent, data, ...rest }) =>
    (data &&
        data.map((item, index) => (
            <ContentComponent
                availableContent={availableContent}
                key={index}
                data={item}
                first={index === 0}
                last={(index === data.length - 1 && data.length > 1) || data.length === 1}
                {...rest}
            />
        ))) ||
    null;

ContentComponents.propTypes = {
    availableContent: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
            layout: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export { ContentComponent, ContentComponents };
