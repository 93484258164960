import React, { useEffect, useRef, useState } from 'react';

import Close from 'assets/icons/Close';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getCurrentTopOffset from 'utils/getCurrentTopOffset';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';

const TransitionElement = styled('div', { shouldForwardProp: prop => ['headerHeights'].indexOf(prop) === -1 })`
    position: fixed;
    top: ${({ headerHeights }) => headerHeights.mobile + headerHeights.banner}px;
    right: 0;
    width: 100%;
    overflow: auto;
    background-color: ${colors.white};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    transition: top ${transitions.secondary}, transform ${transitions.primary} 200ms !important;
    // Fixes notifications animation by first moving them to the top before sliding in.
    // Else they would animate to top simultanously as they slide in

    ${above.tabletLg} {
        top: ${({ headerHeights }) => headerHeights.desktop + headerHeights.banner}px;
        width: 50vw;
        max-width: 960px;
    }
`;

const Content = styled('div')`
    border-width: 4px 0px 4px 0px;
    border-style: solid;
    padding: 24px 45px 24px 12px;

    ${above.tabletLg} {
        border-width: 0px 0px 0px 4px;
        padding: 24px 60px 24px 24px;
    }
`;

const Icon = styled('div')`
    position: absolute;
    top: 16px;
    right: 12px;

    ${above.tabletLg} {
        top: 24px;
        right: 24px;
    }
`;

const NoticePopup = () => {
    const { notice, popups } = useSelector(
        state => state,
        (prev, next) => prev.notice?.data !== next.notice?.data || prev.popups !== next.popups
    );

    const ref = useRef();
    const headerHeights = useHeaderHeights();
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        ref.current.style.top = `${getCurrentTopOffset(scrollPosition, headerHeights)}px`;
    }, [ref, scrollPosition]);

    const closePopup = () => {
        popups.hide('noticePopup');
    };

    const startTimer = () => {
        if (notice.data) {
            window.timer = setTimeout(() => {
                closePopup();
            }, 10000);
        }
    };

    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
        }
    };

    useEffect(() => {
        if (notice.data) {
            startTimer();
        }
        return () => {
            if (!inServer) {
                clearTimeout(window.timer);
            }
        };
    }, [notice]);

    let borderColor = 'none';
    switch (notice.data?.type) {
        case 'error':
            borderColor = colors.error;
            break;

        case 'success':
            borderColor = colors.success;
            break;

        default:
            break;
    }

    return (
        <TransitionElement
            className="slide-in right"
            headerHeights={headerHeights}
            ref={ref}
            onMouseEnter={() => stopTimer()}
            onMouseLeave={() => startTimer()}
        >
            <Content borderColor={borderColor}>
                {notice.data?.title && (
                    <Heading mb="12px" size="xs">
                        {notice.data.title}
                    </Heading>
                )}
                {notice.data?.message && <Paragraph size="xxs">{notice.data.message}</Paragraph>}
                <Icon>
                    <button type="button" onClick={closePopup}>
                        <Close />
                    </button>
                </Icon>
            </Content>
        </TransitionElement>
    );
};

export default NoticePopup;
