import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledBadge = styled('div')`
    padding: 4px 8px;
    border-radius: 14px;

    ${above.desktopSm} {
        padding: 4px 12px;
    }
`;

// Theme names = background-color_text-color
const THEMES = {
    'beige_black': {
        backgroundColor: colors.light.beige,
        color: colors.black,
    },
    'black_white': {
        backgroundColor: colors.black,
        color: colors.white,
    },
};

const Badge = ({ theme = 'blue', ...rest }) => {
    const themeStyling = THEMES[theme] || THEMES['beige_black'];
    const fontStyles = styleObjectToProps('NB International/12_120_4');

    return <StyledBadge {...themeStyling} {...fontStyles} {...rest} />;
};

Badge.propTypes = {
    theme: PropTypes.string.isRequired,
};

export default Badge;
