/**
 * Takes a backroundImageObject from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the backgroundImageData to first pass through this function.
 * All background images in the cms should be able to pass through this function.
 * @param {object} backgroundCmsData - Background data straight from the cms.
 * @param {object} - Transformed backgroundCmsData data.
 */

export default backgroundCmsData => {
    const { background_position: backgroundPosition, background_size: backgroundSize, image } = backgroundCmsData || {};

    let title = '',
        url = '',
        meta = {};
    if (image) {
        title = image.title;
        url = image.url;
        meta = {
            mimeType: image.meta.mime_type,
            ...image.meta,
        };
        delete meta.mime_type;
    }

    // The front-end expects camelCased variables/keys and shallow objects.
    return {
        backgroundPosition,
        backgroundSize,
        title,
        url,
        meta,
    };
};
