import { Global, css } from '@emotion/core';
import React, { Fragment } from 'react';

import { MediaProvider } from 'react-media-universal';
import Site from 'components/Site';
import colors from 'config/theme/colors';
import sanitize from 'sanitize.css';

const App = () => (
    <Fragment>
        <Global
            styles={css`
                ${sanitize}

                * {
                    box-sizing: border-box;
                }

                html {
                    height: 100%;
                }

                body {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    overflow-x: hidden;

                    &.prevent-overflow {
                        height: 100vh;
                        overflow-y: hidden;
                    }
                }

                :root {
                    height: 100%;

                    /* Template styles */
                    /* --where-what-color-state/variant */

                    /* static styles */ // Doesnt change with theme
                    --static-background-color-primary: ${colors.white};
                    --static-background-color-secondary: ${colors.black};
                    --static-color-primary: ${colors.black};
                    --static-color-secondary: ${colors.white};
                    --static-color-active: ${colors.green.two};
                    --static-color-brand-primary: ${colors.green.one};
                    --static-color-brand-primary-light: ${colors.light.green};
                    --static-color-brand-secondary: ${colors.beige};
                    --static-color-brand-secondary-light: ${colors.light.beige};
                    --static-color-brand-tertiary: ${colors.green.two};
                    --static-color-light-one: ${colors.grey.one};
                    --static-color-light-two: ${colors.grey.two};
                    --static-color-light-three: ${colors.grey.three};
                    --static-color-light-four: ${colors.grey.four};
                    --static-color-light-five: ${colors.grey.five};
                    --static-color-light-six: ${colors.grey.six};
                    --static-color-light-seven: ${colors.grey.seven};
                    --static-color-error: ${colors.error};
                    --static-opacity-color-dark-low: ${colors.opacity.black.low};
                    --static-opacity-color-dark-medium: ${colors.opacity.black.medium};
                    --static-opacity-color-dark-high: ${colors.opacity.black.high};
                    --static-opacity-color-dark-higher: ${colors.opacity.black.higher};
                    --static-opacity-color-light-low: ${colors.opacity.white.low};
                    --static-opacity-color-light-medium: ${colors.opacity.white.medium};
                    --static-opacity-color-light-high: ${colors.opacity.white.high};
                    --static-opacity-color-light-higher: ${colors.opacity.white.higher};
                    --static-placeholder-color: ${colors.placeholder};

                    /* Base dynamic colors, changes with theme  */
                    --dynamic-color-primary: ${colors.black};
                    --dynamic-color-secondary: ${colors.white};
                    --dynamic-background-color-primary: var(--dynamic-color-secondary);
                    --dynamic-background-color-secondary: var(--dynamic-color-primary);
                    --dynamic-color-active: ${colors.green.two};
                    --dynamic-color-brand-primary: ${colors.green.one};
                    --dynamic-color-brand-primary-light: ${colors.light.green};
                    --dynamic-color-brand-secondary: ${colors.beige};
                    --dynamic-color-brand-secondary-light: ${colors.light.beige};
                    --dynamic-color-brand-tertiary: ${colors.green.two};
                    --dynamic-color-light-one: ${colors.grey.one};
                    --dynamic-color-light-two: ${colors.grey.two};
                    --dynamic-color-light-three: ${colors.grey.three};
                    --dynamic-color-light-four: ${colors.grey.four};
                    --dynamic-color-light-five: ${colors.grey.five};
                    --dynamic-color-light-six: ${colors.grey.six};
                    --dynamic-color-light-seven: ${colors.grey.seven};
                    --dynamic-color-error: ${colors.error};
                    --dynamic-opacity-color-dark-low: ${colors.opacity.black.low};
                    --dynamic-opacity-color-dark-medium: ${colors.opacity.black.medium};
                    --dynamic-opacity-color-dark-high: ${colors.opacity.black.high};
                    --dynamic-opacity-color-dark-higher: ${colors.opacity.black.higher};
                    --dynamic-opacity-color-light-low: ${colors.opacity.white.low};
                    --dynamic-opacity-color-light-medium: ${colors.opacity.white.medium};
                    --dynamic-opacity-color-light-high: ${colors.opacity.white.high};
                    --dynamic-opacity-color-light-higher: ${colors.opacity.white.higher};
                    --dynamic-placeholder-color: ${colors.placeholder};
                }

                /* Remove input clear cross in IE */
                ::-ms-clear {
                    display: none;
                }
            `}
        />
        <MediaProvider>
            <Site />
        </MediaProvider>
    </Fragment>
);

export default App;
