import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';

/**
 * Takes category map object and transforms them into comma seperated list.
 * The sort function will put the primary category as the first before the alphabetical order.
 * @param {object} categories - Category map object
 * @param {string} primaryCategory - Primary category
 * @returns string
 */

export const transformArticleCategories = (categoriesMap: Record<string, string>, primaryCategory: string) => {
    return Object.values(categoriesMap)
        .sort((a: string, b: string): number => {
            if (a === primaryCategory) {
                return -1;
            }
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
        .join(', ');
};

/**
 * Takes category map object and transforms them into badges in an array.
 * The sort function will put the primary category as the first before the alphabetical order.
 * @param {object} categoriesMap - Category map object
 * @param {string} primaryCategory - Primary category
 * @param {string} theme - Badge theme
 * @returns array
 */

export const transformArticleBadges = (
    categoriesMap: Record<string, string>,
    primaryCategory: string,
    theme = 'light-beige_black'
) => {
    const sortedCategories = Object.values(categoriesMap).sort((a: string, b: string): number => {
        if (a === primaryCategory) {
            return -1;
        }
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    });

    return sortedCategories.map(text => ({ text, theme })).slice(0, 2);
};

/**
 * Takes a date string and formats the date
 * @param {string} dateString - Date string in format YYYY-MM-DD HH:mm:ss
 * @returns string - YY.MM.DD
 */

export const transformArticleDate = (dateString: string) => {
    if (!dateString) {
        return null;
    }

    const iosDateFormatFix = `${dateString.replace(' ', 'T')}Z`;
    const date = new Date(iosDateFormatFix);

    return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
        -2
    )}.${date.getFullYear().toString().slice(-2)}`;
};

/**
 * Transform the algoliaArticleData returned from algolia
 *
 * @param {object} algoliaArticleData - Article data from Algolia
 * @returns object
 */

export const transformArticleCard = (algoliaArticleData: Record<string, any>) => {
    if (!algoliaArticleData) {
        return null;
    }

    const {
        author = '',
        categories_names: categoryNames = [],
        categories = {},
        id,
        preview = {},
        primary_category: primaryCategory,
        uri = '',
    } = algoliaArticleData;

    return {
        author,
        badges: transformArticleBadges(categoryNames, categories[primaryCategory]),
        id,
        image: transformBackgroundImage(preview.image_portrait?.background_image),
        title: preview.title,
        to: uri,
    };
};

/**
 * Transform article data from Aligola Response
 *
 * @param {object} response - Response data from Algolia
 * @returns object
 */

export const transformAlgoliaArticleResponse = (response: Record<string, any>) => {
    const clonedResponse = JSON.parse(JSON.stringify(response));

    if (clonedResponse.hits?.length) {
        clonedResponse.hits = clonedResponse.hits.map(article => transformArticleCard(article));
    }

    return clonedResponse;
};
