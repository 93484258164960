import { above, media } from 'utils/mediaqueries';
import { css, jsx } from '@emotion/core';

import BaseLink from 'components/base/Link';
import PropTypes from 'prop-types';
/** @jsx jsx */
import { buttonThemes } from 'components/buttons/ThemeButtonConfig';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import textStylesConfig from 'config/theme/textStyles';
import transitions from 'config/theme/transitions';

const StyledButton = styled('button', { shouldForwardProp: prop => ['hover', 'theme', 'size'].indexOf(prop) === -1 })`
    ${above.tabletSm} {
        ${media.hover} {
            :hover {
                color: ${({ hover, theme }) => (hover ? hover.color : theme.color)};
                border-color: ${({ hover, theme }) => (hover ? hover.borderColor : theme.borderColor)};
                background-color: ${({ hover, theme }) => (hover ? hover.backgroundColor : theme.backgroundColor)};
                --border-color: ${({ hover }) => hover && hover.borderColor};
                transition: all ${transitions.primary};
            }
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    user-select: none;
    border-radius: 47px;
    border: ${({ theme }) => `1px ${theme.borderStyle} ${theme.borderColor}`};
    text-transform: uppercase;
`;

/**
 * @version 1.1
 * @param {string} as - Change element to ex Link with: as={Link} to get the button Styling as a <a>-tag.
 * @param {object} availableSizes - Getting the avaliable sizes from the sizes object in config.
 * @param {boolean} disabled - Disables button from use.
 * @param {string|string[]} - Font keys
 * @param {object} styles - Button styles.
 * @param {string} theme - Change theme accordingly, can be conditioned in the component of usage.
 * @param {string} type - pass down the button type, default button.
 * @param {string} to - url to link to
 * @param {string|string[]} rest - pass down any specific styling will overwrite default settings from config.
 * @returns <StyledButton>
 */
const ThemeButton = ({
    as,
    children,
    disabled = false,
    fontKeys = 'NB International/14_100_7',
    styles = {},
    target,
    theme = 'outlined',
    to,
    type = 'button',
    ...rest
}) => {
    // Update props
    theme = buttonThemes[theme];
    as = as || typeof to === 'string' ? BaseLink : undefined;

    // Deconstruct the theme object
    const { hover, disabledStyles, ...restTheme } = theme || {};

    // Styling for disabled
    if (disabled && disabledStyles) {
        restTheme[':disabled'] = {
            ...disabledStyles,
            cursor: 'not-allowed',
        };
    }

    // Text styling
    const buttonTextStyles = styleObjectToProps(fontKeys, textStylesConfig.paragraph);

    // Merge all button styles
    const buttonStyles = css({
        ...buttonTextStyles,
        ...restTheme,
        ...styles,
    });

    return (
        <StyledButton
            as={as}
            css={buttonStyles}
            disabled={disabled}
            hover={!disabled && hover}
            target={target}
            theme={theme}
            to={to}
            type={!as ? type : null}
            {...rest}
        >
            {children}
        </StyledButton>
    );
};

ThemeButton.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    disabled: PropTypes.bool,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    styles: PropTypes.object,
    target: PropTypes.string,
    theme: PropTypes.oneOf(['outlined', 'solid', 'outlinedBlack', 'outlinedWhite', 'solidBlack', 'solidWhite']),
    to: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
};

export default ThemeButton;
