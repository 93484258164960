export const addressRequiredFields = ['email', 'firstName', 'lastName', 'address1', 'city', 'zip', 'country', 'phone'];

export default (address: { [key: string]: string | undefined }) => {
    const isFieldRequired = (key: string) => addressRequiredFields.includes(key);
    const empty = (key: string) => address[key] === '' || address[key] === null;
    const valid = Object.keys(address).every(key => (isFieldRequired(key) ? !empty(key) : true));
    const allEmpty = Object.keys(address).every(empty);

    return valid && !allEmpty;
};
