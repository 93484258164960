import Arrow from 'assets/icons/Arrow';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const StyledLink = styled(Link)`
    align-items: center;
    gap: 4px;
    transition: gap ${transitions.secondary};
    text-transform: uppercase;

    ${media.hover} {
        :hover {
            gap: 8px;
        }
    }
`;

const ArrowLink = ({ children, fontKeys = 'NB International/14_100_7', iconWidth = '5px', to = '', ...rest }) => (
    <StyledLink as={!to && 'button'} display="inline-flex" to={to} {...styleObjectToProps(fontKeys)} {...rest}>
        {children}
        <Arrow color="currentColor" width={iconWidth} />
    </StyledLink>
);

ArrowLink.propTypes = {
    children: PropTypes.node.isRequired,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    to: PropTypes.string,
};

export default ArrowLink;
