import React, { useEffect, useRef, useState } from 'react';
import contentSpacing, { bleedSpacing } from 'config/theme/contentSpacing';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import PropTypes from 'prop-types';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import TextLinkDot from 'components/text/TextLinkDot';
import { above } from 'utils/mediaqueries';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import responsiveBreakpointsStyle from 'utils/responsiveBreakpointsStyle';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    width: 100%;
`;

const Links = styled('div')`
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;

    ${above.tabletSm} {
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px 24px;
    }

    &::before,
    &::after {
        display: block;
        content: '';
        ${responsiveBreakpointsStyle(contentSpacing, 'width: {value}; max-width: {value}; flex: 0 0 {value};')}

        ${above.tabletSm} {
            display: none;
        }
    }
`;

const SubCategoryList = ({ items, ...rest }) => {
    const linksRef = useRef();
    const [fitsOnScreen, setFitsOnScreen] = useState();

    // Center links on screen if all fits inside the window.
    useEffect(() => {
        // Run on resize
        const handleResize = () => {
            const clientWidth = document.documentElement.clientWidth;
            const linksWidth = linksRef.current?.getBoundingClientRect().width;
            setFitsOnScreen(clientWidth > linksWidth);
        };

        // Run on mount
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [linksRef]);

    return (
        <Wrapper mx={bleedSpacing} {...rest}>
            <Below
                breakpoint="tabletSm"
                render={() => (
                    <ScrollWrapper
                        displayFadeEffect
                        direction="horizontal"
                        scrollContentProps={fitsOnScreen ? { justifyContent: 'center' } : null}
                    >
                        <Links ref={linksRef}>
                            {items.map(item => (
                                <TextLinkDot
                                    key={item.title}
                                    to={item.to}
                                    isActive={item.current}
                                    onClick={item.onClick}
                                    {...styleObjectToProps(['Recife Display/20', null, 'Recife Display/24_100'])}
                                >
                                    {decodeHTMLEntities(item.title)}
                                </TextLinkDot>
                            ))}
                        </Links>
                    </ScrollWrapper>
                )}
            />
            <Above
                breakpoint="tabletSm"
                render={() => (
                    <Links ref={linksRef}>
                        {items.map(item => (
                            <TextLinkDot
                                key={item.title}
                                to={item.to}
                                isActive={item.current}
                                onClick={item.onClick}
                                {...styleObjectToProps(['Recife Display/20', null, 'Recife Display/24_100'])}
                            >
                                {decodeHTMLEntities(item.title)}
                            </TextLinkDot>
                        ))}
                    </Links>
                )}
            />
        </Wrapper>
    );
};

SubCategoryList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            to: PropTypes.string,
            current: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
};

export default SubCategoryList;
