import { addTheme, lockScroll, removeTheme, resetTheme, unlockScroll } from './actions';

import store from 'state/store';

export default {
    lockScroll: () => {
        store.dispatch(lockScroll());
    },

    unlockScroll: () => {
        store.dispatch(unlockScroll());
    },

    addTheme: () => {
        store.dispatch(addTheme());
    },

    removeTheme: () => {
        store.dispatch(removeTheme());
    },
    resetTheme: () => {
        store.dispatch(resetTheme());
    },
};
