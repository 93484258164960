import React, { useState } from 'react';

import FilterCheckboxOption from 'components/Filter/FilterCheckboxOption';
import FilterColorOption from 'components/Filter/FilterColorOption';
import FilterMultiRangeOption from 'components/Filter/FilterMultiRangeOption';
import FilterSwitchOption from 'components/Filter/FilterSwitchOption';
import PropTypes from 'prop-types';
import SingleAccordion from 'components/accordions/SingleAccordion';
import styled from 'libs/styled';

const Div = styled('div')``;

const FilterAccordion = ({ clearByKey = () => {}, filters = [], handleChange = {} }) => {
    const [activeAccordion, setActiveAccordion] = useState([]);

    if (filters.length < 1) {
        return null;
    }

    return (
        <Div>
            {filters.map((filter, index) => {
                const multirangeIsMissingRange =
                    filter.option === 'multirange' && filter.stats.min === filter.stats.max;

                const someMultirangeIsMissingRange =
                    filter.option === 'multirangeGroup' &&
                    filter.values.every(filter => filter.stats.min === filter.stats.max);

                if (multirangeIsMissingRange || someMultirangeIsMissingRange) {
                    return null;
                }

                if (filter.option === 'switch') {
                    return (
                        <FilterSwitchOption
                            filters={filter.values}
                            handleChange={handleChange.value}
                            key={`${filter.key}-${index}`}
                            parentFilterKey={filter.key}
                        />
                    );
                }
                return (
                    <SingleAccordion
                        animationDuration={200}
                        fontKeys="Recife Display/20"
                        headerPX="0"
                        headerPY="12px"
                        iconSize="15px"
                        iconType="arrow"
                        isActive={activeAccordion.includes(index)}
                        key={`${filter.key}-${index}`}
                        text={`${filter.label} ${filter.totalSelected > 0 ? `(${filter.totalSelected})` : ''}`}
                        timingFunction="ease"
                        handleClick={() =>
                            setActiveAccordion(
                                activeAccordion.includes(index)
                                    ? activeAccordion.filter(activeIndex => activeIndex !== index)
                                    : [...activeAccordion, index]
                            )
                        }
                    >
                        <Div as="ul" padding="12px 0 24px">
                            {filter.option === 'checkbox' && (
                                <FilterCheckboxOption
                                    filters={filter.values}
                                    handleChange={handleChange.value}
                                    parentFilterKey={filter.key}
                                    sortBy={filter.sort}
                                />
                            )}
                            {filter.option === 'color' && (
                                <FilterColorOption
                                    filters={filter.values}
                                    handleChange={handleChange.value}
                                    parentFilterKey={filter.key}
                                    sortBy={filter.sort}
                                />
                            )}
                            {filter.option === 'multirangeGroup' && (
                                <FilterMultiRangeOption
                                    clearByKey={clearByKey}
                                    filters={filter.values}
                                    handleChange={handleChange.range}
                                />
                            )}
                            {filter.option === 'multirange' && (
                                <FilterMultiRangeOption
                                    clearByKey={clearByKey}
                                    filters={[filter]}
                                    handleChange={handleChange.range}
                                />
                            )}
                        </Div>
                    </SingleAccordion>
                );
            })}
        </Div>
    );
};

FilterAccordion.propTypes = {
    clearByKey: PropTypes.func,
    filters: PropTypes.array,
    handleChange: PropTypes.object,
};

export default FilterAccordion;
