import React, { useState } from 'react';
import Above from 'components/breakpoints/Above';
import ProductGallery from './ProductGallery';
import ProductImageZoom from './ProductImageZoom';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import { borderRadius } from 'config/theme/borderRadius';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';

const Div = styled('div')``;

const Wrapper = styled('div')`
    top: 0;
    height: auto;
    width: 100%;
    margin-bottom: 12px;

    ${above.desktopSm} {
        margin-left: -16px;
        width: calc(66.666% + 16px);
    }
`;

const Fallback = styled('div')`
    width: 100%;
    background-color: var(--dynamic-placeholder-color);
    overflow: hidden;
`;

// Responsive logic for images because the image service wants parameters for image sizes
const imageConfig = {
    imageWidths: [375, 455, 768, 512, 640, 720],
    imageSizes: ['100vw', null, '50vw', null, '33vw'],
    srcWidths: [375, 455, 768, 512, 640, 720],
};

const { imageWidths, imageSizes, srcWidths } = imageConfig;

const ProductImages = ({ images = [] }) => {
    const [currentImage, setCurrentImage] = useState(null); // Product zoom

    if (!images.length) {
        return (
            <Div
                display="flex"
                gap="0 16px"
                marginBottom="12px"
                marginLeft={['0', null, null, null, '-16px']}
                width={['100%', null, null, null, 'calc(66.666% + 16px)']}
            >
                <Fallback
                    aspectRatio={[ratios.vertical, null, ratios.horizontal, ratios.vertical]}
                    borderRadius={borderRadius}
                />
                <Fallback
                    aspectRatio={[ratios.vertical, null, ratios.horizontal, ratios.vertical]}
                    borderRadius={borderRadius}
                    display={['none', null, null, 'block']}
                />
            </Div>
        );
    }

    const numberOfImages = images.length;
    const srcHeights = srcWidths.map(width => Math.ceil(width * 1.33));

    // Loop through media array and add widths to each object
    const imagesWithWidths = images.map(image => {
        return {
            ...image,
            widths: imageWidths,
        };
    });

    return (
        <Wrapper borderRadius={borderRadius}>
            <Above
                breakpoint="desktopSm"
                render={() => (
                    <ProductImageZoom
                        currentImageIndex={currentImage}
                        images={images.slice(0, numberOfImages)}
                        setCurrentImage={setCurrentImage}
                    />
                )}
            />
            <ProductGallery
                imageConfig={{ srcHeights, srcWidths, imageSizes }}
                images={imagesWithWidths}
                setCurrentImage={setCurrentImage}
            />
        </Wrapper>
    );
};

ProductImages.propTypes = {
    images: PropTypes.array,
};

export default ProductImages;