/* eslint-disable camelcase */

import { Variant } from 'hooks/useVariantSelector/types';
import { hasDatePassed } from 'utils/hasDatePassed';
import { keysToCamel } from 'utils/object';
import { transformUnit } from 'utils/transformUnit';

type RawData = { [key: string]: any };

interface Badge {
    text: string;
    theme: string;
}

interface CustomAttribute {
    color_name: string;
    display_text: string;
    internal_name: string;
    description: string;
}

interface CustomAttributeMap extends CustomAttribute {
    id: string;
    value?: string;
    values?: Record<string, CustomAttribute>;
}

interface Breadcrumb {
    title: string;
    permalink?: string;
}

type Wysiwyg = string | (string | object)[];

interface Characteristic {
    label: string;
    value: string | Wysiwyg;
}

interface Price {
    [key: string]: any;
}

interface Image {
    name: string;
    alt: string;
    src: string;
}

interface PageRelationships {
    ecommerce_product_category: Object[];
    product_page_content: Object[];
}
interface BadgeItem {
    internal_name: string;
    display_text: string;
    color_theme?: string;
}
interface RawBadgesData {
    customBadges: BadgeItem[];
    discontinued: boolean;
    hideAutomatedBadges?: boolean;
    inStock: boolean;
    isOnlineExclusive: boolean;
    materials: BadgeItem[];
    newUntil: string;
    onSale: boolean;
    price: Price;
    sustainablePerks: BadgeItem[];
    availableFrom: string;
}

/**
 * Transform product price into desired structure
 */

export const transformProductPrice = (rawPrice: Record<string, any>): Price => {
    if (!rawPrice) {
        return {};
    }

    // Take a value that only exist in basket and check if it exists
    const isBasket = !!rawPrice.line;

    const discount = rawPrice.discount || 0;
    const discountPercentage = rawPrice.discount_percentage || 0;
    const discountWithCurrency = rawPrice.discount_with_currency;

    const fullPrice = isBasket ? rawPrice.total_price_before_discount_as_number : rawPrice.price;
    const fullPriceExVat = rawPrice.price_ex_vat;
    const fullPriceWithCurrency = isBasket ? rawPrice.total_price_before_discount : rawPrice.price_with_currency;

    const priceHistory = rawPrice?.price_history?.price || '';
    const priceHistoryWithCurrency = rawPrice?.price_history?.price_with_currency || '';

    const salePrice = isBasket ? rawPrice.total_price_as_number : rawPrice.sale_price;
    const salePriceExVat = rawPrice.sale_price_ex_vat;
    const salePriceWithCurrency = isBasket ? rawPrice.total_price : rawPrice.sale_price_with_currency;

    const vat = rawPrice.vat;
    const vatPercentage = rawPrice.vat_percentage;

    const currency = fullPriceWithCurrency && fullPriceWithCurrency.replace(/[0-9.\s]/g, '');
    const currencyID = rawPrice.currency;

    const badgeNewProduct = rawPrice.new_product || false;
    const badgeOnSale = rawPrice.on_sale || false;
    const isOnSale = salePrice && fullPrice && salePrice < fullPrice;

    return {
        badgeNewProduct,
        badgeOnSale,
        currency,
        currencyID,
        discount,
        discountPercentage,
        discountWithCurrency,
        fullPrice,
        fullPriceExVat,
        fullPriceWithCurrency,
        isOnSale,
        price: fullPrice,
        priceExVat: fullPriceExVat,
        priceWithCurrency: fullPriceWithCurrency,
        priceHistory,
        priceHistoryWithCurrency,
        salePrice,
        salePriceExVat,
        salePriceWithCurrency,
        vat,
        vatPercentage,
    };
};

/**
 * Transform and add product badges so that it is the same across the page
 *
 * Prio order for badges
 * 1. Stock status
 * 2. Online Exclusive
 * 3. Sale
 * 4. New
 * 5. Sustainable Perks
 * 6. Materials
 * 7. Customs
 */

export const transformProductBadges = (rawBadges: RawBadgesData, t: (text: string) => string): Badge[] => {
    const {
        availableFrom,
        customBadges,
        discontinued,
        hideAutomatedBadges,
        inStock,
        isOnlineExclusive,
        materials,
        newUntil,
        onSale,
        price,
        sustainablePerks,
    } = rawBadges;

    const badges: Badge[] = [];

    // #1 Stock status
    if (!inStock && !hideAutomatedBadges) {
        if (discontinued) {
            badges.push({
                text: t('product_card.sold_out'),
                theme: 'beige_black',
            });
        } else {
            // #4 Upcoming
            if (availableFrom && !hasDatePassed(availableFrom)) {
                badges.push({
                    text: t('product_card.coming_soon'),
                    theme: 'beige_black',
                });
            } else {
                badges.push({
                    text: t('product_card.temporary_sold_out'),
                    theme: 'beige_black',
                });
            }
        }
    }

    // #2 Online Exclusive
    if (isOnlineExclusive && !hideAutomatedBadges) {
        badges.push({
            text: t('product_card.online_exclusive'),
            theme: 'beige_black',
        });
    }

    // #3 Sale
    if (onSale && price.discountPercentage > 0 && !hideAutomatedBadges) {
        badges.push({
            text: `${price.discountPercentage}%`,
            theme: 'black_white',
        });
    }

    // #4 New
    if (newUntil && !hideAutomatedBadges) {
        if (!hasDatePassed(newUntil)) {
            badges.push({
                text: t('product_card.new'),
                theme: 'beige_black',
            });
        }
    }

    // #5 Sustainable
    const allowedSustainable = ['eco_friendly', 'recycled_plastic', 'recycled_aluminium'];
    if (sustainablePerks.length > 0 && !hideAutomatedBadges) {
        sustainablePerks.forEach((badge: BadgeItem) => {
            if (allowedSustainable.indexOf(badge.internal_name) !== -1) {
                badges.push({
                    text: badge.display_text,
                    theme: 'beige_black',
                });
            }
        });
    }

    // #6 Materials
    const allowedMaterials = ['organic_cotton', 'r_pet'];
    if (materials.length > 0 && !hideAutomatedBadges) {
        materials.forEach((badge: BadgeItem) => {
            if (allowedMaterials.indexOf(badge.internal_name) !== -1) {
                badges.push({
                    text: badge.display_text,
                    theme: 'beige_black',
                });
            }
        });
    }

    // #7 Custom
    if (customBadges.length > 0) {
        customBadges.forEach((badge: BadgeItem) => {
            badges.push({
                text: badge.display_text,
                theme: badge.color_theme || 'beige_black',
            });
        });
    }

    return badges;
};

/**
 * Transform the data for the product card so that it is the same across the page
 */

export const transformProductCard = (rawData: RawData, marketId: string, t: (text: string) => string) => {
    const inStock: boolean = marketId && rawData._in_stock?.includes(marketId);
    const transformedPrice = transformProductPrice(rawData.price);

    const badges = transformProductBadges(
        {
            availableFrom: rawData._available_from,
            customBadges: rawData._badges,
            discontinued: rawData._discontinued,
            hideAutomatedBadges: rawData.sa_badges_hide_automated,
            inStock,
            isOnlineExclusive: rawData._online_exclusive,
            materials: rawData._materials,
            newUntil: rawData._new_until,
            onSale: rawData._on_sale_badge,
            price: transformedPrice,
            sustainablePerks: rawData._sustainable,
        },
        t
    );

    const name: string = rawData.name;
    const sku: string = rawData.sku;
    const url: string = rawData.uri;
    const category: string = rawData._categories_names
        ? rawData._categories_names[rawData._categories_names.length - 1]
        : '';
    const primaryImage: string = rawData._first_image;
    const hoverImage: string = rawData._hover_image;
    const variationId: string = rawData.variations?.[0]?.id;
    const detailedColor: string = rawData._color_detailed;
    const relatedVariants: object[] = rawData._related_variations?.map(variant => ({
        color: variant.custom_attributes?.sa_color_detailed_display?.value,
        id: variant.id,
        image: variant.image,
        size: variant.custom_attributes?.sa_size?.internal_name,
        uri: variant.uri,
        useOutline: variant.custom_attributes?.sa_use_outline?.value,
    }));
    const size: string = rawData._filter_size?.toLowerCase();
    const image: string = rawData._variant_image;
    const multipack: string = rawData._multipack;
    const colorName: string = rawData._color_detailed;

    return {
        badges: badges.slice(0, 2),
        category,
        colorName,
        detailedColor,
        hoverImage,
        id: parseInt(rawData.id, 10),
        image,
        inStock,
        multipack,
        name,
        price: transformedPrice,
        primaryImage,
        relatedVariants,
        size,
        sku,
        url,
        variationId,
    };
};

/**
 * Transform the data for the product card basket
 * This transform is used both on the product page to prepare "AddedToBasket"
 * and in the checkout/basket. The data structure differs on these places.
 */

export const transformBasketProductData = (rawData: RawData) => {
    const fullPrice: number = rawData.price?.price || rawData.total_price_before_discount_as_number;
    const id = `${rawData.id}`;
    const imageUrl = `${rawData.media[0]?.sizes?.original?.url}`;
    const name = `${rawData.name || rawData.product_reference?.name}`;
    const priceWithCurrency: string = rawData.price?.price_with_currency || rawData.total_price_before_discount;
    const quantity: number = rawData.quantity;
    const salePrice: number = rawData.price?.sale_price || rawData.total_price_as_number;
    const salePriceWithCurrency: string = rawData.price?.sale_price_with_currency || rawData.total_price;
    const to = `${rawData.uri || rawData.url}`;
    const { sa_size: size, sa_multipack: multipack } = rawData.product_reference?.custom_attributes || {};
    const detailedColor: string = rawData.product_reference?.variant_name;

    return {
        detailedColor,
        id,
        imageSrc: imageUrl,
        isOnSale: (salePrice && fullPrice && salePrice < fullPrice) || false,
        line: rawData.line,
        multipack: multipack?.display_text,
        name,
        priceWithCurrency,
        quantity,
        salePriceWithCurrency,
        size: size?.display_text,
        to,
    };
};

/**
 * Transform breadcrumbs for product page
 */

export const transformProductPageBreadcrumbs = (categories: Record<string, any>): Breadcrumb[] => {
    /* Find most precise breadcrumbs */
    let breadcrumbs: Breadcrumb[] = [];
    const initialBreadcrumbs: Breadcrumb[] = [];

    if (categories) {
        const ecommerceCategoryData: object[] = Object.values(categories);

        breadcrumbs = ecommerceCategoryData.reduce(
            (currentBreadcrumbs: Breadcrumb[], category: Record<string, any>) => {
                if (category && currentBreadcrumbs.length < category.breadcrumbs.length + 1) {
                    currentBreadcrumbs = category.breadcrumbs.map(ancestor => {
                        const breadcrumb: Breadcrumb = {
                            title: ancestor.title,
                            permalink: ancestor.permalink,
                        };
                        return breadcrumb;
                    });

                    const breadcrumb: Breadcrumb = {
                        title: category.title,
                        permalink: category.permalink,
                    };

                    currentBreadcrumbs.push(breadcrumb);
                }

                return currentBreadcrumbs;
            },
            initialBreadcrumbs
        );
    }

    return breadcrumbs;
};

/**
 * Transform an image for the product page
 */
const transformProductImage = (image): Image => ({ name: image.name, alt: image.alt, src: image.sizes.original.url });

const transformVariant = (variant, variantAttributesKeys, currentVariant = false) => {
    const variantAttributes = {};

    variantAttributesKeys.forEach(key => {
        if (!variantAttributes[key]) {
            const transformedAttributes = keysToCamel(variant.custom_attributes[key]);
            variantAttributes[key] = transformedAttributes;
        }
    });

    return {
        currentVariant,
        id: variant.id,
        image: variant.media[variant.media.length - 1]
            ? transformProductImage(variant.media[variant.media.length - 1])
            : undefined,
        inStock: variant.variations?.[0]?.in_stock,
        to: variant.uri,
        useOutline: !!variant.custom_attributes?.sa_use_outline?.value,
        variantAttributes,
    };
};

const transformVariants = (product: RawData) => {
    // If we keep selector when no variant, fix here
    const variantAttributesKeys = product.related_variation_attributes;
    const currentVariant: Variant = transformVariant(product, variantAttributesKeys, true);
    const relatedVariants: Variant[] = product.related_variations.map(variant =>
        transformVariant(variant, variantAttributesKeys)
    );

    return {
        currentVariant,
        relatedVariants,
    };
};

const getCharacteristicValues = (characteristic: CustomAttributeMap): CustomAttribute[] =>
    Object.values(characteristic.values || {});

const transformProductCharacteristics = (characteristics: { [key: string]: any }, t: (text: string) => string) => {
    const {
        additionalInformation = '',
        careInstructions = {},
        color = '',
        designers = '',
        detailedMaterials = {},
        diameter = '',
        height = '',
        length = '',
        packaging = '',
        rawMaterials = {},
        series = '',
        sku = '',
        volume = '',
        weight = '',
        width = '',
    } = characteristics;

    let materials;
    const careInstructionsValues = getCharacteristicValues(careInstructions);

    // Prioritize detailedMaterials if it exists
    if (detailedMaterials.value) {
        materials = detailedMaterials.value;
    } else if (rawMaterials.values) {
        const materialsValues = getCharacteristicValues(rawMaterials);
        materials = materialsValues
            .map(material => material.display_text)
            .filter(material => material)
            .join(', ');
    }

    const transformedCharacteristics: Characteristic[] = [
        {
            label: t('product_page.accordions.articlenumber'),
            value: sku,
        },
        {
            label: t('product_page.accordions.series'),
            value: series,
        },
        {
            label: t('product_page.accordions.color'),
            value: color,
        },
        {
            label: t('product_page.accordions.weight'),
            value: weight && parseFloat(weight) > 0 && transformUnit(parseFloat(weight), 'kg'),
        },
        {
            label: t('product_page.accordions.width'),
            value: width && width > 0 && transformUnit(width, 'mm'),
        },
        {
            label: t('product_page.accordions.height'),
            value: height && height > 0 && transformUnit(height, 'mm'),
        },
        {
            label: t('product_page.accordions.length'),
            value: length && length > 0 && transformUnit(length, 'mm'),
        },
        {
            label: t('product_page.accordions.volume'),
            value: volume && volume > 0 && transformUnit(volume, 'cl'),
        },
        {
            label: t('product_page.accordions.diameter'),
            value: diameter && diameter > 0 && transformUnit(diameter, 'mm'),
        },
        {
            label: t('product_page.accordions.designer'),
            value: designers,
        },
        {
            label: t('product_page.accordions.packaging'),
            value: packaging,
        },
        {
            label: t('product_page.accordions.materials'),
            value: materials,
        },
        {
            label: t('product_page.accordions.care_instructions'),
            value: `${careInstructionsValues
                .map(instruction => instruction.description)
                .filter(instruction => instruction)
                .join('. ')}${careInstructionsValues.length > 1 ? '.' : ''}`,
        },
        {
            label: t('product_page.accordions.additional_information'),
            value: additionalInformation?.value,
        },
    ];

    return transformedCharacteristics.filter(characteristic => characteristic.value);
};

/**
 * Transform the data for product page to make the index-file easier to follow
 */
export const transformProductPageData = (
    rawData: RawData,
    pageRelationships: PageRelationships,
    t: (text: string) => string
) => {
    const {
        attributes,
        custom_attributes: nonTypedcustomAttributes,
        description,
        id,
        media,
        name,
        price,
        related_products: unfilteredRelatedProducts,
        short_description: shortDescription,
        sku,
        uri,
        variations,
        related_variation_attributes: relatedVariationAttributes,
    } = rawData;

    const customAttributes: Record<string, CustomAttributeMap> = nonTypedcustomAttributes;

    const {
        sa_additional_information_display: rawAdditionalInformation,
        sa_available_from: rawAvailableFrom,
        sa_badges: rawBadges,
        sa_badges_hide_automated: rawBadgesHideAutomated,
        sa_care_instructions: rawCareInstructions,
        sa_color_detailed_display: rawColor,
        sa_designers: rawDesigners,
        sa_detailed_materials_display: detailedMaterials,
        sa_diameter_display: diameter,
        sa_discontinued: rawDiscontinued,
        sa_height_display: height,
        sa_length_display: length,
        sa_materials: rawMaterials,
        sa_new_until: newUntil,
        sa_on_sale: rawOnSaleBadge,
        sa_online_exclusive: rawOnlineExclusive,
        sa_packaging_type: packaging,
        sa_product_usps: rawUsps,
        sa_series: rawSeries,
        sa_size: rawSize,
        sa_sustainable: rawSustainable,
        sa_volume_display: volume,
        sa_width_display: width,
    } = customAttributes;

    /* Merge and setup page content */
    const rawPageContent: object = pageRelationships?.product_page_content || {};

    const rawPageContentArray: object[][] = Object.values(rawPageContent) || [];

    let pageContent: object[] = [];

    // Only run reduce if there are actually content modules
    if (rawPageContentArray[0]) {
        pageContent = rawPageContentArray.reduce((allModules, moduleGroup) => [...allModules, ...moduleGroup], []);
    }

    /* Save related products ids, used in call to algolia */
    let relatedProductsIds = unfilteredRelatedProducts.reduce((acc, related) => {
        if (!acc[related.relation]) {
            acc[related.relation] = [];
        }

        acc[related.relation].push(parseInt(related.id, 10));

        return acc;
    }, {});

    // In this project we only use the standard-relation
    relatedProductsIds = relatedProductsIds.standard || [];

    /* transformProductCharacteristics parameters  */
    const characteristicsToTransform = {
        additionalInformation: rawAdditionalInformation,
        careInstructions: rawCareInstructions,
        color: rawColor.value,
        designers: rawDesigners?.display_text,
        detailedMaterials,
        diameter: diameter?.value,
        height: height?.value,
        length: length?.value,
        packaging: packaging?.display_text,
        rawMaterials,
        series: rawSeries?.display_text,
        sku,
        volume: volume?.value,
        weight: attributes?.weight?.value,
        width: width?.value,
    };

    /* transformProductBadges parameters  */
    const inStock = variations?.[0]?.in_stock;
    const onSaleBadge = rawOnSaleBadge?.value === '1';
    const isOnlineExclusive = rawOnlineExclusive?.value === '1';
    const discontinued = rawDiscontinued?.value === '1';
    const sustainablePerks = rawSustainable?.values ? Object.values(rawSustainable.values) : [];
    const availableFrom = rawAvailableFrom?.value || '';
    const hideAutomatedBadges = rawBadgesHideAutomated?.value === '1';

    /* Transformers */
    const transformedBasketProductData = transformBasketProductData(rawData);
    const transformedBreadcrumbs = transformProductPageBreadcrumbs(pageRelationships?.ecommerce_product_category);
    const transformedCharacteristics = transformProductCharacteristics(characteristicsToTransform, t);
    const transformedCustomBadges = rawBadges?.values ? Object.values(rawBadges.values) : [];
    const transformedMaterials = rawMaterials?.values ? Object.values(rawMaterials.values) : [];
    const transformedMedia = media.map((image: Image) => (image ? transformProductImage(image) : undefined));
    const transformedPrice = transformProductPrice(price);
    const transformedVariants = transformVariants(rawData);
    const comingSoon = availableFrom ? !hasDatePassed(availableFrom) : false;

    const transformedBadges = transformProductBadges(
        {
            availableFrom,
            customBadges: transformedCustomBadges,
            discontinued,
            hideAutomatedBadges,
            inStock,
            isOnlineExclusive,
            materials: transformedMaterials,
            newUntil: newUntil?.value || '',
            onSale: onSaleBadge,
            price: transformedPrice,
            sustainablePerks,
        },
        t
    );

    /* Images */
    const firstMedia = transformedMedia[0]; // Used for meta data
    let mediaGallery = transformedMedia.map(media => media).filter(media => media); // Used for product page

    //  The last image is used for variant color
    // Remove last image from gallery if color variants exists and there is more than one image
    const hasImageVariants = rawData.related_variation_attributes?.includes('sa_color_detailed_display');
    if (hasImageVariants && mediaGallery.length > 1) {
        mediaGallery = mediaGallery.slice(0, -1);
    }

    /* Typed values */
    const typedColor: string | undefined = rawColor?.value;
    const typedDescription: string = description;
    const typedId: string = id;
    const typedInStock: boolean = inStock;
    const typedName: string = name;
    const typedRelatedProductsIds: string[] = relatedProductsIds;
    const typedSeries: { [key: string]: string } = {
        displayText: rawSeries?.display_text,
        description: rawSeries?.description,
    };
    const typedShortDescription: string = shortDescription;
    const typedSize: string = rawSize?.display_text;
    const typedSku: string = sku;
    const typedUrl: string = uri;
    const typedVariationId: string = variations[0].id;

    let usps;
    if (rawUsps?.values) {
        const uspsAsArray = Object.values(rawUsps?.values);
        usps = uspsAsArray.map(usp => ({
            value: usp.description,
            key: usp.internal_name,
        }));
    }

    return {
        badges: transformedBadges,
        basketProductData: transformedBasketProductData,
        breadcrumbs: transformedBreadcrumbs,
        characteristics: transformedCharacteristics,
        color: typedColor,
        comingSoon,
        description: typedDescription,
        firstMedia,
        id: typedId,
        inStock: typedInStock,
        mediaGallery,
        name: typedName,
        pageContent,
        price: transformedPrice,
        relatedProductsIds: typedRelatedProductsIds,
        relatedVariationAttributes,
        series: typedSeries,
        shortDescription: typedShortDescription,
        size: typedSize,
        sku: typedSku,
        url: typedUrl,
        usps,
        variantData: transformedVariants,
        variationId: typedVariationId,
    };
};

/**
 * Transform products data from Aligola Response
 *
 * @param {object} response - Response data from Algolia
 * @param {string} marketId - Market id as string used for checking in stock
 * @param {func} t - Translation function used to translate badges in transformProductCard function
 * @returns object
 */

export const transformAlgoliaProductResponse = (response: Record<string, any>, marketId: string, t: () => '') => {
    const clonedResponse = JSON.parse(JSON.stringify(response));

    if (clonedResponse.hits?.length) {
        clonedResponse.hits = clonedResponse.hits
            .map(product => {
                // Go through all the variations if we have any.
                if (Array.isArray(product.variations)) {
                    product.variations = product.variations.map(variation => {
                        // Legacy support for in_stock
                        if (variation._in_stock_by_marketplace) {
                            // Set the in_stock variable to true or false if we have this variation in stock for this market.
                            variation.in_stock = variation._in_stock_by_marketplace.indexOf(marketId) !== -1;
                            // Delete the variations stock by market, we only need the market we are currently on.
                            delete variation._in_stock_by_marketplace;
                            // Return the variation.
                        }
                        return variation;
                    });
                }
                // Return the product
                return transformProductCard(product, marketId, t);
            })
            .filter(product => product.price);
    }

    return clonedResponse;
};
