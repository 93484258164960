import Arrow from 'assets/icons/Arrow';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { borderRadius } from 'config/theme/borderRadius';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';

const ImageAndTextCard = ({
    backgroundMediaQueries = mediaQueries.fourColumn,
    handleClick = () => {},
    imageBackgroundPosition = '',
    imageBackgroundSize = '',
    imageRatio = ratios.vertical,
    imageUrl = '',
    label = '',
    to = '',
}) => (
    <Link to={to} onClick={handleClick}>
        <AspectWrapper
            backgroundColor={colors.placeholder}
            borderRadius={borderRadius}
            mb="8px"
            overflow="hidden"
            ratio={imageRatio}
        >
            <BackgroundImage
                position={imageBackgroundPosition}
                query={backgroundMediaQueries}
                size={imageBackgroundSize}
                src={imageUrl}
            />
        </AspectWrapper>
        <Paragraph as="span" display="block" fontKeys="Recife Display/18" lineHeight="1" marginLeft="6px">
            {label} <Arrow color="currentColor" marginLeft="6px" />
        </Paragraph>
    </Link>
);

ImageAndTextCard.propTypes = {
    backgroundMediaQueries: PropTypes.object,
    handleClick: PropTypes.func,
    imageBackgroundPosition: PropTypes.string,
    imageBackgroundSize: PropTypes.string,
    imageRatio: PropTypes.number,
    imageUrl: PropTypes.string,
    label: PropTypes.string,
    to: PropTypes.string,
};

export default ImageAndTextCard;
