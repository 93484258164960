/* eslint-disable camelcase */
import { ContentComponent, ContentComponents } from 'libs/wordpress/utils/cmsComponentHandlers';

import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import loadable from '@loadable/component';

const AccordionLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/AccordionLayout'), { fallback: <Loading /> });
const ArticleFlowModule = loadable(() => import('libs/wordpress/content/cmsModules/ArticleFlowModule'), { fallback: <Loading /> });
const CallToActionModule = loadable(() => import('libs/wordpress/content/cmsModules/CallToActionModule'), { fallback: <Loading /> });
const ColumnsTwoFullscreenModule = loadable(() => import('libs/wordpress/content/cmsLayouts/ColumnsTwoFullscreenModule'), { fallback: <Loading /> });
const ColumnsTwoRatioModule = loadable(() => import('libs/wordpress/content/cmsLayouts/ColumnsTwoRatioModule'), { fallback: <Loading /> });
const ColumnsTwoTextModule = loadable(() => import('libs/wordpress/content/cmsLayouts/ColumnsTwoTextModule'), { fallback: <Loading /> });
const ExpanderLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/ExpanderLayout'), { fallback: <Loading /> });
const ImageFullscreenModule = loadable(() => import('libs/wordpress/content/cmsModules/ImageFullscreenModule'), { fallback: <Loading />});
const ImageRatioModule = loadable(() => import('libs/wordpress/content/cmsModules/ImageRatioModule'), { fallback: <Loading />});
const ImageSmallModule = loadable(() => import('libs/wordpress/content/cmsModules/ImageSmallModule'), { fallback: <Loading />});
const InformationModule = loadable(() => import('libs/wordpress/content/cmsModules/InformationModule'), { fallback: <Loading />});
const InterviewModule = loadable(() => import('libs/wordpress/content/cmsModules/InterviewModule'), { fallback: <Loading /> });
const LinkListModule = loadable(() => import('libs/wordpress/content/cmsModules/LinkListModule'), { fallback: <Loading /> });
const NewsletterModule = loadable(() => import('libs/wordpress/content/cmsModules/NewsletterModule'), { fallback: <Loading /> });
const ProductsPromotionModule = loadable(() => import('libs/wordpress/content/cmsModules/ProductsPromotionModule'), { fallback: <Loading /> });
const QuoteModule = loadable(() => import('libs/wordpress/content/cmsModules/QuoteModule'), { fallback: <Loading /> });
const SalePromotionModule = loadable(() => import('libs/wordpress/content/cmsModules/SalePromotionModule'), { fallback: <Loading /> });
const ShopTheLookModule = loadable(() => import('libs/wordpress/content/cmsModules/ShopTheLookModule'), { fallback: <Loading /> });
const TextLayout = loadable(() => import('libs/wordpress/content/cmsLayouts/TextLayout'), { fallback: <Loading /> });
const VideoInlineFullscreenModule = loadable(() => import('libs/wordpress/content/cmsModules/VideoInlineFullscreenModule'), { fallback: <Loading /> });
const VideoInlineRatioModule = loadable(() => import('libs/wordpress/content/cmsModules/VideoInlineRatioModule'), { fallback: <Loading /> });
const VideoInlineSmallModule = loadable(() => import('libs/wordpress/content/cmsModules/VideoInlineSmallModule'), { fallback: <Loading /> });
const VideoPlayerModule = loadable(() => import('libs/wordpress/content/cmsModules/VideoPlayerModule'), { fallback: <Loading /> });

/**
 * NOTE This file ignores prettier to increase readability by keeping all loadable imports on their own lines
 */

// Object of avalible modules/layouts and their keys
const availableContentComponents = {
    // Add the modules/layouts that exists in core/views directly in here.
    // We don't want to dynamically import them as that would cause circular references.

    'accordion_module': AccordionLayout,
    'article_flow_module': ArticleFlowModule,
    'background_image_fullscreen_module': ImageFullscreenModule,
    'background_image_ratio_module': ImageRatioModule,
    'background_image_small_module': ImageSmallModule,
    'call_to_action_module': CallToActionModule,
    'columns_2_fullscreen_module': ColumnsTwoFullscreenModule,
    'columns_2_ratio_module': ColumnsTwoRatioModule,
    'columns_2_text_module': ColumnsTwoTextModule,
    'expander_module': ExpanderLayout,
    'information_module': InformationModule,
    'interview_module': InterviewModule,
    'link_list_module': LinkListModule,
    'newsletter_module': NewsletterModule,
    'products_promotion_module': ProductsPromotionModule,
    'quote_module': QuoteModule,
    'sale_promotion_module': SalePromotionModule,
    'shop_the_look_module': ShopTheLookModule,
    'text_block_module': TextLayout,
    'video_inline_fullscreen_module': VideoInlineFullscreenModule,
    'video_inline_ratio_module': VideoInlineRatioModule,
    'video_inline_small_module': VideoInlineSmallModule,
    'video_player_module': VideoPlayerModule,
};

/**
 * Accepts raw module data from the cms and tries to match the data to a cmsModule/cmsLayout.
 * If a match is found the cmsModule/cmsLayout is returned and rendered.
 * @param {(object|object[])} data - Raw module data from the cms. Can be a object or an array of objects.
 * @param {...*} rest - All additional props will be forwarded to the cmsModule/cmsLayout.
 */

const CmsModules = ({ data, ...rest }) => {
    if (!data) {
        return null;
    }
    
    if (Array.isArray(data)) {
        return <ContentComponents data={data} availableContent={availableContentComponents} {...rest} />;
    }
    
    return <ContentComponent data={data} availableContent={availableContentComponents} {...rest} />;
};

CmsModules.propTypes = {
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

export default CmsModules;
