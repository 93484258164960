import React, { useCallback, useEffect, useRef, useState } from 'react';

import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    position: relative;
    height: 50px; // Everything is position absolute so therefore the height is set manually
`;

const Input = styled('input')`
    pointer-events: none;
    position: absolute;
    height: 0;
    outline: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    ::-webkit-slider-thumb {
        background-color: var(--static-background-color-primary);
        border-radius: 100%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        filter: none;
        cursor: pointer;
        height: 25px;
        width: 25px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    ::-moz-range-thumb {
        background-color: var(--static-background-color-primary);
        border-radius: 100%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        filter: none;
        cursor: pointer;
        height: 25px;
        width: 25px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
`;

const Slider = styled('div')`
    position: relative;
`;

const Track = styled('div')`
    position: absolute;
    width: 100%;
    height: 2px;
    z-index: 1;
    background-color: var(--dynamic-color-light-four);
`;

const Range = styled('div')`
    position: absolute;
    height: 2px;
    z-index: 2;
    background-color: var(--dynamic-color-primary);
`;

const Value = styled('div')`
    position: absolute;
    top: 24px;
    color: var(--static-color-primary);
`;

/**
 * Range slider with min and max value.
 *
 * @param {func} handleChange - Triggers when range slider values changes
 * @param {func} handleClearByKey - Triggers when min = minVal and max = maxVal
 * @param {string} label - Label displayed above the slider
 * @param {number} max - Maximum value for slider
 * @param {number} min - Minimum value for slider
 * @param {string} unit - Unit for the slider values
 */

const MultiRangeSlider = ({
    handleChange = () => {},
    handleClearByKey = () => {},
    label = '',
    max,
    min,
    unit = '',
    ...rest
}) => {
    // States
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);

    // Refs
    const wrapperRef = useRef(null);
    const inputMinRef = useRef(null);
    const inputMaxRef = useRef(null);
    const rangeRef = useRef(null);
    const filterTimeout = useRef(null);
    const didMount = useRef(false);

    // Styles
    const fontStyles = styleObjectToProps('NB International/12_120_4');
    const wrapperWidth = wrapperRef.current ? `${wrapperRef.current.getBoundingClientRect().width}px` : null;

    // Convert to percentage
    const getPercent = useCallback(value => Math.round(((value - min) / (max - min)) * 100), [min, max]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (rangeRef.current && inputMinRef.current) {
            const minPercent = getPercent(parseInt(inputMinRef.current.value, 10));
            const maxPercent = getPercent(maxVal);
            rangeRef.current.style.setProperty('width', `${maxPercent - minPercent}%`);
        }
    }, [maxVal, getPercent]);

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (rangeRef.current && inputMaxRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(parseInt(inputMaxRef.current.value, 10));
            rangeRef.current.style.setProperty('width', `${maxPercent - minPercent}%`);
            rangeRef.current.style.setProperty('left', `${minPercent}%`);
        }
    }, [minVal, getPercent]);

    // Set min and max values on change
    const handleOnChange = (value, set) => {
        if (set === 'min') {
            setMinVal(value);
        }
        if (set === 'max') {
            setMaxVal(value);
        }
    };

    // Listens to minVal/maxVal and adds delay for handleChange to prevent a filter overload
    useEffect(() => {
        // Do not run on init
        if (didMount.current) {
            clearTimeout(filterTimeout.current);
            if (min === minVal && max === maxVal) {
                handleClearByKey();
            } else {
                filterTimeout.current = setTimeout(() => {
                    handleChange(minVal, maxVal);
                }, 1000);
            }
        } else {
            didMount.current = true;
        }
        return () => clearTimeout(filterTimeout);
         
    }, [minVal, maxVal]);

    return (
        <>
            {label && (
                <Heading as="span" display="block" fontKeys="NB International/14_100" marginBottom="20px">
                    {label}
                </Heading>
            )}
            <Wrapper ref={wrapperRef} {...rest}>
                <Input
                    max={max}
                    min={min}
                    ref={inputMinRef}
                    type="range"
                    width={wrapperWidth} // Everything is position absolute so therefore the width is set by using a ref
                    value={minVal}
                    zIndex={minVal > max - 100 ? '5' : '3'} // To be able to move the left thumb from the extreme right end
                    onChange={event => handleOnChange(Math.min(parseInt(event.target.value, 10), maxVal - 1), 'min')}
                />
                <Input
                    max={max}
                    min={min}
                    ref={inputMaxRef}
                    type="range"
                    width={wrapperWidth} // Everything is position absolute so therefore the width is set by using a ref
                    value={maxVal}
                    zIndex="4"
                    onChange={event => handleOnChange(Math.max(parseInt(event.target.value, 10), minVal + 1), 'max')}
                />
                <Slider width={wrapperRef.current ? `${wrapperRef.current.getBoundingClientRect().width}px` : null}>
                    <Track />
                    <Range ref={rangeRef} />
                    <Value left="0" {...fontStyles}>
                        {minVal} {unit}
                    </Value>
                    <Value right="0" {...fontStyles}>
                        {maxVal} {unit}
                    </Value>
                </Slider>
            </Wrapper>
        </>
    );
};

MultiRangeSlider.propTypes = {
    handleChange: PropTypes.func,
    handleClearByKey: PropTypes.func,
    label: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    unit: PropTypes.string,
};

export default MultiRangeSlider;
