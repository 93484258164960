import Filter from 'components/Filter';
import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const TransitionElement = styled('div')`
    position: absolute;
    top: 0;
    width: 100vw;
    max-width: 400px;
    background-color: var(--static-background-color-primary);
    transition: max-width ${transitions.secondary};

    // Used to prevent a height 100vh bug on android phones.
    // This element will overflow the TransitionElement and hide content behind when viewport changes height.
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: var(--static-background-color-primary);
    }
`;

const FilterOverlay = ({ ...rest }) => (
    <TransitionElement className="slide-in left">
        <Filter {...rest} />
    </TransitionElement>
);

export default FilterOverlay;
