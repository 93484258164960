import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';

/**
 * @version 1.1
 * @author Anton Pedersen
 * @param {getMediaSrcIfImage} func check for avalible media src
 * @param {getOptimalContentImageSrc} func getOptimalContentImageSrc
 * @info Please update this with your content of choice on the site. Different modules containing different data.
 *       Dont forget to update the content?.FooBar
 * @returns {string} src
 */

const getMediaSrcIfImage = media => {
    if (!media) {
        return null;
    } else if (media.type === 'image') {
        return media.url || null;
    }

    return null;
};

const getOptimalContentImageSrc = ({
    background_media_flexible: backgroundMediaFlexible = {},
    hero_content: heroContent = {},
}) => {
    let src = null;

    const heroImage = heroContent?.[0]?.data?.data?.background_media_flexible;
    const postImage = backgroundMediaFlexible;

    if (heroImage) {
        src = getMediaSrcIfImage(transformFlexibleBackground(heroImage)?.desktop);

        return src;
    }

    if (postImage) {
        src = getMediaSrcIfImage(transformFlexibleBackground(postImage)?.desktop);

        return src;
    }

    return src;
};

export default getOptimalContentImageSrc;
