import React, { Fragment } from 'react';

import ArrowDown from 'assets/icons/ArrowDown';
import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PlusIcon from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import withHeightAuto from 'utils/withHeightAuto';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
`;

const InnerWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const AnimationWrapper = withHeightAuto(styled('div')`
    overflow: hidden;
`);

const ToggleButton = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--static-background-color-secondary);
    color: var(--dynamic-color-secondary);
    transition: transform ${transitions.primary};
`;

const SingleAccordion = ({
    anchorTag = '',
    animationDuration = 0,
    children,
    colorText,
    fontKeys,
    handleClick,
    headerPX = '12px',
    headerPY = '16px',
    hideIcon = false,
    iconSize = '12px',
    iconType = 'plus',
    isActive = false,
    maxWithContent = false,
    text = '',
    timingFunction = 'linear',
    url = '',
    ...rest
}) => {
    // Add MaxWidthWrapper with includeContentSpacing prop if maxWithContent is true
    const RenderMaxWidth = maxWithContent ? MaxWidthWrapper : Fragment;
    const RenderMaxWidthProps = maxWithContent ? { includeContentSpacing: true } : {};

    return (
        <Wrapper color={colorText} id={anchorTag} {...rest}>
            <RenderMaxWidth {...RenderMaxWidthProps}>
                <Link volatile to={url || null} style={{ textDecoration: 'none' }}>
                    <InnerWrapper px={headerPX} py={headerPY} onClick={children && !url ? handleClick : null}>
                        <Heading as="h3" fontKeys={fontKeys} width="calc(100% - 48px)">
                            {text}
                        </Heading>
                        {children && !hideIcon && iconType === 'arrow' ? (
                            <ArrowDown
                                color={colorText}
                                height={iconSize}
                                marginRight={['12px', null, '8px']}
                                transform={isActive ? 'rotateX(180deg)' : 'rotateX(0)'}
                                transition={`transform ${transitions.primary}`}
                            />
                        ) : (
                            children &&
                            !hideIcon && (
                                <ToggleButton transform={isActive ? 'rotate(45deg)' : 'none'} type="button">
                                    <PlusIcon color="currentColor" width={iconSize} />
                                </ToggleButton>
                            )
                        )}
                    </InnerWrapper>
                </Link>
                {children && (
                    <AnimationWrapper
                        animationDuration={animationDuration}
                        height={isActive ? 'auto' : '0'}
                        transition={animationDuration > 0 && `${animationDuration}ms ${timingFunction}`}
                    >
                        {children}
                    </AnimationWrapper>
                )}
            </RenderMaxWidth>
        </Wrapper>
    );
};

SingleAccordion.propTypes = {
    anchorTag: PropTypes.string,
    animationDuration: PropTypes.number,
    children: PropTypes.node,
    colorText: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    handleClick: PropTypes.func.isRequired,
    headerPX: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headerPY: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    hideIcon: PropTypes.bool,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    iconType: PropTypes.string,
    isActive: PropTypes.bool,
    maxWithContent: PropTypes.bool,
    text: PropTypes.string,
    timingFunction: PropTypes.string,
    url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default SingleAccordion;
