import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import styled from 'libs/styled';

const Wrapper = styled('ul')``;

const Breadcrumb = styled('li')`
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 8px;

    :not(:last-child) {
        ::after {
            content: '/';
            margin-left: 8px;
        }
    }
`;

const Items = ({ activeColor, linkColor, list = [], textStyles = {}, ...rest }) => (
    <Wrapper {...rest}>
        {list?.map(breadcrumb => {
            if (!breadcrumb) {
                return null;
            }

            const to = breadcrumb.permalink || breadcrumb.to;

            return (
                <Breadcrumb key={breadcrumb.title} {...textStyles}>
                    <TextLink volatile color={to ? linkColor : activeColor} cursor={to ? 'pointer' : 'default'} to={to}>
                        {decodeHTMLEntities(breadcrumb.title)}
                    </TextLink>
                </Breadcrumb>
            );
        })}
    </Wrapper>
);

Items.propTypes = {
    activeColor: PropTypes.string,
    linkColor: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            permalink: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    textStyles: PropTypes.object,
};

export default Items;
