/**
 * Appends a querystring with the image service queryparameters
 *
 * @param {string} src - The image url
 * @param {(number|string)[]} width - Control the width of the image
 * @param {(number|string)[]} height - Control the height of the image
 * @param {string} format - The output format to convert the image to.
 * @param {number|string} quality - Controls the output quality of lossy file formats (jpg, pjpg, webp, or jxr).
 * @param {string} auto - The auto parameter helps you automate a baseline level of optimization across your entire image catalog.
 */

const generateImageSrc = ({ src, width, height, format, quality, auto }) => {
    const awsS3Url = process.env.REACT_APP_AWS_S3_URL;
    const isSvg = src?.endsWith('.svg');
    const isData = src?.startsWith('data:');
    const isPath = src?.startsWith('/'); //Used for e.g. placeholders

    if (src && !isData && !isPath) {
        if (isSvg) {
            const svgUrl = new URL(src);
            const newSvgUrl = `${awsS3Url}${svgUrl.pathname}`;

            // Update src with the aws S3 url.
            src = newSvgUrl;

            // Append image query string
        } else {
            const strippedSrc = src.split('?');
            return (
                strippedSrc[0] +
                (quality ? '?q=' + quality : '') +
                (width ? '&w=' + width : '') +
                (height ? '&h=' + height : '') +
                (format ? '&fm=' + format : '') +
                (auto ? '&auto=' + auto : '')
            );
        }
    }

    return src || undefined;
};

export default generateImageSrc;