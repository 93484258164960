import Close from 'assets/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import RecursiveMenuMobileView from './RecursiveMenuMobileView';
import { borderRadius } from 'config/theme/borderRadius';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: ${zIndex.menuMobile + 4};
    border-top-right-radius: ${borderRadius[0]};
    border-bottom-right-radius: ${borderRadius[0]};
    overflow: hidden;
    background-color: var(--dynamic-color-brand-secondary-light);
`;

const CloseButton = styled('button')`
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 53px;
    cursor: pointer;
    z-index: 2; // z-index 2 makes sure that the close-button is always visible
`;

const MenuMobile = ({ closeMenu = () => {}, totalAnimationDuration }) => {
    const { extraLinks, primaryMenu, secondaryMenu } = useSelector(state => state.header.data.navigation || {});

    return (
        <Wrapper>
            <CloseButton type="button" onClick={closeMenu}>
                <Close color="currentColor" />
            </CloseButton>
            <RecursiveMenuMobileView
                isActive
                isTopLevel
                closeMenu={closeMenu}
                extraLinks={extraLinks}
                primarySubMenu={primaryMenu}
                secondarySubMenu={secondaryMenu}
                totalAnimationDuration={totalAnimationDuration}
            />
        </Wrapper>
    );
};

MenuMobile.propTypes = {
    closeMenu: PropTypes.func,
    totalAnimationDuration: PropTypes.number,
};

export default MenuMobile;
