import { BackgroundImage, Button, Cookie, Link, WYSIWYG } from 'libs/wordpress/types';

import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformLink from 'libs/wordpress/utils/transformLink';

// Banner
interface BannerMessage {
    message: WYSIWYG;
    showArrow: boolean;
}

// Basket
interface Basket {
    checkoutButtonLabel?: string;
    cookie: Cookie;
    empty?: {
        text: string;
        button: Button;
    };
    label?: string;
}

// Search
interface Search {
    buttonLabel: string;
    noResultText: WYSIWYG;
    placeholder: string;
}

// Navigation
type MenuType = 'link' | 'submenu';
type LinkType = 'links' | 'images';
type Column = number | undefined;

interface ImageItem extends Link {
    image: BackgroundImage;
}

interface LinkItem {
    label?: string;
    menuType?: MenuType;
    link?: Link;
    subMenu?: [];
    column?: Column;
}
interface SubMenu {
    linksType: LinkType;
    primaryMenuHeading?: string;
    primaryMenuImages?: ImageItem[];
    primaryMenuLinks?: LinkItem[];
}
interface MenuItem {
    highlight?: boolean;
    label?: string;
    link?: Link;
    menuType?: MenuType;
    subMenu?: SubMenu;
}

interface HeaderData {
    banner?: BannerMessage[];
    basket?: Basket;
    cacheKey: number;
    checkoutLink?: Link;
    navigation: {
        extraLinks?: Link[];
        primaryMenu?: MenuItem[];
        secondaryMenu?: MenuItem[];
    };
    search?: Search;
}

// Recursive function for transforming subMenu data
const transformSubmenu = (submenu: Record<string, any>): SubMenu | object => ({
    linksType: submenu.links_type,
    primaryMenuHeading: submenu.primary_menu_heading,
    primaryMenuImages: submenu.primary_menu_images
        ? submenu.primary_menu_images.map(({ background_image: backgroundImage, label, link }) => ({
              label,
              link: link ? transformLink(link) : undefined,
              image: backgroundImage ? transformBackgroundImage(backgroundImage) : undefined,
          }))
        : undefined,
    primaryMenuLinks: submenu.primary_menu_links
        ? submenu.primary_menu_links.map(({ column, label, link, menu_type: menuType, submenu }) => ({
              label,
              link: link ? transformLink(link) : undefined,
              menuType,
              submenu: submenu ? transformSubmenu(submenu) : undefined,
              column: column ? parseInt(column, 10) : undefined,
          }))
        : undefined,
});

/**
 * @param {object} raw - Object with any data.
 * @param {HeaderData} - Transformed data.
 */

export const transformHeaderData = (raw: Record<string, any>): HeaderData | object => {
    try {
        return {
            banner: raw.banner.messages
                ? raw.banner.messages.map(message => ({
                      message: message?.message,
                      link: transformLink(message.link),
                  }))
                : undefined,
            basket: {
                checkoutButtonLabel: raw.basket.with_products?.checkout_button_label,
                cookie: raw.basket.cookie,
                empty: { text: raw.basket.empty?.text, button: raw.basket.empty?.button },
                label: raw.basket.label,
            },
            cacheKey: Date.now(),
            checkoutLink: transformLink(raw.checkout?.link),
            navigation: {
                extraLinks: raw.navigation.extra_links
                    ? raw.navigation.extra_links.map(({ link }) => transformLink(link))
                    : undefined,
                primaryMenu: raw.navigation.primary_menu
                    ? raw.navigation.primary_menu.map(({ highlight, label, link, menu_type: menuType, submenu }) => ({
                          highlight,
                          label,
                          link: link ? transformLink(link) : undefined,
                          menuType,
                          submenu: submenu ? transformSubmenu(submenu) : undefined,
                      }))
                    : undefined,
                secondaryMenu: raw.navigation.secondary_menu
                    ? raw.navigation.secondary_menu.map(({ label, link, menu_type: menuType, submenu }) => ({
                          label,
                          link: link ? transformLink(link) : undefined,
                          menuType,
                          submenu: submenu ? transformSubmenu(submenu) : undefined,
                      }))
                    : undefined,
            },
            search: {
                buttonLabel: raw.search.button_label,
                noResultText: raw.search.no_result_text,
                placeholder: raw.search.input_placeholder_text,
            },
        };
    } catch (e) {
        console.error(e);
        return {};
    }
};

/**
 * Transform the menu data for desktop level two
 */

export const transformMenuLinksIntoColumns = (raw: LinkItem[] | undefined) => {
    const columns: Array<LinkItem[]> = [];

    if (!raw) {
        return columns;
    }

    raw.forEach((item: LinkItem) => {
        const columnNumber = item?.column ? item?.column - 1 : 0;

        if (!columns[columnNumber]) {
            columns[columnNumber] = [];
        }
        columns[columnNumber].push(item);
    });

    return columns;
};
