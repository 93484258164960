import { toCamelCase, toSnakeCase } from './string';

export type LooseObject = { [key: string]: any };

export const isEmpty = (object: LooseObject) => Object.keys(object).length === 0;

export const isObject = (object: any) => object && typeof object === 'object' && !Array.isArray(object);

export const keysToSnake = (object: LooseObject): any => {
    if (isObject(object)) {
        const newObject: LooseObject = {};

        Object.keys(object).forEach(key => {
            newObject[toSnakeCase(key)] = object[key];
        });

        return newObject;
    }
    return object;
};

export const keysToCamel = (object: LooseObject): LooseObject => {
    if (isObject(object)) {
        const newObject: LooseObject = {};

        Object.keys(object).forEach(key => {
            newObject[toCamelCase(key)] = object[key];
        });

        return newObject;
    }

    return object;
};

export const cloneDeep = (inObject: any) => {
    if (typeof inObject !== 'object' || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    const outObject: any = Array.isArray(inObject) ? [] : {};

    for (const key in inObject) {
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = cloneDeep(inObject[key]);
    }

    return outObject;
};

/**
 * @param {object} object - The object that should be manipulated
 * @param {string} path - Path to the key to be removed. Can be 'key' or 'nested.key'
 */
export const deleteKey = (object: LooseObject, path: string) => {
    const keys = path.split('.').filter(value => value);
    keys.reduce((acc, key, i) => {
        if (i === keys.length - 1) {
            delete acc[key];
            return true;
        }
        return acc[key];
    }, object);
};

/**
 * @param {object} object - The object that should be searched
 * @param {any} value - Value to search for
 * @param {string} path - Nested path for recursive search
 * @return {string[]} - Array of paths to the keys with a matched search value
 */
export const recursiveSearch = (object: LooseObject, value: any, path: string = '') => {
    let props: string[] = [];

    for (const key in object) {
        if (object[key] === value) {
            props.push(`${path}.${key}`);
        }

        if (object[key] instanceof Object) {
            props = [...props, ...recursiveSearch(object[key], value, `${path}.${key}`)];
        }
    }

    return props;
};

/**
 * @param {object} object - The object that should be manipulated
 * @param {any} value - Delete all keys and nested keys with this value
 */
export const recursiveDeleteKeyByValue = (object: LooseObject, value: any) => {
    const keys = recursiveSearch(object, value);
    if (keys.length > 0) {
        keys.forEach(key => {
            deleteKey(object, key);
        });
    }
};

export const filterObject = (subject: object, filterValue: boolean|string|number|undefined) => {
    return Object.entries(subject)
        .filter(([key, value]) => value !== filterValue)
        .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
};
