import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const Size = styled('li')`
    border-radius: 30px;
    cursor: pointer;

    span {
        display: inline-block;
        padding: 6px 12px;
    }
`;

const SizeSelectItem = ({ variant = {}, ...rest }) => {
    const sizeSelectFontStyles = styleObjectToProps('NB International/14_120');
    const { currentVariant, inStock, name, title, to } = variant;

    return (
        <Size
            backgroundColor={currentVariant ? 'transparent' : 'var(--static-color-light-one)'}
            border={
                currentVariant ? '1px solid var(--dynamic-color-primary)' : '1px solid var(--static-color-light-one)'
            }
            color={inStock ? 'var(--dynamic-color-primary)' : 'var(--static-color-light-five)'}
            textDecoration={inStock ? 'none' : 'line-through'}
            {...sizeSelectFontStyles}
            {...rest}
        >
            <Link to={!currentVariant && to} title={title || name}>
                <span>{name}</span>
            </Link>
        </Size>
    );
};

SizeSelectItem.propTypes = {
    variant: PropTypes.object,
};

export default SizeSelectItem;
