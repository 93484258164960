import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledWysiwygParagraph = styled('p')`
    strong {
        font-weight: 700;
    }
`;

/**
 * Regular Paragraph component with extra bold font-weight to be used with the Wysiwyg component.
 * Ex: <Wysiwyg textComponent={props => <WysiwygParagraph {...props} />}/>
 */
const WysiwygParagraph = ({ as = 'p', children, fontKeys = 'NB International/16_160', ...rest }) => {
    const fontStyles = styleObjectToProps(fontKeys);

    return (
        <StyledWysiwygParagraph as={as} {...fontStyles} {...rest}>
            {children}
        </StyledWysiwygParagraph>
    );
};

WysiwygParagraph.propTypes = {
    as: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default WysiwygParagraph;
