import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const MinusIcon = ({ color = colors.black, height = '100%', width = '10px', ...rest }) => (
    <SVG width={width} height={height} fill="none" viewBox="0 0 10 1" {...rest}>
        <Path d="M0 .5h10" stroke={color} />
    </SVG>
);

MinusIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default MinusIcon;
