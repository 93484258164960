import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Li = styled('li', {
    shouldForwardProp: prop => ['isSelected', 'inStock'].indexOf(prop) === -1,
})`
    position: relative;
    cursor: pointer;

    ${({ isSelected }) =>
        isSelected
            ? `
        cursor: default;

      ::after {
        content: '';
        position: absolute;
        z-index: 2;
        top: -7px;
        left: -7px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid var(--dynamic-color-primary);
        

        ${above.desktopSm} {
            top: -5px;
            left: -5px;
            width: 32px;
            height: 32px;
        }
      }
        `
            : `
        :hover {
            ::after {
                content: '';
                position: absolute;
                z-index: 2;
                top: -1px;
                pointer-events: none;
                left: -1px;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: var(--dynamic-opacity-color-light-higher);
              }
        }
        `}

    ${({ inStock }) =>
        !inStock &&
        `
      ::before {
        content: '';
        position: absolute;
        z-index: 3;
        top: 16px;
        left: 8px;
        width: 18px;
        height: 2px;
        background: ${colors.grey.five};
        pointer-events: none;

        ${above.desktopSm} {
            top: 10px;
            left: 2px;  
        }
      }

        ::after {
          content: '';
          position: absolute;
          z-index: 2;
          top: -1px;
          left: -1px;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: ${colors.opacity.white.high};
          pointer-events: none;
        }
          `}
`;

const Div = styled('div')`
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    top: -1px;
    left: -1px;
`;

const ColorSelectItem = ({ variant = {}, ...rest }) => {
    const { currentVariant, imageSrc, inStock, name, title, to, useOutline } = variant;
    const itemSize = ['36px', null, null, null, '24px'];

    return (
        <Li
            height={itemSize}
            inStock={inStock}
            isSelected={currentVariant}
            title={title || name}
            width={itemSize}
            {...rest}
        >
            <Link to={!currentVariant ? to : undefined}>
                <Div
                    width={itemSize}
                    height={itemSize}
                    border={useOutline && !currentVariant ? '1px solid var(--dynamic-color-light-three)' : 'none'}
                >
                    <Image
                        alt={name}
                        position="absolute"
                        top={0}
                        height="100%"
                        objectFit="cover"
                        sizes={['36px', null, null, null, '24px']}
                        src={{ url: imageSrc, width: [36, null, null, 24] }}
                    />
                </Div>
            </Link>
        </Li>
    );
};

ColorSelectItem.propTypes = {
    variant: PropTypes.object,
};

export default ColorSelectItem;
