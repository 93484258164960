import React, { Component } from 'react';

import AlgoliaContainer from 'containers/AlgoliaContainer';
import PropTypes from 'prop-types';
import { injectModels } from 'state';
import { transformAlgoliaArticleResponse } from 'utils/dataTransformers/article';
import { transformAlgoliaCategoryResponse } from 'utils/dataTransformers/category';
import { transformAlgoliaProductResponse } from 'utils/dataTransformers/product';
import { transformAlgoliaSuggestionsResponse } from 'utils/dataTransformers/suggestions';
import { withRouter } from 'react-router-dom';

class SearchContainer extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        categories: PropTypes.array,
        history: PropTypes.object.isRequired,
        ids: PropTypes.array,
        index: PropTypes.string,
        list: PropTypes.string,
        location: PropTypes.object.isRequired,
        pageSize: PropTypes.number,
        render: PropTypes.func.isRequired,
        renderProps: PropTypes.object,
        responseCallback: PropTypes.func,
        search: PropTypes.object,
        searchString: PropTypes.string,
        setSuggestions: PropTypes.func,
        t: PropTypes.func,
        tags: PropTypes.array,
    };

    static defaultProps = {
        categories: [],
        ids: [],
        index: 'products',
        list: '',
        pageSize: 5,
        renderProps: {},
        responseCallback: null,
        search: {},
        searchString: '',
        setSuggestions: () => {},
        t: () => '',
        tags: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            index: props.index,
        };
    }

    shouldComponentUpdate(nextProps) {
        const { application, ...props } = this.props;
        const { application: nextApplication, ...newNextProps } = nextProps;

        if (JSON.stringify(props) !== JSON.stringify(newNextProps)) {
            return true;
        }

        if (
            JSON.stringify(application.shop_config) !== JSON.stringify(nextApplication.shop_config) ||
            application.locale !== nextApplication.locale
        ) {
            return true;
        }

        return false;
    }

    parameters = (ids, categories, tags, pagination, pageSize, application) => ({
        page: pagination,
        hitsPerPage: pageSize,
        filters: [
            [`_pricelists:${application.shop_config.pricelist_id}`],
            [`_marketplaces:${application.shop_config.market_id}`],

            // Only for the backup products, these are not gonna be needed for the normal query.
            ids.map(id => `objectID:"${id}"`),
            categories.map(c => `_categories_ids:"${c}"`),
            tags.map(c => `_keywords:"${c}"`),

            // ONLY SHOW PRODUCTS IN STOCK BASED ON MARKET_ID
            //[`_in_stock:${application.shop_config.market_id}`],
        ].filter(i => i.length !== 0),
    });

    getParameters = () => {
        const { ids, categories, tags, pageSize, searchString, application } = this.props;
        const { index } = this.state;
        const parameters = [];
        const locale = application.locale;
        const indexNameProducts = `${index}_${locale}_search`;
        const indexNameQuerySuggestions = `${index}_${locale}_query_suggestions`;

        // todo: only get default once per changed filter default
        // Default products
        parameters.push({
            indexName: indexNameProducts,
            params: this.parameters(ids, categories, tags, 1, pageSize, application),
        });

        // Search query
        parameters.push({
            indexName: indexNameProducts,
            query: searchString,
            params: this.parameters([], [], [], 1, pageSize, application),
        });

        // Categories
        parameters.push({
            indexName: 'product_categories',
            query: searchString,
            params: {
                page: 1,
                hitsPerPage: 5,
                filters: [[`application_id:${application.applicationId}`]],
            },
        });

        // Articles
        parameters.push({
            indexName: 'articles',
            query: searchString,
            params: {
                page: 1,
                hitsPerPage: 5,
                filters: [[`application_id:${application.applicationId}`]],
            },
        });

        // Suggestions
        parameters.push({
            indexName: indexNameQuerySuggestions,
            query: searchString,
            params: {
                page: 1,
                hitsPerPage: 5,
            },
        });

        return parameters;
    };

    responseCallback = response => {
        if (!response) {
            return null;
        }

        const { responseCallback, application, list, search, setSuggestions, t } = this.props;
        const articles = transformAlgoliaArticleResponse(response[3] || {});
        const categories = transformAlgoliaCategoryResponse(response[2]) || {};
        const fallbackProducts = transformAlgoliaProductResponse(response[0], application.shop_config.market_id, t);
        const products = transformAlgoliaProductResponse(response[1] || {}, application.shop_config.market_id, t);
        const suggestions = transformAlgoliaSuggestionsResponse(response[4] || {});

        // Update redux if there are no suggestions
        if (!search.suggestions.length) {
            setSuggestions(suggestions.hits);
        }

        const data = {
            articles: articles.hits,
            categories: categories.hits,
            fallbackProducts: fallbackProducts.hits,
            list,
            suggestions: suggestions.hits,
            products: products.hits,
            productsHitsCount: products.nbHits,
        };

        return responseCallback ? responseCallback(data) : data;
    };

    render() {
        const { render, renderProps } = this.props;

        return (
            <AlgoliaContainer
                renderProps={renderProps}
                parameters={this.getParameters()}
                responseCallback={this.responseCallback}
                render={({ application, ...rest }) => render({ ...rest })}
            />
        );
    }
}

export default withRouter(injectModels(['application', 'search'])(SearchContainer));
