export const headerThemes = {
    transparentLight: {
        backgroundColor: 'transparent',
        borderColor: 'var(--dynamic-color-secondary)',
        color: 'var(--dynamic-color-secondary)',
        invertedColor: 'var(--dynamic-color-primary)',
    },
    transparentDark: {
        backgroundColor: 'transparent',
        borderColor: 'var(--dynamic-color-primary)',
        color: 'var(--dynamic-color-primary)',
        invertedColor: 'var(--dynamic-color-secondary)',
    },
    solidDark: {
        backgroundColor: 'var(--dynamic-color-brand-secondary-light)',
        borderColor: 'var(--dynamic-color-primary)',
        color: 'var(--dynamic-color-primary)',
        invertedColor: 'var(--dynamic-color-primary)',
    },
};
