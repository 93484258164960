import Close from 'assets/icons/Close';
import Empty from 'Header/Default/Basket/Empty';
import Heading from 'components/text/Heading';
import React from 'react';
import WithProducts from 'Header/Default/Basket/WithProducts';
import styled from 'libs/styled';
import { transformBasketProductData } from 'utils/dataTransformers/product';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const TopSection = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

const CloseButton = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--static-background-color-secondary);
    color: var(--dynamic-color-secondary);
`;

const Basket = () => {
    const { t } = useTranslation();
    const overlay = useSelector(state => state.overlay);
    const basketItems = useSelector(state => state.basket.items || []);
    const totalNumberOfProducts = useSelector(state => state.basket.totalNumberOfProducts);
    const label = useSelector(state => state.header.data.basket?.label || '');
    const hasProducts = basketItems?.length > 0;
    const basketLabel = label || t('checkout_basket.basket');
    const closeBasket = () => {
        overlay.hide();
    };

    return (
        <Wrapper padding={['16px', null, '24px 16px', null, '24px']}>
            <TopSection>
                <Heading as="h2" fontKeys={['Recife Display/20', null, 'Recife Display/32_120']}>
                    {`${basketLabel} (${totalNumberOfProducts})`}
                </Heading>
                <CloseButton type="button" onClick={() => closeBasket()}>
                    <Close color="currentColor" width="12px" />
                </CloseButton>
            </TopSection>
            {hasProducts ? (
                <WithProducts closeBasket={closeBasket} products={basketItems.map(transformBasketProductData)} />
            ) : (
                <Empty closeBasket={closeBasket} />
            )}
        </Wrapper>
    );
};

export default Basket;
