import CloseIcon from 'assets/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { getSortedFilters } from 'utils/dataTransformers/filters';
import styled from 'libs/styled';

const StyledHiglightedFilter = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    flex: 0 0 auto;
    padding: 4px 12px;
    border: 1px solid var(--dynamic-color-primary);
    border-radius: 24px;
    text-transform: uppercase;
`;

const Dot = styled('div')`
    height: 10px;
    width: 10px;
    border: 1px solid var(--dynamic-color-primary);
    border-radius: 100%;
`;

const HiglightedFilter = ({
    filters = [],
    parentFilterKey = '',
    sortBy = 'alphabetically',
    handleChange = () => {},
}) => {
    const sortedFilters = getSortedFilters(filters, sortBy);

    return sortedFilters.map(item => {
        const filterStyles = item.selected
            ? {
                  backgroundColor: 'var(--dynamic-background-color-secondary)',
                  color: 'var(--dynamic-color-secondary)',
              }
            : {
                  backgroundColor: 'var(--dynamic-background-color-primary)',
                  color: 'var(--dynamic-color-primary)',
              };

        return (
            <StyledHiglightedFilter
                key={item.value}
                onClick={() => handleChange(parentFilterKey, item.value)}
                {...filterStyles}
            >
                {item.selected ? <CloseIcon color="currentColor" /> : <Dot />}
                {item.label}
            </StyledHiglightedFilter>
        );
    });
};

HiglightedFilter.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool })).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
    sortBy: PropTypes.string,
};

export default HiglightedFilter;
