import AddToBasketButton from 'components/buttons/functionalButtons/AddToBasketButton';
import ArrowLink from 'components/text/ArrowLink';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import Price from 'components/products/Price';
import PropTypes from 'prop-types';
import React from 'react';
import VariantSelector from '../VariantSelector';
import { above } from 'utils/mediaqueries';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useIsShowroom from 'hooks/useIsShowroom';
import { useTranslation } from 'react-i18next';
import useVariantSelector from 'hooks/useVariantSelector';
import { variantSelectorConfig } from 'config/variants';
import Tooltip from './Tooltip';

const StyledInformationSection = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;

    ${above.desktopSm} {
        width: calc(33.333% - 16px);
        margin-left: 32px;
        margin-bottom: 0;
    }
`;

const HeadingAndPrice = styled('div')`
    width: 100%;
    position: relative;
`;

const PriceWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;

    ${above.desktopSm} {
        height: auto;
    }
`;

const Usps = styled('div')`
    width: 100%;
    text-align: center;

    span:not(:first-of-type) {
        ::before {
            content: ' – ';
        }
    }
`;

const ButtonsWrapper = styled('div')`
    display: none;

    ${above.desktopSm} {
        display: flex;
        gap: 24px;
    }
`;

const InformationSection = ({
    basketProductData = {},
    comingSoon = false,
    inStock = false,
    name = '',
    price = '',
    usps = [],
    variantData = {},
    variationId = '',
    handleSeeMoreButtonClick = () => null,
}) => {
    const { t } = useTranslation();
    const { variants, setSelectedVariant, selectionState } = useVariantSelector(
        variantData,
        Object.keys(variantSelectorConfig)
    );

    const isShowroom = useIsShowroom();

    return (
        <StyledInformationSection>
            <HeadingAndPrice>
                <Heading
                    as="h1"
                    fontKeys={['Recife Display/24_120', null, 'Recife Display/32_120', null, 'Recife Display/56']}
                    margin={['0 0 16px 0', null, null, null, '0 0 24px']}
                >
                    {name}
                </Heading>
                {!isShowroom && (
                    <PriceWrapper marginBottom="40px">
                        <Price
                            fontKeys="NB International/18"
                            isOnSale={price.isOnSale}
                            priceWithCurrency={price.priceWithCurrency}
                            salePriceWithCurrency={price.salePriceWithCurrency}
                        />
                        {!!price.isOnSale && (
                            <Tooltip
                                content={`${t('product_page.hero.tooltip_description')} ${
                                    price.priceHistoryWithCurrency
                                }`}
                            />
                        )}
                    </PriceWrapper>
                )}
            </HeadingAndPrice>
            {Object.keys(variants).length > 0 && (
                <VariantSelector
                    handleMissingCombination={setSelectedVariant}
                    marginBottom={['40px', null, '48px']}
                    selectionIsNeeded={selectionState.selectionIsNeeded}
                    variants={variants}
                />
            )}
            {!isShowroom && (
                <AddToBasketButton
                    comingSoon={comingSoon}
                    currentProduct={basketProductData}
                    inStock={inStock}
                    marginBottom="22px"
                    selectionState={selectionState}
                    variationId={variationId}
                />
            )}
            {usps.length > 0 && (
                <Usps marginBottom={['0px', null, null, null, '48px']}>
                    {usps.map(usp => (
                        <Paragraph
                            as="span"
                            key={usp.text}
                            color="var(--static-color-light-five)"
                            fontKeys="NB International/12_120_4"
                            marginBottom="8px"
                        >
                            {usp.text}
                        </Paragraph>
                    ))}
                </Usps>
            )}
            <ButtonsWrapper {...styleObjectToProps('NB International/16_100')}>
                <ArrowLink onClick={() => handleSeeMoreButtonClick()}>{t('utils.read_more')}</ArrowLink>
            </ButtonsWrapper>
        </StyledInformationSection>
    );
};

InformationSection.propTypes = {
    basketProductData: PropTypes.object,
    comingSoon: PropTypes.bool,
    handleSeeMoreButtonClick: PropTypes.func,
    inStock: PropTypes.bool,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
        isOnSale: PropTypes.bool,
        priceWithCurrency: PropTypes.string.isRequired,
        salePriceWithCurrency: PropTypes.string,
    }),
    usps: PropTypes.array,
    variantData: PropTypes.object,
    variationId: PropTypes.string,
};

export default InformationSection;
