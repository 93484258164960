import Info from 'assets/icons/Info';
import Paragraph from 'components/text/Paragraph';
import Price from 'components/products/Price';
import PropTypes from 'prop-types';
import React from 'react';
import Vouchers from 'components/pages/checkout/CheckoutView/Summary/Vouchers';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;
const Wrapper = styled('div')`
    background-color: var(--dynamic-color-brand-secondary-light);
    padding: 16px;
    border-radius: 12px;

    ${above.tabletSm} {
        padding: 24px;
    }
`;

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px 0;
`;

const Row = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const Summary = ({ showInfoBox = true, ...rest }) => {
    const { t } = useTranslation();

    // Selectors
    const basketItems = useSelector(state => state.basket.items || []);
    const paymentMethodId = useSelector(state => state.basket.paymentMethodId);
    const { information, show_information: showInformation } = useSelector(
        state => state.page.data.checkout.summary || {}
    );
    const { hasDiscountOnProductsPrice, productsPriceBeforeDiscount, totalDiscountIncludingSale } = useSelector(
        state => state.basket.customPrices
    );
    const { final_price: finalPrice, products_sum: productsSum, shipping_price: shippingPrice } = useSelector(
        state => state.basket.totals || {}
    );

    // Variables
    const numberOfItems = basketItems.reduce((total, item) => total + item.quantity, 0);

    return (
        <Div {...rest}>
            <Wrapper>
                <Column my="8px">
                    {productsSum && (
                        <Row>
                            <Paragraph as="span" textTransform="lowercase" fontKeys="NB International/16_120">
                                {`${numberOfItems} ${numberOfItems === 1 ? t('utils.product') : t('utils.products')}`}
                            </Paragraph>
                            <Paragraph as="span" textAlign="right">
                                <Price
                                    flexDirection="row-reverse"
                                    fontKeys="NB International/16_120"
                                    isOnSale={hasDiscountOnProductsPrice}
                                    priceWithCurrency={productsPriceBeforeDiscount || productsSum}
                                    salePriceWithCurrency={productsSum}
                                />
                            </Paragraph>
                        </Row>
                    )}
                    {shippingPrice && paymentMethodId && (
                        <Row>
                            <Paragraph as="span" fontKeys="NB International/16_120">
                                {t('checkout_basket.shipping')}
                            </Paragraph>
                            <Paragraph as="span" fontKeys="NB International/16_120" textAlign="right">
                                {shippingPrice}
                            </Paragraph>
                        </Row>
                    )}
                    <Vouchers />
                </Column>
                <Column>
                    {totalDiscountIncludingSale && (
                        <Row color="var(--static-color-light)">
                            <Paragraph as="span" fontKeys="NB International/16_120">
                                {t('checkout_basket.total_discount')}
                            </Paragraph>
                            <Paragraph as="span" fontKeys="NB International/16_120" textAlign="right">
                                {totalDiscountIncludingSale}
                            </Paragraph>
                        </Row>
                    )}
                    <Row>
                        <Paragraph as="span" fontKeys="NB International/20">
                            {t('checkout_basket.to_pay')}
                        </Paragraph>
                        <Paragraph as="span" fontKeys="NB International/20" textAlign="right">
                            {finalPrice}
                        </Paragraph>
                    </Row>
                </Column>
            </Wrapper>
            {showInfoBox && showInformation && (
                <Div
                    alignItems="flex-start"
                    backgroundColor="var(--static-color-light-one)"
                    display="flex"
                    gap="16px"
                    marginTop="16px"
                    padding="16px"
                >
                    <Info minWidth="24px" />
                    <Wysiwyg
                        data={information}
                        textComponent={props => <WysiwygParagraph fontKeys="NB International/14_120" {...props} />}
                    />
                </Div>
            )}
        </Div>
    );
};

Summary.propTypes = {
    showInfoBox: PropTypes.bool,
};

export default Summary;
