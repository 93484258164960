/* eslint-disable camelcase */
import {
    NEWSLETTER_SUBSCRIBE,
    NEWSLETTER_SUBSCRIBE_ERROR,
    NEWSLETTER_SUBSCRIBE_SUCCESS,
    PRODUCTREMIND,
    PRODUCTREMIND_ERROR,
    PRODUCTREMIND_SUCCESS,
} from './constants';
import { ProductRemind, Subscribe } from 'libs/GrebbCommerceAPI/Newsletter';

//import getHrefLang from 'utils/getHrefLang';
import { getState } from 'state';

export const subscribe = (listId, data) => async dispatch => {
    // Add user country to the newsletterdata if it doesn't exist
    if (!data.country || !data.language) {
        const application = getState('application');
        const userCountry = application?.shop_config?.user_country;
        if (userCountry) {
            data.country = userCountry || 'SE';
        }

        // Transformer might be needed, depending on Centra-setup
        //const locale = getHrefLang(application?.locale);
        const locale = application?.locale;
        if (locale) {
            data.language = locale || 'sv_SE';
        }
    }

    // @todo: should we pass anything more back to the model from the
    // response from the subscribe to newsletter request?
    dispatch({ type: NEWSLETTER_SUBSCRIBE });

    try {
        const response = await Subscribe(listId, data);

        if (response.status >= 200 || response.status < 300) {
            dispatch({ type: NEWSLETTER_SUBSCRIBE_SUCCESS });
            return response;
        }
        throw response.error; // Should we really throw here?
    } catch (e) {
        dispatch({ type: NEWSLETTER_SUBSCRIBE_ERROR });
        throw e;
    }
};

export const productRemind = (listId, data) => async dispatch => {
    dispatch({ type: PRODUCTREMIND });

    try {
        const response = await ProductRemind(listId, data);
        if (response.status >= 200 || response.status < 300) {
            dispatch({ type: PRODUCTREMIND_SUCCESS });
            return response;
        }
        throw response.error; // Should we really throw here?
    } catch (e) {
        dispatch({ types: PRODUCTREMIND_ERROR });
        throw e;
    }
};
