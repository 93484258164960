import AddMultipleToBasketButton from 'components/buttons/functionalButtons/AddMultipleToBasketButton';
import Close from 'assets/icons/Close';
import Heading from 'components/text/Heading';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import contentSpacing from 'config/theme/contentSpacing';
import styled from 'libs/styled';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Wrapper = styled('div')`
    display: relative;
    width: 100%;
    height: 100%;
    background: var(--static-background-color-primary);
    color: var(--static-color-primary);
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none;
    }
`;

const CloseButton = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--static-background-color-secondary);
    color: var(--dynamic-color-secondary);
`;

const Looks = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px 0;
`;

const OverlayContent = ({ look = {} }) => {
    const { t } = useTranslation();
    const overlay = useSelector(state => state.overlay);
    const numberOfProducts = look.products.length;
    const showBuyAllButton = numberOfProducts > 1;
    const isShowroom = useIsShowroom();

    return (
        <Wrapper padding={contentSpacing}>
            <Div alignItems="center" display="flex" justifyContent="space-between" marginBottom="24px">
                <Heading as="h2" fontKeys={['Recife Display/20', null, 'Recife Display/32_120']}>
                    {t('utils.products')} ({numberOfProducts})
                </Heading>
                <CloseButton type="button" onClick={() => overlay.hide()}>
                    <Close color="currentColor" width="12px" />
                </CloseButton>
            </Div>
            <Looks>
                {look.products?.map(product => (
                    <ProductCard
                        quickshop
                        imageSizes={['100vw', '300px']}
                        key={`${product?.id}-${product?.sku}`}
                        srcWidths={[300, 400, 500]}
                        width="100%"
                        {...product}
                    />
                ))}
            </Looks>
            {!isShowroom && showBuyAllButton && <AddMultipleToBasketButton marginTop="24px" multipleIds={look.productIds} />}
        </Wrapper>
    );
};

OverlayContent.propTypes = {
    look: PropTypes.object,
};

export default OverlayContent;
