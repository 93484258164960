import * as Sentry from '@sentry/browser';

import React, { useEffect, useState } from 'react';
import { getState, injectModels } from 'state';

import Events from 'libs/Events';
import PropTypes from 'prop-types';
import appProps from 'utils/proptypes/application';
import basketProp from 'utils/proptypes/basket';
import { scrollTo } from 'utils/scrollFunctions';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/prop-types
const AdyenDropInContainer = ({ basket, application, render: Render, notice }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { t } = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const { address, paymentMethodId, getBasketPayment } = basket;

    const {
        path,
        locale,
        config: {
            options: {
                specified_pages: { checkout_success: checkoutSuccess, checkout_error: checkoutError },
            },
        },
    } = application;

    const baseUrl = `${window.location.protocol}//${window.location.host}${path}`;
    const successUrl = `${baseUrl}${checkoutSuccess.slug}`;
    const errorUrl = `${baseUrl}${checkoutError.slug}`;

    useEffect(() => {
        loadAdyen();
        return () => {
            // Remove the event listener to avoid memory leaks.
            document.removeEventListener('centra_checkout_payment_callback', centraCheckoutPaymentCallback);
        };
    }, []);

    useEffect(() => {
        // If Adyen is loaded and there is a change in basket items we reload adyen widget
        if (isLoaded && basket.paymentMethodId) {
            getPayment();
        }
    }, [JSON.stringify(basket.items)]);

    const getPayment = async () => {
        // Object that takes in extra parameters outside the standard ones
        const extras = {
            'payment_initiate_only': true,
        };

        const response = await getBasketPayment(paymentMethodId, successUrl, errorUrl, locale, address, extras);

        if (response) {
            const { data, warning, error } = response;
            if (data?.formHtml) {
                // Secure that adyen-checkout container do exist
                const adyenContainer = document.getElementById('dropin-container');

                adyenContainer.innerHTML = '';

                adyenContainer.innerHTML = response.data.formHtml;

                evaluate(adyenContainer);

                Events.trigger('payment.loaded');

                return true;
            }

            error && console.error('error: ', response);
            warning && console.error('warning: ', response);
        }

        return false;
    };

    const loadAdyen = async () => {
        document.addEventListener('centra_checkout_payment_callback', centraCheckoutPaymentCallback);

        if (!isLoaded && basket.paymentMethodId) {
            const loaded = await getPayment();
            if (loaded !== isLoaded) {
                setIsLoaded(true);
            }
        } else {
            Sentry.captureEvent({
                message: 'AdyenDropInContainer - Could not load adyen',
                contexts: {
                    basket: {
                        id: basket.basketId,
                        paymentMethodId: basket.paymentMethodId,
                    },
                    isLoaded,
                },
                extra: {
                    application,
                    basket,
                },
            });
            notice.addNotice({
                type: 'error',
                title: t('checkout.payment_error.title'),
                message: t('checkout.payment_error.default'),
            });
        }
    };

    // Clear Adyen and load it once again
    const reloadAdyen = () => {
        setIsLoaded(false);
        const adyenContainer = document.getElementById('dropin-container');
        adyenContainer.innerHTML = '';
        loadAdyen();
    };

    // Scroll to top
    const scrollToTop = () => {
        // Will default to top if no data is provided
        scrollTo({ top: 0 });
    };

    // Function will be triggered by Centra when the user clicks on the widget's buy button
    const centraCheckoutPaymentCallback = async origdata => {
        if (origdata.detail) {
            const { paymentMethodId, address, getBasketPayment } = getState('basket');

            const data = { ...origdata.detail };

            // Ugly hack because they have name their shit wrong.
            // It should be address, not billingAddress.
            if (data.billingAddress) {
                data.address = { ...data.billingAddress };
                delete data.billingAddress;
            }

            // Object that takes in extra parameters outside the standard ones
            const extras = {
                'payment_method_specific_fields': data.paymentMethodSpecificFields,
            };

            const paymentResponse = await getBasketPayment(
                paymentMethodId,
                successUrl,
                errorUrl,
                locale,
                address,
                extras
            );

            const paymentResponseData = paymentResponse?.data;

            // Successful purchase
            if (paymentResponseData) {
                if (paymentResponseData.action === 'redirect') {
                    window.location.href = paymentResponseData.url;
                }

                if (paymentResponseData.action === 'success') {
                    // eslint-disable-next-line prefer-template
                    window.location.href = successUrl + '?status=success&basketId=' + basket.getBasketIdCookie();
                }

                if (paymentResponseData.action === 'javascript') {
                    const updateEvent = new CustomEvent('centra_checkout_payment_response', {
                        detail: paymentResponseData.formFields,
                    });
                    document.dispatchEvent(updateEvent);
                }
                // Failed purchase and error status 406
            } else if (paymentResponse.status === 406) {
                // Get message from error response
                const errorRespose =
                    (paymentResponse.error?.message && JSON.parse(paymentResponse.error.message)) || {};
                const error = errorRespose.errors || {};

                // Update local error state and add message based on message details
                // Feel free to handle more message details and display related messages
                switch (error.details) {
                    case 'CVC Declined':
                        // This error will always be connected to payment, therefore we should not scroll up
                        notice.addNotice({
                            type: 'error',
                            title: t('checkout.payment_error.title'),
                            message: t('checkout.payment_error.406_cvc'),
                        });
                        break;

                    case 'Internal error: Result from Open Invoice service does not contain a redirect URL':
                        // This error will most likley be connected to zip-code, therefore we should scroll up
                        scrollToTop();
                        notice.addNotice({
                            type: 'error',
                            title: t('checkout.payment_error.title'),
                            message: t('checkout.payment_error.406_invoice'),
                        });
                        break;

                    default:
                        // This error will most likley be connected to payment, therefore we should not scroll up
                        notice.addNotice({
                            type: 'error',
                            title: t('checkout.payment_error.title'),
                            message: t('checkout.payment_error.406'),
                        });
                        break;
                }

                // If error response contains { message: { paymentMethod: "failed" } } we need to
                // reload Adyen Drop In to make it work again.
                if (error.paymentMethod === 'failed') {
                    reloadAdyen();
                }
            } else {
                // We don't know what coused this error, therefore we should scroll up
                scrollToTop();
                notice.addNotice({
                    type: 'error',
                    title: t('checkout.payment_error.title'),
                    message: t('checkout.payment_error.default'),
                });
            }
        }
    };

    // Makes sure we dont evaluate type=application/json blobs from Centra.
    // And that the proper snippet will run after the DOM has changed.
    // The functions below comes from centra and this is how they want it
    const evaluate = subtree => {
        const getSnippetType = ({ type }) => type || '';

        const getRawSnippet = script => {
            return script.text || script.textContent || script.innerHTML || '';
        };

        if (subtree) {
            try {
                const scripts = subtree.getElementsByTagName('script');

                const scriptsAsArray = Array.from(scripts).filter(
                    script => getSnippetType(script) !== 'application/json'
                );

                scriptsAsArray.forEach(script => {
                    const unescaped = getRawSnippet(script).replace(/\\/gi, '');
                    // eslint-disable-next-line
                    eval.call(window, unescaped);
                });
            } catch (error) {
                console.error('Catched error in evaluate function:', error);
            }
        }
    };

    return <Render isLoaded />;
};

AdyenDropInContainer.propTypes = {
    application: appProps.isRequired,
    basket: basketProp.isRequired,
    notice: PropTypes.object.isRequired,
};

export default injectModels(['application', 'basket', 'notice'])(AdyenDropInContainer);
