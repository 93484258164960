import PropTypes from 'prop-types';
import React from 'react';
import Submenu from './Submenu';
import usePrevious from 'hooks/usePrevious';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const MenuDesktop = ({ closeMenu = () => {}, totalAnimationDuration }) => {
    const { primaryMenu, secondaryMenu } = useSelector(state => state.header.data.navigation);
    const activeMenu = useSelector(state => state.header.state.activeMenu);
    const prevActiveMenu = usePrevious(activeMenu);

    if (!primaryMenu || !secondaryMenu) {
        return null;
    }

    const newViewTransition = typeof prevActiveMenu !== 'string' || typeof activeMenu !== 'string';

    return (
        <>
            {primaryMenu.map(({ label = '', menuType = '', submenu = {} }) => {
                if (submenu && menuType !== 'submenu') {
                    return null;
                }

                return (
                    <Submenu
                        closeMenu={closeMenu}
                        featuredLinks={submenu.featuredLinks}
                        isActive={activeMenu === label}
                        key={label}
                        label={label}
                        linksType={submenu.linksType}
                        newViewTransition={newViewTransition}
                        primaryMenuHeading={submenu.primaryMenuHeading}
                        primaryMenuImages={submenu.primaryMenuImages}
                        primaryMenuLinks={submenu.primaryMenuLinks}
                        totalAnimationDuration={totalAnimationDuration}
                        zIndex={activeMenu === label ? zIndex.menuDesktop : 0}
                    />
                );
            })}
            {secondaryMenu.map(({ label = '', menuType = '', submenu = {} }) => {
                if (submenu && menuType !== 'submenu') {
                    return null;
                }

                return (
                    <Submenu
                        closeMenu={closeMenu}
                        featuredLinks={submenu.featuredLinks}
                        isActive={activeMenu === label}
                        key={label}
                        label={label}
                        linksType={submenu.linksType}
                        newViewTransition={newViewTransition}
                        primaryMenuHeading={submenu.primaryMenuHeading}
                        primaryMenuImages={submenu.primaryMenuImages}
                        primaryMenuLinks={submenu.primaryMenuLinks}
                        totalAnimationDuration={totalAnimationDuration}
                        zIndex={activeMenu === label ? zIndex.menuDesktop : 0}
                    />
                );
            })}
        </>
    );
};

MenuDesktop.propTypes = {
    closeMenu: PropTypes.func,
    totalAnimationDuration: PropTypes.number,
};

export default MenuDesktop;
