import CookieInformation from 'assets/icons/CookieInformation';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';

const Wrapper = styled('a')`
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    background-color: var(--static-background-color-secondary);
    z-index: 1;
    transform: scale(0.7);
    transition: all 0.3s ease;
    cursor: pointer;

    ${media.hover} {
        :hover {
            transform: scale(1);
        }
    }
`;

const CookieInformationButton = ({ ...rest }) => (
    // eslint-disable-next-line no-script-url
    <Wrapper alt="Renew Cookie Consent" href="javascript:CookieConsent.renew();" {...rest}>
        <CookieInformation color="var(--static-background-color-primary)" width="30px" />
    </Wrapper>
);

export default CookieInformationButton;
