import React, { useEffect, useState } from 'react';
import { getSortedFilters, getTransformedFilters } from 'utils/dataTransformers/filters';

import CloseIcon from 'assets/icons/Close';
import FilterAccordion from 'components/accordions/FilterAccordion';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import contentSpacing from 'config/theme/contentSpacing';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const FlexDiv = styled('div')`
    display: flex;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

const Filter = ({ filters = [], handleChange = {}, clear = () => null, hitsCount }) => {
    const overlay = useSelector(state => state.overlay);
    const { t } = useTranslation();
    const clientHeight = useClientHeight();
    const [filterIsSelected, setFilterIsSelected] = useState(false);

    // Transform filters
    const transformedFilters = getTransformedFilters(filters, t);

    // Sort filters by priority
    const sortedFilters = getSortedFilters(transformedFilters, 'priority');

    useEffect(() => {
        setFilterIsSelected(sortedFilters.some(filter => filter.totalSelected !== 0));
    }, [sortedFilters]);

    return (
        <FlexDiv
            postion="relative"
            flexDirection="column"
            overflowY="auto"
            height={clientHeight}
            px={contentSpacing}
            paddingTop="24px"
            paddingBottom="96px" // extra space in the bottom so content isn't hidden behind the bottom buttons (72px + 24px)
        >
            <FlexDiv alignItems="flex-start" justifyContent="space-between" marginBottom={['64px', null, '56px']}>
                <FlexDiv alignItems="flex-start">
                    <Heading as="h3" display="inline-block" fontKeys="Recife Display/32_120">
                        {t('filter_config.filter')}
                    </Heading>
                </FlexDiv>
                <button type="button" onClick={() => overlay.hide()}>
                    <CloseIcon width="14px" />
                </button>
            </FlexDiv>
            <FilterAccordion clearByKey={clear.byKey} filters={sortedFilters} handleChange={handleChange.filter} />
            {hitsCount < 1 && (
                <Paragraph as="span" fontKeys="NB International/12_120_7" marginTop="12px" textAlign="center">
                    {t('filter_config.no_results_with_these_filters')}
                </Paragraph>
            )}
            <FlexDiv
                background="var(--dynamic-color-brand-secondary-light)"
                bottom="0"
                gap="8px"
                justifyContent="center"
                left="0"
                padding="16px 8px"
                position="absolute"
                width="100%"
            >
                <ThemeButton flexGrow="0" disabled={!filterIsSelected} onClick={() => clear.filter()}>
                    <CloseIcon width="8px" marginRight="10px" color="currentColor" />
                    {t('filter_config.clear_filter')}
                </ThemeButton>
                <ThemeButton flexGrow="1" theme="solid" onClick={() => overlay.hide()}>
                    {t('filter_config.show_results')} {`(${hitsCount})`}
                </ThemeButton>
            </FlexDiv>
        </FlexDiv>
    );
};

Filter.propTypes = {
    clear: PropTypes.object,
    filters: PropTypes.array,
    handleChange: PropTypes.shape({
        filter: PropTypes.shape({
            value: PropTypes.func,
            range: PropTypes.func,
        }),
        sort: PropTypes.func,
    }),
    hitsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default Filter;
