import React from 'react';
import { openMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import { useDispatch } from 'react-redux';

const Button = styled('button')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--dynamic-background-color-secondary);
`;

const Lines = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4px 0;
`;

const Line = styled('span')`
    width: 14px;
    height: 1px;
    background-color: var(--dynamic-background-color-primary);
`;

const MenuButton = () => {
    const dispatch = useDispatch();

    return (
        <Button type="button" onClick={() => dispatch(openMenu())}>
            <Lines>
                <Line />
                <Line />
                <Line />
            </Lines>
        </Button>
    );
};

export default MenuButton;
