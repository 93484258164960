import { above, media } from 'utils/mediaqueries';
import { borderRadius, hoverRadius } from 'config/theme/borderRadius';

import Badges from 'components/Badges';
import Heading from './text/Heading';
import Image from 'components/Image';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Div = styled('div')``;

const ImageWrapper = styled('div')`
    height: auto;
    overflow: hidden;
    position: relative;
    transition: border-radius ${transitions.primary};

    ${above.tabletSm} {
        ${media.hover} {
            :hover {
                border-radius: ${hoverRadius};
            }
        }
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px 0;
`;

const ArticleCard = ({
    author = '',
    badges = [],
    contentStyles = {},
    image,
    imageStyles = {},
    ratio = ratios.vertical,
    title,
    to = '',
    ...rest
}) => (
    <Div {...rest}>
        <StyledLink to={to}>
            <ImageWrapper
                backgroundColor={colors.placeholder}
                borderRadius={borderRadius}
                aspectRatio={ratio}
                {...imageStyles}
            >
                {badges.length > 0 && (
                    <Badges
                        badges={badges}
                        flexDirection="column"
                        left={['8px', null, null, null, '16px']}
                        position="absolute"
                        top={['8px', null, null, null, '16px']}
                    />
                )}
                <Image
                    alt={title}
                    height="100%"
                    objectFit="cover"
                    sizes={['100vw', '33.33vw']}
                    src={{ url: image.url, width: [375, 768, 1024] }}
                />
            </ImageWrapper>
            {(author || title) && (
                <Div display="flex" flexDirection="column" gap="8px 0" width="100%" {...contentStyles}>
                    {author && (
                        <Paragraph
                            as="span"
                            fontKeys={['NB International/12_120_4', null, null, null, 'NB International/14_120']}
                        >
                            {author}
                        </Paragraph>
                    )}
                    {title && (
                        <Heading as="h3" fontKeys={['Recife Display/16', null, null, null, 'Recife Display/24_120']}>
                            {title}
                        </Heading>
                    )}
                </Div>
            )}
        </StyledLink>
    </Div>
);

ArticleCard.propTypes = {
    author: PropTypes.string,
    badges: PropTypes.array,
    contentStyles: PropTypes.object,
    image: PropTypes.object.isRequired,
    imageStyles: PropTypes.object,
    ratio: PropTypes.number,
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default ArticleCard;
