import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')`
    em {
        font-style: normal;
        text-decoration: underline;
    }
`;

const Categories = ({ categories = [], hideOverlay = () => {}, ...rest }) => {
    const { t } = useTranslation();
    const searchItemFontStyles = styleObjectToProps(['Recife Display/20', null, null, null, 'Recife Display/24_100']);

    return (
        <Div {...rest}>
            <Paragraph margin="24px 0 8px">{t('search.categories')}</Paragraph>
            <Div display="flex" flexWrap="wrap" gap={['8px 16px', null, null, null, null, '8px 24px']}>
                {categories.map(category => (
                    <Link key={category.uri} to={category.url} onClick={hideOverlay}>
                        <Div
                            dangerouslySetInnerHTML={{ __html: category.highlightResult?.value }}
                            {...searchItemFontStyles}
                        />
                    </Link>
                ))}
            </Div>
        </Div>
    );
};

Categories.propTypes = {
    categories: PropTypes.array,
    hideOverlay: PropTypes.func,
};

export default Categories;
