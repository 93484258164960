import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div', { shouldForwardProp: prop => ['error'].indexOf(prop) === -1 })`
    position: relative;
    --input-color: ${({ error }) => (error ? 'var(--dynamic-color-error)' : 'var(--dynamic-color-primary)')};
    --label-color: ${({ error }) => (error ? 'var(--dynamic-color-error)' : 'var(--dynamic-color-light-four)')};
`;

const Label = styled('label', { shouldForwardProp: prop => ['required'].indexOf(prop) === -1 })`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    transition: all ${transitions.secondary};
    user-select: none;

    ${({ required }) =>
        required &&
        `
    ::after {
        content: '*';
        display: block;
        position: absolute;
        top: 0;
        right: -7px;
    }
`}
`;

const StyledInput = styled('input', { shouldForwardProp: prop => !['hasInput'].includes(prop) })`
    width: 100%;
    padding-left: 12px;
    padding-bottom: 4px;
    text-decoration: none;
    border: 1px solid var(--dynamic-color-primary);
    border-radius: 14px;
    padding-top: 20px;
    background-color: transparent;
    -webkit-appearance: none;
    color: var(--input-color);

    :focus {
        outline: none;

        + label {
            top: 16px;
            font-size: 12px;
            color: var(--label-color);
            letter-spacing: 0.04em;

            ::after {
                display: none;
            }
        }
    }

    ${({ hasInput }) =>
        hasInput &&
        `
        & + label {
            top: 16px;            
            font-size: 12px;
            color: var(--label-color);
            letter-spacing: 0.04em;

            ::after {
                display: none;
            }
        }`}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        box-shadow: 0 0 0 30px var(--dynamic-background-color-primary) inset !important;
    }
`;

const Input = ({
    defaultValue,
    error,
    handleBlur = () => {},
    handleChange = () => {},
    label,
    name,
    required = false,
    type,
}) => {
    const [value, setValue] = useState(defaultValue);
    const fontStyles = styleObjectToProps('NB International/16_160');

    const change = e => {
        setValue(e.target.value);
        handleChange(e);
    };

    const blur = e => {
        if (!e.target.value) {
            setValue(undefined);
        }
        handleBlur(e);
    };

    return (
        <Wrapper error={error}>
            <StyledInput
                id={name}
                name={name}
                type={type}
                aria-label={label}
                required={required}
                hasInput={!!value}
                value={value}
                onChange={change}
                onBlur={blur}
                {...fontStyles}
            />
            <Label htmlFor={name} required={required} {...fontStyles}>
                {error?.message || label}
            </Label>
        </Wrapper>
    );
};

Input.propTypes = {
    color: PropTypes.string,
    defaultValue: PropTypes.string,
    error: PropTypes.exact({
        message: PropTypes.string,
        name: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

export default Input;
