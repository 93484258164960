import React, { forwardRef } from 'react';

import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import { above } from 'utils/mediaqueries';
import { borderRadius } from 'config/theme/borderRadius';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')``;

const DescriptionWrapper = styled('div')`
    margin-bottom: 54px;

    ${above.desktopSm} {
        width: 66.666%;
    }
`;

const AdditionalInformationWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 54px;

    ${above.desktopSm} {
        flex-direction: row;
        margin-top: 54px;
        gap: 16px;
    }
`;

const InformationWrapper = styled('div')`
    padding: 12px;
    flex: 1 1 50%;
    background: var(--dynamic-color-light-seven);
    overflow: hidden;
    color: var(--static-color-primary);

    ${above.desktopSm} {
        padding: 24px;
    }
`;

const CharacteristicWrapper = styled(InformationWrapper)`
    order: 1;

    ${above.desktopSm} {
        order: 2;
    }
`;

const Content = styled('div')`
    width: 100%;
`;

const Table = styled('table')`
    width: 100%;
    margin: 0;
    padding: 0;
`;

const TableRow = styled('tr')`
    display: flex;
    padding: 8px 0 16px;
    text-align: left;
    align-items: flex-start;

    :first-of-type {
        border-top: 1px solid var(--dynamic-color-primary);
    }

    :not(:last-of-type) {
        border-bottom: 1px solid var(--dynamic-color-primary);
    }
`;

const TableCol = styled('th')`
    width: 50%;
    padding: 0;
    display: flex;
    align-items: center;
`;

const SeriesWrapper = styled(InformationWrapper)`
    order: 2;

    ${above.desktopSm} {
        order: 1;
    }
`;

const ProductInformation = forwardRef(
    (
        {
            attributesLabel = '',
            characteristics = [],
            description = '',
            informationLabel = '',
            seriesDescription = '',
            seriesHeading = '',
            ...rest
        },
        ref
    ) => {
        const { t } = useTranslation();

        const tableColFontLabelStyles = styleObjectToProps([
            'NB International/12_120_7',
            null,
            null,
            null,
            'NB International/14_100_7',
        ]);
        const tableColValueFontStyles = styleObjectToProps([
            'NB International/14_120',
            null,
            null,
            null,
            'NB International/14_120',
        ]);

        const seriesDescriptionFontStyles = styleObjectToProps([
            'NB International/14_160',
            null,
            null,
            null,
            'NB International/16_160',
        ]);

        return (
            <Wrapper {...rest}>
                {description && (
                    <DescriptionWrapper ref={ref}>
                        <Heading fontKeys="NB International/14_100_7" marginBottom="16px">
                            {informationLabel}
                        </Heading>
                        <Paragraph fontKeys={['Recife Display/22_140', null, null, null, 'Recife Display/32_140']}>
                            {description}
                        </Paragraph>
                    </DescriptionWrapper>
                )}
                {(characteristics || seriesDescription) && (
                    <AdditionalInformationWrapper>
                        {characteristics.length > 0 && (
                            <CharacteristicWrapper borderRadius={borderRadius}>
                                <Heading
                                    fontKeys={['Recife Display/24_100', null, null, null, 'Recife Display/32_140']}
                                    marginBottom="16px"
                                >
                                    {attributesLabel}
                                </Heading>
                                <Content>
                                    <Table>
                                        <tbody>
                                            {characteristics.map(({ label, value }) => (
                                                <TableRow key={`${label}-${value}`}>
                                                    <TableCol {...tableColFontLabelStyles} textTransform="uppercase">
                                                        {label}
                                                    </TableCol>
                                                    <TableCol>
                                                        <Wysiwyg
                                                            data={value}
                                                            textComponent={props => (
                                                                <WysiwygParagraph
                                                                    {...tableColValueFontStyles}
                                                                    {...props}
                                                                />
                                                            )}
                                                            tagComponents={{ url: TextLinkWysisyg }}
                                                        />
                                                    </TableCol>
                                                </TableRow>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Content>
                            </CharacteristicWrapper>
                        )}
                        {seriesDescription && (
                            <SeriesWrapper borderRadius={borderRadius}>
                                <Heading
                                    fontKeys={['Recife Display/24_100', null, null, null, 'Recife Display/32_140']}
                                    marginBottom="16px"
                                >
                                    {seriesHeading || t('product_page.accordions.series')}
                                </Heading>
                                <Paragraph {...seriesDescriptionFontStyles}>{seriesDescription}</Paragraph>
                            </SeriesWrapper>
                        )}
                    </AdditionalInformationWrapper>
                )}
            </Wrapper>
        );
    }
);

ProductInformation.propTypes = {
    attributesLabel: PropTypes.string,
    characteristics: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        })
    ),
    description: PropTypes.string,
    informationLabel: PropTypes.string,
    seriesDescription: PropTypes.string,
    seriesHeading: PropTypes.string,
};

ProductInformation.defaultProps = {
    attributesLabel: '',
    characteristics: [],
    description: '',
    informationLabel: '',
    seriesDescription: '',
    seriesHeading: '',
};

export default ProductInformation;
