import React, { useRef, useState } from 'react';

import Cookies from 'js-cookie';
import Globe from 'assets/icons/Globe';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import getCurrencyByCountryCode from 'utils/getCurrencyByCountryCode';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import TextLinkUnderline from 'components/buttons/TextButtonUnderline';
import transitions from 'config/theme/transitions';
import zIndex from 'config/theme/z-index';

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 40px;

    ${above.tabletSm} {
        padding-right: 24px;
    }
`;

const SubMenu = styled('div', { shouldForwardProp: prop => ['isOpen', 'refHeight'].indexOf(prop) === -1 })`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: ${({ isOpen, refHeight }) => (isOpen ? `${refHeight}px` : '0px')};
    margin-bottom: 42px;
    background: ${colors.background};
    transition: height ${transitions.primary};
    overflow: hidden;
    z-index: ${zIndex.popup};
    border-radius: 4px;
`;

// Extra wrapper because safari cannot get height on nodes that have css height 0
const SubMenuWrapper = styled('div', { shouldForwardProp: prop => ['isOpen'].indexOf(prop) === -1 })`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

const SubMenuLink = styled(TextLinkUnderline, { shouldForwardProp: prop => ['active'].indexOf(prop) === -1 })`
    display: inline-block;
    color: ${colors.black};
    position: relative;
    padding: 8px 12px;

    // Adjustments for underline
    &::after {
        left: 12px;
        bottom: 6px;
        width: calc(100% - 24px);
    }
`;

const CurrentSite = styled('button')`
    display: flex;
    justify-content: center;
    gap: 8px;
`;

const SiteSelector = () => {
    const currentAliasId = useSelector(state => state.application.aliasId);
    const languages = useSelector(state => state.application.languages);
    const protocol = useSelector(state => state.application.protocol);
    const alternateHrefs = useSelector(state => state.page.data?.alternate_hrefs) || [];
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef();

    // Transform site data
    const sites = languages?.map(langObj => {
        const countryCode = langObj.countries?.length > 0 ? langObj.countries[0] : '';
        const currency = getCurrencyByCountryCode(countryCode);
        const aliasId = langObj.alias_id;
        const alternateHref = alternateHrefs.find(obj => obj.alias_id === aliasId) || {};
        
        // Use alternateHref if there is one, else add protocol to uri if it starts with //
        const uri = alternateHref.uri ? alternateHref.uri : langObj.uri.startsWith('//') ? `${protocol}:${langObj.uri}` : langObj.uri;
        const path = new URL(uri).pathname;
        return {
            ...langObj,
            currency,
            label: langObj.countries?.length === 1 ? langObj.countries[0] : 'GLOBAL',
            uri,
            path,
        };
    });

    // Transform current site to label
    const transformCurrentSiteLabel = ({ label, currency }) => (currency ? `${label}/${currency}` : label);

    // Current site data and label
    const currentSiteData = sites.find(site => site.alias_id === currentAliasId);
    const currentSiteLabel = transformCurrentSiteLabel(currentSiteData);

    // Sort sites
    const sitesOrder = ['SE', 'DK', 'FI', 'GLOBAL'];
    sites.sort((a, b) => sitesOrder.indexOf(a.label) - sitesOrder.indexOf(b.label));

    // Set selected site to cookie
    const selectApplication = (selectedPath, selectedAliasId) => {
        const cookieValue = JSON.stringify({ selectedPath, selectedAliasId });
        Cookies.set(process.env.REACT_APP_SITE_SELECTOR_POPUP_COOKIE, cookieValue, {
            expires: 7,
            selectedPath,
        });
    };

    return (
        <Wrapper>
            <SubMenu isOpen={isOpen} refHeight={ref.current?.getBoundingClientRect()?.height}>
                <SubMenuWrapper isOpen={isOpen} ref={ref}>
                    {sites.map(site => {
                        const active = site.alias_id === currentAliasId;
                        const siteText = transformCurrentSiteLabel(site);

                        return (
                            <SubMenuLink
                                active={active}
                                noUnderline={!active}
                                key={site.label}
                                onClick={() => selectApplication(site.path, site.alias_id)}
                                to={site.uri}
                            >
                                {siteText}
                            </SubMenuLink>
                        );
                    })}
                </SubMenuWrapper>
            </SubMenu>
            <CurrentSite type="button" onClick={() => setIsOpen(!isOpen)}>
                {currentSiteLabel}
                <Globe color={colors.white} />
            </CurrentSite>
        </Wrapper>
    );
};

export default SiteSelector;
