export const breakpoints = [
    '360px', // mobile
    '768px', // tabletSm
    '1024px', // tabletLg
    '1280px', // desktopSm
    '1366px', // desktopMd
    '1536px', // desktopLg
    '1920px', // desktopXl
    '2560px', // desktopXxl
];

export const labels = [
    'mobile',
    'tabletSm',
    'tabletLg',
    'desktopSm',
    'desktopMd',
    'desktopLg',
    'desktopXl',
    'desktopXxl',
];

export type BreakPointsType = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
};

// Prepared mediaqueries for common usecases with Image or BackgroundImage
export const mediaQueries = {
    fullWidth: [
        /* 0  <-  mobile */ { w: 360, q: 70 },
        /* tabletSm <-> tabletLg */ { w: 768, q: 70 },
        /* tabletLg <-> desktopSm */ { w: 1024, q: 70 },
        /* desktopSm <-> desktopMd */ { w: 1280, q: 70 },
        /* desktopMd <-> desktopLg */ { w: 1366, q: 70 },
        /* desktopLg ->   desktopXl  */ { w: 1536, q: 70 },
        /* desktopXl <-> desktopXxl */ { w: 1920, q: 70 },
        /* desktopXxl ->     */ { w: 2560, q: 70 },
    ],
    maxWidth: [
        /* 0  <-  mobile */ { w: 360, q: 70 },
        /* tabletSm <-> tabletLg */ { w: 768, q: 70 },
        /* tabletLg <-> desktopSm */ { w: 1024, q: 70 },
        /* desktopSm <-> desktopMd */ { w: 1280, q: 70 },
        /* desktopMd <-> desktopLg */ { w: 1366, q: 70 },
        /* desktopLg ->   desktopXl  */ { w: 1536, q: 70 },
        /* desktopXl <-> desktopXxl */ { w: 1920, q: 70 },
        /* desktopXxl ->     */ { w: 2560, q: 70 },
    ],
    twoColumn: [
        /* 0  <-  mobile */ { w: 360, q: 70 },
        /* tabletSm <-> tabletLg */ { w: 768 / 2, q: 70 },
        /* tabletLg <-> desktopSm */ { w: 1024 / 2, q: 70 },
        /* desktopSm <-> desktopMd */ { w: 1280 / 2, q: 70 },
        /* desktopMd <-> desktopLg */ { w: 1366 / 2, q: 70 },
        /* desktopLg ->   desktopXl  */ { w: 1536 / 2, q: 70 },
        /* desktopXl <-> desktopXxl */ { w: 1920 / 2, q: 70 },
        /* desktopXxl ->     */ { w: 2560 / 2, q: 70 },
    ],
    threeColumn: [
        /* 0  <-  mobile */ { w: 360, q: 70 },
        /* tabletSm <-> tabletLg */ { w: 768 / 3, q: 70 },
        /* tabletLg <-> desktopSm */ { w: 1024 / 3, q: 70 },
        /* desktopSm <-> desktopMd */ { w: 1280 / 3, q: 70 },
        /* desktopMd <-> desktopLg */ { w: 1366 / 3, q: 70 },
        /* desktopLg ->   desktopXl  */ { w: 1536 / 3, q: 70 },
        /* desktopXl <-> desktopXxl */ { w: 1920 / 3, q: 70 },
        /* desktopXxl ->     */ { w: 2560 / 3, q: 70 },
    ],
    fourColumn: [
        /* 0  <-  mobile */ { w: 360, q: 70 },
        /* tabletSm <-> tabletLg */ { w: 768 / 4, q: 70 },
        /* tabletLg <-> desktopSm */ { w: 1024 / 4, q: 70 },
        /* desktopSm <-> desktopMd */ { w: 1280 / 4, q: 70 },
        /* desktopMd <-> desktopLg */ { w: 1366 / 4, q: 70 },
        /* desktopLg ->   desktopXl  */ { w: 1536 / 4, q: 70 },
        /* desktopXl <-> desktopXxl */ { w: 1920 / 4, q: 70 },
        /* desktopXxl ->     */ { w: 2560 / 4, q: 70 },
    ],
};
