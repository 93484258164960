import React, { useEffect, useState } from 'react';

import AddressSummary from './AddressSummary';
import AdyenForm from 'components/pages/checkout/CheckoutView/Payment/paymentMethods/Adyen/AdyenForm';
import Heading from 'components/text/Heading';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const AdyenFormWrapper = ({
    headingFontKeys = '',
    isLoaded = false,
    setShowShippingAndPayment,
    showShippingAndPayment,
    tracking = {},
}) => {
    const { t } = useTranslation();
    const heading = useSelector(state => state.page.data.checkout.information?.heading || {});
    const [showForm, setShowForm] = useState(true);

    useEffect(() => {
        if (showShippingAndPayment) {
            setShowForm(false);
        }
    }, [showShippingAndPayment]);

    const editAddress = () => {
        setShowForm(true);
        setShowShippingAndPayment(false);
    };

    return (
        <Div position="relative">
            <Div alignItems="center" display="flex" justifyContent="space-between">
                <Heading fontKeys={headingFontKeys} marginTop="16px" to={heading.type} position="relative">
                    01. {heading.text}
                </Heading>
                {showShippingAndPayment && (
                    <TextLinkUnderline
                        as="button"
                        display={['none', null, null, null, 'inline']}
                        onClick={editAddress}
                        {...styleObjectToProps('NB International/12_120_7')}
                    >
                        {t('checkout_basket.edit_address')}
                    </TextLinkUnderline>
                )}
            </Div>
            <Div>
                <Div display={showForm ? 'none' : 'block'}>
                    <AddressSummary />
                </Div>
                <Div display={showForm ? 'block' : 'none'}>
                    <AdyenForm setShowShippingAndPayment={setShowShippingAndPayment} tracking={tracking} />
                </Div>
            </Div>
            <Loading hide={isLoaded} />
        </Div>
    );
};

AdyenFormWrapper.propTypes = {
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isLoaded: PropTypes.bool,
    setShowShippingAndPayment: PropTypes.func.isRequired,
    showShippingAndPayment: PropTypes.bool,
    tracking: PropTypes.shape({
        trigger: PropTypes.bool,
        setTrigger: PropTypes.func,
    }),
};

export default AdyenFormWrapper;
