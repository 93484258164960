import React, { useState } from 'react';

import FilterButton from './FilterButton';
import PropTypes from 'prop-types';
import SortOptions from './SortOptions';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const FilterButtons = ({ handleChange = {}, openFilterOverlay = () => {}, sorts = [] }) => {
    const { t } = useTranslation();
    const [showSort, setShowSort] = useState(false);

    const handleSortChange = value => {
        handleChange.sort(value);
        setShowSort(false);
    };

    return (
        <>
            <FilterButton textTransform="uppercase" handleClick={() => openFilterOverlay()}>
                {t('filter_config.filter')}
            </FilterButton>
            <FilterButton textTransform="uppercase" active={showSort} handleClick={() => setShowSort(!showSort)}>
                {t('filter_config.sort')}
            </FilterButton>
            <SortOptions show={showSort} sorts={sorts} handleChange={value => handleSortChange(value)} />
        </>
    );
};

FilterButtons.propTypes = {
    handleChange: PropTypes.object.isRequired,
    openFilterOverlay: PropTypes.func.isRequired,
    sorts: PropTypes.array.isRequired,
};

export default withRouter(FilterButtons);
