/**
 * Takes a backroundVideoObject from the cms and renames/restructures the data so that it is easier to work with in the front-end.
 * All front-end components should expect the backgroundVideoData to first pass through this function.
 * All background videos in the cms should be able to pass through this function.
 * @param {object} data - Background data straight from the cms.
 * @param {object} - Transformed backgroundCmsData data.
 */

// The front-end expects shallow objects.
export default data => (data ? { ...data.video, ...data.settings } : {});
