import React, { useEffect, useRef, useState } from 'react';

import Loading from './Loading';
import Paragraph from 'components/text/Paragraph';
import Plus from 'assets/icons/Plus';
import Voucher from 'components/pages/checkout/CheckoutView/Summary/Vouchers/Voucher';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Button = styled('button')`
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
`;

const VoucherForm = styled('form')`
    position: relative;
`;

const VoucherInput = styled('input')`
    width: 100%;
    border: 0;
    background: transparent;
    outline: none;
    color: var(--static-color-primary);

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        box-shadow: 0 0 0 30px var(--static-background-color-primary) inset !important;
    }

    ::-webkit-input-placeholder {
        color: var(--static-color-primary);
    }
    ::-moz-placeholder {
        color: var(--static-color-primary);
    }
    :-moz-placeholder {
        color: var(--static-color-primary);
    }
    :-ms-input-placeholder {
        color: var(--static-color-primary);
    }
`;

const VoucherButton = styled(Button)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

const Error = styled(Paragraph)`
    position: absolute;
    right: 0;
    top: 50%;
    margin: 0;
    color: var(--static-color-error);
    transform: translateY(-50%);
`;

const CheckoutVouchers = () => {
    const { t } = useTranslation();

    // Selectors
    const basket = useSelector(state => state.basket);
    const {
        default_value: inputText,
        error_message: inputErrorMessage,
        label: voucherText,
        placeholder: inputPlaceholder,
    } = useSelector(state => state.page.data.checkout.summary?.voucher);

    // Refs
    const voucherInput = useRef(null);
    const voucherWrapper = useRef(null);

    // States
    const [showInput, setShowInput] = useState(false);
    const [showLoader, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Variables
    const { addVoucher, totalDiscount, removeVoucher } = basket;

    // Styles
    const voucherTextStyles = styleObjectToProps('Recife Display/16');

    useEffect(() => {
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
         
    }, []);

    useEffect(() => {
        showInput && voucherInput.current.focus();
    }, [showInput]);

    useEffect(() => {
        if (error) {
            window.timer = setTimeout(() => {
                clearTimeout(window.timer);
                resetInput();
                setError(false);
            }, 3000);
        }
        return () => {
            if (error) {
                clearTimeout(window.timer);
            }
        };
    }, [error]);

    const resetInput = () => {
        voucherInput.current.value = '';
        return setShowInput(false);
    };

    const handleClick = e => {
        // Close input if clicked outside
        const wrapper = voucherWrapper.current;
        if (wrapper && e.target && !wrapper.contains(e.target)) {
            return resetInput();
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (voucherInput.current.value === '') {
            return resetInput();
        }

        if (!showLoader) {
            setLoading(true);
        }
        setError(false);

        addVoucher(voucherInput.current.value)
            .then(r => {
                switch (r.status) {
                    case 404:
                        return setError(inputErrorMessage);
                    case 201:
                        return resetInput();
                    default:
                        setError(t('Internal server error'));
                        return resetInput();
                }
            })
            .catch(() => {
                setError(t('Internal server error'));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Div padding="16px 0" ref={voucherWrapper}>
            {totalDiscount?.discounts?.length > 0 && (
                <Div display="flex" flexDirection="column">
                    {totalDiscount.discounts.map(discount => (
                        <Voucher
                            key={discount.id}
                            discountId={discount.type === 'automatic_discount' ? discount.name : discount.id}
                            removeVoucher={removeVoucher}
                        />
                    ))}
                </Div>
            )}
            <Button
                display={showInput ? 'none' : 'flex'}
                width="100%"
                justifyContent="space-between"
                type="button"
                onClick={() => setShowInput(!showInput)}
                {...voucherTextStyles}
            >
                {voucherText}
                <Plus height="16px" width="16px" />
            </Button>
            <VoucherForm display={showInput ? 'block' : 'none'} onSubmit={handleSubmit}>
                <VoucherInput
                    id="voucher"
                    ref={voucherInput}
                    type="text"
                    placeholder={inputPlaceholder}
                    {...voucherTextStyles}
                />
                <label htmlFor="voucher" style={{ display: 'none' }}>
                    {inputText}
                </label>
                {error ? (
                    <Error>{error}</Error>
                ) : (
                    <VoucherButton type="submit" disabled={showLoader} {...voucherTextStyles}>
                        {showLoader ? <Loading /> : t('checkout_basket.apply')}
                    </VoucherButton>
                )}
            </VoucherForm>
        </Div>
    );
};

export default CheckoutVouchers;
