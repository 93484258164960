import HeroContent from 'components/checkout/HeroContent';
import React from 'react';
import emptyAnimation from 'assets/animations/empty.json';
import { useSelector } from 'react-redux';
import transformButton from 'libs/wordpress/utils/transformButton';

const EmptyCheckout = () => {
    const { button, heading, paragraph } = useSelector(state => state.page.data.checkout.empty_checkout || {});
    const transformedButton = transformButton(button);
    return <HeroContent animation={emptyAnimation} button={transformedButton} heading={heading} text={paragraph} />;
};

export default EmptyCheckout;
