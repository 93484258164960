import { unitRules } from 'config/unitRules';

/**
 * Transforms numbers to desired amount of decimals to avoid floating point bugs(700 instead of 699.99999999999999)
 *
 * @param number - Number that needs formatting
 * @param decimals - Maximum amount of decimals
 * @returns - Formatted number, ex: formatNumber(11.499999999999, 1) -> 11.5
 */
const formatNumber = (number: number, decimals: number): number => {
    // + converts it back to a number, removing trailing zeroes
    return +number.toFixed(decimals);
};

/**
 * Transforms units and their values into rule specific formats.
 
 * @param value - Value of measurement. Ex: 10
 * @param unit - Unit of measurement. Ex: "cm"
 * @returns - * Ex: 100cl -> 1l.
 */
export const transformUnit = (value: number, unit: string) => {
    const rules = unitRules || {};
    const ruleExists = rules[unit];

    if (!ruleExists) {
        return `${value}${unit}`;
    }

    const { breakpoints, units, divisors, decimals } = rules[unit];

    const breakpoint = breakpoints.reduce((acc, breakpoint) => {
        if (value >= acc && value >= breakpoint) {
            acc = breakpoint;
        }

        return acc;
    }, 0);

    const breakpointExists = breakpoints.includes(breakpoint);

    if (breakpointExists && value >= breakpoint) {
        const index = breakpoints.indexOf(breakpoint);
        const unitAfterBreakpoint = units[index];
        const divisor = divisors[index];
        const maximumDecimals = decimals[index];

        return `${formatNumber(value / divisor, maximumDecimals)}${unitAfterBreakpoint}`;
    }

    return `${value}${unit}`;
};
