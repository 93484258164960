import React, { useEffect } from 'react';
/* eslint-disable camelcase */
import { Transition, TransitionGroup } from 'react-transition-group';
import { lockScroll, unlockScroll } from 'state/models/Site/actions';
import { useDispatch, useSelector } from 'react-redux';

import BasketOverlay from 'components/Overlay/overlayComponents/BasketOverlay';
import FilterOverlay from 'components/Overlay/overlayComponents/FilterOverlay';
import OverlayWrapper from 'components/Overlay/OverlayWrapper';
import PropTypes from 'prop-types';
import SearchOverlay from 'components/Overlay/overlayComponents/SearchOverlay';
import ShopTheLookOverlay from 'components/Overlay/overlayComponents/ShopTheLookOverlay';
import SiteSelectorOverlay from 'components/Overlay/overlayComponents/SiteSelectorOverlay';
import { injectModel } from 'state';
import overlayProps from 'utils/proptypes/overlay';

const availableOverlays = {
    'basketOverlay': BasketOverlay,
    'filterOverlay': FilterOverlay,
    'searchOverlay': SearchOverlay,
    'shopTheLookOverlay': ShopTheLookOverlay,
    'siteSelectorOverlay': SiteSelectorOverlay,
};

/**
 * Overlays are used to "lock" the page with content that appears above the page content.
 * Mostly the overlay will have a darker background that covers the rest of the page content.
 * There can only be one overlay active at a time.
 * Overlay data is stored in redux state overlay.
 */

const Overlay = ({ overlay, unmountDelay = 500 }) => {
    const dispatch = useDispatch();
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const { current: currentOverlay, data } = overlay;

    // Get the wrapper that should be used for the active overlay, defualts to OverlayWrapper
    const Wrapper = data.wrapper ? data.wrapper : OverlayWrapper;

    // Get the content component that should be used for the active overlay
    const Component =
        currentOverlay && availableOverlays.hasOwnProperty(currentOverlay) ? availableOverlays[currentOverlay] : null;

    // Lock scroll if any of these overlays are active.
    // Also lock scroll if menu is open.
    // The extra check for menu because the menu behaves like an overlay but is not an overley due to. it is custom made.
    const lockScrollOverlays =
        ['basketOverlay', 'filterOverlay', 'searchOverlay', 'siteSelectorOverlay', 'shopTheLookOverlay'].indexOf(
            currentOverlay
        ) !== -1 || menuIsOpen;

    useEffect(() => {
        if (lockScrollOverlays) {
            dispatch(lockScroll());
        } else {
            dispatch(unlockScroll());
        }

        return () => dispatch(unlockScroll());
    }, [currentOverlay, menuIsOpen]);

    return (
        // Renders the active overlay inside a TransitionGroup so that it animates on exit when it's unmounted and removed
        <TransitionGroup>
            {!!Component && (
                // Add a transition element to the overlay that handles entering and exit states
                <Transition
                    mountOnEnter
                    unmountOnExit
                    enter
                    key={currentOverlay}
                    in={!!Component}
                    timeout={{ appear: 0, exit: unmountDelay }}
                >
                    {transitionState => (
                        <Wrapper className={transitionState} overlayName={currentOverlay} {...(data || {})}>
                            <Component overlayName={currentOverlay} {...(data || {})} />
                        </Wrapper>
                    )}
                </Transition>
            )}
        </TransitionGroup>
    );
};

Overlay.propTypes = {
    overlay: overlayProps.isRequired,
    unmountDelay: PropTypes.number,
};

export default injectModel('overlay')(Overlay);
