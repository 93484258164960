import {
    AttributeType,
    AttributeValue,
    AttributesMap,
    Data,
    SortValue,
    State,
    TransformedSelectItem,
    Variant,
    VariantAttributeValues,
    VariantGroups,
    VariantsData,
} from './types';
import { useEffect, useState } from 'react';

import { variantSelectorConfig } from 'config/variants';

/* Local Values */
let variantAttributeTypes: AttributeType[];
let allVariants: Variant[];

/**
 * sortValue can be sorted alphabetically (A-Z) or numericaly (low to high)
 */
const sortVariants = (a: { sortValue: SortValue }, b: { sortValue: SortValue }) => {
    if (a.sortValue < b.sortValue) {
        return -1;
    }
    if (b.sortValue < a.sortValue) {
        return 1;
    }
    return 0;
};

/**
 * Transforms a product variant into a object ready to be used by the VariantSelector
 *
 * @param variant - Object of raw variant data, used to get relevant select item data.
 * @param attributeType - The types of the variant attributes, ex: sa_color, sa_size etc.
 * @param variantAttributesValues - The values of the variant attributes, ex: red, large etc.
 * @param selectionNeeded - Tells us if the preferred combination doesn't exist
 */
const transformSelectItem = (
    variant: Variant,
    attributeType: AttributeType,
    variantAttributesValues: VariantAttributeValues,
    selectionNeeded: boolean
): TransformedSelectItem => {
    const { image, inStock, to, variantAttributes, useOutline } = variant || {};

    const { displayText, internalName, priority, value } = variantAttributes[attributeType] || {};
    const priorityAsNumber = priority && parseInt(priority, 10);
    const id = internalName?.trim().toLowerCase() || value?.trim().toLowerCase();

    const title = variantAttributeTypes.reduce((prev, currentAttribute, index) => {
        const variantAttributeValueKey = variantSelectorConfig[currentAttribute]?.attributeValueKey;
        const variantAttributeValue = variant.variantAttributes[currentAttribute]?.[variantAttributeValueKey]
            ?.trim()
            .toLowerCase();

        if (index) {
            prev += ' - ';
        }

        if (variantAttributeValue) {
            prev += variantAttributeValue;
        }

        return prev;
    }, '');

    return {
        currentVariant: variantAttributesValues[attributeType] === id,
        id,
        imageSrc: image?.src,
        inStock,
        name: displayText || value,
        sortValue: priorityAsNumber || internalName?.trim().toLowerCase() || value?.trim().toLowerCase(),
        title,
        to: !selectionNeeded ? to : undefined,
        type: attributeType,
        useOutline,
    };
};

/**
 * Creates an attribute map that will be used to find what variants should be visible in the selector
 *
 * @param currentLevel - Tells us what level we are at in the recursion.
 * @param attributeValue - Value of the attribute, ex: red, large etc. Used as keys in the map.
 */
const createNestedAttributesMap = (currentLevel = 0, attributeValue?: AttributeValue): AttributesMap => {
    const attributesMap = {};
    const firstLevel = currentLevel === 0;
    const lastLevel = variantAttributeTypes.length === currentLevel + 1;
    const currentAttribute = variantAttributeTypes[currentLevel];
    attributeValue = attributeValue?.trim().toLowerCase();

    allVariants.forEach((variant: Variant) => {
        const variantAttributeValueKey = variantSelectorConfig[currentAttribute]?.attributeValueKey;
        const variantAttributeValue = variant.variantAttributes[currentAttribute]?.[variantAttributeValueKey]
            ?.trim()
            .toLowerCase();

        const previousValues = variantAttributeTypes.reduce((acc, key, i) => {
            if (currentLevel > i || (currentLevel === 0 && i === 0)) {
                const previousAttributeKey = variantSelectorConfig[key].attributeValueKey;
                const previousAttributeValue = variant.variantAttributes[key]?.[previousAttributeKey]
                    ?.trim()
                    .toLowerCase();

                return `${acc ? `${acc}|` : ''}${previousAttributeValue}`;
            }

            return acc;
        }, '');

        if (firstLevel || previousValues === attributeValue) {
            if (!lastLevel) {
                const nextLevelAttributeValue = `${attributeValue ? `${attributeValue}|` : ''}${variantAttributeValue}`;

                const nextLevelMap = createNestedAttributesMap(currentLevel + 1, nextLevelAttributeValue);

                attributesMap[`${variantAttributeValue}`] = nextLevelMap;
            } else {
                attributesMap[`${variantAttributeValue}`] = variant;
            }
        }
    });

    return attributesMap;
};

/**
 * Retrieves the variant from the nested attributes map
 * @param attributeValue - Value of the attribute, ex: red, large etc. Used as keys in the map.
 * @param nestedAttributesMap - An attribute map that will be used to find what variants should be visible in the selector. Returns the variant when on the last level.
 * @param variantAttributeValues - Values of the variant's attributes, ex: red, large etc.
 *  @param currentLevel - Tells us what level we are at in the recursion.
 */
const getVariantFromNestedAttributes = (
    attributeValue: AttributeValue,
    nestedAttributesMap: AttributesMap | Variant,
    variantAttributeValues: VariantAttributeValues,
    currentLevel = 0
): TransformedSelectItem => {
    let variant;
    let currentAttributesMap = nestedAttributesMap;
    let selectionNeeded = false;
    const attributeTypes: AttributeType[] = Object.keys(variantAttributeValues);
    const depth = attributeTypes.length;
    const lastLevel = currentLevel === depth - 1;
    attributeValue = attributeValue?.trim().toLowerCase();

    for (let i = currentLevel; i < depth; i++) {
        const firstStep = i === currentLevel;
        const lastStep = i === depth - 1;
        const attributeType = attributeTypes[i];

        // The last step should retrive the variant
        if (lastStep) {
            // We are at the last level, display the wanted variants
            if (lastLevel) {
                variant = transformSelectItem(
                    currentAttributesMap[attributeValue],
                    attributeTypes[currentLevel],
                    variantAttributeValues,
                    selectionNeeded
                );

                // We have dug to get here, display the prefered
            } else {
                const preferredVariantKey = variantAttributeValues[attributeType];

                if (currentAttributesMap[preferredVariantKey]) {
                    variant = transformSelectItem(
                        currentAttributesMap[preferredVariantKey],
                        attributeTypes[currentLevel],
                        variantAttributeValues,
                        selectionNeeded
                    );
                } else {
                    const fallbackVariantKey = Object.keys(currentAttributesMap)[0];
                    selectionNeeded = true;
                    variant = transformSelectItem(
                        currentAttributesMap[fallbackVariantKey],
                        attributeTypes[currentLevel],
                        variantAttributeValues,
                        selectionNeeded
                    );
                }
            }

            // The first step should always get the supplied attributeValue to dig deeper
        } else if (firstStep) {
            currentAttributesMap = currentAttributesMap[attributeValue];

            // All other steps should dig deeper based on the selectedValue
        } else {
            const preferredVariantKey = variantAttributeValues[attributeType];

            if (currentAttributesMap[preferredVariantKey]) {
                currentAttributesMap = currentAttributesMap[preferredVariantKey];
            } else {
                const fallbackVariantKey = Object.keys(currentAttributesMap)[0];
                selectionNeeded = true;
                currentAttributesMap = currentAttributesMap[fallbackVariantKey];
            }
        }
    }

    return variant;
};

/**
 * Creates an object with our current variant's related variants, divided up in attribute types
 * @param nestedAttributesMap - An attribute map that will be used to find what variants should be visible in the selector. Returns the variant when on the last level.
 * @param variantAttributeValues - Values of the variant's attributes, ex: red, large etc.
 * @param returnObject - The object to be return at the end of the recursion.
 * @param currentLevel - Tells us what level we are at in the recursion.
 */
const createVariantGroups = (
    nestedAttributesMap: AttributesMap | Variant,
    variantAttributeValues: VariantAttributeValues,
    returnObject: VariantGroups = {},
    currentLevel = 0
): VariantGroups => {
    const lastLevel = variantAttributeTypes.length - 1 === currentLevel;
    const attributeType = variantAttributeTypes[currentLevel];
    returnObject[attributeType] = [];

    if (nestedAttributesMap) {
        Object.keys(nestedAttributesMap).forEach(attributeKey => {
            returnObject[attributeType].push(
                getVariantFromNestedAttributes(attributeKey, nestedAttributesMap, variantAttributeValues, currentLevel)
            );
        });
    }

    if (lastLevel) {
        return returnObject;
    }

    const nextLevelAttributesMap = nestedAttributesMap[variantAttributeValues[attributeType]];
    return createVariantGroups(nextLevelAttributesMap, variantAttributeValues, returnObject, currentLevel + 1);
};

/**
 * Transforms and returns the variants to be displayed in the variant selector
 * @param variantAttributeValues - The values of the variant attributes, ex: red, large etc.
 */
const variantSelectionGroupTransformer = (variantAttributeValues: VariantAttributeValues): VariantGroups => {
    // Fallback for products that are configured wrong or don't have any different variations
    if (!Object.keys(variantAttributeValues).length) {
        return {};
    }

    // Create a attribute map that will be used to find what variants should be visible in the selector
    const nestedAttributesMap = createNestedAttributesMap();

    // Use the nestedAttributesMap to prepare a object used by the VariantSelector.
    const variantGroups = createVariantGroups(nestedAttributesMap, variantAttributeValues);

    // Sort each selection group
    variantAttributeTypes.forEach(key => {
        // Removes undefined values
        variantGroups[key] = variantGroups[key].filter(item => item.id !== undefined);

        variantGroups[key].sort(sortVariants);
    });

    return variantGroups;
};

/**
 * @param data - Contains the current variant and all the related variants
 * @param attributeTypes - The types of the variant attributes, ex: sa_color, sa_size etc.
 */
const useVariantSelector = (data: Data, attributeTypes: AttributeType[]): VariantsData => {
    const { currentVariant, relatedVariants } = data;
    const variantAttributeValues = {};

    // Save these values in a wider scope so we donät need to pass them to every function
    allVariants = [currentVariant, ...relatedVariants];
    variantAttributeTypes = attributeTypes;

    // Adds the current variant's different attributes to variantAttributes
    variantAttributeTypes.forEach(attributeType => {
        const attributeValueKey = variantSelectorConfig[attributeType].attributeValueKey;
        const attributeValue = currentVariant.variantAttributes[attributeType]?.[attributeValueKey]
            ?.trim()
            .toLowerCase();

        variantAttributeValues[attributeType] = attributeValue;
    });

    const [state, setState] = useState<State>({
        id: currentVariant.id,
        selectionState: {
            selectionIsNeeded: false,
            selectionIsNeededFor: [],
        },
        variantAttributes: variantAttributeValues,
        variants: variantSelectionGroupTransformer(variantAttributeValues),
    });

    // Updates state on route change
    useEffect(() => {
        const hasSwitchedVariant = state.id !== currentVariant.id;

        if (hasSwitchedVariant) {
            setState({
                id: currentVariant.id,
                variantAttributes: variantAttributeValues,
                selectionState: {
                    selectionIsNeeded: false,
                    selectionIsNeededFor: [],
                },
                variants: variantSelectionGroupTransformer(variantAttributeValues),
            });
        }
    }, [currentVariant, currentVariant.id, state.id, variantAttributeValues]);

    /**
     * Sets the variant state after the selected variant. Use when the selected variant does not have a link.
     * A variant does not have a link if the variant does not exist in the same variant combination as the
     * currently selected variant.
     *
     * Example: The current variant is in size large but the selected variant does not exist in large.
     */
    const setSelectedVariant = (selectedVariant: TransformedSelectItem) => {
        const selectionIsNeededFor: string[] = [];
        const attributeTypeIndex = variantAttributeTypes.indexOf(selectedVariant.type);
        const selectedVariantAttributes = {};

        variantAttributeTypes.forEach((attributeType, index) => {
            let attributeValue;
            const previouslySelectedType = attributeTypeIndex > index;
            const currentlySelectedType = attributeTypeIndex === index;

            if (previouslySelectedType) {
                attributeValue = state.variantAttributes[attributeType]?.trim().toLowerCase();
            } else if (currentlySelectedType) {
                attributeValue = selectedVariant.id?.trim().toLowerCase();
            } else {
                // Saves the attribute key so that we can display the correct message
                selectionIsNeededFor.push(attributeType);
            }

            selectedVariantAttributes[attributeType] = attributeValue;
        });
        const selectionIsNeeded = Object.values(selectedVariantAttributes).includes(undefined);

        setState(prev => ({
            ...prev,
            selectionState: {
                selectionIsNeeded,
                selectionIsNeededFor,
            },
            variantAttributes: selectedVariantAttributes,
            variants: variantSelectionGroupTransformer(selectedVariantAttributes),
        }));
    };

    return {
        variants: state.variants,
        setSelectedVariant,
        selectionState: state.selectionState,
    };
};

export default useVariantSelector;
