import React, { useEffect, useState } from 'react';

import Arrow from 'assets/icons/Arrow';
import Heading from 'components/text/Heading';
import ImageAndTextCard from 'components/cards/ImageAndTextCard';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import SubMenu from './Submenu';
import contentSpacing from 'config/theme/contentSpacing';
import { fadeAnimation } from 'Header/Default/Menu/menuStyledComponents';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div', {
    shouldForwardProp: prop => ['isActive', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: absolute;
    top: 0;
    left: ${({ isActive }) => (isActive ? '0%' : '100%')};
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: var(--dynamic-color-brand-secondary-light);
    color: var(--dynamic-color);

    // The following transform-style is a fix for safari z-index bug
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;

    // The delay is needed for the fadeIn- and fadeOut-animations to work properly
    transition: all 0ms linear ${({ totalAnimationDuration }) => totalAnimationDuration / 2}ms;
`;

const TopBar = styled('div', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    whitespace: nowrap;
    textoverflow: ellipsis;
    cursor: pointer;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

const ScrollContent = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */

    -ms-overflow-style: none; /* IE and Edge */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const MenuList = styled('ul')`
    display: flex;
    flex-direction: column;
    margin-left: 0;
    gap: 24px 0;
`;

const MenuItem = styled('li', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    width: 100%;

    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })};
`;

const MenuItemImageWrapper = styled('ul')`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px 8px;
    align-items: start;
`;

const AnimationWrapper = styled('div', {
    shouldForwardProp: prop => ['fadeIn', 'fadeOut', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    ${({ fadeIn, fadeOut, totalAnimationDuration }) =>
        fadeAnimation({ fadeIn, fadeOut, duration: totalAnimationDuration })}
`;

const RecursiveMenuMobileView = ({
    closeMenu = () => {},
    closeSubMenu = () => {},
    extraLinks = [],
    isActive = false,
    isTopLevel = false,
    label = '',
    linksType = 'links',
    menuImages = [],
    primaryMenuHeading = '',
    primarySubMenu = [],
    secondarySubMenu = [],
    totalAnimationDuration,
    ...rest
}) => {
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const topBarHeight = 53;
    const showImages = linksType === 'images';

    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const hasExtraLinks = extraLinks?.length > 0;
    const hasMenuImages = menuImages?.length > 0;
    const hasPrimaryMenuLinks = primarySubMenu?.length > 0;
    const hasSecondaryMenuLinks = secondarySubMenu?.length > 0;

    const menuItemTextStyles = { ...styleObjectToProps(isTopLevel ? 'Recife Display/20' : 'NB International/16_120') };

    const fadeOut = !isActive || typeof activeSubMenu === 'number';
    const fadeIn = !fadeOut && isActive;

    // Reset the menu to level one if menu is closed
    useEffect(() => {
        let resetTimeout;

        if (typeof activeSubMenu === 'number' && !menuIsOpen) {
            // The delay will delay the reset until the menu is fully closed
            resetTimeout = setTimeout(() => {
                setActiveSubMenu(null);
            }, totalAnimationDuration / 2);
        }

        return () => {
            clearTimeout(resetTimeout);
        };
    }, [activeSubMenu, menuIsOpen, totalAnimationDuration, label]);

    return (
        <Wrapper isActive={isActive} totalAnimationDuration={totalAnimationDuration} {...rest}>
            {!isTopLevel && (
                <TopBar
                    fadeIn={fadeIn}
                    fadeOut={fadeOut}
                    height={`${topBarHeight}px`}
                    px={contentSpacing}
                    totalAnimationDuration={totalAnimationDuration}
                    onClick={() => closeSubMenu(null)}
                    {...styleObjectToProps('NB International/14_120')}
                >
                    <Arrow
                        color="currentColor"
                        height="16px"
                        marginRight="12px"
                        transform="rotate(180deg)"
                        width="16px"
                    />
                    {label}
                </TopBar>
            )}
            <ScrollContent justifyContent={hasMenuImages && showImages ? 'flex-start' : 'space-between'}>
                {hasPrimaryMenuLinks && !showImages && (
                    <MenuList
                        margin="0 0 24px 0"
                        paddingTop={isTopLevel ? `${topBarHeight}px` : '12px'}
                        px={contentSpacing}
                        width="100%"
                    >
                        <SubMenu
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            menuItemTextStyles={menuItemTextStyles}
                            totalAnimationDuration={totalAnimationDuration}
                            menu={primarySubMenu}
                            closeMenu={closeMenu}
                            setActiveSubMenu={setActiveSubMenu}
                            activeSubMenu={activeSubMenu}
                        />
                    </MenuList>
                )}
                {hasMenuImages && showImages && (
                    <>
                        {primaryMenuHeading && (
                            <AnimationWrapper
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                totalAnimationDuration={totalAnimationDuration}
                            >
                                <Heading as="span" display="block" fontKeys="Recife Display/20" margin="24px 16px">
                                    {primaryMenuHeading}
                                </Heading>
                            </AnimationWrapper>
                        )}
                        <MenuItemImageWrapper px={contentSpacing}>
                            {menuImages?.map(({ image, label, link }) => (
                                <AnimationWrapper
                                    as="li"
                                    fadeIn={fadeIn}
                                    fadeOut={fadeOut}
                                    key={label + link.to}
                                    totalAnimationDuration={totalAnimationDuration}
                                >
                                    <ImageAndTextCard
                                        handleClick={closeMenu}
                                        imageBackgroundPosition={image.backgroundPosition}
                                        imageBackgroundSize={image.backgroundSize}
                                        imageUrl={image.url}
                                        label={label}
                                        to={link.to}
                                    />
                                </AnimationWrapper>
                            ))}
                        </MenuItemImageWrapper>
                    </>
                )}
                {hasSecondaryMenuLinks && !showImages && (
                    <MenuList margin="0 0 24px 0" px={contentSpacing} width="100%">
                        <SubMenu
                            activeSubMenu={activeSubMenu}
                            closeMenu={closeMenu}
                            fadeIn={fadeIn}
                            fadeOut={fadeOut}
                            menu={secondarySubMenu}
                            menuItemTextStyles={menuItemTextStyles}
                            setActiveSubMenu={setActiveSubMenu}
                            totalAnimationDuration={totalAnimationDuration}
                        />
                    </MenuList>
                )}
                {hasExtraLinks && !showImages && (
                    <MenuList
                        flexGrow="1"
                        justifyContent="flex-end"
                        margin="0 0 32px 0"
                        px={contentSpacing}
                        width="100%"
                    >
                        {extraLinks.map(({ label, to }) => (
                            <MenuItem
                                fadeIn={fadeIn}
                                fadeOut={fadeOut}
                                key={label + to}
                                totalAnimationDuration={totalAnimationDuration}
                                {...styleObjectToProps('NB International/14_120')}
                            >
                                <Link to={to} onClick={closeMenu}>
                                    {label}
                                </Link>
                            </MenuItem>
                        ))}
                    </MenuList>
                )}
            </ScrollContent>
        </Wrapper>
    );
};

RecursiveMenuMobileView.propTypes = {
    closeMenu: PropTypes.func,
    closeOverlayMenu: PropTypes.func,
    closeSubMenu: PropTypes.func,
    extraLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    isActive: PropTypes.bool,
    isTopLevel: PropTypes.bool,
    label: PropTypes.string,
    linksType: PropTypes.oneOf(['links', 'images']),
    menuImages: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                url: PropTypes.string,
            }),
            label: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    primaryMenuHeading: PropTypes.string,
    primarySubMenu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.object,
            mneuType: PropTypes.string,
            highlight: PropTypes.bool,
            submenu: PropTypes.object,
        })
    ),
    secondarySubMenu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.object,
            mneuType: PropTypes.string,
            submenu: PropTypes.object,
        })
    ),
    totalAnimationDuration: PropTypes.number,
};

export default RecursiveMenuMobileView;
