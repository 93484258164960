import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import TextLink from 'components/text/TextLink';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;
const Button = styled('button')``;

const Wrapper = styled('div')`
    overflow: hidden;
    transition: all ${transitions.primary};
`;

/**
 * If needed, adds a max-height and a toggle button for the content.
 * @param {node} children - Child components
 * @param {number} fallbackContentHeight - Fallback height of children. This height will rarley be used thanks to setContentHeight.
 * @param {string} maxHeightMobile - Height(pixels) of the wrapper when content is hidden on smaller viewports.
 * @param {string} maxHeightDesktop - Height(pixels) of the wrapper when content is hidden on larger viewports.
 */

const ShowMoreWrapper = ({
    children,
    fallbackContentHeight = 1000,
    maxHeightMobile = 0,
    maxHeightDesktop = 0,
    ...rest
}) => {
    const { t } = useTranslation();
    let wrapperRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(fallbackContentHeight);
    const [showButton, setShowButton] = useState(false);
    const isDesktop = useAboveBreakpoint('desktopMd');

    const maxClosedHeight = isDesktop ? maxHeightDesktop : maxHeightMobile;

    const checkIfContentFits = () => {
        // Only trigger check if wrapperRef is set
        if (wrapperRef) {
            const { scrollHeight } = wrapperRef;

            // Check if the the wrapper has overflowed content and therfore needs to show the toggle button
            const hasOverflowedContent = maxClosedHeight > 0 && scrollHeight > maxClosedHeight;

            // If the status of overflowed content has changed since last check, update state
            if (showButton !== hasOverflowedContent) {
                setShowButton(hasOverflowedContent);
            }
            if (scrollHeight) {
                setContentHeight(scrollHeight);
            }
        }
    };

    const handleWrapperLoaded = ref => {
        wrapperRef = ref;

        // Trigger checkIfContentFits when the content is fully loaded
        checkIfContentFits();
    };

    useEffect(() => {
        // Initial calculation
        checkIfContentFits();

        // Add a resize listerner that triggers checkIfContentFits on resize.
        window.addEventListener('resize', checkIfContentFits);
        return () => window.removeEventListener('resize', checkIfContentFits);
    }, [wrapperRef]);

    return (
        <Div {...rest}>
            <Wrapper
                maxHeight={isOpen || maxClosedHeight === 0 ? `${contentHeight}px` : `${maxClosedHeight}px`}
                ref={ref => handleWrapperLoaded(ref)}
            >
                {children}
            </Wrapper>
            {/* Only show the toggle button if content dosen't fit the wrapper */}
            {showButton && (
                <Button marginTop="4px" type="button" onClick={() => setIsOpen(!isOpen)}>
                    <TextLink>{isOpen ? t('utils.read_less') : t('utils.read_more')}</TextLink>
                </Button>
            )}
        </Div>
    );
};

ShowMoreWrapper.propTypes = {
    children: PropTypes.node,
    fallbackContentHeight: PropTypes.string,
    maxHeightDesktop: PropTypes.number,
    maxHeightMobile: PropTypes.number,
};

export default ShowMoreWrapper;
