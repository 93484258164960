/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import { createPaginationPages, createPaginationUrl } from 'components/Pagination';

import AlgoliaContainer from 'containers/AlgoliaContainer';
import PropTypes from 'prop-types';
import { extractQueryParams } from 'utils/query';
import { inServer } from 'config/constants';
import { injectModels } from 'state';
import { transformAlgoliaProductResponse } from 'utils/dataTransformers/product';
import { withRouter } from 'react-router-dom';

class ProductsContainer extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired, //this is redux store application
        categories: PropTypes.array,
        collections: PropTypes.array,
        filters: PropTypes.shape({
            // this filter is set and never changed for one time.
            default: PropTypes.array,
            // This filter i the active filter that changes
            selected: PropTypes.array,
        }),
        history: PropTypes.object.isRequired,
        ids: PropTypes.array,
        index: PropTypes.string,
        list: PropTypes.string,
        location: PropTypes.object.isRequired,
        page: PropTypes.number,
        pageSize: PropTypes.number,
        permalink: PropTypes.string,
        products: PropTypes.object.isRequired,
        render: PropTypes.func.isRequired,
        renderProps: PropTypes.object,
        responseCallback: PropTypes.func,
        search: PropTypes.string,
        suffix: PropTypes.string,
        t: PropTypes.func,
    };

    static defaultProps = {
        categories: [],
        collections: [],
        filters: {
            default: [],
            selected: [],
        },
        ids: [],
        index: 'products',
        list: '',
        page: 1,
        pageSize: 24,
        permalink: undefined,
        renderProps: {},
        responseCallback: null,
        search: '',
        suffix: '',
        t: () => '',
    };

    constructor(props) {
        super(props);
        const page = ProductsContainer.pageReset(props);

        this.state = {
            page,
        };
    }

    /**
     * @return array pagination
     */
    // eslint-disable-next-line react/sort-comp
    static pageReset = props => {
        const { location, page } = props;

        const query = extractQueryParams(location.search);
        let newPage = query.page ? query.page : page;

        if (newPage > 1) {
            newPage = 1;
        }

        return newPage;
    };

    static getDerivedStateFromProps(props, state) {
        const { location } = props;
        const query = inServer ? extractQueryParams(location.search) : extractQueryParams(window.location.search);

        if (parseInt(query.page, 10) !== state.page) {
            return {
                ...state,
                page: query.page ? parseInt(query.page, 10) : 1,
            };
        }

        return state;
    }

    shouldComponentUpdate(nextProps) {
        const { ids, application, filters, categories, collections, search, suffix, location, permalink } = this.props;
        const {
            application: nextApplication,
            categories: nextCategories,
            collections: nextCollections,
            filters: nextFilters,
            ids: nextIds,
            location: nextLoation,
            permalink: nextPermalink,
            search: nextSearch,
            suffix: nextSuffix,
        } = nextProps;

        if (
            JSON.stringify(application.shop_config) !== JSON.stringify(nextApplication.shop_config) ||
            application.locale !== nextApplication.locale
        ) {
            return true;
        }

        if (
            JSON.stringify(ids) !== JSON.stringify(nextIds) ||
            JSON.stringify(filters) !== JSON.stringify(nextFilters) ||
            JSON.stringify(categories) !== JSON.stringify(nextCategories) ||
            JSON.stringify(collections) !== JSON.stringify(nextCollections) ||
            JSON.stringify(suffix) !== JSON.stringify(nextSuffix) ||
            JSON.stringify(permalink) !== JSON.stringify(nextPermalink) ||
            search !== nextSearch
        ) {
            const page = ProductsContainer.pageReset(this.props);
            this.setState({
                page,
            });
            return true;
        }

        // Update component if we remain on the same page but the QueryParams (search) changes
        // Changes between different categories and filters will be handled by the previous if-statement
        if (location.pathname === nextLoation.pathname && location.search !== nextLoation.search) {
            return true;
        }

        return false;
    }

    getPagination = (currentIndex, pageCount) => {
        const { location } = this.props;
        const activeLocation = inServer ? location : window.location;
        return {
            pages: createPaginationPages(currentIndex + 1, pageCount, activeLocation),
            previousUrl: currentIndex !== 0 && createPaginationUrl(Math.max(1, currentIndex), activeLocation),
            nextUrl: currentIndex + 1 !== pageCount && createPaginationUrl(currentIndex + 2, activeLocation),
        };
    };

    parameters = (ids, categories, collections, filters, page, pageSize, application) => ({
        facets: ['*'],
        page,
        hitsPerPage: pageSize,
        filters: [
            [`_pricelists:${application.shop_config.pricelist_id}`],
            [`_marketplaces:${application.shop_config.market_id}`],
            ids.map(id => `id:"${id}"`),
            categories.map(c => `_categories_ids:"${c}"`),
            collections.map(c => `_collections:"${c}"`),

            // ONLY SHOW PRODUCTS IN STOCK BASED ON MARKET_ID
            //[`_in_stock:${application.shop_config.market_id}`],
            ...filters,
        ].filter(i => i.length !== 0),
    });

    getParameters = () => {
        const { ids, suffix, categories, collections, filters, search, application, pageSize, index } = this.props;
        const { page } = this.state;
        const parameters = [];

        const locale = application.locale;
        let indexName = `${index}_${locale}`;

        if (suffix) {
            indexName = `${indexName}_${suffix}`;
        }

        // todo: only get default once per changed filter default
        // default query
        parameters.push({
            indexName,
            query: search,
            params: this.parameters(ids, categories, collections, filters.default, page, pageSize, application),
        });

        // selected query
        if (search !== '' || filters.selected.length > 0) {
            const selected = filters.default.concat(filters.selected);
            parameters.push({
                indexName,
                query: search,
                params: this.parameters(ids, categories, collections, selected, page, pageSize, application),
            });
        }

        return parameters;
    };

    responseCallback = response => {
        if (!response) {
            return null;
        }

        const { responseCallback, pageSize, application, list, t } = this.props;
        const { page } = this.state;

        const defaultProducts = transformAlgoliaProductResponse(
            response[0] || {},
            application.shop_config.market_id,
            t
        );
        const products = transformAlgoliaProductResponse(response[1] || {}, application.shop_config.market_id, t);

        // take products and use defaultProducts as fallback
        // this is used when there is no props.filters.selected
        const getAlgoliaPropertyData = key =>
            products.hasOwnProperty(key)
                ? products[key]
                : defaultProducts.hasOwnProperty(key)
                ? defaultProducts[key]
                : [];

        const hitsPerPage = getAlgoliaPropertyData('hitsPerPage');
        const newPage = hitsPerPage > pageSize ? hitsPerPage / pageSize : page;

        const data = {
            filters: defaultProducts.facets,
            filterStats: defaultProducts.facets_stats,
            products: getAlgoliaPropertyData('hits'),
            productsCount: getAlgoliaPropertyData('hits').length,
            page: getAlgoliaPropertyData('page'),
            pageSize: Math.max(hitsPerPage, pageSize),
            pageCount: getAlgoliaPropertyData('nbPages'),
            hitsCount: getAlgoliaPropertyData('nbHits'),
            hasMore: getAlgoliaPropertyData('hasMore'),
            pagination: this.getPagination(getAlgoliaPropertyData('page'), getAlgoliaPropertyData('nbPages')),
            list,
            setUseResponse: this.setUseResponse,
        };

        this.setState({
            page: newPage,
        });

        return responseCallback ? responseCallback(data) : data;
    };

    render() {
        const { render, renderProps } = this.props;

        return (
            <AlgoliaContainer
                renderProps={renderProps}
                parameters={this.getParameters()}
                responseCallback={this.responseCallback}
                render={({ application, ...rest }) => render({ ...rest, renderProps })}
            />
        );
    }
}

export default withRouter(injectModels(['application', 'products'])(ProductsContainer));
