import { PageEvents, ProductEvents } from 'libs/Events/constants';
import React, { Component, Fragment } from 'react';

import Cookies from 'js-cookie';
import Events from 'libs/Events';
import Favicon from 'components/metadata/Favicon';
import PageMeta from 'components/metadata/PageMeta';
import { UpdateBasket } from 'libs/GrebbCommerceAPI/Basket';
import appProp from 'utils/proptypes/application';
import { extractQueryParams } from 'utils/query';
import historyProps from 'utils/proptypes/history';
import { inServer } from 'config/constants';
import { injectModels } from 'state';
import locationProps from 'utils/proptypes/location';
import pageProps from 'utils/proptypes/page';
import productProp from 'utils/proptypes/product';
import views from 'views';
import { withRouter } from 'react-router-dom';

class RouteResolver extends Component {
    static propTypes = {
        application: appProp.isRequired,
        history: historyProps.isRequired,
        location: locationProps.isRequired,
        page: pageProps.isRequired,
        product: productProp.isRequired,
    };

    componentDidMount() {
        const { application, page, product, location } = this.props;

        if (page && !page.data && !page.type) {
            this.resolve(application, page, product, location);
        } else if (!inServer) {
            Events.trigger(PageEvents.VIEW, { application, page });

            if (page.type === 'product' && page.data) {
                Events.trigger(ProductEvents.VIEW, {
                    product: {
                        name: page.data.name,
                        sku: page.data.sku,
                        price: page.data.price.price,
                        salePrice: page.data.price.sale_price,
                        categoies: page.data.categoies,
                    },
                });
            }
        }
        this.lastPageRender = null;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.application.shop_config.currency !== this.props.application.shop_config.currency) {
            this.resolve(nextProps.application, nextProps.page, nextProps.product);
        }

        if (this.props.page.isFetching && !nextProps.page.isFetching) {
            return true;
        }

        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.resolve(nextProps.application, nextProps.page, nextProps.product);
        }

        return false;
    }

    resolve = async (application, page) => {
        const currentPathname = application.getCurrentPathname();
        let pageUrl = currentPathname === application.path ? '/' : currentPathname.slice(application.path.length);

        // Always remove the last / if there is one when resolving.
        if (pageUrl.lastIndexOf('/') === pageUrl.length - 1) {
            pageUrl = pageUrl.slice(0, -1);
        }

        const result = await page.resolveSlug(pageUrl, {
            'market_id': (application.shop_config && application.shop_config.market_id) || null,
            'pricelist_id': (application.shop_config && application.shop_config.pricelist_id) || null,
        });

        if (result && result.data) {
            //await this.resolveCampaignSite();
            // await application.fetchCategories();
            // product.resolveProductDependencies(result.data);
        }

        // todo: move contentLoaded from application. It rerenders the whole site when active
        // application.setContentLoaded(true);
    };

    resolveCampaignSite = async () => {
        const { application, location } = this.props;
        const qs = extractQueryParams(location.search);
        const campaignCookie = Cookies.get('campaign_site');
        const basketId = Cookies.get('basket_id');
        const marketId = application.shop_config.market_id;
        basketId && marketId && (await UpdateBasket(basketId, marketId));

        if ((qs && qs.campaign) || campaignCookie) {
            const key = qs && qs.campaign ? qs.campaign : campaignCookie;
            const response = await application.resolveCampaignSite(key);

            if (response) {
                Cookies.set('campaign_site', key, {
                    expires: 30,
                });
            } else {
                Cookies.remove('campaign_site', { expires: 30 });
            }
            return response;
        }
    };

    render() {
        const {
            page,
            history,
            application: { path },
        } = this.props;
        const NotFound = views.not_found || null;

        // Default page = Not Found.
        let PageRenderer = NotFound;

        // If we are loading and have an old page to show.
        if (page.isFetching && this.lastPageRender) {
            PageRenderer = this.lastPageRender;
            // If we do not have a page.type and we are not loading, we should show NotFound.
        } else if (page.isFetching || page.isFetching === null) {
            PageRenderer = () => <div />;
        }

        if (page.isFetching === false && page.type) {
            if (!inServer && page.type === 'redirect' && page.data.to) {
                history.push(`${path || ''}${page.data.to}`);
                return null;
            }
            // Check if this page has a template set.
            if (page.template && views[page.type] && views[page.type].templates) {
                // Check if the template we're after is defined.
                if (views[page.type].templates[page.template]) {
                    PageRenderer = views[page.type].templates[page.template];
                    this.lastPageRender = PageRenderer;
                } else {
                    console.error(`The template ${page.template} for page type ${page.type} was not found.`);
                }
            } else {
                PageRenderer = page.type && views[page.type];
                this.lastPageRender = PageRenderer;
            }
        } else if (page.isFetching === false && !page.type) {
            // if last page was 404 set as last page
            this.lastPageRender = PageRenderer;
        }

        return (
            <Fragment>
                <Favicon />
                {page.type !== 'debug' && <PageMeta />}
                <PageRenderer data={page.data} relationships={page.relationships} />
            </Fragment>
        );
    }
}

export default withRouter(injectModels(['application', 'page', 'product'])(RouteResolver));
