interface Rules {
    [key: string]: {
        breakpoints: number[];
        divisors: number[];
        units: string[];
        decimals: number[];
    };
}

export const unitRules: Rules = {
    cl: {
        breakpoints: [100],
        divisors: [100],
        units: ['l'],
        decimals: [1],
    },
    g: {
        breakpoints: [1000],
        divisors: [1000],
        units: ['kg'],
        decimals: [2],
    },
    kg: {
        breakpoints: [0, 1],
        divisors: [0.001, 1],
        units: ['g', 'kg'],
        decimals: [0, 2],
    },
    mm: {
        breakpoints: [10],
        divisors: [10],
        units: ['cm'],
        decimals: [1],
    },
};
