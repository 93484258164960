/**
 * Use this util to get currency label based on country code.
 *
 */

const currencies = {
    'SE': 'SEK',
    'FI': 'EUR',
    'DK': 'DKK',
};

const getCurrencyByCountryCode = (countryCode: string | undefined) => {
    const foundCurrency = Object.entries(currencies).find(country => country[0] === countryCode);
    if( foundCurrency !== undefined && foundCurrency.length > 0 ) {
        return foundCurrency[1];
    }

    return '';
};

export default getCurrencyByCountryCode;