import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';

const H1 = ({ children }) => (
    <Heading as="h1" fontKeys={['Recife Display/40', null, 'Recife Display/48', null, 'Recife Display/56']}>
        {children}
    </Heading>
);

const H2 = ({ children }) => (
    <Heading as="h2" fontKeys={['Recife Display/32_120', null, 'Recife Display/40', null, 'Recife Display/48']}>
        {children}
    </Heading>
);

const H3 = ({ children }) => (
    <Heading as="h3" fontKeys={['Recife Display/24_120', null, 'Recife Display/32_120', null, 'Recife Display/40']}>
        {children}
    </Heading>
);

const H4 = ({ children }) => (
    <Heading as="h4" fontKeys={['Recife Display/22_120', null, 'Recife Display/24_120', null, 'Recife Display/32_120']}>
        {children}
    </Heading>
);

const H5 = ({ children }) => (
    <Heading as="h5" fontKeys={['Recife Display/20', null, 'Recife Display/22_120', null, 'Recife Display/24_120']}>
        {children}
    </Heading>
);

const H6 = ({ children }) => (
    <Heading as="h6" fontKeys={['Recife Display/18', null, 'Recife Display/20', null, 'Recife Display/22_120']}>
        {children}
    </Heading>
);

H1.propTypes = { children: PropTypes.node.isRequired };
H2.propTypes = { children: PropTypes.node.isRequired };
H3.propTypes = { children: PropTypes.node.isRequired };
H4.propTypes = { children: PropTypes.node.isRequired };
H5.propTypes = { children: PropTypes.node.isRequired };
H6.propTypes = { children: PropTypes.node.isRequired };

export { H1, H2, H3, H4, H5, H6 };
