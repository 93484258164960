/**
 * First draft of useSlider
 * @version 1.0
 *
 * @param {obj} sliderProps - Sets slide ref and activeIndex. Spread to slider
 * @param {number} activeIndex - Current index of slider
 * @param {func} slideNext - Slides to next index in list of slides
 * @param {func} slidePrev - Slides to previous index in list of slides
 * @param {func} slideTo - Takes index as argument. Slides to index passed as argument
 * @param {bool} isThumbnailActive - Checks if a Thumbnails corresponding slides index is the activeIndex
 *
 * @usage Import useSlider, and deconstruct the functions you need
 * @usage For example ike so: const [sliderProps, { slideTo, activeIndex }] = useSlider(null);
 * @usage Spread {...sliderProps} on your SliderComponent
 */

import { useCallback, useRef, useState } from 'react';

const useSlider = () => {
    const ref = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const setRef = useCallback(node => (ref.current = node), []);
    const sliderProps = { ref: setRef, onSlideChange: setActiveIndex };

    const isThumbnailActive = (index, slidesPerView, numberOfSlides) => {
        return (
            activeIndex === index ||
            (activeIndex === -1 && index === numberOfSlides - 1) ||
            (activeIndex === -2 && index === numberOfSlides - 2) ||
            (slidesPerView === 2 &&
                (activeIndex === index - 1 ||
                    (activeIndex === numberOfSlides - 1 && index === 0) ||
                    (activeIndex === -1 && index === numberOfSlides) ||
                    (activeIndex === -2 && index === numberOfSlides - 1)))
        );
    };

    return [
        sliderProps,
        {
            isThumbnailActive,
            slideTo: index => ref.current.slideTo(index),
            slideNext: () => ref.current.slideNext(),
            slidePrev: () => ref.current.slidePrev(),
            activeIndex,
        },
    ];
};

export default useSlider;
