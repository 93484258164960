import { Button, Link, NewsletterForm } from 'libs/wordpress/types';

import transformLink from 'libs/wordpress/utils/transformLink';
import transformNewsletterForm from 'libs/wordpress/utils/transformNewsletterForm';

interface IconItem {
    icon: string;
    name: string;
    to: string;
}

interface FooterData {
    company: {
        privacyButton?: Button;
    };
    heading: {
        text?: string;
        type?: string;
    };
    menu: {
        label?: string;
        links?: Link[];
    };
    newsletter: {
        text?: string;
        form?: NewsletterForm;
    };
    partners?: IconItem[];
    socialMedia: {
        label?: string;
        links?: IconItem[];
    };
}

/**
 * @param {object} raw - Object with any data.
 * @param {FooterData} - Transformed data.
 */

export const transformFooterData = (raw: Record<string, any>): FooterData | object => {
    try {
        return {
            company: {
                privacyButton: raw.company?.button,
            },
            heading: {
                text: raw.heading.heading.text,
                type: raw.heading.heading.type,
            },
            menu:
                raw.menu?.length > 0
                    ? raw.menu.map(menu => ({
                          label: menu.label,
                          links: menu.sub_menu ? menu.sub_menu.map(({ link }) => transformLink(link)) : [],
                      }))
                    : [],
            newsletter: {
                text: raw.newsletter.text,
                form: transformNewsletterForm(raw.newsletter.newsletter_form),
            },
            partners:
                raw.partners?.length > 0
                    ? raw.partners.map(partner => ({
                          icon: partner.icon?.url,
                          name: partner.name,
                          to: partner.url,
                      }))
                    : [],
            socialMedia: {
                label: raw.social_media.label,
                links:
                    raw.social_media?.links?.length > 0
                        ? raw.social_media.links.map(link => ({
                              icon: link.icon?.url,
                              name: link.name,
                              to: link.url,
                          }))
                        : [],
            },
        };
    } catch (e) {
        console.error(e);
        return {};
    }
};
