import Above from 'components/breakpoints/Above';
import ArrowIcon from 'assets/icons/Arrow';
import Below from 'components/breakpoints/Below';
import Image from 'components/Image';
import Paragraph from 'components/text/Paragraph';
import PlusIcon from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import React from 'react';
import TouchableSlider from 'components/base/sliders/TouchableSlider';
import { above } from 'utils/mediaqueries';
import { borderRadius } from 'config/theme/borderRadius';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useSlider from 'hooks/useSlider';

const Wrapper = styled('div')`
    position: relative;
`;

const Div = styled('div')``;

const ScrollWrapper = styled('div')`
    display: flex;
    overflow-x: auto;
    gap: 0 12px;

    /* Hide scrollbars */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const ImageOuterWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ImageInnerWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: var(--dynamic-placeholder-color);
    overflow: hidden;
`;

const ZoomButton = styled('button')`
    display: none;

    ${above.desktopSm} {
        display: flex;
        background: white;
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 1;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        height: 32px;
        width: 32px;
        background: var(--static-background-color-primary);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    }
`;

const NavigationButtons = styled('div')`
    display: none;

    ${above.desktopSm} {
        display: flex;
        align-items: center;
        position: absolute;
        right: 16px;
        bottom: 16px;
        border-radius: 64px;
        padding: 4px 16px;
        background: var(--static-background-color-primary);
    }
`;

const Button = styled('button')`
    transform: translateY(-2px); // Alignment fix
`;

const ProductGallery = ({ imageConfig = {}, images = [], setCurrentImage = () => null, ...rest }) => {
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const [sliderProps, { activeIndex, slidePrev, slideNext }] = useSlider(null);

    if (!images.length) {
        return null;
    }

    const numberOfImages = images.length;
    const addExtraImage = numberOfImages === 1;
    let slidesPerView = 1.1;

    if (isAboveTabletSm) {
        slidesPerView = numberOfImages === 2 ? 2 : 2.25;
    }

    if (isAboveDesktopSm) {
        slidesPerView = 2;
    }

    if (addExtraImage) {
        images.push(images[0]);
    }

    const { imageSizes, srcWidths, srcHeights } = imageConfig;

    return (
        <Wrapper cursor={numberOfImages > 2 ? 'move' : 'default'} {...rest}>
            <>
                <Below
                    breakpoint="desktopSm"
                    render={() => (
                        <ScrollWrapper>
                            {images.map((image, index) => {
                                const addFrame = addExtraImage && index === 1;

                                return (
                                    <ImageOuterWrapper
                                        aspectRatio={ratios.vertical}
                                        background={addFrame ? 'var(--dynamic-color-brand-secondary)' : null}
                                        borderRadius={borderRadius}
                                        flex={[
                                            '0 0 91.666%',
                                            null,
                                            addExtraImage ? '0 0 calc(50% - 6px)' : '0 0 41.666%',
                                            null,
                                            'auto',
                                        ]}
                                        key={`${image.src}${index}`}
                                    >
                                        {!addFrame && (
                                            <ZoomButton onClick={() => setCurrentImage(index)}>
                                                <PlusIcon width="16px" />
                                            </ZoomButton>
                                        )}
                                        <Div
                                            aspectRatio={ratios.vertical}
                                            width={addFrame ? '75%' : '100%'}
                                            height={addFrame ? '75%' : '100%'}
                                        >
                                            <ImageInnerWrapper borderRadius={borderRadius}>
                                                <Image
                                                    alt={image.alt || image.name}
                                                    draggable="false"
                                                    height="100%"
                                                    loading="none"
                                                    mixBlendMode="multiply"
                                                    objectFit="contain"
                                                    quality="90"
                                                    sizes={imageSizes}
                                                    src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                                    title={image.name}
                                                    userSelect="none" // Prevents ::selection from marking the image
                                                    width="100%"
                                                />
                                            </ImageInnerWrapper>
                                        </Div>
                                    </ImageOuterWrapper>
                                );
                            })}
                        </ScrollWrapper>
                    )}
                />
                <Above
                    breakpoint="desktopSm"
                    render={() => (
                        <>
                            <TouchableSlider slidesPerView={slidesPerView} spaceBetween="12px" {...sliderProps}>
                                {images.map((image, index) => {
                                    const addFrame = addExtraImage && index === 1;

                                    return (
                                        <ImageOuterWrapper
                                            aspectRatio={ratios.vertical}
                                            background={addFrame ? 'var(--dynamic-color-brand-secondary)' : null}
                                            borderRadius={borderRadius}
                                            flex={['0 0 91.666%', null, '0 0 41.666%', null, 'auto']}
                                            key={`${image.src}${index}`}
                                        >
                                            {!addFrame && (
                                                <ZoomButton onClick={() => setCurrentImage(index)}>
                                                    <PlusIcon width="16px" />
                                                </ZoomButton>
                                            )}
                                            <Div
                                                aspectRatio={ratios.vertical}
                                                width={addFrame ? '80%' : '100%'}
                                                height={addFrame ? '80%' : '100%'}
                                            >
                                                <ImageInnerWrapper borderRadius={borderRadius}>
                                                    <Image
                                                        alt={image.alt || image.name}
                                                        draggable="false"
                                                        height="100%"
                                                        loading="none"
                                                        mixBlendMode="multiply"
                                                        objectFit="contain"
                                                        quality="90"
                                                        sizes={imageSizes}
                                                        src={{ url: image.src, width: srcWidths, height: srcHeights }}
                                                        title={image.name}
                                                        userSelect="none" // Prevents ::selection from marking the image
                                                        width="100%"
                                                    />
                                                </ImageInnerWrapper>
                                            </Div>
                                        </ImageOuterWrapper>
                                    );
                                })}
                            </TouchableSlider>
                            {numberOfImages > 2 && (
                                <NavigationButtons>
                                    <Button cursor={activeIndex === 0 ? 'default' : 'pointer'} onClick={slidePrev}>
                                        <ArrowIcon
                                            color={
                                                activeIndex === 0
                                                    ? 'var(--static-color-light-four)'
                                                    : 'var(--static-color-primary)'
                                            }
                                            width="8px"
                                            transform="rotate(180deg)"
                                        />
                                    </Button>
                                    <Paragraph
                                        minWidth="48px"
                                        textAlign="center"
                                        {...styleObjectToProps('NB International/16_160')}
                                    >
                                        {activeIndex + 1}/{numberOfImages - 1}
                                    </Paragraph>
                                    <Button
                                        cursor={activeIndex + 1 === numberOfImages - 1 ? 'default' : 'pointer'}
                                        onClick={slideNext}
                                    >
                                        <ArrowIcon
                                            color={
                                                activeIndex + 1 === numberOfImages - 1
                                                    ? 'var(--static-color-light-four)'
                                                    : 'var(--static-color-primary)'
                                            }
                                            width="8px"
                                        />
                                    </Button>
                                </NavigationButtons>
                            )}
                        </>
                    )}
                />
            </>
        </Wrapper>
    );
};

ProductGallery.propTypes = {
    imageConfig: PropTypes.object,
    images: PropTypes.array,
    setCurrentImage: PropTypes.func,
};

ProductGallery.defaultProps = {
    imageConfig: {},
    images: [],
    setCurrentImage: () => null,
};

export default ProductGallery;