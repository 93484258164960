import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

import regExp from 'utils/regExp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useFormSubmit = ({ action, required = [], ignore = [] }) => {
    const { t } = useTranslation();
    const [error, setError] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [submittedForm, setSubmittedForm] = useState(false);

    const handleSubmit = event => {
        event.preventDefault();
        // @todo: FormData does not work in IE
        const formData = new FormData(event.target);
        const errors = [];
        const inputs = [...event.target];
        const phoneInput = inputs.filter(input => input.id === 'phoneNumber')[0];
        const customerLocale = phoneInput?.getAttribute('data-locale').toUpperCase();
        let data = {};

        // Extra validation for input fields that need more than html5 validation
        const validateInput = (name, value) => {
            // Check if reason is checked because html5 validation does not work on elements that are display none
            if ((name === 'reason' || name === 'state') && !selectedValue) {
                errors.push({
                    name,
                    message: t('utils.please_select_one'),
                });
            } else if (name === 'email') {
                !regExp.validation.correctEmail.test(value.toLowerCase()) &&
                    errors.push({
                        name,
                        message: t('utils.not_a_correct_email_please_check_it'),
                    });
            }

            if (name === 'phoneNumber' && !isValidPhoneNumber(value, customerLocale)) {
                errors.push({ name, message: t('utils.not_a_correct_number_please_check_it') });
            }
        };

        const formatPhoneNumber = (number, locale) => parsePhoneNumber(number, locale).formatInternational();

        // Loop through formData, for each name/data and add to the data object
        // @todo: for...of does not work in IE
        for (const [name, value] of formData) {
            validateInput(name, value);
            const ignoreData = name.indexOf(ignore) !== -1;

            data = !ignoreData ? { ...data, [name]: value } : { ...data };
        }

        // Double check if every required item exists in data, e.g. is a checkbox checked
        required.forEach(
            name =>
                Object.keys(data).indexOf(name) >= 0 ||
                errors.push({
                    name,
                    message: t('utils.you_have_to_check_this'),
                })
        );

        if (errors.length < 1) {
            setError([]);

            if (data.phoneNumber) {
                // Formats phone number before submitting it
                data.phoneNumber = formatPhoneNumber(data.phoneNumber, customerLocale);
            }

            typeof action === 'function' && action(data);

            // Hide form and show success message
            setSubmittedForm(true);
        } else {
            setError(errors);
        }
    };

    return {
        error,
        submittedForm,
        selectedValueArray: [selectedValue, setSelectedValue],
        handleSubmit,
    };
};

export default useFormSubmit;
