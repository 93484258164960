import Arrow from 'assets/icons/Arrow';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import CloseIcon from 'assets/icons/Close';
import Heading from 'components/text/Heading';
import Image from 'components/Image';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import contentSpacing from 'config/theme/contentSpacing';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;
const Button = styled('button')``;

const AddedToBasket = ({ closePopup = () => {}, openBasket = () => {}, product = {} }) => {
    const { t } = useTranslation();
    const { imageSrc, name, to } = product;
    const customBorderRadius = ['8px', null, '12px'];

    return (
        <Div
            borderRadius={customBorderRadius}
            display="flex"
            backgroundColor="var(--dynamic-background-color-primary)"
            color="var(--dynamic-color-primary)"
            padding={contentSpacing}
            boxShadow="0px 2px 8px var(--dynamic-opacity-color-dark-higher)"
        >
            <CloseIcon
                color="currentColor"
                cursor="pointer"
                height="15px"
                position="absolute"
                right="16px"
                top="16px"
                width="15px"
                onClick={() => closePopup()}
            />
            <Link to={to} onClick={() => closePopup()}>
                <AspectWrapper
                    backgroundColor="var(--static-placeholder-color)"
                    borderRadius={customBorderRadius}
                    marginRight={['12px', null, '24px']}
                    overflow="hidden"
                    ratio={ratios.vertical}
                    width={['100px', null, '123px']}
                >
                    <Image
                        alt={name}
                        mixBlendMode="multiply"
                        sizes={['100px', null, '123px']}
                        src={{ url: imageSrc, width: [100, 246, 369] }}
                        title={name}
                    />
                </AspectWrapper>
            </Link>
            <Div display="flex" flexDirection="column" justifyContent="space-between">
                <Heading fontKeys="Recife Display/20" marginRight="38px">
                    {product.name}
                </Heading>
                <Button
                    alignItems="center"
                    display="flex"
                    cursor="pointer"
                    gap="0 8px"
                    textTransform="uppercase"
                    type="button"
                    width="100%"
                    onClick={() => {
                        closePopup();
                        openBasket();
                    }}
                    {...styleObjectToProps('NB International/12_120_7')}
                >
                    {t('checkout_basket.added_to_basket')}
                    <Arrow color="currentColor" width="6px" />
                </Button>
            </Div>
        </Div>
    );
};

AddedToBasket.propTypes = {
    closePopup: PropTypes.func.isRequired,
    openBasket: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

export default AddedToBasket;