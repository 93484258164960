import React, { useEffect, useState } from 'react';

import { CheckoutEvents } from 'libs/Events/constants';
import Cookies from 'js-cookie';
import Events from 'libs/Events';
import HeroContent from 'components/checkout/HeroContent';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import appProp from 'utils/proptypes/application';
import basketProp from 'utils/proptypes/basket';
import { extractQueryParams } from 'utils/query';
import { inServer } from 'config/constants';
import { injectModels } from 'state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// @todo: Change theme color to Light Red/Black on this page
const ThankYou = ({ application, basket, newsletter }) => {
    const { t } = useTranslation();
    const { button, heading, text } = useSelector(state => state.page.data.checkout_response || {});

    // Setup the loading state
    const [loading, setLoading] = useState(true);

    // Not used right now
    // Setup the klarnaScript ref
    // const klarnaScript = useRef(null);

    // Setup the orderData state
    const [orderData, setOrderData] = useState(null);

    // Create a constant which holds the cookie name, should prolly be moved.
    const OLD_ORDER_COOKIE_NAME = 'old_order_id';

    useEffect(() => {
        const callback = async () => {
            // Acquire the query params (AND POST PARAMS ON THE SERVER)
            const queryParams = inServer ? basket.postData : extractQueryParams(window.location.search);

            // Get the oldBasketId if we have any
            const oldBasketId = Cookies.get(OLD_ORDER_COOKIE_NAME);
            // Get the current basketId
            const basketId = queryParams['basketId'] || basket.getBasketIdCookie() || oldBasketId;

            // Check if this is an old order
            const isOldOrder = (oldBasketId && oldBasketId === basketId) || false;

            // If it is an old order or adyen processed the order instantly, get the receipt otherwise make the payment callback to Centra
            const response =
                (queryParams['basketId'] && queryParams['status'] === 'success') || isOldOrder
                    ? await basket.getBasketReceipt(basketId)
                    : await basket.getBasketPaymentCallback(basket.postData);

            // Check if the request was successful
            if (response && (response.status === 200 || response.status === 201)) {
                // Get the response data
                const responseData = response.data;

                // Set the order data to use in the render function
                setOrderData(responseData);

                // If this is a new order (not old)
                if (!isOldOrder) {
                    // Trigger the transaction event
                    Events.trigger(CheckoutEvents.TRANSACTION, responseData);

                    // Check if we should signup the user for newsletters
                    if (localStorage.getItem('newsletter_signup') === 'true') {
                        // Subscribe them to the newsletter
                        newsletter.subscribe('default', { email: responseData.address.email });
                        // Remove the signup key so we don't sign them up multiple times
                        localStorage.removeItem('newsletter_signup');
                        localStorage.removeItem('newsletter_signup_list');
                    }

                    // Set the old basket id so we can use that for receipts if needed
                    Cookies.set(OLD_ORDER_COOKIE_NAME, basketId, {
                        expires: 1,
                        path: application.path === '' ? '/' : application.path,
                    });

                    // Remove the current basket
                    basket.removeBasket();
                }

                // Remove the loading
                setLoading(false);
            } else {
                // TODO: We should handle err responses better than this, for example let the customer know why it failed
                // If something went wrong, send the customer to the error page
                if (application.config?.options?.specified_pages?.checkout_error) {
                    window.location.href = `${window.location.protocol}//${window.location.host}${application.path}${application.config.options.specified_pages.checkout_error.slug}`;
                }
            }
        };

        // Run the "callback"
        callback();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <HeroContent
                    cssAnimation
                    animation=""
                    button={button}
                    flexGrow="1"
                    heading={heading}
                    headingFontKeys={['Recife Display/20', null, 'Recife Display/24_120']}
                    height="auto"
                    includeContentSpacing={false}
                    orderText={`${t('checkout.success.order_number')}: ${orderData.id}`}
                    text={text}
                />
            )}
        </>
    );
};

ThankYou.propTypes = {
    application: appProp.isRequired,
    basket: basketProp.isRequired,
    newsletter: PropTypes.shape({
        subscribe: PropTypes.func,
    }).isRequired,
};

export default injectModels(['application', 'basket', 'newsletter'])(ThankYou);
