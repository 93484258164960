import Paragraph from 'components/text/Paragraph';
import React from 'react';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div')`
    margin: 32px 0 24px;
    color: var(--dynamic-color-light-four);
`;

const AddressSummary = () => {
    const { address = {} } = useSelector(state => state.basket);
    const {
        shop_config: { countries },
    } = useSelector(state => state.application);

    const country = countries.find(c => c.id === address.country);
    const choosenState = address.state && country?.states?.filter(s => s.value === address.state)?.[0];

    return (
        <Wrapper>
            <Paragraph fontKeys="NB International/16_160">
                {address?.first_name} {address.last_name}
                <br />
                {address?.email}
                <br />
                {address?.phone}
                <br />
                {choosenState && `${choosenState.label}, `}
                {country?.name}
                <br />
                {address?.address1}
                <br />
                {address.address2 && (
                    <>
                        {address.address2} <br />
                    </>
                )}
                {address?.zip} {address?.city}
                <br />
            </Paragraph>
        </Wrapper>
    );
};

export default AddressSummary;
