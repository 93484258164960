export const APP_NODE_NAMESPACE = '___REACT_APP_NAMESPACE_GREBBAN__';
export const INITIAL_STATE_KEY = '__INITIAL_STATE__';
export const INITIAL_PROMISES_CONTEXT = '__INITIAL_PROMISES_CONTEXT__';
export const SERVER_RENDER_BREAKPOINT = '__SERVER_RENDER_BREAKPOINT__';
export const SITE_DATA_CACHE_EXPIRATION = 60 * 1000; // 1 minute
export const POST_DATA_CACHE_EXPIRATION = 60 * 1000; // 1 minute
export const BRAND_COUNT_CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour
export const RESOLVE_URL_DATA_CACHE_EXPIRATION = 60 * 1000; // 1 minute

export const IMAGE_PLACEHOLDER = 'https://via.placeholder.com/400';

// Boolean to tell if we're in production-environment or not.
export const inProduction = process.env.NODE_ENV === 'production';

// Boolean to tell if we should build for SSR
export const inServer = process.env.BUILD_TARGET === 'server';

export const isMobile = typeof window !== 'undefined' && window.orientation > -1;

export const isIOS = typeof window !== 'undefined' && window.CSS && CSS.supports('-webkit-overflow-scrolling: touch');
export const isSafari = typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default {
    INITIAL_STATE_KEY: '__INITIAL_STATE__',
};
