import Paragraph from 'components/text/Paragraph';
import PlusIcon from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Button = styled('button', { shouldForwardProp: prop => ['active'].indexOf(prop) === -1 })`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${media.hover} {
        &:hover {
            .plus-icon {
                transform: rotate(90deg);
            }
        }

        &:active {
            .plus-icon {
                transform: rotate(360deg);
            }
        }
    }

    ${({ active }) =>
        active &&
        `
        .plus-icon {
                transform: rotate(45deg);
            }
    `}
`;

const FilterButton = ({ active = false, children, handleClick = () => {}, type = 'button', ...rest }) => (
    <Button active={active} type={type} onClick={() => handleClick()} {...rest}>
        <Paragraph as="span" fontKeys={['NB International/14_100_7', null, null, null, 'NB International/12_120_7']}>
            {children}
        </Paragraph>
        <PlusIcon
            color="currentColor"
            marginTop="-2px"
            ml="4px"
            transition={`transform ${transitions.primary}`}
            width="10px"
        />
    </Button>
);

FilterButton.propTypes = {
    active: PropTypes.bool,
    handleClick: PropTypes.func,
    type: PropTypes.string,
};

export default FilterButton;
