import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import CheckoutBasket from 'components/pages/checkout/CheckoutView/CheckoutBasket';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Payment from 'components/pages/checkout/CheckoutView/Payment';
import PropTypes from 'prop-types';
import React from 'react';
import Summary from 'components/pages/checkout/CheckoutView/Summary';
import { injectModel } from 'state';
import styled from 'libs/styled';
import { transformBasketProductData } from 'utils/dataTransformers/product';
import { useSelector } from 'react-redux';

const Column = styled('section')``;
const InnerWrapper = styled('div')``;

const CheckoutComponent = ({ tracking = {} }) => {
    const basket = useSelector(state => state.basket);

    return (
        <MaxWidthWrapper includeContentSpacing>
            <InnerWrapper
                display="flex"
                flexDirection={['column', null, null, 'row']}
                gap={['0', null, null, '0 16px']}
                width="100%"
            >
                <Column borderTop="2px solid var(--static-color-primary)" width={['100%', null, null, '58.33333333%']}>
                    <CheckoutBasket items={basket.items?.map(transformBasketProductData)} />
                    <Below breakpoint="tabletLg" render={() => <Summary marginBottom="48px" />} />
                    <Payment paymentMethodId={basket.paymentMethodId} tracking={tracking} />
                </Column>
                <Column
                    width={['100%', null, null, 'calc(41.66666667% - 8px)']}
                    paddingLeft={['0', null, null, null, '8.33333333%']}
                >
                    <Above
                        breakpoint="tabletLg"
                        render={() => <Summary position="sticky" right="0" top="24px" marginBottom="16px" />}
                    />
                </Column>
            </InnerWrapper>
        </MaxWidthWrapper>
    );
};

CheckoutComponent.propTypes = {
    tracking: PropTypes.shape({
        trigger: PropTypes.bool,
        setTrigger: PropTypes.func,
    }),
};

export default injectModel(['basket'])(CheckoutComponent);
