import React, { useEffect, useState } from 'react';
import useHeaderHeights, { desktopHeaderHeight, mobileHeaderHeight } from 'hooks/useHeaderHeights';

import Above from 'components/breakpoints/Above';
import Banner from 'Header/Default/Banner';
import BasketButton from 'Header/Default/Menu/buttons/BasketButton';
import Below from 'components/breakpoints/Below';
import Link from 'components/base/Link';
import Menu from 'Header/Default/Menu';
import MenuButton from './Menu/buttons/MenuButton';
import SagaformLogo from 'assets/icons/logos/SagaformLogo';
import SearchButton from 'Header/Default/Menu/buttons/SearchButton';
import TopLevel from 'Header/Default/Menu/MenuDesktop/TopLevel';
import { above } from 'utils/mediaqueries';
import { headerThemes } from 'Header/Default/HeaderConfig';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import useIsShowroom from 'hooks/useIsShowroom';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const Header = styled('header', { shouldForwardProp: prop => ['hideDropShadow'].indexOf(prop) === -1 })`
    left: 8px;
    z-index: ${zIndex.header};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 16px);
    height: ${mobileHeaderHeight}px;
    padding: 0 16px;
    border-radius: 56px;
    transition: background-color ${transitions.primary};
    filter: ${({ hideDropShadow }) =>
        hideDropShadow ? 'none' : 'drop-shadow(0px 4px 4px var(--dynamic-opacity-color-dark-higher))'};

    ${above.desktopMd} {
        left: 24px;
        width: calc(100% - 48px);
        height: ${desktopHeaderHeight - 10}px; // 10px is the overlapping
        justify-content: normal;
        border-radius: ${({ menuIsOpen }) => (menuIsOpen ? '12px 12px 0 0 ' : '12px')};
    }
`;

const IconsWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 12px;
`;

const Default = () => {
    const headerHeights = useHeaderHeights();
    const isDesktopMd = useAboveBreakpoint('desktopMd');

    const pageTemplate = useSelector(state => state.page.template);
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const overlay = useSelector(state => state.overlay);
    const heroModuleTextColor = useSelector(state => state.page.data?.hero_content?.[0]?.data?.data?.text_color);

    const [scrolledPastBanner, setScrolledPastBanner] = useState(false);
    const [theme, setTheme] = useState('transparentDark');
    const [isHovered, setIsHovered] = useState(false);
    
    const isShowroom = useIsShowroom();

    const isFrontPage = pageTemplate === 'frontpage';
    const overlayIsOpen = overlay.current !== null;
    const themeSettings = headerThemes[theme];
    const hideDropShadow = menuIsOpen || overlay.current === 'searchOverlay';

    useEffect(() => {
        let newTheme = 'transparentDark';

        if (!isHovered && isFrontPage && heroModuleTextColor === 'white') {
            newTheme = 'transparentLight';
        }

        if (scrolledPastBanner || isHovered || menuIsOpen || overlayIsOpen) {
            newTheme = 'solidDark';
        }

        if (newTheme !== theme) {
            setTheme(newTheme);
        }
    }, [heroModuleTextColor, isFrontPage, isHovered, menuIsOpen, overlayIsOpen, scrolledPastBanner, theme]);

    useEffect(() => {
        const updateHeader = () => {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || 0;
            const newScolledPastBanner = scrollPosition > headerHeights.banner;

            if (newScolledPastBanner !== scrolledPastBanner) {
                setScrolledPastBanner(newScolledPastBanner);
            }
        };

        window.addEventListener('scroll', updateHeader);
        return () => window.removeEventListener('scroll', updateHeader);
    }, [headerHeights, scrolledPastBanner]);

    let mobileTop = `${headerHeights.mobileGap + headerHeights.banner}px`;
    let desktopTop = `${headerHeights.desktopGap + headerHeights.banner}px`;

    if (scrolledPastBanner) {
        mobileTop = `${headerHeights.mobileGap}px`;
        desktopTop = `${headerHeights.desktopGap}px`;
    }

    return (
        <>
            <Banner />
            <Header
                backgroundColor={themeSettings.backgroundColor}
                color={themeSettings.color}
                hideDropShadow={hideDropShadow}
                position={scrolledPastBanner ? 'fixed' : 'absolute'}
                top={[mobileTop, null, null, null, null, desktopTop]}
                onMouseEnter={isDesktopMd ? () => setIsHovered(true) : null}
                onMouseLeave={isDesktopMd ? () => setIsHovered(false) : null}
            >
                <Link to="/">
                    <SagaformLogo color="currentColor" width="73px" />
                </Link>
                <Above breakpoint="desktopMd" render={() => <TopLevel />} />
                <IconsWrapper>
                    <Below breakpoint="desktopMd" render={() => <MenuButton />} />
                    <SearchButton />
                    {!isShowroom && <BasketButton />}
                </IconsWrapper>
            </Header>
            <Menu />
        </>
    );
};

export default Default;
