/* eslint-disable camelcase */
import React, { Component } from 'react';

import ProductsContainer from 'containers/ProductsContainer';
import PropTypes from 'prop-types';
import { extractQueryParams } from 'utils/query';
import { inServer } from 'config/constants';
import { injectModel } from 'state';
import { withRouter } from 'react-router-dom';

class FilterContainer extends Component {
    clear = {
        byValue: (key, value) => {
            const { filters } = this.state;
            const { config, products } = this.props;
            let newState = JSON.parse(JSON.stringify(filters));

            newState = newState.map(f => {
                if (f.key !== key) {
                    return f;
                }

                const type = config.hasOwnProperty(key) && config[key].type;
                switch (type) {
                    case 'checkbox':
                        f.selected = f.selected.filter(s => s !== value);
                        break;
                    case 'range':
                        f.selected = f.selected.filter(s => !value.includes(s));
                        break;
                    case 'radio':
                        f.selected = '';
                        break;
                    default:
                }
                return f;
            });

            products.setNextFetchMode('replace');

            this.url.set(newState);
            this.setState({ filters: newState });
        },

        byKey: key => {
            const { filters } = this.state;
            let newState = JSON.parse(JSON.stringify(filters));

            newState = newState.map(f => {
                if (f.key === key) {
                    f.selected = [];
                }
                return f;
            });

            this.url.set(newState);
            this.setState({ filters: newState });
        },

        filter: () => {
            const { filters } = this.state;
            const { config } = this.props;
            let newState = JSON.parse(JSON.stringify(filters));

            newState = newState.reduce((a, filter) => {
                const type = config.hasOwnProperty(filter.key) && config[filter.key].type;
                switch (type) {
                    case 'checkbox':
                    case 'range':
                        return [...a, { ...filter, selected: [] }];
                    case 'radio':
                        return [...a, { ...filter, selected: '' }];
                    default:
                        return [...a];
                }
            }, []);

            this.url.set(newState);
            this.setState({ filters: newState });
        },
    };

    get = {
        valid: () => {
            const { config } = this.props;
            return Object.entries(config).map(([k]) => k);
        },

        labels: () => {
            const { config } = this.props;
            const labels = {};
            Object.entries(config).forEach(([k, a]) => {
                labels[k] = a.label;
            });
            return labels;
        },

        types: () => {
            const { config } = this.props;
            const types = {};
            Object.entries(config).forEach(([k, a]) => {
                types[k] = a.type;
            });
            return types;
        },

        params: () => {
            const { config } = this.props;
            const params = {};
            Object.entries(config).forEach(([k, a]) => {
                params[k] = a.param;
            });
            return params;
        },
    };

    handleChange = {
        filter: {
            value: (key, value) => {
                const { filters } = this.state;
                const { config, products } = this.props;
                const newState = JSON.parse(JSON.stringify(filters));

                const index = newState.findIndex(v => v.key === key);

                // check if key exists
                if (index === -1) {
                    return;
                }

                let isSelected = null;
                const type = config.hasOwnProperty(key) && config[key].type;

                switch (type) {
                    case 'checkbox':
                        isSelected = newState[index].selected.indexOf(value);
                        if (isSelected === -1) {
                            newState[index].selected.push(value);
                        } else {
                            newState[index].selected.splice(isSelected, 1);
                        }
                        break;
                    case 'radio':
                        isSelected = newState[index].selected === value;
                        if (!isSelected) {
                            newState[index].selected = value;
                        } else {
                            newState[index].selected = '';
                        }
                        break;
                    default:
                }

                products.setNextFetchMode('replace');

                this.url.set(newState);
                this.setState({ filters: newState });
            },

            range: (key, min, max) => {
                const { filters } = this.state;
                const { products } = this.props;
                const newState = JSON.parse(JSON.stringify(filters));
                const index = newState.findIndex(v => v.key === key);

                // check if key exists
                if (index === -1) {
                    return;
                }

                newState[index].selected = [min, max];

                products.setNextFetchMode('replace');

                this.url.set(newState);
                this.setState({ filters: newState });
            },
        },

        sort: sort => {
            const { filters } = this.state;
            const { products } = this.props;
            this.url.set(filters, sort);
            products.setNextFetchMode('replace');
            this.setState({ sort: sort === 'default' ? null : sort });
        },
    };

    url = {
        set: (filters = this.state.filters, sort = null) => {
            const { config, history, location } = this.props;

            // OBS we have to use window.location instead of withRouter location. Because of withRouter is to slow // Robin
            const params = extractQueryParams(window.location.search);

            delete params.page;

            filters.forEach(filter => {
                const type = config.hasOwnProperty(filter.key) && config[filter.key].type;
                switch (type) {
                    case 'checkbox':
                    case 'range':
                        if (filter.selected.length > 0) {
                            params[config[filter.key].param] = filter.selected
                                .map(q => encodeURIComponent(q))
                                .join(',');
                        } else {
                            delete params[config[filter.key].param];
                        }
                        break;
                    case 'radio':
                        if (filter.selected.length > 0) {
                            params[config[filter.key].param] = filter.selected;
                        } else {
                            delete params[config[filter.key].param];
                        }
                        break;
                    default:
                }
            });

            if (sort) {
                if (sort === 'default' && params['sort']) {
                    delete params['sort'];
                } else if (sort !== 'default') {
                    params['sort'] = sort;
                }
            }

            history.replace(
                `${location.pathname}?${Object.entries(params)
                    .map(q => q.join('='))
                    .join('&')}`
            );
        },

        get: () => {
            const { location } = this.props;
            const queries = extractQueryParams(location.search);
            const params = [];

            Object.keys(queries).forEach(p => {
                const entity = Object.entries(this.get.params()).find(k => k[1] === p);
                if (entity) {
                    params[entity[0]] = queries[p].split(',').map(q => decodeURIComponent(q));
                }
            });

            return params;
        },

        apply: () => {},
    };

    format = {
        filters: (filters = this.state.filters) => {
            return filters.reduce((a, filter) => {
                switch (filter.type) {
                    case 'checkbox':
                        return [
                            ...a,
                            this.format.checkbox({
                                key: filter.key,
                                label: filter.label,
                                option: filter.option,
                                priority: filter.priority,
                                selected: filter.selected,
                                sort: filter.sort,
                                stats: filter.stats,
                                values: filter.values,
                            }),
                        ];
                    case 'range':
                        return [
                            ...a,
                            this.format.range({
                                key: filter.key,
                                label: filter.label,
                                option: filter.option,
                                priority: filter.priority,
                                selected: filter.selected,
                                stats: filter.stats,
                                unit: filter.unit,
                                values: filter.values,
                            }),
                        ];
                    case 'radio':
                        return [
                            ...a,
                            this.format.radio({
                                key: filter.key,
                                label: filter.label,
                                option: filter.option,
                                priority: filter.priority,
                                selected: filter.selected,
                                sort: filter.sort,
                                stats: filter.stats,
                                values: filter.values,
                            }),
                        ];
                    default:
                        return a;
                }
            }, []);
        },

        checkbox: ({
            key = '',
            label = '',
            option = '',
            priority,
            selected = [],
            sort = '',
            stats = {},
            values = [],
        }) => ({
            key,
            label,
            option,
            priority,
            sort,
            stats,
            totalSelected: selected ? selected.length : 0,
            values: values.map(v => ({ ...v, selected: selected.includes(v.value.toString()) })),
        }),

        range: ({
            key = '',
            label = '',
            option = '',
            priority,
            selected = [],
            stats = {},
            unit = '',
            values = [],
        }) => ({
            key,
            label,
            option,
            priority,
            selected,
            stats,
            unit,
            values,
        }),

        radio: ({
            key = '',
            label = '',
            option = '',
            priority,
            selected = [],
            sort = '',
            stats = {},
            values = [],
        }) => ({
            key,
            label,
            option,
            priority,
            selected: selected.length > 0,
            sort,
            stats,
            values: values.map(v => ({ ...v, selected: v.value.toString() === selected })),
        }),

        selectedFilters: (filters = this.state.filters) => {
            const { config } = this.props;
            const selected = [];

            this.format.filters(filters).forEach(filter => {
                const type = config.hasOwnProperty(filter.key) && config[filter.key].type;
                switch (type) {
                    case 'checkbox':
                        filter.values.forEach(v => {
                            if (v.selected) {
                                selected.push({
                                    key: filter.key,
                                    label: v.label,
                                    option: filter.option,
                                    sort: filter.sort,
                                    value: v.value,
                                });
                            }
                        });
                        break;
                    case 'range':
                        if (filter.selected.length === 2) {
                            selected.push({
                                key: filter.key,
                                label: filter.label,
                                option: filter.option,
                                value: filter.selected,
                            });
                        }
                        break;
                    case 'radio':
                        filter.values.forEach(v => {
                            if (v.selected) {
                                selected.push({
                                    key: filter.key,
                                    label: v.label,
                                    option: filter.option,
                                    totalHits: v.totalHits,
                                    value: v.value,
                                });
                            }
                        });
                        break;
                    default:
                }
            });
            return selected;
        },

        sorts: () => {
            const { sort } = this.state;
            const { sorts } = this.props;

            return Object.keys(sorts).map(key => ({
                index: key,
                label: sorts[key],
                selected: sort ? sort === key : key === 'default',
            }));
        },
    };

    filters = {
        filters: (filters, filterStats = {}) => {
            const { config } = this.props;
            const urlParams = this.url.get();
            const order = Object.keys(config);

            return Object.keys(filters)
                .reduce((a, k) => {
                    if (this.get.valid().indexOf(k) === -1 && filters[k]) {
                        return a;
                    }

                    switch (this.get.types()[k]) {
                        case 'checkbox':
                            return [
                                ...a,
                                this.filters.checkbox({
                                    key: k,
                                    stats: filterStats[k],
                                    selected: urlParams[k] ? urlParams[k] : [],
                                    values: filters[k],
                                }),
                            ];
                        case 'range':
                            return [
                                ...a,
                                this.filters.range({
                                    key: k,
                                    stats: filterStats[k],
                                    selected: urlParams[k] ? urlParams[k] : [],
                                }),
                            ];
                        case 'radio':
                            return [
                                ...a,
                                this.filters.radio({
                                    key: k,
                                    stats: filterStats[k],
                                    selected: urlParams[k] ? urlParams[k][0] : '',
                                    values: filters[k],
                                }),
                            ];
                        default:
                            return a;
                    }
                }, [])
                .filter(i => i)
                .sort((a, b) => {
                    if (order.indexOf(a.key) > order.indexOf(b.key)) {
                        return 1;
                    }
                    if (order.indexOf(b.key) > order.indexOf(a.key)) {
                        return -1;
                    }
                    return 0;
                });
        },

        checkbox: ({ key = '', stats = {}, selected = [], values = {} }) => {
            const { config } = this.props;
            return {
                key,
                label: config.hasOwnProperty(key) && config[key].label,
                option: config.hasOwnProperty(key) && config[key].option,
                priority: config.hasOwnProperty(key) && config[key].priority,
                selected,
                sort: config.hasOwnProperty(key) && config[key].sort,
                stats: stats ? stats : values,
                type: config.hasOwnProperty(key) && config[key].type,
                values: Object.keys(values).map(v => ({ label: v, value: v, prefix: '' })),
            };
        },

        range: ({ key = '', stats = {}, selected = [] }) => {
            const { config } = this.props;
            return {
                key,
                label: config.hasOwnProperty(key) && config[key].label,
                option: config.hasOwnProperty(key) && config[key].option,
                priority: config.hasOwnProperty(key) && config[key].priority,
                selected,
                stats,
                type: config.hasOwnProperty(key) && config[key].type,
                unit: config.hasOwnProperty(key) && config[key].unit,
                values: [stats.min, stats.max],
            };
        },

        radio: ({ key = '', stats = {}, selected = [], values = {} }) => {
            const { config } = this.props;
            return {
                key,
                label: config.hasOwnProperty(key) && config[key].label,
                option: config.hasOwnProperty(key) && config[key].option,
                priority: config.hasOwnProperty(key) && config[key].priority,
                selected,
                sort: config.hasOwnProperty(key) && config[key].sort,
                stats: stats ? stats : values,
                type: config.hasOwnProperty(key) && config[key].type,
                values: Object.keys(values).map(v => ({
                    label: v,
                    value: v,
                    totalHits: values && values[v],
                    prefix: '',
                })),
            };
        },

        default: () => {
            const { filters } = this.props;
            // Here one can add code.... // Thanks Robin
            return filters;
        },

        selected: () => {
            const { filters } = this.state;
            const selected = {
                checkbox: (selected, key, prefix) =>
                    selected.map(v => {
                        return this.filters.combine.checkbox(key, v, prefix);
                    }),
                range: (selected, key, prefix) => {
                    return [this.filters.combine.range(key, selected[0], selected[1], prefix)];
                },
                radio: (selected, key, prefix, operator) => {
                    return [this.filters.combine.radio(key, selected, prefix, operator)];
                },
            };

            const selectedFilters = filters
                .map(f => {
                    const type = this.get.types()[f.key];

                    return f.selected.length > 0 ? selected[type](f.selected, f.key, f.prefix) : [];
                })
                .filter(i => i.length !== 0);

            // selected filters from url
            if (filters.length === 0) {
                const query = this.url.get();
                Object.entries(query).forEach(([key, array]) => {
                    const type = this.get.types()[key];
                    selectedFilters.push(selected[type](array, key));
                });
            }

            return selectedFilters;
        },

        combine: {
            checkbox: (key, value, prefix = null) => {
                return `${(prefix ? `${prefix} ` : '') + key}:"${
                    typeof value === 'string' ? value.replace('"', '\\"') : value
                }"`;
            },
            range: (key, min, max, prefix = null) => {
                return `${(prefix ? `${prefix} ` : '') + key}:${min} TO ${max}`;
            },
            radio: (key, value, prefix = null, operator = '>') => {
                return `${(prefix ? `${prefix} ` : '') + key} ${operator} ${value}`;
            },
        },
    };

    static propTypes = {
        categories: PropTypes.array,
        config: PropTypes.shape({
            key: PropTypes.shape({
                label: PropTypes.string,
                option: PropTypes.string,
                param: PropTypes.string,
                priority: PropTypes.number,
                sort: PropTypes.string,
                type: PropTypes.string,
            }),
        }).isRequired,
        filters: PropTypes.array,
        history: PropTypes.object.isRequired,
        index: PropTypes.string,
        list: PropTypes.string,
        location: PropTypes.object.isRequired,
        marketId: PropTypes.string,
        pageSize: PropTypes.number,
        permalink: PropTypes.string,
        products: PropTypes.object.isRequired,
        render: PropTypes.func.isRequired,
        renderProps: PropTypes.object,
        responseCallback: PropTypes.func,
        search: PropTypes.string,
        sorts: PropTypes.object,
        suffix: PropTypes.string,
        t: PropTypes.func,
    };

    static defaultProps = {
        categories: [],
        filters: [],
        index: 'products',
        list: '',
        marketId: '',
        pageSize: 24,
        renderProps: {},
        responseCallback: null,
        search: '',
        permalink: undefined,
        sorts: {},
        suffix: '',
        t: () => '',
    };

    constructor(props) {
        super(props);

        const queries = !inServer
            ? extractQueryParams(window.location.search)
            : extractQueryParams(this.props.location.search);

        this.state = {
            sort: queries.hasOwnProperty('sort') ? queries.sort : null,
            filters: [],
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { filters, search, categories, renderProps, pageSize, index, suffix, products, permalink } = this.props;

        if (
            JSON.stringify(filters) !== JSON.stringify(nextProps.filters) ||
            JSON.stringify(categories) !== JSON.stringify(nextProps.categories) ||
            search !== nextProps.search
        ) {
            products.setNextFetchMode('replace');
            this.setState({ filters: [], sort: null });
            return true;
        }

        if (JSON.stringify(renderProps) !== JSON.stringify(nextProps.renderProps)) {
            return true;
        }

        if (JSON.stringify(pageSize) !== JSON.stringify(nextProps.pageSize)) {
            return true;
        }

        if (JSON.stringify(permalink) !== JSON.stringify(nextProps.permalink)) {
            return true;
        }

        if (JSON.stringify(suffix) !== JSON.stringify(nextProps.suffix)) {
            return true;
        }

        if (JSON.stringify(index) !== JSON.stringify(nextProps.index)) {
            return true;
        }

        if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
            return true;
        }

        return false;
    }

    init = response => {
        let filters = this.filters.filters(response.filters, response.filterStats || {});
        filters = this.initFiltersModifier(filters, response.filters, this.props.t);

        return filters;
    };

    /*
     * Dynamic filter handling can be added here.
     */
    initFiltersModifier = (filters = [], responseFilter = {}, t = () => '') => {
        // Custom color combination handling -->
        if (responseFilter.hasOwnProperty('_color_combinations') && responseFilter['_color_combinations']) {
            const colorImages = Object.keys(responseFilter['_color_combinations']).reduce((acc, value) => {
                if (value.indexOf('|') < 0) {
                    return acc;
                }
                const [name, key, img, outline] = value.split('|');

                acc[name] = {
                    name,
                    key,
                    img,
                    outline: outline === '1',
                };
                return acc;
            }, []);

            filters.forEach(filter => {
                if (filter.key === '_filter_colors') {
                    filter.values = filter.values.map(val => ({
                        ...val,
                        image: colorImages.hasOwnProperty(val.value) ? colorImages[val.value].img : null,
                        useOutline: colorImages.hasOwnProperty(val.value) ? colorImages[val.value].outline : null,
                    }));
                }
            });
        }

        // <-- End color customization

        // Custom size combination handling -->
        if (responseFilter.hasOwnProperty('_size_combination') && responseFilter['_size_combination']) {
            const sizePriority = Object.keys(responseFilter['_size_combination']).reduce((acc, value) => {
                if (value.indexOf('|') < 0) {
                    return acc;
                }
                const [name, key, prio] = value.split('|');

                acc[name] = {
                    name,
                    key,
                    prio,
                };
                return acc;
            }, []);

            filters.forEach(filter => {
                if (filter.key === '_filter_size') {
                    filter.values = filter.values.map(val => ({
                        ...val,
                        priority: sizePriority.hasOwnProperty(val.value)
                            ? parseInt(sizePriority[val.value].prio, 10)
                            : null,
                    }));
                }
            });
        }

        // <-- End size customization

        // Custom in stock combination handling
        if (this.props.config.hasOwnProperty('_in_stock')) {
            filters.push({
                key: '_in_stock',
                values: [
                    {
                        label: t('filter_config.in_stock'),
                        value: this.props.marketId,
                        prefix: '',
                    },
                ],
                selected: [],
                // eslint-disable-next-line react/prop-types
                ...this.props.config._in_stock,
            });
        }

        // <-- End size customization

        return filters;
    };

    responseCallback = response => {
        if (!response) {
            return null;
        }

        const { filters } = this.state;
        const { responseCallback, products } = this.props;

        let newFilters = JSON.parse(JSON.stringify(filters));

        if (Object.keys(response.filters).length > 0 && filters.length === 0) {
            newFilters = this.init(response);
        }

        // setAllProducts adds or replaces the current products to the existing products in redux depending on setProductsMode.
        products.setAllProducts(response.products);

        const data = {
            ...response,
            handleChange: this.handleChange,
            clear: this.clear,
            isAnySelected: newFilters.some(f => f.selected),
            filters: this.format.filters(newFilters),
            selectedFilters: this.format.selectedFilters(newFilters),
            sorts: this.format.sorts(),
        };

        this.setState({ filters: newFilters });

        return responseCallback ? responseCallback(data) : data;
    };

    render() {
        const { sort } = this.state;
        const { suffix, categories, pageSize, render, renderProps, search, list, permalink, index, t } = this.props;

        return (
            <ProductsContainer
                enablePaginationTracking
                suffix={sort || suffix}
                index={index}
                list={list}
                categories={categories}
                search={search}
                permalink={permalink}
                filters={{
                    default: this.filters.default(),
                    selected: this.filters.selected(),
                }}
                pageSize={pageSize}
                responseCallback={this.responseCallback}
                t={t}
                render={rest => render({ ...rest, renderProps })}
            />
        );
    }
}

export default withRouter(injectModel('products')(FilterContainer));
