import PropTypes from 'prop-types';
import React from 'react';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';

const StyledHeading = styled('h2')``;

const Heading = ({ as = 'h2', children, fontKeys = 'Recife Display/32_120', ...rest }) => {
    const fontStyles = styleObjectToProps(fontKeys);

    return (
        <StyledHeading as={as} {...fontStyles} {...rest}>
            {children}
        </StyledHeading>
    );
};

Heading.propTypes = {
    as: PropTypes.string,
    fontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Heading;
