import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`;

const StyledLink = styled(Link)`
    :hover ~ div {
        opacity: 1;
        transform: translateY(0px);
    }
`;

const Dot = styled('div')`
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: currentColor;
    transition: all ${transitions.primary};
`;

const TextLinkDot = ({ isActive = false, children, to = '', ...rest }) => {
    const dotTransitionStyles = isActive
        ? { opacity: 1, transform: 'translateY(0px)' }
        : { opacity: 0, transform: 'translateY(5px)' };

    return (
        <Wrapper>
            <StyledLink as={to ? Link : 'button'} to={to} {...rest}>
                {children}
            </StyledLink>
            <Dot {...dotTransitionStyles} />
        </Wrapper>
    );
};

TextLinkDot.propTypes = {
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool,
    to: PropTypes.string,
};

export default TextLinkDot;
