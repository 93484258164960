import PropTypes from 'prop-types';
import React from 'react';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { above } from 'utils/mediaqueries';

const LinkList = styled('ul')`
    padding: 12px 0;
`;

const LinkWrapper = styled('li')`
    :not(:last-of-type) {
        margin-bottom: 16px;

        ${above.desktopSm} {
            margin-bottom: 12px;
        }
    }
`;

const FooterLinks = ({ links = [] }) => (
    <LinkList>
        {links.map?.((link, index) => (
            <LinkWrapper key={`${link.to}-${index}`}>
                <TextLinkUnderline
                    color="var(--static-color-secondary)"
                    to={link.to}
                    {...styleObjectToProps(['NB International/16_120', null, null, null, 'NB International/14_120'])}
                >
                    {link.label}
                </TextLinkUnderline>
            </LinkWrapper>
        ))}
    </LinkList>
);

FooterLinks.propTypes = {
    links: PropTypes.array,
};

export default FooterLinks;
