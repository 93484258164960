/*
 * NOTE: use this file to setup your projects overall transitions.
 * Customize as your design is.
 */

export const animations = {
    primary: {
        delay: 150, //ms
        duration: 2000, //ms
        timingFunction: 'ease-in-out',
        translate: ['16px', null, '60px'],
    },
    secondary: {
        delay: 150, //ms
        duration: 500, //ms
        timingFunction: 'ease-in-out',
    },
};

const transitions = {
    primary: `300ms ease-in-out`,
    secondary: `500ms ease-in-out`,
};

export default transitions;
