import PropTypes from 'prop-types';
import React from 'react';
import TextLinkUnderline from 'components/text/TextLinkUnderline';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')``;

const Tabs = ({ activeTab = '', changeTab = () => '', hasArticles = false, hasProducts = false }) => {
    const { t } = useTranslation();

    return (
        <Wrapper
            alignItems="flex-start"
            display="flex"
            gap="0 12px"
            marginBottom="16px"
            width="100%"
            {...styleObjectToProps('NB International/16_120')}
        >
            {hasProducts && (
                <TextLinkUnderline noUnderline={activeTab !== 'products'} onClick={() => changeTab('products')}>
                    {t('search.products')}
                </TextLinkUnderline>
            )}
            {hasArticles && (
                <TextLinkUnderline noUnderline={activeTab !== 'articles'} onClick={() => changeTab('articles')}>
                    {t('search.articles')}
                </TextLinkUnderline>
            )}
        </Wrapper>
    );
};

Tabs.propTypes = {
    activeTab: PropTypes.string,
    changeTab: PropTypes.func,
    hasArticles: PropTypes.bool,
    hasProducts: PropTypes.bool,
};

export default Tabs;
