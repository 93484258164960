import { useDispatch, useSelector } from 'react-redux';

import Paragraph from 'components/text/Paragraph';
import React from 'react';
import Search from 'assets/icons/Search';
import { closeMenu } from 'state/models/Header/actions';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useTranslation } from 'react-i18next';
import zIndex from 'config/theme/z-index';

const Button = styled('button')`
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--dynamic-background-color-secondary);
    color: var(--dynamic-color-secondary);
`;

// todo: Lägg till rätt fontkeys
const SearchButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showOverlay = useSelector(state => state.overlay.show);
    const hideOverlay = useSelector(state => state.overlay.hide);
    const currentOverlay = useSelector(state => state.overlay.current);
    const isAboveDesktopMd = useAboveBreakpoint('desktopMd');

    const toggleSearch = () => {
        dispatch(closeMenu());

        if (currentOverlay === 'searchOverlay') {
            hideOverlay('searchOverlay');
        } else {
            showOverlay('searchOverlay', {
                zIndex: isAboveDesktopMd ? zIndex.searchOverlayDesktop : zIndex.searchOverlayMobile,
            });
        }
    };

    return (
        <Button
            justifyContent={['center', null, null, null, 'space-between']}
            padding={['0', null, null, null, '0 12px']}
            type="button"
            width={['36px', null, null, null, '90px']}
            onClick={() => toggleSearch()}
        >
            <Paragraph as="span" display={['none', null, null, null, 'block']} fontKeys="NB International/12_120_4">
                {t('header.search')}
            </Paragraph>
            <Search color="currentColor" width={['12px', null, null, null, '15px']} />
        </Button>
    );
};

export default SearchButton;
