import FallbackShipping from 'components/pages/checkout/CheckoutView/Payment/Shipping/FallbackShipping';
import Heading from 'components/text/Heading';
import IngridWidget from '@grebban/centra-ingrid-delivery-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';

const Div = styled('div')``;

const ShippingHandler = ({ headingFontKeys = '', showShippingAndPayment = true }) => {
    const { shippingMethodId, shippingMethods } = useSelector(state => state.basket || {});
    const heading = useSelector(state => state.page.data.checkout.shipping?.heading);

    // Check if Ingrid is available.
    // If it is, we ALWAYS want to use it.
    let ShippingMethod = FallbackShipping;
    let usedShippingMethod = shippingMethodId;

    if (shippingMethods.ingrid !== undefined && shippingMethods.ingrid.snippet !== null) {
        usedShippingMethod = 'ingrid';
        ShippingMethod = IngridWidget;
    }

    return (
        <>
            <Heading fontKeys={headingFontKeys} type={heading.type}>
                02. {heading.text}
            </Heading>
            <Div display={showShippingAndPayment ? 'block' : 'none'} margin="36px 0 48px">
                <ShippingMethod data={shippingMethods[usedShippingMethod]} />
            </Div>
        </>
    );
};

ShippingHandler.propTypes = {
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    showShippingAndPayment: PropTypes.bool,
};

export default ShippingHandler;
