import fontFamilies from 'config/theme/fontFamilies';

export interface TextStyle {
    fontFamily: string;
    fontStyle: string;
    fontSize: string;
    fontWeight?: string | number;
    letterSpacing: string;
    lineHeight: string;
}

// @todo: add font varaiations when we have new names
const textStylesConfig: Record<string, TextStyle> = {
    'Recife Display/14': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/16': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
    'Recife Display/18': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
    'Recife Display/20': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '2rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/22_120': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '2.2rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/22_140': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '2.2rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '140%',
    },
    'Recife Display/24_100': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '100%',
    },
    'Recife Display/24_120': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/32_120': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/32_140': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '140%',
    },
    'Recife Display/40': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '4rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/48': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '120%',
    },
    'Recife Display/56': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '5.6rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '110%',
    },
    'Recife Display/64': {
        fontFamily: fontFamilies.primary.serif,
        fontStyle: 'normal',
        fontSize: '6.4rem',
        fontWeight: 400,
        letterSpacing: '-0.01em',
        lineHeight: '110%',
    },
    'NB International/9': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '0.9rem',
        fontWeight: 400,
        letterSpacing: '-0.02em',
        lineHeight: '100%',
    },
    'NB International/11_120': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.1rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
    'NB International/11_120_2': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.1rem',
        fontWeight: 400,
        letterSpacing: '0.02em',
        lineHeight: '120%',
    },
    'NB International/12_120_4': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.2rem',
        fontWeight: 400,
        letterSpacing: '0.04em',
        lineHeight: '120%',
    },
    'NB International/12_120_7': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.2rem',
        fontWeight: 400,
        letterSpacing: '0.07em',
        lineHeight: '120%',
    },
    'NB International/14_100': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '100%',
    },
    'NB International/14_100_4': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0.04em',
        lineHeight: '100%',
    },
    'NB International/14_100_7': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0.07em',
        lineHeight: '100%',
    },
    'NB International/14_120': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
    'NB International/14_160': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '160%',
    },
    'NB International/16_100': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '100%',
    },
    'NB International/16_120': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
    'NB International/16_160': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '160%',
    },
    'NB International/18': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '110%',
    },
    'NB International/20': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '2rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
    'NB International/24': {
        fontFamily: fontFamilies.primary.sans,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        fontWeight: 400,
        letterSpacing: '0',
        lineHeight: '120%',
    },
};

export default textStylesConfig;
