import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const BasketIcon = ({ color = colors.black, height = '100%', width = '12px', ...rest }) => (
    <SVG width={width} height={height} fill="none" viewBox="0 0 12 18" {...rest}>
        <Path
            clipRule="evenodd"
            d="M3.5 3.5a2.5 2.5 0 0 1 5 0V4h-5v-.5Zm-1 .5v-.5a3.5 3.5 0 1 1 7 0V4H12v14H0V4h2.5ZM1 17V5h10v12H1Z"
            fill={color}
            fillRule="evenodd"
        />
    </SVG>
);

BasketIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default BasketIcon;
