import Checkbox from 'components/forms/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import { getSortedFilters } from 'utils/dataTransformers/filters';
import styled from 'libs/styled';

const Item = styled('li')`
    display: flex;
    flex-direction: column;
    gap: 8px 0;
`;

const FilterCheckboxOption = ({
    filters = [],
    handleChange = () => {},
    parentFilterKey = '',
    sortBy = 'alphabetically',
}) => {
    const sortedFilters = getSortedFilters(filters, sortBy);

    return (
        <Item>
            {sortedFilters.map(item => (
                <Checkbox
                    checked={item.selected}
                    key={item.value}
                    label={item.label}
                    name={item.label}
                    handleChange={() => handleChange(parentFilterKey, item.value)}
                />
            ))}
        </Item>
    );
};

FilterCheckboxOption.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool })).isRequired,
    handleChange: PropTypes.func.isRequired,
    parentFilterKey: PropTypes.string.isRequired,
    sortBy: PropTypes.string,
};

export default FilterCheckboxOption;
