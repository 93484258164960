import { CurrencyFormat, Product, TotalDiscount } from 'libs/centra/types';

interface ProductsPriceBeforeDiscount {
    currencyFormat: CurrencyFormat;
    products: Product[];
    productsSumAsNumber: number;
}

// Can return value or false to mimic the structure from Centra
interface ProductsPriceBeforeDiscountReturn {
    priceBeforeDiscount: string | boolean;
    priceBeforeDiscountAsNumber: number | boolean;
    hasDiscountOnProductsPrice: boolean;
}

interface TotalDiscountIncludingSale {
    currencyFormat: CurrencyFormat;
    products: Product[];
    totalDiscount: TotalDiscount;
}

// Can return value or false to mimic the structure from Centra
interface TotalDiscountIncludingSaleReturn {
    discount: string | boolean;
    discountAsNumber: number | boolean;
}

interface PriceWithCurrency {
    currencyFormat: CurrencyFormat;
    priceAsNumber: number;
}

/**
 *
 * Transforms a price as numnber to a price with currency and rounds off.
 *
 * @param {object} currencyFormat - Object with data about currency, for example: suffix, prefix, currency and decimal_digit.
 * @param {number} priceAsNumber - Price as number
 *
 * @returns {string} - Price with currency prefix or suffix
 * @example - "1000 SEK" or "$1000"
 */

export const getPriceWithCurrency = ({ currencyFormat, priceAsNumber }: PriceWithCurrency): string => {
    // Number of decimals (0 - 2)
    const rawDecimalDigit = currencyFormat.decimal_digit;

    // Make sure deciemalDigit is a number
    const decimalDigitAsNumber = typeof rawDecimalDigit === 'string' ? parseInt(rawDecimalDigit, 10) : rawDecimalDigit;

    // Select roundNumber for the equation that rounds off. Outputs: 0 = 1, 1 = 10 and 2 = 100
    const roundNumber = decimalDigitAsNumber === 2 ? 100 : decimalDigitAsNumber === 1 ? 10 : 1;

    // Round the number depending on how many decimals
    const roundedItemsAndDiscountsAsNumber = Math.round((priceAsNumber + Number.EPSILON) * roundNumber) / roundNumber;

    // Add prefix before price
    if (currencyFormat?.prefix) {
        return `${currencyFormat.prefix}${roundedItemsAndDiscountsAsNumber}`;
    }

    // Add suffix after price
    if (currencyFormat?.suffix) {
        return `${roundedItemsAndDiscountsAsNumber}${currencyFormat.suffix}`;
    }

    return `${roundedItemsAndDiscountsAsNumber}`;
};

/**
 *
 * Calculates total price for products before discount
 *
 * @param {object} currencyFormat - Object with data about currency, for example: suffix, prefix, currency and decimal_digit.
 * @param {array} items - Basket items
 * @param {number} productsSumAsNumber - Price of all products before discount
 *
 * @returns {object} productsPriceBeforeDiscount - Contains priceBeforeDiscount and priceBeforeDiscountAsNumber
 * @example - { priceBeforeDiscount: "-1 000 SEK", priceBeforeDiscountAsNumber: -1000 }
 *
 */

export const getProductsPriceBeforeDiscount = ({
    currencyFormat,
    products,
    productsSumAsNumber,
}: ProductsPriceBeforeDiscount): ProductsPriceBeforeDiscountReturn => {
    const totalPriceAsNumber = products.reduce((acc, current) => {
        return acc + current.price_each_before_discount_as_number * current.quantity;
    }, 0);

    // Check if there is discount on the products.
    const hasDiscountOnProductsPrice = totalPriceAsNumber !== productsSumAsNumber;

    // Convert discount as number to a discount with currency
    const totalPrice = getPriceWithCurrency({ currencyFormat, priceAsNumber: totalPriceAsNumber });

    return {
        hasDiscountOnProductsPrice,
        priceBeforeDiscount: !hasDiscountOnProductsPrice ? false : totalPrice,
        priceBeforeDiscountAsNumber: !hasDiscountOnProductsPrice ? false : totalPriceAsNumber,
    };
};

/**
 *
 * Calculates total discount, includes:
 * - Total discounts on all products
 * - Total discount from all vouchers
 *
 * @param {object} currencyFormat - Object with data about currency, for example: suffix, prefix, currency and decimal_digit.
 * @param {array} items - Basket items
 * @param {object} totalDiscount - Object with data about total voucher discount
 *
 * @returns {object} totalDiscountIncludingSale - Contains discount and discountAsNumber
 * @example - { discount: "-1000 SEK", discountAsNumber: -1000 }
 *
 */

export const getTotalDiscountIncludingSale = ({
    currencyFormat,
    products,
    totalDiscount,
}: TotalDiscountIncludingSale): TotalDiscountIncludingSaleReturn => {
    // Loop through all the products and calculate the total discount for the products
    const totalItemsDiscountAsNumber = products.reduce(
        (acc, current) =>
            acc + (current.price_each_before_discount_as_number - current.price_each_as_number) * current.quantity,
        0
    );

    // Total discount on products and all vouchers
    const itemsAndDiscountsAsNumber = (totalItemsDiscountAsNumber || 0) - (totalDiscount?.discount_as_number || 0);

    // Change to a negative number
    const negativeRoundedItemsAndDiscountsAsNumber = itemsAndDiscountsAsNumber * -1;

    // Convert discount as number to a discount with currency
    const newDiscountWithCurrency = getPriceWithCurrency({
        currencyFormat,
        priceAsNumber: negativeRoundedItemsAndDiscountsAsNumber,
    });

    return {
        discount: itemsAndDiscountsAsNumber === 0 ? false : newDiscountWithCurrency,
        discountAsNumber: itemsAndDiscountsAsNumber === 0 ? false : negativeRoundedItemsAndDiscountsAsNumber,
    };
};

/**
 *
 * Completes the data in the shopping cart from Centra
 *
 * @param {object} currencyFormat - Object with data about currency
 * @param {array} products - Basket items
 * @param {number} productsSumAsNumber - Price of all products before discount
 * @param {object} totalDiscount - Object with data about total voucher discount
 *
 * @returns {object}  - Contains missing data
 *
 */

export const getCustomPrices = ({ currencyFormat, products, productsSumAsNumber, totalDiscount }) => {
    const productsPriceBeforeDiscount = getProductsPriceBeforeDiscount({
        currencyFormat,
        products,
        productsSumAsNumber,
    });
    const totalDiscountIncludingSale = getTotalDiscountIncludingSale({ currencyFormat, products, totalDiscount });

    return {
        hasDiscountOnProductsPrice: productsPriceBeforeDiscount.hasDiscountOnProductsPrice,
        productsPriceBeforeDiscount: productsPriceBeforeDiscount.priceBeforeDiscount,
        productsPriceBeforeDiscountAsNumber: productsPriceBeforeDiscount.priceBeforeDiscountAsNumber,
        totalDiscountIncludingSale: totalDiscountIncludingSale.discount,
        totalDiscountIncludingSaleAsNumber: totalDiscountIncludingSale.discountAsNumber,
    };
};
