import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import ShowMoreWrapper from 'components/wrappers/ShowMoreWrapper';
import SubCategoryList from 'components/SubCategoryList';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import contentSpacing from 'config/theme/contentSpacing';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Div = styled('div')``;

const Hero = ({
    activePage = 0,
    breadcrumbs = [],
    heroDescription = '',
    heroTitle = '',
    subCategoryItems = [],
    ...rest
}) => {
    const showDescription = heroDescription?.[0] !== '' && activePage === 0;
    const showSubCategories = subCategoryItems?.length > 1;
    const { mobileAndGap, desktopAndGap } = useHeaderHeights();
    const headingKeys = ['Recife Display/32_120', null, 'Recife Display/48', null, 'Recife Display/56'];

    return (
        <Div marginTop={[`${mobileAndGap + 12}px`, null, null, null, `${desktopAndGap + 12}px`]} {...rest}>
            {breadcrumbs && (
                <Breadcrumbs
                    currentTitle={heroTitle}
                    items={breadcrumbs}
                    marginBottom="40px"
                    marginLeft={['16px', null, null, '40px']}
                />
            )}
            <Div display="flex" flexDirection="column" alignItems="center">
                <Heading as="h1" fontKeys={headingKeys} mx={contentSpacing} my="0">
                    {heroTitle}
                </Heading>

                {showDescription && (
                    <Div marginTop="18px" maxWidth="450px" mx={contentSpacing}>
                        <ShowMoreWrapper
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            maxHeightDesktop={80} // height of 3 lines of paragraph text
                            maxHeightMobile={80} // height of 3 lines of paragraph text
                            width="100%"
                        >
                            <Div textAlign="center">
                                <Wysiwyg
                                    data={heroDescription}
                                    textComponent={props => (
                                        <WysiwygParagraph
                                            {...props}
                                            {...styleObjectToProps('NB International/16_160')}
                                        />
                                    )}
                                    tagComponents={{ url: TextLinkWysisyg }}
                                />
                            </Div>
                        </ShowMoreWrapper>
                    </Div>
                )}
                {showSubCategories && <SubCategoryList marginTop="40px" items={subCategoryItems} maxWidth="800px" />}
            </Div>
        </Div>
    );
};

Hero.propTypes = {
    activePage: PropTypes.number,
    breadcrumbs: PropTypes.array,
    heroDescription: wysiwygProp,
    heroTitle: PropTypes.string.isRequired,
    subCategoryItems: PropTypes.array,
};

export default Hero;
