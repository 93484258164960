import OverlayContent from 'components/contentLayouts/ShopTheLook/OverlayContent';
import React from 'react';
import { above } from 'utils/mediaqueries';
import { borderRadius } from 'config/theme/borderRadius';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';

const TransitionElement = styled('div')`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    color: var(--static-color-primary);
    background: var(--static-background-color-primary);
    overflow: hidden;

    ${above.tabletSm} {
        max-width: 370px;
        right: 16px !important; // override slide-in right class
        top: 16px;
        border-radius: ${borderRadius[2]};
    }
`;

const ShopTheLookOverlay = ({ ...rest }) => {
    const clientHeight = useClientHeight();

    return (
        <TransitionElement className="slide-in right" height={[clientHeight, null, `calc(${clientHeight} - 32px)`]}>
            <OverlayContent {...rest} />
        </TransitionElement>
    );
};

export default ShopTheLookOverlay;
