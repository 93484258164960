import React, { Component } from 'react';

import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import debounce from 'lodash/debounce';
import hexToRGBA from 'utils/hexToRGBA';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const excludedProps = [
    'direction',
    'disableClickDrag',
    'displayFadeEffect',
    'displayScrollbar',
    'fadeEffectAfterBottomPosition',
    'fadeEffectBeforeTopPosition',
    'fadeEffectColor',
    'fadeEffectLength',
    'fadeEffectTriggerOffset',
    'fadeEffectTriggerOffset',
    'fullyScrolled',
    'handleScroll',
    'notScrolled',
    'scrollbarThumbColor',
    'scrollbarTrackColor',
    'startScrollAtEnd',
];

const Wrapper = styled('div', {
    shouldForwardProp: prop => excludedProps.indexOf(prop) === -1,
})`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ScrollContent = styled('div', {
    shouldForwardProp: prop => excludedProps.indexOf(prop) === -1,
})`
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    // This will disable click
    &.disable-pointer-events * {
        pointer-events: none !important;
    }

    // Common fade effect styles
    &::before,
    &::after {
        display: block;
        content: ${({ displayFadeEffect }) => (displayFadeEffect ? '""' : null)};
        position: absolute;
        transition: all ${transitions.primary};
        z-index: 2;
    }

    // Start fade effect
    &::before {
        top: ${({ fadeEffectBeforeTopPosition }) => fadeEffectBeforeTopPosition};
        left: 0;
        width: ${({ fadeEffectLength, direction }) => (direction === 'vertical' ? '100%' : fadeEffectLength)};
        height: ${({ fadeEffectLength, direction }) => (direction === 'vertical' ? fadeEffectLength : '100%')};
        background: ${({ direction, fadeEffectColor }) =>
            direction === 'vertical'
                ? `linear-gradient(0deg, ${hexToRGBA(fadeEffectColor, 0)}, ${hexToRGBA(fadeEffectColor, 1)})`
                : `linear-gradient(-90deg, ${hexToRGBA(fadeEffectColor, 0)}, ${hexToRGBA(fadeEffectColor, 1)})`};
        visibility: ${({ notScrolled }) => (notScrolled ? 'hidden' : 'visible')};
        opacity: ${({ notScrolled }) => (notScrolled ? '0' : '1')};
    }

    // End fade effect
    &::after {
        bottom: ${({ fadeEffectAfterBottomPosition }) => fadeEffectAfterBottomPosition};
        right: 0;
        width: ${({ fadeEffectLength, direction }) => (direction === 'vertical' ? '100%' : fadeEffectLength)};
        height: ${({ fadeEffectLength, direction }) => (direction === 'vertical' ? fadeEffectLength : '100%')};
        background: ${({ direction, fadeEffectColor }) =>
            direction === 'vertical'
                ? `linear-gradient(0deg, ${hexToRGBA(fadeEffectColor, 1)}, ${hexToRGBA(fadeEffectColor, 0)})`
                : `linear-gradient(-90deg, ${hexToRGBA(fadeEffectColor, 1)}, ${hexToRGBA(fadeEffectColor, 0)})`};
        visibility: ${({ fullyScrolled }) => (fullyScrolled ? 'hidden' : 'visible')};
        opacity: ${({ notScrolled }) => (notScrolled ? '0' : '1')};
    }

    ${({ displayScrollbar, scrollbarThumbColor, scrollbarTrackColor }) =>
        displayScrollbar
            ? `
            scrollbar-width: 1px;
            scrollbar-color: ${scrollbarThumbColor} ${scrollbarTrackColor};
            ::-webkit-scrollbar {
                width: 1px;
                height: 1px;
                background-color: ${scrollbarTrackColor};
            }
            ::-webkit-scrollbar-track {
                background-color: ${scrollbarTrackColor};
            }
            ::-webkit-scrollbar-thumb {
                background-color: ${scrollbarThumbColor};
            } 
        `
            : `
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            &::-webkit-scrollbar {
                display: none;
            }
        `}
`;

/**
 * ScrollWrapper
 * @version 2.1
 * A wrapper that can add drag-functionality, custom scrollbar styles and start-/end-fade-effects
 *
 * @param {node} children - JSX children that should be wrapped by a scroll
 * @param {string} direction - Define if the scroll should be vertical or horizontal
 * @param {boolean} disableClickDrag - Will disable drag functionality if true
 * @param {boolean} displayFadeEffect - Will display the fade effect uf true
 * @param {boolean} displayScrollbar - Will display custom scrollbar if true
 * @param {string} fadeEffectAfterBottomPosition - Top position for fade before effect
 * @param {string} fadeEffectBefeoreTopPosition - Bottom position for fade after effect
 * @param {string} displayScrollbar - Top position for fade before effect
 * @param {string} fadeEffectColor - Hexcode for the fade effect color
 * @param {string} fadeEffectLength - The length of the start/end-effect in px
 * @param {number} fadeEffectTriggerOffset - How close to the start/end should the fade effect be displayed (px)
 * @param {function} handleScroll - Add listener for scroll events
 * @param {string} scrollbarThumbColor - Color for the custom scrollbar thumb
 * @param {string} scrollbarTrackColor - Color for the custom scrollbar track
 * @param {object} scrollContentProps - Pass props like styles to the ScrollContent-element
 * @param {boolean} startScrollAtEnd - The initial scroll position will be at the end instead of at the start
 */
class ScrollWrapper extends Component {
    static propTypes = {
        children: PropTypes.node,
        direction: PropTypes.oneOf(['vertical', 'horizontal']),
        disableClickDrag: PropTypes.bool,
        displayFadeEffect: PropTypes.bool,
        displayScrollbar: PropTypes.bool,
        fadeEffectAfterBottomPosition: PropTypes.string,
        fadeEffectBeforeTopPosition: PropTypes.string,
        fadeEffectColor: PropTypes.string,
        fadeEffectLength: PropTypes.string,
        fadeEffectTriggerOffset: PropTypes.number,
        handleScroll: PropTypes.func,
        scrollContentProps: PropTypes.object,
        scrollbarThumbColor: PropTypes.string,
        scrollbarTrackColor: PropTypes.string,
        startScrollAtEnd: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        direction: 'vertical',
        disableClickDrag: false,
        displayFadeEffect: false,
        displayScrollbar: false,
        fadeEffectAfterBottomPosition: '0px',
        fadeEffectBeforeTopPosition: '0px',
        fadeEffectColor: colors.background,
        fadeEffectLength: '56px',
        fadeEffectTriggerOffset: 0,
        handleScroll: null,
        scrollContentProps: {},
        scrollbarThumbColor: colors.black,
        scrollbarTrackColor: colors.grey.three,
        startScrollAtEnd: false,
    };

    state = {
        dragging: false,
        fullyScrolled: this.props.startScrollAtEnd, // Defaults to false
        notScrolled: !this.props.startScrollAtEnd, // Defaults to true
    };

    componentDidMount() {
        const el = this.scrollRef;
        const { disableClickDrag, displayFadeEffect, startScrollAtEnd, handleScroll } = this.props;

        // Add listeners used by drag functionality
        if (!disableClickDrag) {
            el.addEventListener('mousedown', this.handleMouseDown);
        }

        // Add listeners used by fade effect functionality
        if (displayFadeEffect || handleScroll) {
            el.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', debounce(this.handleScroll, 250));
            this.handleScroll();
        }

        // Set the initial scroll position to the end instead of the begining
        if (startScrollAtEnd) {
            if (this.props.direction === 'vertical') {
                el.scrollTop = 99999;
            } else {
                el.scrollLeft = 99999;
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children && this.props.displayFadeEffect) {
            this.handleScroll();
        }
    }

    componentWillUnmount() {
        const el = this.scrollRef;

        // Clear drag events
        clearTimeout(this.touchTimeout);
        el.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);

        // Clear scroll events
        el.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    }

    handleScroll = () => {
        // This section checks how much the component is scrolled.
        // Use the values fron these calculations to display start- and end-scroll-effect
        const el = this.scrollRef;
        const { fadeEffectTriggerOffset, direction, handleScroll } = this.props;

        if (el) {
            let fullyScrolled;
            let notScrolled;
            let scrollPercentageX = 0;
            let scrollPercentageY = 0;

            const elementWidth = el.getBoundingClientRect().width;
            const elementHeight = el.getBoundingClientRect().height;
            const scrollWidth = el.scrollWidth;
            const scrollLeft = el.scrollLeft;
            const scrollHeight = el.scrollHeight;
            const scrollTop = el.scrollTop;

            if (direction === 'horizontal') {
                scrollPercentageX = scrollLeft / (scrollWidth - elementWidth);
                fullyScrolled = scrollLeft >= scrollWidth - elementWidth - fadeEffectTriggerOffset;
                notScrolled = scrollLeft <= 0;
                this.setState({
                    fullyScrolled,
                    notScrolled,
                });
            } else {
                scrollPercentageY = scrollTop / (scrollHeight - elementHeight);
                fullyScrolled = scrollTop >= scrollHeight - elementHeight - fadeEffectTriggerOffset;
                notScrolled = scrollTop <= 0;
                this.setState({
                    fullyScrolled,
                    notScrolled,
                });
            }

            if (handleScroll) {
                handleScroll({
                    element: el,
                    elementHeight,
                    elementWidth,
                    fullyScrolled,
                    notScrolled,
                    scrollHeight,
                    scrollLeft,
                    scrollPercentageX,
                    scrollPercentageY,
                    scrollTop,
                    scrollWidth,
                });
            }
        }
    };

    handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener('mouseup', this.handleMouseUp);

        // If the user makes a quick click it will work like usual
        // But if the user holds the click for more than 100ms it will enable drag
        this.touchTimeout = setTimeout(() => {
            document.addEventListener('mousemove', this.handleMouseMove);
        }, 100);
    };

    handleMouseMove = e => {
        e.preventDefault();
        this.startDrag(e);
    };

    handleMouseUp = e => {
        e.preventDefault();
        clearTimeout(this.touchTimeout);
        this.endDrag();
    };

    startDrag = e => {
        const el = this.scrollRef;
        el.scrollLeft += e.movementX * -1;
        el.scrollTop += e.movementY * -1;
        this.setState({ dragging: true });
    };

    endDrag = () => {
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);
        this.setState({ dragging: false });
    };

    render() {
        const {
            children,
            direction,
            displayFadeEffect,
            displayScrollbar,
            fadeEffectAfterBottomPosition,
            fadeEffectBeforeTopPosition,
            fadeEffectColor,
            fadeEffectLength,
            scrollbarThumbColor,
            scrollbarTrackColor,
            scrollContentProps,
            ...rest
        } = this.props;

        const { notScrolled, fullyScrolled, dragging } = this.state;

        return (
            <Wrapper position={displayFadeEffect ? 'relative' : undefined} {...rest}>
                <ScrollContent
                    alignItems="center"
                    className={dragging ? 'disable-pointer-events' : undefined}
                    direction={direction}
                    display="flex"
                    displayFadeEffect={displayFadeEffect}
                    displayScrollbar={displayScrollbar}
                    fadeEffectAfterBottomPosition={fadeEffectAfterBottomPosition}
                    fadeEffectBeforeTopPosition={fadeEffectBeforeTopPosition}
                    fadeEffectColor={fadeEffectColor}
                    fadeEffectLength={fadeEffectLength}
                    flexDirection={direction === 'vertical' ? 'column' : 'row'}
                    flexWrap="nowrap"
                    fullyScrolled={fullyScrolled}
                    notScrolled={notScrolled}
                    overflowX={direction === 'vertical' ? 'hidden' : 'auto'}
                    overflowY={direction === 'vertical' ? 'auto' : 'hidden'}
                    ref={ref => (this.scrollRef = ref)}
                    scrollbarThumbColor={scrollbarThumbColor}
                    scrollbarTrackColor={scrollbarTrackColor}
                    whiteSpace={direction === 'vertical' ? 'none' : 'nowrap'}
                    {...scrollContentProps}
                >
                    {children}
                </ScrollContent>
            </Wrapper>
        );
    }
}

export default ScrollWrapper;
