/**
 * @param  {(translation:string)=>string} t - Translation function
 * @param  {string} currency - Used for filter "price.sale_price"
 */
export default (t: (translation: string) => string, currency: string) => ({
    // key: {           // Algolia key
    //     label: '',   // Displayed in the FE
    //     option: '',  // Filter appearance option
    //     type: '',    // What type of filter is it?
    //     param: ''    // Http-param for filter/sort
    //     priority: '' // Sort order for filters
    //     sort: ''     // Sort options for values, only works for option checkbox and color
    //     type: ''     // Type of filter, used in filter container
    // },

    // Product Type
    '_product_types': {
        label: t('filter_config.product_type'),
        option: 'checkbox',
        param: 'product_type',
        priority: 1,
        sort: 'alphabetically',
        type: 'checkbox',
    },

    // Colors
    '_filter_colors': {
        label: t('filter_config.colors'),
        option: 'color',
        param: 'color',
        priority: 2,
        sort: 'alphabetically',
        type: 'checkbox',
    },

    // Materials
    '_filter_materials': {
        label: t('filter_config.materials'),
        option: 'checkbox',
        param: 'material',
        priority: 3,
        sort: 'alphabetically',
        type: 'checkbox',
    },

    // Price
    'price.sale_price': {
        label: t('filter_config.price'),
        option: 'multirange',
        param: 'price',
        priority: 4,
        type: 'range',
        unit: currency,
    },

    // Measure group - priority: 4

    // Measure - Diameter
    '_diameter_value': {
        label: t('filter_config.diameter'),
        option: 'multirange',
        param: 'diameter',
        type: 'range',
        unit: 'mm',
    },

    // Measure - Height
    '_height_value': {
        label: t('filter_config.height'),
        option: 'multirange',
        param: 'height',
        type: 'range',
        unit: 'mm',
    },

    // Measure - Volume
    '_volume_value': {
        label: t('filter_config.volume'),
        option: 'multirange',
        param: 'volume',
        type: 'range',
        unit: 'cl',
    },

    // Measure - Width
    '_width_value': {
        label: t('filter_config.width'),
        option: 'multirange',
        param: 'width',
        type: 'range',
        unit: 'mm',
    },

    // Series
    '_serie': {
        label: t('filter_config.series'),
        option: 'checkbox',
        param: 'serie',
        priority: 5,
        sort: 'alphabetically',
        type: 'checkbox',
    },

    // Sizes
    '_filter_size': {
        label: t('filter_config.sizes'),
        option: 'checkbox',
        param: 'size',
        priority: 6,
        sort: 'priority',
        type: 'checkbox',
    },

    // In Stock
    '_in_stock': {
        label: t('filter_config.in_stock'),
        option: 'switch',
        param: 'in_stock',
        priority: 7,
        type: 'checkbox',
    },
});
