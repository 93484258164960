import Loading from 'components/Loading';
import React from 'react';
import loadable from '@loadable/component';

export const templates = {
    'ecommerce_checkout': loadable(() => import('views/Page/Checkout'), { fallback: <Loading /> }),
    'customer_service_sub_page': loadable(() => import('views/Page/CustomerService'), { fallback: <Loading /> }),
    'customer_service': loadable(() => import('views/Page/CustomerService'), { fallback: <Loading /> }),
    'default': loadable(() => import('views/Page/Default'), { fallback: <Loading /> }),
    'frontpage': loadable(() => import('views/Page/FrontPage'), { fallback: <Loading /> }),
    'magazine': loadable(() => import('views/Page/Magazine'), { fallback: <Loading /> }),
    'search': loadable(() => import('views/Page/Search'), { fallback: <Loading /> }),
};
