import colors from 'config/theme/colors';

/**
 * @version 1.1
 * @param {object} buttonThemes define the button buttonThemes from design
 * @note Colors and Transitions is also to be modified to the design needs.
 *
 * Description: "outlinedBlack", "outlinedWhite", "solidBlack" and "solidWhite" etc. are static themes.
 * "outlined" and "solid" are dynamic themes, meaning they change with the site's color theme
 */

export const buttonThemes = {
    outlinedBlack: {
        borderColor: colors.black,
        borderStyle: 'solid',
        color: colors.black,
        hover: {
            backgroundColor: colors.black,
            borderColor: colors.black,
            color: colors.white,
        },
        disabledStyles: {
            color: colors.opacity.black.medium,
            borderColor: colors.opacity.black.medium,
        },
    },
    outlinedWhite: {
        borderColor: colors.white,
        borderStyle: 'solid',
        color: colors.white,
        hover: {
            backgroundColor: colors.white,
            borderColor: colors.white,
            color: colors.black,
        },
        disabledStyles: {
            color: colors.opacity.white.medium,
            borderColor: colors.opacity.white.medium,
        },
    },
    solidBlack: {
        backgroundColor: colors.black,
        borderColor: colors.black,
        borderStyle: 'solid',
        color: colors.white,
        hover: {
            backgroundColor: colors.grey.six,
            borderColor: colors.grey.six,
            color: colors.white,
        },
        disabledStyles: {
            backgroundColor: colors.grey.three,
            borderColor: colors.grey.three,
            color: colors.grey.five,
        },
    },
    solidWhite: {
        backgroundColor: colors.white,
        borderColor: colors.white,
        borderStyle: 'solid',
        color: colors.black,
        hover: {
            backgroundColor: colors.grey.one,
            borderColor: colors.grey.one,
        },
        disabledStyles: {
            backgroundColor: colors.grey.three,
            borderColor: colors.grey.three,
            color: colors.grey.five,
        },
    },
    // Dynamic button themes
    outlined: {
        borderColor: 'var(--dynamic-color-primary)',
        borderStyle: 'solid',
        color: 'var(--dynamic-color-primary)',
        hover: {
            backgroundColor: 'var(--dynamic-color-primary)',
            borderColor: 'var(--dynamic-color-primary)',
            borderStyle: 'solid',
            color: 'var(--dynamic-background-color-primary)',
        },
        disabledStyles: {
            color: colors.grey.five,
            borderColor: colors.grey.five,
        },
    },
    solid: {
        backgroundColor: 'var(--dynamic-color-primary)',
        borderColor: 'var(--dynamic-color-primary)',
        borderStyle: 'solid',
        color: 'var(--dynamic-background-color-primary)',
        hover: {
            backgroundColor: 'var(--dynamic-color-light-six)',
            borderColor: 'var(--dynamic-color-light-six)',
            color: 'var(--static-color-secondary)',
            borderStyle: 'solid',
        },
        disabledStyles: {
            backgroundColor: colors.grey.three,
            borderColor: colors.grey.three,
            color: colors.grey.five,
        },
    },
};
