/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import Arrow from 'assets/icons/Arrow';
import NewsletterCheckbox from 'components/Newsletter/NewsletterCheckbox';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { injectModels } from 'state';
import regExp from 'utils/regExp';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const Wrapper = styled('div')`
    width: 100%;
`;

const FormWrapper = styled('form')`
    position: relative;
    display: inline-block;
    width: 100%;
    // not(:placeholder-shown) ~ label Keeps label up when value is invalid
    input:focus ~ label,
    input:valid ~ label,
    input:not(:placeholder-shown) ~ label {
        // move label above the border, hence 105%
        bottom: 105%;
        font-size: 12px;
        line-height: 1;
    }
`;

const MessageRow = styled('p')`
    margin-bottom: 16px;
`;

const ErrorMessages = styled('span')`
    color: var(--static-color-error);
    margin-right: 12px;
    :before {
        content: '*';
    }
    &:last-of-type {
        margin-right: 0;
    }
`;

const SubmitButton = styled('button')``;

const EmailInput = styled('input')`
    width: 100%;
    height: 51px;
    background: transparent;
    box-shadow: none;
    border: none;
    padding-left: 12px;
    outline: 0;
    opacity: ${({ loading }) => (loading ? '0.2' : '1')};
    line-height: 1;
    :focus {
        padding-left: 16px;
    }
    ::-ms-clear {
        display: none;
    }
    ::-webkit-input-placeholder {
        opacity: 0;
    }
    ::-moz-placeholder {
        opacity: 0;
    }
    :-moz-placeholder {
        opacity: 0;
    }
    :-ms-input-placeholder {
        opacity: 0;
    }
`;

const Label = styled('label')`
    position: absolute;
    left: 24px;
    padding-right: 80px;
    transition: all ${transitions.primary};
    cursor: text;
    user-select: none;
    pointer-events: none;
    bottom: 16px;
    transform: translateY(1px);
`;

const InputWrapper = styled('div')`
    position: relative;
    display: flex;
    border-radius: 48px;
    border-width: 1px;
    border-style: solid;
    padding-left: 12px;
    width: 100%;
    height: 51px;
    background: transparent;
    justify-content: space-between;
    align-items: center;
`;

const NewsletterWrapper = styled('div')`
    margin-top: 16px;
    margin-left: 8px;
`;

class Newsletter extends Component {
    static propTypes = {
        backgroundColor: PropTypes.string,
        buttonTheme: PropTypes.string,
        checkboxErrorText: PropTypes.string,
        formErrorText: PropTypes.string,
        formSuccessText: PropTypes.string,
        heading: PropTypes.string,
        headingTag: headingTagProp,
        hoverColor: PropTypes.string,
        id: PropTypes.string.isRequired,
        inputErrorText: PropTypes.string,
        inputTextColor: PropTypes.string,
        linkColor: PropTypes.string,
        newsletter: PropTypes.shape({ subscribe: PropTypes.func }).isRequired,
        onSignUp: PropTypes.func,
        placeholder: PropTypes.string,
        privacyText: wysiwygProp,
        submitLabel: PropTypes.string,
        tag: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
    };

    static defaultProps = {
        backgroundColor: '',
        buttonTheme: 'outlinedWhite',
        checkboxErrorText: 'Please accept the privacy policy',
        formErrorText: 'Something went wrong, please try again or contact support',
        formSuccessText: 'Thank you for signing up!',
        heading: undefined,
        headingTag: 'h2',
        hoverColor: 'var(--static-color-active)',
        inputErrorText: 'Please enter correct email',
        linkColor: 'var(--default-link-color)',
        onSignUp: () => {},
        inputTextColor: '',
        placeholder: 'Enter email here',
        privacyText: ['I have read and accepted the privacy policy'],
        submitLabel: 'Sign up',
        tag: '',
        text: '',
        textColor: '',
    };

    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.checkboxInput = React.createRef();
    }

    state = {
        email: null,
        emailVerified: false,
        privacyIsChecked: false,
        signupComplete: false,
        signupFailed: false,
        errors: false,
        timeout: null,
        loading: false,
    };

    handleCheckboxChange = e => {
        this.setState({ privacyIsChecked: e.target.checked });
    };

    handleEmailChange = ({ target }) => {
        let emailVerified = false;

        if (target.value.length < 1) {
            this.setState({ errors: false });
        }

        const value = target.value.trim();
        emailVerified = regExp.validation.correctEmail.test(value);

        this.setState({ emailVerified, email: value });
    };

    handleSubmit = async () => {
        const { privacyIsChecked, emailVerified, email } = this.state;
        const { newsletter } = this.props;

        this.setState({
            errors: true,
            loading: true,
        });

        if (!privacyIsChecked || !emailVerified) {
            this.setState({ loading: false });
        }

        if (privacyIsChecked && emailVerified) {
            try {
                await newsletter.subscribe('default', {
                    email,
                });

                this.setState({ signupComplete: true }, () => {
                    this.props.onSignUp();
                });
            } catch (err) {
                this.setState({ signupFailed: true });
            }

            this.setState({
                errors: false,
                emailVerified: false,
                privacyIsChecked: false,
            });

            if (this.state.signupComplete || this.state.signupFailed) {
                this.setState({ loading: false });
            }

            if (this.emailInput.current) {
                this.emailInput.current.blur();
                this.emailInput.current.value = '';
            }

            if (this.checkboxInput.current) {
                this.checkboxInput.current.checked = false;
            }

            this.state.timeout = setTimeout(() => {
                clearTimeout(this.state.timeout);
                this.resetNewsletter();
            }, 20000);
        }
    };

    resetNewsletter = () => {
        clearTimeout(this.state.timeout);
        this.setState({
            email: null,
            signupComplete: false,
            signupFailed: false,
            timeout: null,
            loading: false,
        });
    };

    render() {
        const {
            backgroundColor,
            submitLabel,
            checkboxErrorText,
            formErrorText,
            formSuccessText,
            hoverColor,
            id,
            inputErrorText,
            inputTextColor,
            linkColor,
            placeholder,
            privacyText,
            text,
            textColor,
        } = this.props;

        const { privacyIsChecked, emailVerified, errors, signupComplete, signupFailed, loading } = this.state;

        const inputTextStyles = styleObjectToProps('NB International/16_120');

        const submitTextStyles = styleObjectToProps('NB International/12_120_7');

        const placeholderText = decodeHTMLEntities(
            signupComplete ? formSuccessText : signupFailed ? formErrorText : `${placeholder}*`
        );

        return (
            <Wrapper backgroundColor={backgroundColor} color={textColor}>
                {text && (
                    <Paragraph
                        fontKeys={['NB International/18', null, null, null, 'NB International/20']}
                        marginBottom={['32px', null, '16px', null, '48px']}
                    >
                        {text}
                    </Paragraph>
                )}
                {/* <FormWrapper
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.handleSubmit();
                    }}
                >
                    <InputWrapper borderColor={textColor}>
                        <EmailInput
                            required
                            focus={signupComplete ? 1 : 0}
                            color="var(--static-color-secondary)"
                            id={id}
                            loading={loading ? 1 : 0}
                            maxLength="75"
                            placeholder={placeholderText}
                            type="email"
                            ref={this.emailInput}
                            onChange={this.handleEmailChange}
                            {...inputTextStyles}
                        />
                        {!errors && placeholderText && (
                            <Label
                                color={inputTextColor ? inputTextColor : textColor}
                                {...inputTextStyles}
                                htmlFor={id}
                            >
                                {placeholderText}
                            </Label>
                        )}
                        <SubmitButton
                            alignItems="center"
                            border="none"
                            display="flex"
                            flex="none"
                            height="100%"
                            paddingRight="26px"
                            type="submit"
                            {...submitTextStyles}
                        >
                            {submitLabel}
                            <Arrow color={inputTextColor} marginLeft="10px" transform="translateY(1px)" />
                        </SubmitButton>
                    </InputWrapper>
                    <NewsletterWrapper>
                        <NewsletterCheckbox
                            checkboxSize="12px"
                            hoverColor={hoverColor}
                            linkColor={linkColor}
                            color={textColor}
                            privacyText={privacyText}
                            ref={this.checkboxInput}
                            handleChange={e => this.handleCheckboxChange(e)}
                        />
                    </NewsletterWrapper>
                    {errors && (
                        <ErrorWrapper {...inputTextStyles}>
                            {!emailVerified && errors && (
                                <MessageRow>
                                    <ErrorMessages>{decodeHTMLEntities(inputErrorText)}</ErrorMessages>
                                </MessageRow>
                            )}
                            {!privacyIsChecked && errors && (
                                <MessageRow>
                                    <ErrorMessages>{decodeHTMLEntities(checkboxErrorText)}</ErrorMessages>
                                </MessageRow>
                            )}
                        </ErrorWrapper>
                    )}
                </FormWrapper> */}
                {/* This is a temporary signup form used until the rule-package is implemented */}
                {/* Remove eslint disable no-unused-vars once this code is removed */}
                <FormWrapper
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.handleSubmit();
                    }}
                >
                    <InputWrapper>
                        <EmailInput
                            required
                            focus={signupComplete ? 1 : 0}
                            id={id}
                            color={!errors ? 'var(--footer-text-color)' : 'var(--footer-text-color-error)'}
                            loading={loading ? 1 : 0}
                            maxLength="75"
                            placeholder={`${placeholder}*`}
                            type="email"
                            ref={this.emailInput}
                            onChange={this.handleEmailChange}
                            {...inputTextStyles}
                        />
                        {!errors && placeholderText && (
                            <Label
                                color={signupFailed ? 'var(--footer-text-color-error)' : 'var(--footer-text-color)'}
                                htmlFor={id}
                            >
                                {placeholderText}
                            </Label>
                        )}
                        <SubmitButton
                            type="submit"
                            display={this.state.email ? 'flex' : 'none'}
                            alignItems="center"
                            border="none"
                            flex="none"
                            height="100%"
                            paddingRight="26px"
                            {...submitTextStyles}
                        >
                            {submitLabel}
                            <Arrow color={inputTextColor} marginLeft="10px" transform="translateY(1px)" />
                        </SubmitButton>
                    </InputWrapper>
                    <NewsletterWrapper>
                        {errors && (
                            <MessageRow color="var(--footer-text-color)">
                                {!emailVerified && errors && (
                                    <ErrorMessages>{decodeHTMLEntities(inputErrorText)}</ErrorMessages>
                                )}
                                {!privacyIsChecked && errors && (
                                    <ErrorMessages>{decodeHTMLEntities(checkboxErrorText)}</ErrorMessages>
                                )}
                            </MessageRow>
                        )}
                        <NewsletterCheckbox
                            checkboxSize="12px"
                            hoverColor={hoverColor}
                            labelStyling={{ color: textColor }}
                            linkColor={linkColor}
                            color={textColor}
                            privacyText={privacyText}
                            ref={this.checkboxInput}
                            handleChange={e => this.handleCheckboxChange(e)}
                        />
                    </NewsletterWrapper>
                </FormWrapper>
            </Wrapper>
        );
    }
}

export default injectModels(['newsletter', 'application'])(Newsletter);
