import ArticleCard from 'components/ArticleCard';
import ProductCard from 'components/products/ProductCard';
import PropTypes from 'prop-types';
import React from 'react';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';

const Div = styled('div')``;

const Cards = ({
    activeTab = 'products',
    articles = [],
    hasProducts = false,
    hideOverlay = () => {},
    products = [],
    ...rest
}) => (
    <Div width="100%" {...rest}>
        <Div
            display="grid"
            flexGrow="1"
            gap="24px 16px"
            gridTemplateColumns={[
                'repeat(2, minmax(0, 1fr))',
                null,
                'repeat(4, minmax(0, 1fr))',
                null,
                null,
                'repeat(3, minmax(0, 1fr))',
            ]}
            marginBottom={!hasProducts ? '48px' : null}
            width="100%"
        >
            {activeTab === 'products' &&
                products.map(product => (
                    <ProductCard
                        color="var(--dynamic-color-primary)"
                        key={`${product.sku}_${product.url}`}
                        onClick={hideOverlay}
                        {...product}
                    />
                ))}
            {activeTab === 'articles' &&
                articles.map(article => <ArticleCard key={article.id} ratio={ratios.vertical} {...article} />)}
        </Div>
    </Div>
);

Cards.propTypes = {
    activeTab: PropTypes.string,
    articles: PropTypes.array,
    hasProducts: PropTypes.bool,
    hideOverlay: PropTypes.func,
    products: PropTypes.array,
};

export default Cards;
