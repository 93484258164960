import Helmet from 'react-helmet';
import React from 'react';

const PinterestSiteVerifications = () => {
    if (!process.env.REACT_APP_PINTEREST_SITE_VERIFICATIONS) {
        return null;
    }
    const pinterestVerifications = process.env.REACT_APP_PINTEREST_SITE_VERIFICATIONS?.split(',') || [];

    return (
        <Helmet>
            {pinterestVerifications.map(key => {
                const metaContent = key.split('|')[1];

                return metaContent ? <meta key={metaContent} name="p:domain_verify" content={metaContent} /> : null;
            })}
        </Helmet>
    );
};

export default PinterestSiteVerifications;
