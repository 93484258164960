export const variantSelectorConfig = {
    'sa_size': {
        attributeValueKey: 'internalName',
        componentType: 'size',
        label: 'product_page.size',
        selectionLabel: 'product_page.choose_size',
    },
    'sa_color_detailed_display': {
        attributeValueKey: 'value',
        componentType: 'color',
        label: 'product_page.color',
        selectionLabel: 'product_page.choose_color',
    },
};
