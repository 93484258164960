import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import Breadcrumbs from 'components/Breadcrumbs';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { borderRadius } from 'config/theme/borderRadius';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Hero = ({
    author = '',
    background,
    backgroundQueries = mediaQueries.fullWidth,
    backgroundRatio = [ratios.vertical, ratios.horizontal, null, ratios.horizontalWide],
    backgroundType,
    breadcrumbs = [],
    categories = '',
    date = '',
    preamble = [],
    title = '',
}) => {
    const { t } = useTranslation();
    const infoFontKeys = 'NB International/14_120';
    const hasPreamble = preamble?.length > 0;

    return (
        <Div marginBottom="64px">
            <Breadcrumbs
                currentTitle={title}
                items={breadcrumbs}
                marginBottom={['24px', null, '48px', null, '64px']}
                marginLeft={['0px', null, '8px', null, null, '24px']}
            />
            <Div
                display="flex"
                flexDirection={['column', null, 'row']}
                marginBottom={['24px', null, '16px', null, '24px']}
            >
                <Div
                    paddingRight={['0', null, '24px', null, '48px']}
                    width={['100%', null, '50%', null, '58.33333333%']}
                >
                    <Heading
                        as="h1"
                        fontKeys={['Recife Display/32_120', null, null, null, 'Recife Display/64']}
                        marginBottom={['24px', null, '0']}
                        marginTop="0"
                        maxWidth="1000px"
                    >
                        {title}
                    </Heading>
                </Div>
                {(categories || date || author) && (
                    <Div
                        alignItems="flex-end"
                        display="flex"
                        gap="0 24px"
                        width={['100%', null, '50%', null, '41.66666667%']}
                    >
                        {date && (
                            <Div display="flex" flexDirection="column" gap="2px 0">
                                <Paragraph as="span" color="var(--dynamic-color-light-five)" fontKeys={infoFontKeys}>
                                    {t('articles.date')}
                                </Paragraph>
                                <Paragraph as="span" fontKeys={infoFontKeys}>
                                    {date}
                                </Paragraph>
                            </Div>
                        )}
                        {author && (
                            <Div display="flex" flexDirection="column" gap="2px 0">
                                <Paragraph as="span" color="var(--dynamic-color-light-five)" fontKeys={infoFontKeys}>
                                    {t('articles.written_by')}
                                </Paragraph>
                                <Paragraph as="span" fontKeys={infoFontKeys}>
                                    {author}
                                </Paragraph>
                            </Div>
                        )}
                        {categories && (
                            <Div display="flex" flexDirection="column" gap="2px 0">
                                <Paragraph as="span" color="var(--dynamic-color-light-five)" fontKeys={infoFontKeys}>
                                    {t('articles.subject')}
                                </Paragraph>
                                <Paragraph as="span" fontKeys={infoFontKeys}>
                                    {categories}
                                </Paragraph>
                            </Div>
                        )}
                    </Div>
                )}
            </Div>
            <AspectWrapper borderRadius={borderRadius} maxHeight="100vh" overflow="hidden" ratio={backgroundRatio}>
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={backgroundQueries} />
            </AspectWrapper>
            {hasPreamble && (
                <Div
                    marginTop={['48px', null, null, null, '64px']}
                    width={['100%', null, null, null, 'calc(66.66666667% - 8px)']}
                >
                    <Wysiwyg
                        data={preamble}
                        textComponent={props => (
                            <Paragraph
                                fontKeys={['Recife Display/22_140', null, null, null, 'Recife Display/32_140']}
                                {...props}
                            />
                        )}
                        tagComponents={{ url: TextLinkWysisyg }}
                    />
                </Div>
            )}
        </Div>
    );
};

Hero.propTypes = {
    author: PropTypes.string,
    background: PropTypes.shape({
        backgroundPosition: PropTypes.string,
        backgroundSize: PropTypes.string,
        url: PropTypes.string,
    }),
    backgroundQueries: PropTypes.array,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: PropTypes.string,
    breadcrumbs: PropTypes.array.isRequired,
    categories: PropTypes.string,
    date: PropTypes.string,
    preamble: PropTypes.array,
    title: PropTypes.string.isRequired,
};

export default Hero;
