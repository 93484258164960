/**
 * This is a base component of an html5 button.
 * @author Hampus Lindholm & Anton Pedersen
 * @version 1.0
 */

import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';

// Reset browser buttons styles
const ButtonElement = styled('button')`
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    overflow: visible;
    text-transform: none;
    user-select: none;
    cursor: pointer;

    &[type='button'],
    &[type='reset'],
    &[type='submit'] {
        -webkit-appearance: button;
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
    }
`;

/**
 * @param {node} children -  JSX children.
 * @param {bool} disabled - Option to disable the button.
 * @param {string} name -  Adds a name to the button.
 * @param {string|func} onClick - Adds a function that runs on click. If it is a string link will be used instead.
 * @param {string} to - Link to internal or external url. Acts like a href.
 * @param {string} [type=button] - Specifies the type e.g. button, reset and submit.
 * @param {string} value - Adds an initial value for the button.
 * @param {string} volatile - If true, the component is renderd as a link and the to-prop is false the component will only render its children.
 */

const Button = ({ children, disabled, name, onClick, to, type, value, volatile, ...rest }) => {
    // Check if onClick is a string because it can be a string or a function
    const onClickIsString = typeof onClick === 'string';

    // Check if a link exists to decide if Link or Button should be used
    const renderAsLink = to || onClickIsString;

    // Check what should be passed in as href to the link
    const linkTo = to || (onClickIsString && onClick) || '';

    return renderAsLink ? (
        <Link to={linkTo} volatile={volatile} onClick={onClick} {...rest}>
            {children}
        </Link>
    ) : (
        <ButtonElement disabled={disabled} name={name} type={type} value={value} onClick={onClick} {...rest}>
            {children}
        </ButtonElement>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    to: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    volatile: PropTypes.bool,
};

Button.defaultProps = {
    children: undefined,
    disabled: false,
    name: undefined,
    onClick: undefined,
    to: '',
    type: 'button',
    value: undefined,
    volatile: true,
};

export default Button;
