/**
 * @author Timmie Sarjanen
 * @version 1.1
 * @todo: Document this
 */

import React, { Children, PureComponent, forwardRef } from 'react';

import PropTypes from 'prop-types';
import Slider from './Slider';

class InfiniteSlider extends PureComponent {
    slideTimeout = null;

    state = {
        activeSlideIndex: null,
        isTransitionDisabled: false,
        numberOfPreSlides: 0,
        numberOfSlides: 0,
        slides: [],
    };

    static propTypes = {
        children: PropTypes.node.isRequired,
        onSlideChange: PropTypes.func,
        slidesPerView: PropTypes.number,
        transformOffset: PropTypes.number,
        transitionDuration: PropTypes.number,
    };

    static defaultProps = {
        onSlideChange: null,
        slidesPerView: 1,
        transformOffset: 0,
        transitionDuration: 300,
    };

    static getDerivedStateFromProps(props, state) {
        const originalSlides = Children.toArray(props.children);
        const numberOfSlides = originalSlides.length;

        if (numberOfSlides !== state.numberOfSlides) {
            const preSliceStart = numberOfSlides - (props.slidesPerView + 2);
            const preSlides = originalSlides.slice(preSliceStart > -1 ? preSliceStart : 0);
            const postSlides = originalSlides.slice(0, props.slidesPerView + 2);

            return {
                ...state,
                activeSlideIndex: state.activeSlideIndex === null ? preSlides.length : state.activeSlideIndex,
                numberOfPreSlides: preSlides.length,
                numberOfSlides,
                slides: [...preSlides, ...originalSlides, ...postSlides].map((child, index) => ({
                    ...child,
                    key: `.$${index}${child.key}`,
                })),
            };
        }

        return state;
    }

    get activeSlideIndex() {
        return this.state.activeSlideIndex - this.state.numberOfPreSlides;
    }

    onSlideChange = index => {
        const { numberOfSlides, numberOfPreSlides, isTransitionDisabled } = this.state;
        const { transitionDuration, onSlideChange } = this.props;

        const realIndex = index - numberOfPreSlides;
        const jumpBack = realIndex <= -2;
        const jumpForward = realIndex >= numberOfSlides;

        if (!isTransitionDisabled && (jumpBack || jumpForward)) {
            const activeSlideIndex = jumpForward ? index - numberOfSlides : jumpBack ? index + numberOfSlides : index;

            setTimeout(() => {
                this.setState({ activeSlideIndex, isTransitionDisabled: true });
            }, transitionDuration);
        }

        onSlideChange && onSlideChange(realIndex);
    };

    slideTo = index => {
        const { numberOfPreSlides } = this.state;
        const { transitionDuration } = this.props;

        if (!this.slideTimeout) {
            requestAnimationFrame(() => {
                this.setState({ activeSlideIndex: index + numberOfPreSlides, isTransitionDisabled: false });

                this.slideTimeout = setTimeout(() => {
                    this.slideTimeout = null;
                }, transitionDuration);
            });
        }
    };

    slidePrev = () => this.slideTo(this.state.activeSlideIndex - this.state.numberOfPreSlides - 1);

    slideNext = () => this.slideTo(this.state.activeSlideIndex - this.state.numberOfPreSlides + 1);

    render() {
        const { activeSlideIndex, slides, isTransitionDisabled } = this.state;
        const { children, onSlideChange, transitionDuration, ...props } = this.props;

        return (
            <Slider
                {...props}
                activeSlideIndex={activeSlideIndex}
                transitionDuration={isTransitionDisabled ? 0 : transitionDuration}
                onSlideChange={this.onSlideChange}
            >
                {slides}
            </Slider>
        );
    }
}

export default forwardRef((props, ref) => <InfiniteSlider {...props} ref={ref} />);
