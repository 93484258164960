import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const ArrowIcon = ({ color = colors.black, height = '100%', width = '6px', ...rest }) => (
    <SVG width={width} height={height} fill="none" viewBox="0 0 6 12" {...rest}>
        <Path
            clipRule="evenodd"
            d="M1.4 11.465.857 11l2.858-5L.857 1 1.4.535 5.857 6 1.4 11.465Z"
            fill={color}
            fillRule="evenodd"
        />
    </SVG>
);

ArrowIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default ArrowIcon;
