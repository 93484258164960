import React, { useEffect, useState } from 'react';

import Minus from 'assets/icons/Minus';
import Paragraph from 'components/text/Paragraph';
import Plus from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const Wrapper = styled('div', { shouldForwardProp: prop => ['isAdyenForm'].indexOf(prop) === -1 })`
    position: relative;
    ${({ isAdyenForm }) => (isAdyenForm ? `margin-bottom: 16px;` : `margin-top: 32px;`)}
`;

Wrapper.propTypes = {
    isAdyenForm: PropTypes.bool.isRequired,
};

const SelectList = styled('div', {
    shouldForwardProp: prop => ['isActive'].indexOf(prop) < 0,
})`
    width: 100%;
    color: ${colors.black};
    font-size: 16px;
    line-height: 16px;
    border: 1px solid ${({ isActive }) => (isActive ? colors.black : colors.grey.two)};
    padding: 16px;
    border-radius: 4px;
    position: relative;
`;

const Option = styled('div')`
    &:last-of-type {
        label {
            padding-bottom: 8px;
        }
    }
`;

const Options = styled('div', {
    shouldForwardProp: prop => ['isActive'].indexOf(prop) < 0,
})`
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    border-top: 1px solid ${colors.grey.two};
    margin-top: 16px;
    padding-top: 24px;
`;

const Label = styled('label')`
    display: block;
    cursor: pointer;
    padding-bottom: 24px;
`;

// Only used for payment method Adyen
const AdyenLabel = styled('label')`
    color: ${colors.grey.two};
    font-size: 16px;
    margin-bottom: 8px;
`;

const Icon = styled('div')`
    position: absolute;
    top: 24px;
    right: 24px;
    margin-top: 1px;
    transform: scale(1.01) translateY(-50%); /* Scale is fix for low resoluton displays */
`;

const Input = styled('input')`
    display: none;
    &:checked + label {
        margin: 0;
    }
`;

const Value = styled('div')`
    cursor: pointer;
`;

const ErrorMessage = styled(Paragraph)`
    color: var(--dynamic-color-error);
    position: absolute;
    top: 20px; /* Not theme because of height of text */
    right: 56px;
    margin: 0;
`;

const Select = ({
    defaultValue,
    error,
    handleChange = () => {},
    isAdyenForm = false,
    label,
    name,
    options,
    selectedValue,
    setSelectedValue,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);

    // Set selectedValue to default on mount because we want to skip the validation
    // TODO: Remove this when we stop passing a defaultValue and set select to required
    useEffect(() => {
        setSelectedValue(defaultValue);
    }, []);

    if (!options) {
        return null;
    }

    return (
        <Wrapper isAdyenForm={isAdyenForm} {...rest}>
            {isAdyenForm && <AdyenLabel>{label}</AdyenLabel>}
            <SelectList isActive={isActive} onClick={() => setIsActive(!isActive)}>
                <Value>
                    <Icon>{isActive ? <Minus /> : <Plus />}</Icon>
                    {isActive ? label : selectedValue || defaultValue || label}
                    {error && <ErrorMessage>*{error.message}</ErrorMessage>}
                </Value>
                <Options isActive={isActive}>
                    {options.map(option => (
                        <Option key={option.value}>
                            <Input
                                type="radio"
                                id={option.value}
                                name={name}
                                value={option.value}
                                checked={option.label === (selectedValue || defaultValue)}
                                onChange={() => {
                                    setSelectedValue(option.label);
                                    setIsActive(false);
                                    handleChange(option);
                                }}
                            />
                            <Label htmlFor={option.value}>{option.label}</Label>
                        </Option>
                    ))}
                </Options>
            </SelectList>
        </Wrapper>
    );
};

Select.propTypes = {
    defaultValue: PropTypes.string,
    error: PropTypes.exact({
        message: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    isAdyenForm: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.exact({
        label: PropTypes.string,
        value: PropTypes.string,
    }).isRequired,
    selectedValue: PropTypes.string,
    setSelectedValue: PropTypes.func.isRequired,
};

export default Select;
