import React, { Fragment } from 'react';

import Cookies from 'js-cookie';
import Helmet from 'react-helmet';
import { getModel } from 'state';
import { inServer } from 'config/constants';
import styled from 'libs/styled';

const TableWrapper = styled('div')`
    border: 1px solid black;
`;

const TitleRow = styled('div')`
    padding: 10px 20px;
`;

const HeadTitle = styled('h2')`
    margin: 0;
`;

const Row = styled('div')`
    padding: 10px 25px;
    margin: 0;
    border-top: 1px solid black;
    line-height: 30px;
`;

const Title = styled('h3')`
    margin: 0;
`;

const Paragraph = styled('p')`
    margin: 0;
    letter-spacing: 1px;
`;

export default () => {
    if (inServer) {
        return null;
    }

    const basketId = Cookies.get('basket_id') || 'none';
    const userAgent = navigator.userAgent;
    const {
        shop_config: { market_id: marketId, pricelist_id: priceListId },
    } = getModel('application');

    return (
        <Fragment>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <TableWrapper>
                <TitleRow>
                    <HeadTitle>{'User information'}</HeadTitle>
                </TitleRow>
                <Row>
                    <Title> {'marketId:'}</Title>
                    <Paragraph>{marketId}</Paragraph>
                </Row>
                <Row>
                    <Title>{'pricelistId: '}</Title>
                    <Paragraph>{priceListId}</Paragraph>
                </Row>
                <Row>
                    <Title>{'basketId: '}</Title>
                    <Paragraph>{basketId}</Paragraph>
                </Row>
                <Row>
                    <Title>{'userAgent: '}</Title>
                    <Paragraph>{userAgent}</Paragraph>
                </Row>
            </TableWrapper>
        </Fragment>
    );
};
