import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import ThemeButton from 'components/buttons/ThemeButton';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import WysiwygParagraph from 'components/text/WysiwygParagraph';
import { above } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 200px 0 180px;
`;

const Content = styled('div')`
    width: 83.333%;
    padding: 24px 42px;
    text-align: center;

    ${above.desktopSm} {
        width: 33.333%;
    }
`;

const Div = styled('div')``;

const HeroContent = ({
    button = {},
    heading = {},
    headingFontKeys = ['Recife Display/32_120'],
    orderText = '',
    text = '',
    ...rest
}) => {
    return (
        <Wrapper {...rest}>
            <Content>
                {orderText && (
                    <Paragraph fontKeys="NB International/16_100" marginBottom="12px">
                        {orderText}
                    </Paragraph>
                )}
                {heading && (
                    <Heading as={heading.type} fontKeys={headingFontKeys} marginBottom="24px">
                        {heading.text}
                    </Heading>
                )}
                {text && (
                    <Wysiwyg
                        data={text}
                        tagComponents={{ url: TextLinkWysisyg }}
                        textComponent={props => (
                            <WysiwygParagraph fontKeys="NB International/16_120" marginBottom="24px" {...props} />
                        )}
                    />
                )}
                <Div display="inline-block">
                    {button && (
                        <ThemeButton to={button.to} theme="solid">
                            {button.label}
                        </ThemeButton>
                    )}
                </Div>
            </Content>
        </Wrapper>
    );
};

HeroContent.propTypes = {
    animation: PropTypes.object,
    button: PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
    cssAnimation: PropTypes.bool,
    heading: PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
    }),
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    includeContentSpacing: PropTypes.bool,
    orderText: PropTypes.string,
    scrollButton: PropTypes.bool,
    text: wysiwygProp,
};

export default HeroContent;
