import DefaultLink from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import colors from 'config/theme/colors';
import { media } from 'utils/mediaqueries';
import styled from 'libs/styled';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';
import styleObjectToProps from 'utils/styleObjectToProps';

const excludeProps = ['checkboxSize', 'textMargin'];

const Label = styled('label', {
    shouldForwardProp: prop => ['hoverColor', 'invertedColor', 'linkColor'].indexOf(prop) === -1,
})`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: fit-content;

    * {
        line-height: 1 !important; // Fixes alignment issue with wysiwyg
    }

    input:checked ~ span,
    input:hover ~ span {
        background-color: var(--static-color-secondary);
        border: 1px solid var(--static-color-secondary);
        box-shadow: inset 0 0 0 2px var(--static-color-brand-primary);
    }

    a {
        color: ${({ linkColor }) => linkColor};
        ${media.hover} {
            &:hover {
                color: ${({ hoverColor }) => hoverColor};
            }
        }
    }
`;

const Text = styled('p', {
    shouldForwardProp: prop => excludeProps.indexOf(prop) === -1,
})`
    user-select: 'none';
    margin: ${({ textMargin }) => (textMargin ? textMargin : '0 0 0 8px')};
    color: ${({ color }) => color};
`;

const Button = styled('span', { shouldForwardProp: prop => excludeProps.indexOf(prop) === -1 })`
    border: 1px solid ${({ color }) => color};
    height: ${({ checkboxSize }) => checkboxSize};
    width: ${({ checkboxSize }) => checkboxSize};
    min-width: ${({ checkboxSize }) => checkboxSize};
    background: transparent;
    transition: background 0.2s linear;
    cursor: pointer;
`;

const InvisibleInput = styled('input')`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const StyledLink = styled(DefaultLink)`
    color: inherit;
    text-decoration: none;
    position: relative;

    &::after {
        height: 1px;
        width: 100%;
        background: ${colors.background};
        position: absolute;
        display: block;
        content: '';
        left: 0;
        bottom: 0;
    }

    @keyframes link {
        0% {
            width: 100%;
            left: 0%;
        }
        25% {
            width: 0%;
            left: 100%;
        }
        50% {
            width: 0%;
            left: 0%;
        }
        75% {
            width: 0%;
            left: 0%;
        }
        100% {
            width: 100%;
            left: 0%;
        }
    }

    &::after {
        transform: scaleX(1);
    }

    &:hover::after {
        animation-duration: 0.6s;
        will-change: transform;
        animation-name: link;
    }
`;

const WysiwygUrl = ({ attrs, children }) => {
    return <StyledLink to={attrs['data-url']}>{children} </StyledLink>;
};

WysiwygUrl.propTypes = {
    attrs: PropTypes.shape({
        'data-url': PropTypes.string,
    }).isRequired,
    children: PropTypes.node.isRequired,
};

const NewsletterCheckbox = ({
    forwardedRef,
    checkboxSize = '14px',
    checked = false,
    color = colors.black,
    handleChange = () => {},
    hoverColor = 'var(--static-color-active)',
    invertedColor = colors.white,
    labelStyling = {},
    linkColor = 'var(--default-link-color)',

    privacyText = '',
    ...rest
}) => {
    const textStyles = styleObjectToProps(['NB International/12_120_4', null, null, null, 'NB International/16_100']);

    return (
        <Label
            hoverColor={hoverColor}
            invertedColor={invertedColor}
            linkColor={linkColor}
            onChange={e => handleChange(e)}
            {...labelStyling}
        >
            <InvisibleInput ref={forwardedRef} type="checkbox" value={privacyText} defaultChecked={checked} {...rest} />
            <Button color={color} checkboxSize={checkboxSize} borderRadius={`${parseInt(checkboxSize, 10) / 2}px`} />
            <Wysiwyg
                data={privacyText}
                tagComponents={{ url: TextLinkWysisyg }}
                textComponent={props => <Text color={color} m={0} {...props} {...textStyles} />}
            />
        </Label>
    );
};

NewsletterCheckbox.propTypes = {
    checkboxSize: PropTypes.string,
    checked: PropTypes.bool,
    color: PropTypes.string,
    forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    handleChange: PropTypes.func,
    hoverColor: PropTypes.string,
    invertedColor: PropTypes.string,
    labelStyling: PropTypes.object,
    linkColor: PropTypes.string,

    privacyText: wysiwygProp,
};

export default React.forwardRef((props, ref) => <NewsletterCheckbox forwardedRef={ref} {...props} />);
