import textStylesConfig from 'config/theme/textStyles';

type Styles = string | string[];

interface StyleProps {
    [key: string]: Styles;
}

const warn = (styleKey: string) => console.warn(`Text style "${styleKey}" not defined.`);
/**
 * Transforms object to responsive style props
 * @param {string | (string | null)[]} styles - Style key/s
 * @param {string} styleConfig - Object with css values
 * @returns {object} object with responsive style props
 */
const styleObjectToProps = (styleKeys: Styles, styleConfig = textStylesConfig): StyleProps => {
    if (!styleKeys || !styleConfig) {
        return {};
    }

    // Creates object to be used as the completed styling
    let cssProps = {};

    // If styling is a string we just pick the corresponding style from TextConfig else we want to create responsive styling
    if (typeof styleKeys === 'string') {
        if (styleConfig[styleKeys]) {
            cssProps = styleConfig[styleKeys];
        } else {
            warn(styleKeys);
        }
    } else {
        cssProps = styleKeys.reduce((acc, style) => {
            // If current size is null or styleKeys doesn't contain style we want to add null to all the css attributes
            // Example: { fontWeight: [500, null], fontSize: ['10rem', null] }
            if (style === null || !styleConfig[style]) {
                Object.keys(acc).forEach(cssAttribute => {
                    acc[`${cssAttribute}`] = [...acc[`${cssAttribute}`], null];
                });
                // Otherwise we create responsive arrays for each css attribute
            } else {
                // Pick the corresponding style from TextConfig
                const currentTextStyle = styleConfig[style] || {};

                // Make styling object be an array
                const currentStyleAsArray = Object.keys(currentTextStyle);

                currentStyleAsArray.forEach(cssAttribute => {
                    // If cssAttribute already exists in our acc we just want to push the new attribute to the array
                    // Example: color: ['red', 'blue']
                    if (!(cssAttribute in acc)) {
                        acc[`${cssAttribute}`] = [];
                    }

                    acc[`${cssAttribute}`] = [...acc[`${cssAttribute}`], currentTextStyle[cssAttribute]];
                });
            }

            if (style && !styleConfig[style]) {
                warn(style);
            }

            return acc;
        }, {});
    }

    return cssProps;
};

export default styleObjectToProps;
