import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const CloseIcon = ({ color = colors.black, height = '100%', width = '12px', ...rest }) => (
    <SVG width={width} height={height} viewBox="0 0 12 12" fill="none" {...rest}>
        <Path
            clipRule="evenodd"
            d="M6 5.116 11.058.058l.883.884L6.883 6l5.058 5.058-.883.884L6 6.884.942 11.942l-.884-.884L5.116 6 .058.942.942.058 6 5.116Z"
            fillRule="evenodd"
            fill={color}
        />
    </SVG>
);

CloseIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default CloseIcon;
