import { ColorThemes, themes } from 'config/theme/themes';

import { inServer } from 'config/constants';

const cssVariables = [
    { variable: '--dynamic-color-primary', property: 'primaryColor' },
    { variable: '--dynamic-color-secondary', property: 'secondaryColor' },
    { variable: '--dynamic-color-error', property: 'errorColor' },
    { variable: '--dynamic-color-active', property: 'activeColor' },
];

export const changeSiteColorTheme = (colorTheme: ColorThemes = 'default') => {
    const theme = themes[colorTheme] || themes['default'];
    const root = !inServer ? document.documentElement : null;

    if (root) {
        cssVariables.forEach(({ variable, property }) => {
            root.style.setProperty(variable, theme[property]);
        });
    }
};
