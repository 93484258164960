import { getSortedFilters, getTransformedFilters } from 'utils/dataTransformers/filters';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import HighlightedFilter from 'components/Filter/HighlightedFilter';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollWrapper from 'components/scrollComponents/ScrollWrapper';
import contentSpacing from 'config/theme/contentSpacing';
import styled from 'libs/styled';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin: 0 auto;
    max-width: 684px;
`;

const HighlightedFilters = ({ handleChange = () => {}, filters = [], ...rest }) => {
    const { t } = useTranslation();

    const transformedFilters = getTransformedFilters(filters, t);
    const sortedFilters = getSortedFilters(transformedFilters, 'priority');
    const allowedFilters = ['checkbox', 'color'];

    return (
        <>
            <Below
                breakpoint="desktopSm"
                render={() => {
                    return (
                        <ScrollWrapper
                            displayFadeEffect
                            direction="horizontal"
                            scrollContentProps={{ gap: '8px' }}
                            marginLeft={contentSpacing}
                            {...rest}
                        >
                            {sortedFilters.map(filter => {
                                if (allowedFilters.includes(filter.option)) {
                                    return (
                                        <HighlightedFilter
                                            filters={filter.values}
                                            handleChange={handleChange.filter.value}
                                            parentFilterKey={filter.key}
                                            sortBy={filter.sort}
                                        />
                                    );
                                }

                                return null;
                            })}
                        </ScrollWrapper>
                    );
                }}
            />
            <Above
                breakpoint="desktopSm"
                render={() => {
                    return (
                        <Wrapper {...rest}>
                            {sortedFilters.map(filter => {
                                if (allowedFilters.includes(filter.option)) {
                                    return (
                                        <HighlightedFilter
                                            filters={filter.values}
                                            handleChange={handleChange.filter.value}
                                            parentFilterKey={filter.key}
                                            sortBy={filter.sort}
                                        />
                                    );
                                }

                                return null;
                            })}
                        </Wrapper>
                    );
                }}
            />
        </>
    );
};

HighlightedFilters.propTypes = {
    filters: PropTypes.array,
    handleChange: PropTypes.func,
};

export default HighlightedFilters;
