interface SubColors {
    one?: string;
    two?: string;
    three?: string;
    four?: string;
    five?: string;
    six?: string;
    seven?: string;
    eight?: string;
    nine?: string;
    ten?: string;
}

interface Opacity {
    black: {
        low: string;
        medium: string;
        high: string;
        higher: string;
    };
    white: {
        low: string;
        medium: string;
        high: string;
        higher: string;
    };
}

interface Light {
    beige: string;
    green: string;
    grey: string;
}

interface Colors {
    background: string;
    beige: string;
    black: string;
    error: string;
    green: SubColors;
    grey: SubColors;
    light: Light;
    opacity: Opacity;
    placeholder: string;
    success: string;
    white: string;
}

/**
 * Add new colors from design to the palette object
 * Add color name into to ColorType
 * Customize as needed
 */

const palette: {
    beige: string[];
    black: string[];
    green: string[];
    grey: string[];
    red: string[];
    white: string[];
} = {
    beige: ['#DBD6CB', '#F2EFE8'],
    black: ['#000000', 'rgba(0,0,0,0.9)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.3)', 'rgba(0,0,0,0.1)'], // Basic Colors Black, Basic Colors Black 60%, Basic Colors Black 30%, Basic Colors Black 10%
    green: ['#606141', '#ABAC8B', '#4B6F00'],
    grey: ['#F3F3F3', '#D0D0D0', '#C0C0C0', '#969696', '#595959', '#3C3C3C', '#F9F9F9', '#F6F6F6'], // Grey 01, Grey 02, Grey 03, Grey 04, Grey 05,  Grey 06, Grey 07, Placeholder Color
    red: ['#D25108'],

    white: [
        '#FFFFFF',
        'rgba(255,255,255,0.9)',
        'rgba(255,255,255,0.5)',
        'rgba(255,255,255,0.3)',
        'rgba(255,255,255,0.1)',
    ], // Basic Colors White, Basic Colors White 80%, Basic Colors White 60%
};

const colors: Colors = {
    background: palette.white[0],
    beige: palette.beige[0],
    black: palette.black[0],
    green: {
        one: palette.green[0],
        two: palette.green[2],
    },
    grey: {
        one: palette.grey[0],
        two: palette.grey[1],
        three: palette.grey[2],
        four: palette.grey[3],
        five: palette.grey[4],
        six: palette.grey[5],
        seven: palette.grey[6],
    },
    opacity: {
        black: {
            low: palette.black[1],
            medium: palette.black[2],
            high: palette.black[3],
            higher: palette.black[4],
        },

        white: {
            low: palette.white[1],
            medium: palette.white[2],
            high: palette.white[3],
            higher: palette.white[4],
        },
    },
    placeholder: palette.grey[7],
    error: palette.red[0],
    success: palette.black[0],
    light: {
        green: palette.green[1],
        beige: palette.beige[1],
        grey: palette.grey[6],
    },
    white: palette.white[0],
};

export default colors;
