/**
 * @param  {(translation:string)=>string} t - Translation function
 */

export default (t: (translation: string) => string) => ({
    //popularity_desc: t('filter_config.popular'),
    'default': t('filter_config.relevance'),
    'newest_desc': t('filter_config.news'),
    'price_asc': t('filter_config.price_low-high'),
    'price_desc': t('filter_config.price_high-low'),
});
