import { above, media } from 'utils/mediaqueries';

import Link from 'components/base/Link';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import Paragraph from 'components/text/Paragraph';
import React from 'react';
import SagaformLogo from 'assets/icons/logos/SagaformLogo';
import TextLink from 'components/text/TextLink';
import styled from 'libs/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    color: var(--dynamic-color-primary);
    margin-top: 16px;
    margin-bottom: 48px;

    ${above.tabletSm} {
        margin-top: 24px;
        align-items: center;
    }

    ${above.desktopSm} {
        margin-bottom: 64px;
    }
`;

const BackToShop = styled(TextLink)`
    text-transform: uppercase;
    letter-spacing: 0.07em;
    display: block;

    ${media.hover} {
        :hover {
            text-decoration: none;
        }
    }

    ::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--dynamic-background-color-secondary);
        margin-top: 6px;
    }
`;

const CheckoutHeader = () => {
    const { t } = useTranslation();
    const pageTemplate = useSelector(state => state.page.template);
    const goBackLink = useSelector(state => state.header.data.checkoutLink || {});
    const checkoutLink = useSelector(state => state.application.shop_config.checkout_uri || {});

    // Success page has its own header
    if (pageTemplate === 'ecommerce_checkout_success') {
        return null;
    }

    return (
        <MaxWidthWrapper includeContentSpacing as="header">
            <Wrapper>
                <Link to="/">
                    <SagaformLogo color="currentColor" width="73px" />
                </Link>
                {checkoutLink && (
                    <Paragraph
                        display={['none', null, null, null, 'block']}
                        fontKeys="NB International/12_120_7"
                        style={{ textTransform: 'uppercase' }}
                    >
                        {t('checkout_basket.checkout')}
                    </Paragraph>
                )}
                {goBackLink?.label && goBackLink?.to && (
                    <BackToShop color="currentColor" to={goBackLink.to} size="sm">
                        {goBackLink.label}
                    </BackToShop>
                )}
            </Wrapper>
        </MaxWidthWrapper>
    );
};

export default CheckoutHeader;
