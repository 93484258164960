import React, { useEffect } from 'react';

import AddedToBasket from 'components/AddedToBasket';
import contentSpacing from 'config/theme/contentSpacing';
import { inServer } from 'config/constants';
import styled from 'libs/styled';
import useHeaderHeights from 'hooks/useHeaderHeights';
import { useSelector } from 'react-redux';
import zIndex from 'config/theme/z-index';

const TransitionElement = styled('div')``;

const AddedToBasketPopup = () => {
    const latestAddedProduct = useSelector(state => state.latestAddedProduct);
    const overlay = useSelector(state => state.overlay);
    const popups = useSelector(state => state.popups);
    const headerHeights = useHeaderHeights();

    const openBasket = () => {
        const openOverlay = overlay.current;

        if (openOverlay !== 'basketOverlay') {
            overlay.show('basketOverlay', { zIndex: zIndex.basket });
        }
    };

    const closePopup = () => {
        popups.hide('addedToBasketPopup');
    };

    const startTimer = () => {
        if (!inServer && latestAddedProduct.product !== null) {
            window.timer = setTimeout(() => {
                closePopup();
            }, 3000);
        }
    };

    const stopTimer = () => {
        if (!inServer) {
            clearTimeout(window.timer);
        }
    };

    useEffect(() => {
        if (latestAddedProduct) {
            startTimer();
        }
        return () => {
            if (!inServer) {
                clearTimeout(window.timer);
            }
        };
    }, [latestAddedProduct]);

    return (
        <TransitionElement
            className="slide-in right"
            maxWidth="500px"
            overflow="auto"
            position="fixed"
            right={[`${contentSpacing[0]} !important`, null, `${contentSpacing[2]} !important`]}
            top={[
                `${headerHeights.mobileGapAndBanner}px !important`,
                null,
                null,
                null,
                `${headerHeights.desktopGapAndBanner}px !important`,
            ]}
            width={[`calc(100% - ${contentSpacing[0]} * 2)`, null, `calc(100% - ${contentSpacing[2]} * 2)`]}
            onMouseEnter={() => stopTimer()}
            onMouseLeave={() => startTimer()}
        >
            <AddedToBasket
                closePopup={() => closePopup()}
                openBasket={() => openBasket()}
                product={latestAddedProduct.product}
            />
        </TransitionElement>
    );
};

export default AddedToBasketPopup;
