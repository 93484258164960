import { CLOSE_MENU, HIDE_HEADER, OPEN_MENU, RESET_MENU, SET_ACTIVE_MENU, SHOW_HEADER } from './constants';

export const setActiveMenu = activeMenu => dispatch => {
    dispatch({
        type: SET_ACTIVE_MENU,

        activeMenu,
    });
};

export const resetMenu = () => dispatch => {
    dispatch({
        type: RESET_MENU,
    });
};

export const openMenu = () => dispatch => {
    dispatch({
        type: OPEN_MENU,
    });
};

export const closeMenu = () => dispatch => {
    dispatch({
        type: CLOSE_MENU,
    });
};

export const showHeader = () => dispatch => {
    dispatch({
        type: SHOW_HEADER,
    });
};

export const hideHeader = () => dispatch => {
    dispatch({
        type: HIDE_HEADER,
    });
};
