import React, { useCallback, useEffect, useMemo } from 'react';
import { desktopHeaderHeight, mobileHeaderHeight } from 'hooks/useHeaderHeights';
import { useDispatch, useSelector } from 'react-redux';

import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import PropTypes from 'prop-types';
import { above } from 'utils/mediaqueries';
import { closeMenu } from 'state/models/Header/actions';
import colors from 'config/theme/colors';
import hexToRGBA from 'utils/hexToRGBA';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';
import { useLocation } from 'react-router';
import usePrevious from 'hooks/usePrevious';
import zIndex from 'config/theme/z-index';
import zoomOutCursor from 'assets/icons/cursors/zoom-out.svg';

const Wrapper = styled('nav', {
    shouldForwardProp: prop => ['clientHeight', 'menuIsOpen', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: fixed;
    top: 0;
    width: 100vw;
    max-width: 320px;
    height: ${({ clientHeight }) => clientHeight};
    z-index: ${zIndex.menuMobile + 3};
    transition: max-width ${({ totalAnimationDuration }) => totalAnimationDuration}ms ease-out,
        transform ${({ totalAnimationDuration }) => totalAnimationDuration}ms ease-out,
        height ${({ totalAnimationDuration }) => totalAnimationDuration}ms ease-out;
    transform: translateX(${({ menuIsOpen }) => (menuIsOpen ? '0' : '-100%')});

    // Used to prevent a height 100vh bug on android phones
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: var(--dynamic-color-brand-secondary-light);
    }

    ${above.desktopMd} {
        top: ${desktopHeaderHeight}px;
        left: 24px;
        width: calc(100% - 48px);
        max-width: none;
        height: ${({ menuIsOpen }) => (menuIsOpen ? '100vh' : '0')};
        z-index: ${zIndex.menuDesktop};
        transform: none;
        overflow: hidden;
    }
`;

const BackDrop = styled('div', {
    shouldForwardProp: prop => ['menuIsOpen', 'totalAnimationDuration'].indexOf(prop) === -1,
})`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background: ${hexToRGBA(colors.green.one, 0.4)}; // CSS variables is not working here
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(16px);
    visibility: ${({ menuIsOpen }) => (menuIsOpen ? 'visible' : 'hidden')};
    opacity: ${({ menuIsOpen }) => (menuIsOpen ? 1 : 0)};
    transition: all ${({ totalAnimationDuration }) => totalAnimationDuration}ms ease-out;
    z-index: ${zIndex.menuMobile + 1};

    ${above.desktopMd} {
        z-index: ${zIndex.menuDesktop - 2};
    }
`;

const CloseMenuArea = styled('div', { shouldForwardProp: prop => ['clientHeight', 'menuIsOpen'].indexOf(prop) === -1 })`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${({ clientHeight }) => `calc(${clientHeight} + ${mobileHeaderHeight}px)`};
    z-index: ${zIndex.menuMobile + 2};
    visibility: ${({ menuIsOpen }) => (menuIsOpen ? 'visible' : 'hidden')};
    cursor: url('${zoomOutCursor}') 40 40, pointer;

    ${above.desktopMd} {
        height: ${({ clientHeight }) => `calc(${clientHeight} + ${desktopHeaderHeight}px)`};
        z-index: ${zIndex.menuDesktop - 1};
    }
`;

const Menu = ({ totalAnimationDuration = 800 }) => {
    const menuIsOpen = useSelector(state => state.header.state.menuIsOpen);
    const hideOverlay = useSelector(state => state.overlay.hide);
    const clientHeight = useClientHeight();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleCloseMenu = useCallback(() => {
        dispatch(closeMenu());
    }, [dispatch]);

    //Sets previous location-history
    const prevPathname = usePrevious(location.pathname);

    //Checks if the new targeted location matches the old one. If it doesn't, it closes the menu.
    useEffect(() => {
        if (location.pathname !== prevPathname) {
            handleCloseMenu();
        }
    }, [location]);

    useEffect(() => {
        if (menuIsOpen) {
            hideOverlay();
        }
    }, [menuIsOpen]);

    return useMemo(
        () => (
            <>
                <Wrapper
                    clientHeight={clientHeight}
                    menuIsOpen={menuIsOpen}
                    totalAnimationDuration={totalAnimationDuration}
                >
                    <Below
                        breakpoint="desktopMd"
                        render={() => (
                            <MenuMobile closeMenu={handleCloseMenu} totalAnimationDuration={totalAnimationDuration} />
                        )}
                    />
                    <Above
                        breakpoint="desktopMd"
                        render={() => (
                            <MenuDesktop closeMenu={handleCloseMenu} totalAnimationDuration={totalAnimationDuration} />
                        )}
                    />
                    <CloseMenuArea clientHeight={clientHeight} menuIsOpen={menuIsOpen} onClick={handleCloseMenu} />
                </Wrapper>
                <BackDrop
                    height={clientHeight}
                    menuIsOpen={menuIsOpen}
                    totalAnimationDuration={totalAnimationDuration}
                />
            </>
        ),
        [menuIsOpen, clientHeight, handleCloseMenu, totalAnimationDuration]
    );
};

Menu.propTypes = {
    totalAnimationDuration: PropTypes.number,
};

export default Menu;
