import Path from 'assets/icons/base/Path';
import PropTypes from 'prop-types';
import React from 'react';
import Rect from './base/Rect';
import SVG from 'assets/icons/base/SVG';
import colors from 'config/theme/colors';

const Info = ({ color = colors.black, height = '100%', width = '24px', ...rest }) => (
    <SVG fill="none" height={height} viewBox="0 0 24 24" width={width} {...rest}>
        <Path
            d="M11.884 8.284c.624 0 1.104-.512 1.104-1.104 0-.624-.48-1.104-1.104-1.104-.592 0-1.104.48-1.104 1.104 0 .592.512 1.104 1.104 1.104Zm-1.68 9.216h3.344l.128-.128v-.608l-.128-.128h-.24a.601.601 0 0 1-.592-.592v-5.12l.128-1.2-.128-.128h-.432l-2.096.784-.112.144v.368l.144.144h.224c.32 0 .592.24.592.576v4.432c0 .32-.272.592-.592.592h-.24l-.128.128v.608l.128.128Z"
            fill={color}
        />
        <Rect x="1" y="1" width="22px" height="22px" rx="11" stroke={color} stroke-width="2" />
    </SVG>
);

Info.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Info;
