// @todo: Timmie document this
// @todo: Investigate if we want this as a custom hook instead
import { Component, createElement } from 'react';

import PropTypes from 'prop-types';
import { capitalize } from 'utils/string';

export default class Interactable extends Component {
    static getX = event => (event.changedTouches ? event.changedTouches[0].clientX : event.clientX);
    static getY = event => (event.changedTouches ? event.changedTouches[0].clientY : event.clientY);

    isSwiping = false;
    touchStartX = null;
    touchStartY = null;

    static propTypes = {
        children: PropTypes.node,
        is: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
        onSwipe: PropTypes.func,
        onSwipeDown: PropTypes.func,
        onSwipeEnd: PropTypes.func,
        onSwipeLeft: PropTypes.func,
        onSwipeRight: PropTypes.func,
        onSwipeUp: PropTypes.func,
        onTap: PropTypes.func,
    };

    static defaultProps = {
        children: null,
        is: 'div',
        onSwipe: null,
        onSwipeDown: null,
        onSwipeEnd: null,
        onSwipeLeft: null,
        onSwipeRight: null,
        onSwipeUp: null,
        onTap: null,
    };

    componentDidMount() {
        // @todo: We probably want this check in shared utils folder
        let supportsPassiveEvents = false;

        try {
            // eslint-disable-next-line no-unused-vars
            const opts = Object.defineProperty({}, 'passive', {
                get() {
                    supportsPassiveEvents = true;
                    return true;
                },
            });

            window.addEventListener('test', null, opts);
            window.removeEventListener('test', null, opts);
        } catch (e) {
            // noop();
        }

        // IE does not support an object as the last argument
        window.addEventListener('touchmove', this.preventDefault, supportsPassiveEvents ? { passive: false } : false);
    }

    componentWillUnmount() {
        window.removeEventListener('touchmove', this.preventDefault);
    }

    preventDefault = event => {
        if (event.touches.length === 1 && this.isSwiping) {
            event.cancelable ? event.preventDefault() : this.reset();
        }
    };

    reset() {
        this.isSwiping = false;
        this.touchStartX = null;
        this.touchStartY = null;
    }

    getEventData = event => {
        const x = Interactable.getX(event);
        const y = Interactable.getY(event);
        const diffX = x - this.touchStartX;
        const diffY = y - this.touchStartY;
        const absX = Math.abs(diffX);
        const absY = Math.abs(diffY);
        const direction = absX > absY ? (diffX < 0 ? 'left' : 'right') : diffY > 0 ? 'up' : 'down';

        return {
            x,
            y,
            absX,
            absY,
            diffX,
            diffY,
            direction,
            event,
            isSwiping: this.isSwiping,
            touchStartX: this.touchStartX,
            touchStartY: this.touchStartY,
        };
    };

    // @todo: For some reason this event doesn't always get executed.
    //        Thats why we need to check in touch move/end that touchStartX is not null.
    onTouchStart = event => {
        // Pinch to zoom
        if ((event.changedTouches || []).length > 1) {
            return null;
        }

        this.touchStartX = Interactable.getX(event);
        this.touchStartY = Interactable.getY(event);
    };

    onTouchMove = event => {
        if (this.touchStartX !== null && this.touchStartY !== null) {
            if ((event.changedTouches || []).length > 1) {
                this.reset();
            }

            const data = this.getEventData(event);

            if (!this.isSwiping) {
                if (data.absY > 10 * window.devicePixelRatio) {
                    this.reset();
                } else if (data.absX > 5 * window.devicePixelRatio) {
                    const onSwipeDirection = this.props[`onSwipe${capitalize(data.direction)}`];

                    this.isSwiping = true;
                    onSwipeDirection && onSwipeDirection(data);
                }
            } else {
                const { onSwipe } = this.props;
                onSwipe && onSwipe(data);
            }
        }
    };

    onTouchEnd = event => {
        const { onSwipeEnd, onTap } = this.props;
        const data = this.getEventData(event);

        if (this.touchStartX !== null && this.touchStartY !== null) {
            !this.isSwiping && onTap && onTap(data);

            this.isSwiping = false;

            onSwipeEnd && onSwipeEnd(data);
        }

        this.reset();
    };

    render() {
        const {
            is,
            onSwipe,
            onSwipeDown,
            onSwipeEnd,
            onSwipeLeft,
            onSwipeRight,
            onSwipeUp,
            onTap,
            ...props
        } = this.props;

        return createElement(is, {
            onTouchStart: this.onTouchStart,
            onTouchMove: this.onTouchMove,
            onTouchEnd: this.onTouchEnd,
            onMouseDown: this.onTouchStart,
            onMouseMove: this.onTouchMove,
            onMouseUp: this.onTouchEnd,
            onMouseLeave: this.onTouchEnd,
            ...props,
        });
    }
}
