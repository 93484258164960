import React, { Fragment, useMemo, useState } from 'react';

import CmsModules from 'libs/wordpress/content/CmsModules';
import FilterContainer from 'containers/FilterContainer';
import Hero from 'components/pages/category/Hero';
import ProductListing from 'components/products/ProductListing';
import PropTypes from 'prop-types';
import getFilterConfig from 'config/filters';
import getSortConfig from 'config/sorts';
import styled from 'libs/styled';
import useCalculatePagesize from 'hooks/useCalculatePagesize';
import useSaveResponse from 'hooks/useSaveResponse';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const ListingWrapper = styled('div')`
    margin-bottom: 80px;
`;

const Category = ({ data }) => {
    const { t } = useTranslation();
    const marketId = useSelector(state => state.application.shop_config.market_id);
    const currency = useSelector(state => state.application.shop_config.currency);

    const {
        breadcrumbs,
        children,
        hero,
        permalink,
        page_content: pageContent,
        centra_relationship: categoryId,
        children: rawSubCategories,
        siblings: rawSiblingCategories,
        ancestors: parentCategories,
    } = data;

    const [activePage, setActivePage] = useState(null);
    const pageSize = useCalculatePagesize();

    // Save response from container
    const [savedResponse, saveResponse] = useSaveResponse();

    // Setup logic for sub-category links
    const subCategories = rawSubCategories?.map(cat => ({
        title: cat.title,
        to: cat.permalink,
    }));

    const siblingCategories = rawSiblingCategories?.map(cat => ({
        title: cat.title,
        to: cat.permalink,
        current: cat.permalink === permalink,
    }));

    const mainCategory = {
        title: t('category_page.see_all'),
        to: subCategories.length > 0 ? permalink : parentCategories[parentCategories?.length - 1]?.permalink,
        current: subCategories.length > 0,
    };

    const subLinks = subCategories.length > 0 ? subCategories : siblingCategories;
    const subCategoryItems = [mainCategory, ...subLinks];

    const filterConfig = getFilterConfig(t, currency);
    const sortConfig = getSortConfig(t);

    return useMemo(
        () => (
            <Fragment>
                <Hero
                    activePage={activePage}
                    breadcrumbs={breadcrumbs}
                    heroDescription={hero?.paragraph}
                    heroTitle={hero?.heading}
                    subCategoryItems={subCategoryItems}
                />
                <ListingWrapper>
                    <FilterContainer
                        categories={[parseInt(categoryId, 10)]}
                        config={filterConfig}
                        marketId={marketId}
                        pageSize={pageSize}
                        permalink={permalink}
                        sorts={sortConfig}
                        t={t}
                        render={({ response }) => {
                            saveResponse(response);
                            setActivePage(savedResponse.current.page);

                            return (
                                <ProductListing
                                    response={savedResponse.current}
                                    noResultText={t('category_page.no_products_found')}
                                />
                            );
                        }}
                    />
                </ListingWrapper>
                {pageContent && <CmsModules isProductCategory data={pageContent} />}
            </Fragment>
        ),

        [breadcrumbs, categoryId, children, hero, pageContent, permalink, savedResponse, subCategoryItems]
    );
};

// CMS data
Category.propTypes = {
    data: PropTypes.any.isRequired,
};

export default Category;
