/* eslint-disable camelcase */
// import { useSelector } from 'react-redux';

// None of this is used in this project.
// Saved it in case it should start being used in the future.
const useCalculatePagesize = () => {
    // const { application, page, products } = useSelector(store => store);
    // // Content
    // const defaultContent = application?.config?.options?.category_pages?.product_grid?.grid_content;
    // const categoryContent = page?.data?.product_grid?.product_grid?.grid_content;

    // // Pagesizes
    // const defaultPageSize = parseInt(
    //     application?.config?.options?.category_pages?.product_grid?.settings?.products_per_page,
    //     10
    // );
    // const categoryPageSize = parseInt(page?.data?.product_grid?.product_grid?.settings?.products_per_page, 10);

    // Fallback value for pageSize
    const calculatedPageSize = 40;

    // // See which page size to use
    // const pageSize = categoryPageSize || defaultPageSize || calculatedPageSize;

    // // check set mode
    // const remove = products.setProductsMode === 'replace';

    // // Remove two from pageSize if content exists
    // return categoryContent || defaultContent ? pageSize - (remove ? 2 : 0) : pageSize;

    return calculatedPageSize;
};

export default useCalculatePagesize;
